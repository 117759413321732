import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import { Tag } from '../../models/tags';

import { addTagToCategory, fetchTagsForCategory, getTags, getTagsLoading, updateTag } from '../../store/tags';

import { useBoolean } from '../../utils/hooks';

import BackToPage from '../../components/BackToPage';
import Drawer from '../../components/FlexDrawer';
import DrawerFooter from '../../components/FlexDrawer/Footer';
import DrawerHeader from '../../components/FlexDrawer/Header';
import { Input } from '../../components/Forms';
import VfSpinner from '../../components/Spinner';
import { VfButton, VfChips, VfDivider, VfSwitch } from '../../components/vfDesign';
import styles from './TagEditor.module.scss';

export const TagCategoryEditor: FC<{ category: string }> = ({ category }) => {
  const dispatch = useDispatch();
  const tagCategories = useSelector(getTags);
  const isLoading = useSelector(getTagsLoading);
  const [isEditDrawerOpen, openEditDrawer, closeEditDrawer] = useBoolean(false);
  const [tagToEdit, setTagToEdit] = useState<Tag | null>(null);

  const { name, id, tags } = tagCategories.find((cat) => cat.id === category) ?? {};

  useEffect(() => {
    dispatch(fetchTagsForCategory({ category }));
  }, [dispatch, category]);

  if (isLoading) {
    return (
      <div className="d-flex flex-fill justify-content-center align-items-center mb-2">
        <VfSpinner />
      </div>
    );
  }

  if (!id || !tags) {
    return (
      <>
        <BackToPage link="/tags" title="tags" />
        <div className="vf-container">
          <h1 className={styles.headline}>Create & Edit Tags error</h1>
          <p>An error occured while loading tags.</p>
        </div>
      </>
    );
  }

  const onSubmit = (values: any): void => {
    if (tagToEdit) {
      dispatch(
        updateTag({
          category,
          id: tagToEdit?.id,
          name: values.name,
          active: values.active,
        }),
      );
      setTagToEdit(null);
    } else {
      dispatch(addTagToCategory({ category, tag: values.name }));
    }
    closeEditDrawer();
  };

  const handleAddTag = () => {
    setTagToEdit(null);
    openEditDrawer();
  };
  const handleEditTag = (tag: any) => {
    setTagToEdit(tag);
    openEditDrawer();
  };

  return (
    <>
      <BackToPage link="/tags" title="tags" />

      <div className="vf-container">
        <div className="vf-row">
          <div className="vf-col">
            <h1 className={styles.headline}>Create & Edit Tags for {name}</h1>
          </div>
        </div>

        <div className="vf-row">
          <div className="vf-col">
            <VfButton type="secondary" onClick={handleAddTag}>
              Add Tag
            </VfButton>
          </div>
        </div>

        <VfDivider />

        <div className="vf-row">
          <div className="vf-col">
            <VfChips
              disabled
              clearAll={() => null}
              onClick={(value) => handleEditTag(value)}
              chips={tags.map((tag) => ({
                label: tag.name,
                color: tag.active ? 'primary' : undefined,
                value: tag,
              }))}
            />
          </div>
        </div>
      </div>

      <Drawer isMounted={isEditDrawerOpen} isOpen={isEditDrawerOpen} onClose={closeEditDrawer} side="right">
        <Formik initialValues={tagToEdit ?? { name: '', active: true }} onSubmit={onSubmit}>
          {(addTagForm) => {
            const { handleSubmit, setFieldValue, values, dirty } = addTagForm;
            return (
              <form onSubmit={handleSubmit} className={styles.form}>
                <DrawerHeader
                  headline={tagToEdit ? `Edit tag ${(tagToEdit as any).name}` : 'Add tag'}
                  onClose={closeEditDrawer}
                />
                <div className={styles.content}>
                  <Input className="mb-2" name="name" label="Tag name *" />

                  {tagToEdit && (
                    <VfSwitch
                      className="admin__tags-switch"
                      name="active"
                      title={!values.active ? 'inactive' : 'active'}
                      onChange={() => setFieldValue('active', !values.active)}
                      checked={values.active}
                    />
                  )}
                </div>
                <DrawerFooter>
                  <VfButton outline="secondary" onClick={closeEditDrawer}>
                    Cancel
                  </VfButton>
                  <VfButton type="primary" htmlType="submit" disabled={!dirty}>
                    Submit
                  </VfButton>
                </DrawerFooter>
              </form>
            );
          }}
        </Formik>
      </Drawer>
    </>
  );
};
