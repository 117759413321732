import { useState } from 'react';
import { useSelector } from 'react-redux';

import { InfoElement } from '../../../models/infoElement';

import { getInfoElementByName } from '../../../store/infoElement';

import { InfoElementNames } from '../../../components/Tooltip/consts';

interface InfoElementHandler {
  (newInfoElementName: InfoElementNames): void;
}

const useInfoElement = (initialInfoElementName: InfoElementNames): [InfoElement, InfoElementHandler] => {
  const [infoElementName, setInfoElementName] = useState<InfoElementNames>(initialInfoElementName);
  const infoElement = useSelector(getInfoElementByName)(infoElementName);

  const setInfoElement = (newInfoElementName: InfoElementNames) => {
    setInfoElementName(newInfoElementName);
  };

  return [infoElement, setInfoElement];
};

export default useInfoElement;
