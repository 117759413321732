import { FC } from 'react';

import classNames from 'classnames';

import { ADUser } from '../../../models/user';

import { IBulkUpdateLessonsResponse } from '../../../store/lessonsDetails';

import DrawerFooter from '../../FlexDrawer/Footer';
import IconButton from '../../IconButton';
import Tooltip from '../../Tooltip';
import UserSelector from '../../UserSelector';
import { VfButton, VfIcon } from '../../vfDesign';
import styles from './bulkUpdateLessonsDrawer.module.scss';
import { IFormikValues } from './types';

interface BulkUpdateLessonsFormProps {
  canBulkEditActionTaker: boolean;
  canBulkEditActionTakerDeputy: boolean;
  editActionTaker: boolean;
  editActionTakerDeputy: boolean;
  errors: any;
  isSubmitDisabled?: boolean;
  onChange: (name: string, value?: Partial<ADUser>) => void;
  onClose: () => void;
  onSubmit: () => void;
  onUpdate: () => void;
  toggleActionTakerDeputyEdit: () => void;
  toggleActionTakerEdit: () => void;
  updateStatus?: IBulkUpdateLessonsResponse | null;
  values: IFormikValues;
}

const BulkUpdateLessonsForm: FC<BulkUpdateLessonsFormProps> = ({
  canBulkEditActionTaker,
  canBulkEditActionTakerDeputy,
  editActionTaker,
  editActionTakerDeputy,
  errors,
  isSubmitDisabled,
  onChange,
  onClose,
  onSubmit,
  onUpdate,
  toggleActionTakerDeputyEdit,
  toggleActionTakerEdit,
  updateStatus,
  values,
}) => {
  const renderFailureReasons = () =>
    updateStatus?.lessonsNotUpdated.map(({ id, failureReason, title }) => (
      <div key={id}>
        <strong>{title}:</strong> {failureReason}
      </div>
    ));

  const summaryClassNames = classNames(styles['bulk-lesson-details__summary'], 'mt-2');

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className={styles['bulk-lesson-details']}>
          <div className={styles['bulk-lesson-details__row']}>
            <div className={styles['bulk-lesson-details__headline']}>
              Action Taker {canBulkEditActionTaker && <IconButton icon="pencil" onClick={toggleActionTakerEdit} />}
            </div>
            {editActionTaker && (
              <div className="mt-2" style={{ width: '100%' }}>
                <UserSelector
                  name="actionTaker"
                  onSelect={onChange}
                  value={values.actionTaker}
                  error={errors.actionTaker}
                />
              </div>
            )}
          </div>
          <div className={styles['bulk-lesson-details__row']}>
            <div className={styles['bulk-lesson-details__headline']}>
              Action Taker Deputy
              {canBulkEditActionTakerDeputy && <IconButton icon="pencil" onClick={toggleActionTakerDeputyEdit} />}
              {values.actionTakerDeputy && (
                <IconButton icon="trash" onClick={() => onChange('actionTakerDeputy', undefined)} />
              )}
            </div>
            {editActionTakerDeputy && (
              <div className="mt-2" style={{ width: '100%' }}>
                <UserSelector name="actionTakerDeputy" onSelect={onChange} value={values.actionTakerDeputy} />
                <div>Leaving the value empty will remove the action taker deputy from the selected lessons.</div>
              </div>
            )}
          </div>
          {updateStatus && (
            <div className={summaryClassNames}>
              <div>Successful updates - {updateStatus.lessonsUpdated.length}</div>
              <div>
                <span className="mr-1">Rejected updates - {updateStatus.lessonsNotUpdated.length}</span>
                {updateStatus.lessonsNotUpdated.length > 0 && (
                  <Tooltip text={renderFailureReasons()} position="softRight">
                    <VfIcon name="info" />
                  </Tooltip>
                )}
              </div>
              <div>
                Users removed from action taker roles:
                <div>
                  {updateStatus.usersRemovedFromActionTakerRole.length > 0
                    ? updateStatus.usersRemovedFromActionTakerRole.map(
                        ({ user: { id, displayName }, numberOfLessons }) => (
                          <div key={id}>
                            {displayName} - {numberOfLessons}
                          </div>
                        ),
                      )
                    : 'None'}
                </div>
              </div>
              <div>
                Users removed from action taker deputy roles:
                <div>
                  {updateStatus.usersRemovedFromActionTakerDeputyRole.length > 0
                    ? updateStatus.usersRemovedFromActionTakerDeputyRole.map(
                        ({ user: { id, displayName }, numberOfLessons }) => (
                          <div key={id}>
                            {displayName} - {numberOfLessons}
                          </div>
                        ),
                      )
                    : 'None'}
                </div>
              </div>
            </div>
          )}
        </div>

        <DrawerFooter>
          <VfButton outline="secondary" onClick={onClose}>
            Close
          </VfButton>
          <VfButton type="primary" disabled={false} onClick={onUpdate}>
            Update
          </VfButton>
        </DrawerFooter>
      </form>
    </>
  );
};

export default BulkUpdateLessonsForm;
