import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { fetchLessonsWithNoAT, getLessonsWithNoAT } from '../../../store/lessonsWithNoActionTaker';

import styles from '../../../components/Feedback/feedback-form.module.scss';
import LessonDetailsDrawer, { TabIndex } from '../../../components/LessonDetails';
import Spinner from '../../../components/Spinner';
import { useAppDispatch } from '../../../root';
import ActionTakersFilters from './ActionTakersFilters';
import LessonWithNoActiveActionTaker from './LessonWithNoActiveActionTaker';
import { IFilterProps, initialFilters } from './consts';

const LessonsWithNoActiveActionTaker = () => {
  const dispatch = useAppDispatch();
  const { lessons, isLoading } = useSelector(getLessonsWithNoAT);
  const [lessonId, setLessonId] = useState<string>('');
  const [passedTab, setPassedTab] = useState(0);
  const [filters, setFilters] = useState<IFilterProps>(initialFilters);

  useEffect(() => {
    dispatch(fetchLessonsWithNoAT(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(filters)]);

  const handleOpenDrawer = (lessonId: string, tabIndex: TabIndex) => {
    setLessonId(lessonId);
    setPassedTab(tabIndex);
  };

  const handleCloseDrawer = () => {
    setLessonId('');
  };

  const lessonsWithoutAT = lessons?.map(({ actionTaker, description, id, projectName, title }) => (
    <LessonWithNoActiveActionTaker
      key={id}
      actionTaker={actionTaker}
      description={description}
      id={id}
      projectName={projectName}
      title={title}
      setLessonId={handleOpenDrawer}
    />
  ));

  return (
    <div className="vf-container">
      <LessonDetailsDrawer
        open={!!lessonId}
        isOpen={!!lessonId}
        onClose={handleCloseDrawer}
        side="right"
        onOutsideClick={handleCloseDrawer}
        lessonId={lessonId}
        passedTab={passedTab}
        setPassedTab={setPassedTab}
        lastActionRequestTime={undefined}
        isFromAssignATPage={true}
        actionTakerFilter={filters}
      />
      <div className="vf-row mt-3">
        <div className="vf-col">
          <h3 className="mb-3">Lessons with no active action taker</h3>
          <ActionTakersFilters setFilters={setFilters} />
          <div className="vf-container" id="scrollableDiv">
            {isLoading && (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Spinner />
              </div>
            )}
            {lessonsWithoutAT.length === 0 && !isLoading ? (
              <p className={styles.text}>No lessons to show.</p>
            ) : (
              lessonsWithoutAT
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonsWithNoActiveActionTaker;
