import { FC, ReactElement, useRef } from 'react';

import classNames from 'classnames';

import useOutsideClick from '../../../utils/hooks/useOutsideClick';

import styles from './vfPopover.module.scss';

interface VfPopoverProps {
  className?: string;
  closeDropdown: () => void;
  component: ReactElement;
  id?: string;
  isOpen?: boolean;
  left?: boolean;
  right?: boolean;
  marginRight?: number;
  onClick?: (e?: any) => void; // TODO: handle any
}

const VfPopover: FC<VfPopoverProps> = ({
  children,
  className,
  closeDropdown,
  component,
  id,
  isOpen,
  left,
  marginRight,
  onClick,
  right,
}) => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const dropdownClasses = classNames(styles['popover__box'], className, {
    [styles['popover__box--show']]: isOpen,
    [styles['popover__box--right']]: right,
    [styles['popover__box--left']]: left,
  });

  useOutsideClick(ref, () => {
    closeDropdown();
  });

  return (
    <div ref={ref} id={id} className={styles.popover} style={{ right: `${marginRight}px` }} onClick={onClick}>
      {component}
      <div className={dropdownClasses}>
        <div className={styles.popover__list}>{children}</div>
      </div>
    </div>
  );
};

VfPopover.defaultProps = {
  id: '',
  isOpen: false,
  right: false,
  left: false,
  className: '',
  onClick: () => {},
  marginRight: 0,
};

export default VfPopover;
