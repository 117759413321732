import React, { FC, memo } from 'react';

import classNames from 'classnames';
import formatDistance from 'date-fns/formatDistance';

import { MessageResponse, MESSAGES_WITH_LINK, MESSAGE_TYPE, Parameters } from '../../models/messages';

import { VfIcon } from '../vfDesign';
import styles from './_notification_center.module.scss';
import MessageParameter from './messageParameter';
import MessageParameters from './messageParameters';

const MESSAGE_ICONS = {
  [MESSAGE_TYPE.LESSON_CREATED]: { name: 'success', color: 'green' },
  [MESSAGE_TYPE.LESSON_UPDATED]: { name: 'success', color: 'green' },
  [MESSAGE_TYPE.LESSON_CLOSED]: { name: 'success', color: 'green' },
  [MESSAGE_TYPE.LESSON_NOT_PROGRESSED]: { name: 'info', color: 'orange' },
  [MESSAGE_TYPE.LESSON_NOT_PROGRESSED]: { name: 'info', color: 'orange' },
};

// Helper function for separating and formatting parameters
const unpackParams = (params: Parameters) => {
  const separate = ['url', 'taskName', 'taskUrl', 'projectUrl'];

  const separated: Parameters = {};
  const rest: { name: string; value: any }[] = [];

  Object.keys(params).forEach((key) => {
    if (separate.includes(key)) {
      separated[key] = Array.isArray(params[key]) ? JSON.parse(params[key]) : params[key];
    } else {
      rest.push({ name: key, value: params[key] });
    }
  });

  return { separated, rest };
};

interface MessageItemProps extends MessageResponse {
  onRemove: (id: string) => void;
  removeDisabled: boolean;
}

const MessageItem: FC<MessageItemProps> = ({
  createDate,
  id,
  markedRead,
  notificationType,
  parameters,
  onRemove,
  removeDisabled,
}) => {
  const title = MESSAGE_TYPE[notificationType] || notificationType;

  const iconName = MESSAGE_ICONS[title] ? MESSAGE_ICONS[title].name : 'info';
  const iconColor = MESSAGE_ICONS[title] ? MESSAGE_ICONS[title].color : 'blue';
  const messageClasses = classNames('message-item', {
    'message-item--readed': markedRead,
  });
  const timeAgo = formatDistance(new Date(createDate), new Date(), {
    addSuffix: true,
  });

  const { separated, rest: standardParams } = unpackParams(parameters);
  const hasUrl = MESSAGES_WITH_LINK.includes(notificationType);
  const hasParameters = Object.keys(parameters).length > 0;

  const handleRemove = () => {
    if (!removeDisabled) {
      onRemove(id);
    }
  };

  const isSharepointLink = separated.url && separated.url.includes('https://vattenfall.sharepoint.com/');

  const getParameterName = () => {
    if (isSharepointLink) {
      return 'File';
    }

    if (separated.taskName) {
      return 'Task Name';
    }

    if (separated.projectUrl) {
      return 'Project Url';
    }

    return '';
  };

  const getParameterTitle = () => {
    if (isSharepointLink) {
      return 'Link to Sharepoint';
    }

    return separated.taskName || 'Project Link';
  };

  return (
    <div className={styles['notification-center__item']} id={`notification-item-${id}`}>
      <div className={styles['message-icon']}>
        <VfIcon name={iconName} color={iconColor} />
      </div>

      <div className={messageClasses}>
        <span className={styles['message-item__title']}>{title}</span>

        {hasUrl && (
          <div className={styles['message-item__parameters']}>
            <MessageParameter
              name={getParameterName()}
              value={
                <a
                  href={separated.url || separated.taskUrl || separated.projectUrl}
                  target={isSharepointLink ? '_blank' : undefined}
                  rel="noreferrer"
                >
                  {getParameterTitle()}
                </a>
              }
            />
          </div>
        )}

        {hasParameters && <MessageParameters id={id} items={standardParams} />}
        <span className={styles['message-item__time']}>{timeAgo}</span>
      </div>

      <div className={styles['message-clear']}>
        <VfIcon name="rubbish" color={removeDisabled ? 'grey' : 'blue'} asButton onClick={handleRemove} />
      </div>
    </div>
  );
};

MessageItem.defaultProps = {
  markedRead: false,
  parameters: {},
  removeDisabled: false,
};

export default memo(MessageItem);
