import { useEffect, useRef } from 'react';

const usePrevPropsAndState = <T, K = {}>(props: T, state?: K) => {
  const prevPropsAndStateRef = useRef<{
    props: T | null;
    state: K | undefined | null;
  }>({ props: null, state: null });
  const prevProps = prevPropsAndStateRef.current.props;
  const prevState = prevPropsAndStateRef.current.state;

  useEffect(() => {
    prevPropsAndStateRef.current = { props, state };
  });

  return { prevProps, prevState };
};

export default usePrevPropsAndState;
