import { FC, MouseEvent, useEffect } from 'react';

import VfIcon from '../vfIcon';
import './notification.scss';

export type NotificationType = 'SUCCESS' | 'ERROR' | 'INFO';

export interface types {
  [key: string]: NotificationType;
}

export const NOTIFICATION_TYPES: types = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INFO: 'INFO',
};

export const NOTIFICATION_ROOT = 'notification-root';

export interface NotificationProps {
  id: string;
  type: NotificationType;
  message: string;
  clearNotification: (id: string) => void;
  timeout?: number;
}

const VfNotification: FC<NotificationProps> = ({ message, id, type, clearNotification, timeout }) => {
  const closeNotification = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    clearNotification(id);
  };

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        clearNotification(id);
      }, timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const switchIcon = (): string => { //TODO: add icons from jupiter
    switch (type) {
      case NOTIFICATION_TYPES.SUCCESS:
        return 'success';
      case NOTIFICATION_TYPES.ERROR:
        return 'error';
      case NOTIFICATION_TYPES.INFO:
      default:
        return 'info';
    }
  };

  const icon = switchIcon();

  return (
    <div className={`notification notification--${type}`}>
      <div className="notification__content">
        <VfIcon name={icon} color="white" className="notification__status-icon" />
        <span className="notification__message">{message}</span>
      </div>
      <div className="notification__utils" onClick={closeNotification}>
        <VfIcon name="close" color="white" asButton />
      </div>
    </div>
  );
};

VfNotification.defaultProps = {
  timeout: 5000,
};

export default VfNotification;
