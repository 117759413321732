export const isProd = process.env.NODE_ENV === 'production';

export const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

interface InstanceConfig {
  API_URL: string;
  INSIGHTS: string;
  MSAL_CLIENT_ID: string;
  MSAL_SCOPES: Array<string>;
  MSAL_TENANT: string;
  [key: string]: string | Array<string>;
}

export const AVAILABLE_NOTIFICATION_TYPES = {
  NEW_TASK_ASSIGNMENT: 'NEW_TASK_ASSIGNMENT',
  PROJECT_TIMELINE_UPDATED: 'PROJECT_TIMELINE_UPDATED',
  TASK_CHANGED: 'TASK_CHANGED',
  TASK_DEADLINE_OVERDUE: 'TASK_DEADLINE_OVERDUE',
  UPCOMING_MILESTONE: 'UPCOMING_MILESTONE',
  UPCOMING_PHASE_DECISION: 'UPCOMING_PHASE_DECISION',
  UPCOMING_TASK_DEADLINE: 'UPCOMING_TASK_DEADLINE',
  USER_ASSIGNED_TO_PROJECT_ROLE: 'USER_ASSIGNED_TO_PROJECT_ROLE',
};

const parseProcessEnv = (env: NodeJS.ProcessEnv, replaceKeys: boolean): InstanceConfig => {
  const config: InstanceConfig = {
    API_URL: '',
    INSIGHTS: '',
    MSAL_CLIENT_ID: '',
    MSAL_SCOPES: [],
    MSAL_TENANT: '',
  };

  Object.entries(env)
    .filter(([id]) => id.startsWith('REACT_APP_CONFIG_'))
    .forEach(([key, value]) => {
      const newKey: string = key.replace('REACT_APP_CONFIG_', '');

      config[replaceKeys ? newKey : key] = value?.includes('[') ? JSON.parse(value) : value;
    });

  return config;
};

const getRuntimeConfig = (): InstanceConfig => {
  const htmlElement = document.getElementById('js-bundle-runtime-config');

  if (isProd) {
    return htmlElement
      ? parseProcessEnv(JSON.parse(htmlElement.innerHTML), true)
      : { API_URL: '', INSIGHTS: '', MSAL_CLIENT_ID: '', MSAL_SCOPES: [], MSAL_TENANT: '' };
  }

  return parseProcessEnv(process.env, true);
};

const config = getRuntimeConfig();

export default config;
