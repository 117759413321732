import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Notification } from '../../../models/notification';

import { clearNotification, getNotifications } from '../../../store/notifications';

import { useAppDispatch } from '../../../root';
import Portal from '../../Portal';
import VfNotification, { NOTIFICATION_ROOT } from '../../vfDesign/vfNotification';

const Notifications: FC = () => {
  const dispatch = useAppDispatch();

  const onClear = (id: string) => {
    dispatch(clearNotification(id));
  };

  const notifications = useSelector(getNotifications);
  const renderNotifications = notifications.map((notification: Notification) => {
    const { message, id, type } = notification;

    return <VfNotification message={message} id={id} key={id} type={type} clearNotification={onClear} />;
  });

  return <Portal id={NOTIFICATION_ROOT}>{renderNotifications}</Portal>;
};

export default Notifications;
