import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { useIsAuthenticated } from '@azure/msal-react';

import { fetchUserDetails, getUserDetails } from '../../store/user';

import { useCookie } from '../../utils/hooks';

import Spinner from '../../components/Spinner';
import { VfButton } from '../../components/vfDesign';
import { AUTH_REDIRECT_PATH } from '../../consts/auth';
import styles from './Welcome.module.scss';

const Welcome: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(getUserDetails);
  const [redirectURL] = useCookie(AUTH_REDIRECT_PATH, '/home');
  const isAuthenticated = useIsAuthenticated();

  const handleClick = () => {
    history.push('/login');
  };

  useEffect(() => {
    dispatch(fetchUserDetails());
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vf-vh-100 ">
        <Spinner />
      </div>
    );
  }

  if (data || isAuthenticated) {
    return <Redirect to={redirectURL} />;
  }

  return (
    <div className={styles.login}>
      <div className={styles.container}>
        <h1 className={styles.headline}>Welcome to Lesson Learned Portal!</h1>
        <h2 className={styles.subheadline}>Please login to continue</h2>
        <VfButton type="primary" onClick={handleClick}>
          Login
        </VfButton>
      </div>
    </div>
  );
};

export default Welcome;
