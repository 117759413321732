import { useState, useEffect } from 'react';

import { ADUser } from '../../../models/user';

import Api from '../../../services/api';
import { throttle } from '../../functions';

const useFindAdUser = (): [ADUser[], boolean, (searchTxt: string) => void, () => void] => {
  const [data, setData] = useState<ADUser[]>([]);
  const [query, onChange] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchUsers = async (q: string) => {
    setIsLoading(true);
    const response = await Api.get<ADUser[]>('/users/find-ad-user', { q });

    setData(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    const debounced = throttle(() => fetchUsers(query), 300);

    try {
      if (query) {
        debounced();
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }, [query]);

  const resetUsers = () => {
    setData([]);
  };

  return [data, isLoading, onChange, resetUsers];
};

export default useFindAdUser;
