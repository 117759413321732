import { FC } from 'react';

export interface ActionOption {
  // TODO: handle any
  disabled: (row: any) => boolean;
  label: (row: any) => string;
  method: (row: any) => void;
}

interface ActionsProps {
  options: ActionOption[];
  row: Object;
}

const Actions: FC<ActionsProps> = ({ options, row }) => {
  const renderOptions = options.map((opt) => {
    const handleClick = () => {
      opt.method(row);
    };

    const renderLabel = (() => {
      return opt.label(row);
    })();

    const isDisabled = (): boolean => {
      if (opt.disabled) {
        return opt.disabled(row);
      }

      return false;
    };

    return (
      <button
        type="button"
        id={`table-action:${renderLabel.toLowerCase()}`}
        disabled={isDisabled()}
        key={`table-action-${renderLabel.toLowerCase()}`}
        onClick={handleClick}
        className="table-action"
      >
        {renderLabel}
      </button>
    );
  });

  return <div className="table-action-menu">{renderOptions}</div>;
};

Actions.defaultProps = {
  row: {},
};

export default Actions;
