// MSAL imports
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';

import { useCookie } from '../../../utils/hooks';

import { AUTH_REDIRECT_PATH } from '../../../consts/auth';
import Layout from '../Layout';
import LoginWidget from './LoginWidget';
import Route from './Route';

interface Props {
  component: FC;
  exact: boolean;
  path: string;
  withLayout?: boolean;
}

const PrivateRoute: FC<Props> = ({ component, exact, path, withLayout }) => {
  const [, setRedirectURL] = useCookie(AUTH_REDIRECT_PATH);
  const { pathname, search } = useLocation();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!['/login', '/', '/login#'].includes(pathname)) {
      setRedirectURL(`${pathname}${search}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, setRedirectURL]);

  useEffect(() => {
    if (isAuthenticated) {
      setRedirectURL('', { expires: -1 });
    }
  }, [isAuthenticated, setRedirectURL]);

  if (withLayout) {
    return (
      <Layout>
        <AuthenticatedTemplate>
          <Route component={component} exact={exact} path={path} />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <LoginWidget />
        </UnauthenticatedTemplate>
      </Layout>
    );
  }

  return (
    <>
      <AuthenticatedTemplate>
        <Route component={component} exact={exact} path={path} />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginWidget />
      </UnauthenticatedTemplate>
    </>
  );
};

PrivateRoute.defaultProps = {
  withLayout: true,
};

export default PrivateRoute;
