import { FC } from 'react';
import { FileWithPath } from 'react-dropzone';

import { useFormikContext } from 'formik';

import { Textarea } from '../../Forms';
import FileUpload from '../../uploadFile';
import styles from '../Shared.module.scss';

interface FormValues {
  additionalInformation: string;
  files: [];
}

const ProjectMore: FC = () => {
  const { setFieldValue, values } = useFormikContext<FormValues>();

  const handleFiles = (files: FileWithPath[]) => {
    setFieldValue('files', files);
  };

  return (
    <div className={styles.content}>
      <h5 className={styles.lead}>Is there anything else you'd like to mention?</h5>

      <div className="w-75 w-sm-75 w-xs-100">
        <Textarea name="additionalInformation" label="Additional information" textareaStyles={styles.textarea__small} />
      </div>

      <div className="w-75 w-sm-75 w-xs-100">
        <FileUpload closeInput={() => {}} maxFileUpload={10} uploadCallback={handleFiles} attachments={values.files} />

        <p className={styles.helper}>Max. 5 MB</p>
      </div>
    </div>
  );
};

export default ProjectMore;
