import { FC, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Formik, FormikHelpers } from 'formik';
import { object } from 'yup';

import { LessonDetails } from '../../models/lessonDetails';

import { toggleFavourite, triggerActionTaker } from '../../store/lessons';
import {
  clearLessonDetails,
  fetchLessonDetails,
  getLessonDetails,
  selectLessonError,
  takeAction,
  updateLessonDetails,
} from '../../store/lessonsDetails';
import { getIsAdmin } from '../../store/user';

import { useBoolean } from '../../utils/hooks';
import { switchInitialValues } from './utils/initialValues';
import { baseValidationSchema, notLegacyProjectSchema, takeActionValidationSchema } from './utils/validationSchema';

import { FLOW_TYPES } from '../../consts/newLessonForm';
import { IFilterProps } from '../../pages/Admin/LessonsWithNoActiveActionTaker/consts';
import { useAppDispatch } from '../../root';
import Drawer from '../FlexDrawer';
import DrawerHeader from '../FlexDrawer/Header';
import Spinner from '../Spinner';
import Tooltip from '../Tooltip';
import { VfTabs } from '../vfDesign';
import VfIcon from '../vfDesign/vfIcon';
import { DocsTab, InfoTab, TakeActionTab, TagTab } from './drawerTabs';
import styles from './lessonDetails.module.scss';

export interface LessonDetailsDrawerProps {
  isOpen: boolean;
  lastActionRequestTime?: string;
  lessonId: string;
  onClose: () => void;
  onOutsideClick: () => void;
  open: boolean;
  side: string;
  passedTab?: TabIndex;
  setPassedTab?: (tabIdx: number) => void;
  isFromAssignATPage?: boolean;
  actionTakerFilter?: IFilterProps;
}

export enum TabIndex {
  INFO = 0,
  FILES = 1,
  TAGS = 2,
  TAKE_ACTION = 3,
}

const getValidationSchema = (isLegacyProject: boolean = false, tabName: TabIndex) => {
  switch (tabName) {
    case TabIndex.INFO:
      return object().shape(isLegacyProject ? baseValidationSchema : notLegacyProjectSchema);
    case TabIndex.TAKE_ACTION:
      return object().shape(takeActionValidationSchema);
  }
};

const LessonDetailsDrawer: FC<LessonDetailsDrawerProps> = ({
  isOpen,
  lessonId,
  lastActionRequestTime,
  onClose,
  onOutsideClick,
  open,
  side,
  passedTab,
  setPassedTab,
  isFromAssignATPage = false,
  actionTakerFilter,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { data, isLoading, isDeleting } = useSelector(getLessonDetails);
  const hasError = useSelector(selectLessonError);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFullScreen, _, closeFullscreen, toggleFullscreen] = useBoolean(false);
  const [tabName, setTabName] = useState<TabIndex>(0);
  const [isTriggeredActionTaker, setIsTriggerActionTaker] = useState(false);

  useEffect(() => {
    if (lessonId) {
      dispatch(fetchLessonDetails(lessonId));
    }
  }, [dispatch, lessonId, data.lastActionRequestTime]);

  useEffect(() => {
    if (hasError) {
      history.push('/404');
    }
  }, [hasError, history]);

  const initialValues = {
    ...switchInitialValues(data.origin),
    ...data,
  };

  const showLoader = isLoading || isDeleting.includes(lessonId);

  const isOrganizationUnit = initialValues.origin === FLOW_TYPES.ORGANIZATIONAL_UNIT;
  const isProject = initialValues.origin === FLOW_TYPES.PROJECT;
  const isOperatingSite = initialValues.origin === FLOW_TYPES.OPERATING_SITE;
  const isLegacyLesson = isProject && !!initialValues.projectName && !initialValues.projectId;
  const validationSchema = getValidationSchema(isLegacyLesson, tabName);
  const isAdmin = useSelector(getIsAdmin);
  const triggeredActionTaker = isTriggeredActionTaker || Boolean(lastActionRequestTime);

  useEffect(() => {
    setIsTriggerActionTaker(false);
  }, [lessonId]);

  const handleClose = () => {
    onClose();
    closeFullscreen();
    dispatch(clearLessonDetails());
  };

  const handleToggleFavourite = (e: MouseEvent<HTMLSpanElement>) => {
    dispatch(
      toggleFavourite({
        lessonId: lessonId,
      }),
    );
  };

  const handleTriggerActionTaker = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    setIsTriggerActionTaker(true);
    dispatch(triggerActionTaker({ lessonId }));
  };

  const onSubmit = (
    values: LessonDetails | Partial<LessonDetails>,
    { resetForm }: FormikHelpers<LessonDetails>,
  ): void => {
    switch (tabName) {
      case TabIndex.INFO:
        dispatch(updateLessonDetails({ lesson: values, shouldUpdateATLesson: isFromAssignATPage, actionTakerFilter }));
        break;
      case TabIndex.TAKE_ACTION:
        dispatch(takeAction({ lesson: values, shouldUpdateATLesson: isFromAssignATPage }));
        break;
    }
    resetForm();
    handleClose();
  };

  return (
    <Drawer
      delayTime={400}
      full={isFullScreen}
      isMounted={open}
      isOpen={isOpen}
      onClose={handleClose}
      onOutsideClick={onOutsideClick}
      side={side}
    >
      <>
        <DrawerHeader
          headline="Lesson Details"
          isFullScreen={isFullScreen}
          isOpen={isOpen}
          onClose={handleClose}
          onMaximize={toggleFullscreen}
          isTriggeredActionTaker={triggeredActionTaker}
        />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            const tabs = [
              {
                id: 1,
                name: 'Info',
                component: InfoTab,
                props: {
                  isLegacyLesson,
                  isFullScreen: isFullScreen,
                  isOperatingSite,
                  isOrganizationUnit,
                  isProject,
                  lessonDetails: data,
                  onClose: handleClose,
                  tabNameCallback: setTabName,
                  ...formik,
                },
              },
              {
                id: 2,
                name: 'Files',
                component: DocsTab,
                props: {
                  userRight: data?.userRights?.canEditLesson,
                  lessonId: data?.id,
                  attachments: data?.attachments,
                  onClose: handleClose,
                  tabNameCallback: setTabName,
                  ...formik,
                },
              },
              {
                id: 3,
                name: 'Tags',
                component: TagTab,
                props: {
                  lessonId,
                  lessonTags: data?.tags,
                  onClose: handleClose,
                  organizationId: data?.organizationId,
                  tabNameCallback: setTabName,
                  readonly: !data?.userRights?.canEditLesson,
                },
              },
              {
                id: 4,
                name: 'Take Action',
                component: TakeActionTab,
                props: {
                  lessonDetails: data,
                  onClose: handleClose,
                  tabNameCallback: setTabName,
                  ...formik,
                },
              },
            ];

            return (
              <>
                {!showLoader && (
                  <>
                    <div className={styles['lesson-details__title']}>
                      <div>
                        <Tooltip text="Add to favourites" position="bottom">
                          <VfIcon
                            name={`${data.favourite ? 'star-full' : 'star-outlined'}`}
                            color={data.favourite ? 'yellow' : 'black'}
                            className={styles['favorite-button']}
                            onClick={handleToggleFavourite}
                          />
                        </Tooltip>

                        {data?.priority === 'HIGH' && (
                          <Tooltip text="High priority" position="bottom">
                            <VfIcon name="vf-status--alert" color="red" />
                          </Tooltip>
                        )}

                        <span className={data.delayed ? styles.titleDelayed : ''}>{data?.title}</span>
                      </div>

                      {isAdmin && (
                        <Tooltip text="Trigger Action Taker" position="softBottom">
                          <VfIcon
                            name={`schedule-send`}
                            color={'black'}
                            className={
                              Boolean(data.lastActionRequestTime)
                                ? styles['workshop-indicator-trigger-action']
                                : styles['favorite-button']
                            }
                            onClick={handleTriggerActionTaker}
                            disabled={triggeredActionTaker}
                          />
                        </Tooltip>
                      )}
                    </div>
                    <VfTabs tabs={tabs} small passedTab={passedTab} setPassedTab={setPassedTab} />
                  </>
                )}

                {showLoader && (
                  <div className={styles['lesson-details__spinner-wrapper']}>
                    <Spinner />
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </>
    </Drawer>
  );
};

export default LessonDetailsDrawer;
