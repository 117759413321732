import { AxiosError } from 'axios';
import { compare } from 'fast-json-patch';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Lesson } from '../models/lesson';
import { LessonDetails } from '../models/lessonDetails';

import { handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import { NOTIFICATION_TYPES } from '../components/vfDesign/vfNotification';
import { ORDER } from '../components/vfDesign/vfTable/headers';
import Api from '../services/api';
import { setNotification } from './notifications';
import { WorkshopLessonInitialValues } from './workshopDetails';

export const sliceName = 'workshopLessons';
export const INITIAL_PAGE: number = 1;
export const PAGE_SIZE: number = 30;

export interface WorkshopLessonInitialState {
  areLessonsLoading: boolean;
  currentLessonData: LessonDetails;
  isCurrentLoading: boolean;
  isEditMode: boolean;
  isLessonInEditMode: boolean;
  isPublishing: string[];
  lessons: Lesson[];
  sortColumns: any;
  totalPages: number;
  loadingMore: boolean;
  page: number;
  sort: { sortBy: string; order: ORDER };
}

export interface DeleteLessonFromWorkshopArgs {
  id: string;
  workshopId: string;
}

export interface EditLessonInWorkshopArgs {
  id: string;
  body: WorkshopLessonInitialValues;
}

export interface TogglePublishLesson {
  id: string;
  workshopId: string;
  checked: boolean;
}

export interface FilteredLessonsParams {
  id?: string;
  body?: any; // TODO handle any, check do we need body
}

export const fetchFilteredLessonInWorkshop = createAsyncThunk(
  `${sliceName}/workshops/getFilteredLessons`,
  async ({ id }: FilteredLessonsParams, { dispatch, getState }) => {
    try {
      const state = getState() as ReduxStoreType;

      const isWorkshopChange = state.workshopDetails?.current?.id !== id;

      if (isWorkshopChange) {
        dispatch(clearWorkshopLessonState());
      }

      const { page, sort } = state[sliceName];
      const payload = {
        workshopId: id,
        page,
        pageSize: PAGE_SIZE,
        reverseOrder: sort.order === ORDER.DESC,
        sort: sort.sortBy,
      };

      const response = await Api.post<Lesson[]>(`/lessons/filter-in-workshop/`, payload);

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch lessons in workshop', dispatch);
    }
  },
);

export const addLessonToWorkshop = createAsyncThunk(
  `${sliceName}/workshops/addLessonToWorkshop`,
  async (body: WorkshopLessonInitialValues, { dispatch }) => {
    try {
      await Api.post(`/lessons/in-workshop`, body);

      const payload = {
        id: body.workshopId,
        body: {},
      };

      await dispatch(fetchFilteredLessonInWorkshop(payload));
    } catch (err) {
      handleApiError(err as AxiosError, ' We could not add lesson to Workshop', dispatch);
    }
  },
);

export const editLessonInwWorkshop = createAsyncThunk(
  `${sliceName}/workshops/EditLesson`,
  async ({ id, body }: EditLessonInWorkshopArgs, { dispatch, getState }) => {
    try {
      const state = getState() as ReduxStoreType;
      const jsonPatchData = compare(state[sliceName].currentLessonData, body);
      await Api.patch(`/lessons/${id}/draft`, jsonPatchData, {}, true);

      const payload = {
        id: body.workshopId,
        body: {},
      };

      await dispatch(fetchFilteredLessonInWorkshop(payload));
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not edit lesson in Workshop', dispatch);
    }
  },
);

export const deleteLessonFromWorkshop = createAsyncThunk(
  `${sliceName}/workshops/deleteLessonFromWorkshop`,
  async ({ id, workshopId }: DeleteLessonFromWorkshopArgs, { dispatch }) => {
    try {
      await Api.delete(`/lessons/${id}`);

      const payload = {
        id: workshopId,
        body: {},
      };

      await dispatch(fetchFilteredLessonInWorkshop(payload));
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not delete lesson in workshop', dispatch);
    }
  },
);

export const togglePublishLesson = createAsyncThunk(
  `${sliceName}/workshops/deleteLessonFromWorkshop`,
  async ({ id, workshopId, checked }: TogglePublishLesson, { dispatch }) => {
    const publishUnpublish = checked ? 'published' : 'unpublished';

    try {
      await Api.post('/lessons/publish-lessons', {
        lessonStates: {
          [id]: checked,
        },
        workshopId,
      });

      dispatch(setNotification(`Lesson was ${publishUnpublish}`, NOTIFICATION_TYPES.SUCCESS));
    } catch (err) {
      handleApiError(err as AxiosError, `We could not ${publishUnpublish} lesson`, dispatch);
    }
  },
);

export const initialState: WorkshopLessonInitialState = {
  areLessonsLoading: false,
  currentLessonData: {} as LessonDetails,
  isCurrentLoading: false,
  isEditMode: false,
  isLessonInEditMode: false,
  isPublishing: [],
  lessons: [],
  sortColumns: [],
  totalPages: 0,
  loadingMore: false,
  page: INITIAL_PAGE,
  sort: { sortBy: 'TITLE', order: ORDER.ASC },
};

export const WorkshopLessonsReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCurrentLesson: (state: WorkshopLessonInitialState, { payload }) => {
      state.isLessonInEditMode = true;
      state.currentLessonData = payload;
    },
    clearCurrentLesson: (state: WorkshopLessonInitialState) => {
      state.isLessonInEditMode = false;
      state.currentLessonData = initialState.currentLessonData;
    },
    setWorkshopDetailsPage: (state: WorkshopLessonInitialState, { payload }) => {
      state.page = payload;
      state.loadingMore = true;
    },
    setWorkshopDetailsSort: (state: WorkshopLessonInitialState, { payload }) => {
      state.sort = payload;
      state.page = INITIAL_PAGE;
    },
    clearWorkshopLessonState: (state: WorkshopLessonInitialState) => {
      state.lessons = initialState.lessons;
      state.page = initialState.page;
      state.sortColumns = initialState.sortColumns;
      state.totalPages = initialState.totalPages;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFilteredLessonInWorkshop.pending, (state: WorkshopLessonInitialState) => {
      state.areLessonsLoading = true;
    });
    builder.addCase(fetchFilteredLessonInWorkshop.fulfilled, (state: WorkshopLessonInitialState, { payload }) => {
      state.areLessonsLoading = false;

      if (state.loadingMore) {
        // @ts-ignore // TODO: handle ts issues on state
        state.lessons = [].concat(...state.lessons, ...payload.content);
        state.loadingMore = false;
      } else {
        // @ts-ignore
        state.lessons = payload.content;
      }

      // @ts-ignore
      state.totalPages = payload.totalPages;
    });
    builder.addCase(fetchFilteredLessonInWorkshop.rejected, (state: WorkshopLessonInitialState) => {
      state.areLessonsLoading = false;
    });
    builder.addCase(
      togglePublishLesson.pending,
      (
        state: WorkshopLessonInitialState,
        {
          meta: {
            arg: { id },
          },
        },
      ) => {
        state.isPublishing = [...state.isPublishing, id];
      },
    );
    builder.addCase(
      togglePublishLesson.fulfilled,
      (
        state: WorkshopLessonInitialState,
        {
          meta: {
            arg: { id, checked },
          },
        },
      ) => {
        const lessonIndex = state.lessons?.findIndex((lesson: Lesson) => lesson.id === id);

        if (lessonIndex > -1) {
          state.lessons[lessonIndex].published = checked;
        }

        state.isPublishing = state.isPublishing.filter((lessonId) => lessonId !== id);
      },
    );
    builder.addCase(
      togglePublishLesson.rejected,
      (
        state: WorkshopLessonInitialState,
        {
          meta: {
            arg: { id },
          },
        },
      ) => {
        state.isPublishing = state.isPublishing.filter((lessonId) => lessonId !== id);
      },
    );
  },
});

export const getFilteredLessonsInWorkshop = (state: ReduxStoreType) => ({
  lessons: state[sliceName].lessons,
});

export const getCurrentLessonInWorkshop = (state: ReduxStoreType) => ({
  currentLesson: state[sliceName].currentLessonData,
  isEditMode: state[sliceName].isLessonInEditMode,
});

export const getLessonsInWorkshopState = (state: ReduxStoreType) => state[sliceName];

export const getLessonsIsPublishing = (state: ReduxStoreType): string[] => state[sliceName].isPublishing;

export const {
  clearCurrentLesson,
  clearWorkshopLessonState,
  setCurrentLesson,
  setWorkshopDetailsPage,
  setWorkshopDetailsSort,
} = WorkshopLessonsReducer.actions;

export default WorkshopLessonsReducer.reducer;
