import { FC, ReactNode } from 'react';

import Icon from '../vfIcon';
import './_expandable-item.scss';
import withExpandable from './withExpandable';

export const getItemsHint = (usedItems: Array<Object> | string | undefined) => {
  if (Array.isArray(usedItems)) {
    return usedItems.length || 'NONE';
  }

  return usedItems ? null : 'NONE';
};

interface ExpandableTaskItemProps {
  asTableRow?: boolean;
  hideHint?: boolean;
  iconClasses: string;
  isEditable?: boolean;
  title: string;
  usedItems?: Array<Object> | string | undefined;
  toolTip?: ReactNode;
}

const ExpandableTaskItem: FC<ExpandableTaskItemProps> = ({
  asTableRow,
  hideHint,
  iconClasses,
  isEditable,
  title,
  usedItems,
  toolTip,
}) => {
  const itemHint = getItemsHint(usedItems);
  const showIndicator = isEditable || itemHint !== 'NONE';
  const isItemTitleRestricted = !hideHint && !!itemHint;

  if (asTableRow) {
    return (
      <tr>
        {showIndicator && <span className={iconClasses} />}
        {!showIndicator && <span className="expandable-item__empty" />}

        <span className="expandable-item__title">
          {title}
          {isItemTitleRestricted && <span className="expandable-item__count">{`(${itemHint})`}</span>}
        </span>
      </tr>
    );
  }

  return (
    <>
      {showIndicator && <Icon className={iconClasses} name="chevron" />}
      {!showIndicator && <span className="expandable-item__empty" />}
      <div className="expandable-item--container">
        <span className="expandable-item__title">
          {title}
          {isItemTitleRestricted && <span className="expandable-item__count">{`(${itemHint})`}</span>}
        </span>
        {toolTip}
      </div>
    </>
  );
};

ExpandableTaskItem.defaultProps = {
  asTableRow: false,
  hideHint: false,
  isEditable: false,
  usedItems: [],
};

export default withExpandable(ExpandableTaskItem);
