import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormikValues, useField, useFormikContext } from 'formik';

import { AsyncThunk } from '@reduxjs/toolkit';

import { mapStructureToOptions, StructureBase } from '../../models/structure';

import { StructureTypes } from '../../store/structure';

import { useDropdownSelection } from '../../utils/hooks';

import { ReduxStoreType } from '../../store';
import VfAutoComplete, { AutocompleteProps, Option } from '../vfDesign/vfAutocomplete';
import { NO_DATA_OPTION } from '../vfDesign/vfDropdown/helpers';
import { Param, StructureEntities } from './Dropdown';

interface FormikAutoCompleteProps extends AutocompleteProps {}

const FormikAutoComplete: FC<FormikAutoCompleteProps> = ({ ...rest }) => {
  const [, , helpers] = useField(rest.name!);

  const onClick = (name: string, value: string | boolean) => {
    helpers.setValue(value);
  };

  return <VfAutoComplete {...rest} onClick={onClick} />;
};

interface WithReduxDataProps {
  className?: string;
  disabled?: boolean;
  dispatchFn: AsyncThunk<any[], any, {}>;
  fetchOn?: Param;
  groupedNamesConfig?: Record<string, string>;
  isGroupedOptions?: boolean;
  label: string;
  labelKey: string;
  name: string;
  onDismountCallback?: AsyncThunk<any[], void, {}>;
  onSelectCallback?: (option: StructureEntities) => void;
  params?: Param;
  parentField?: string[];
  relatedFields?: string[];
  selector: (state: ReduxStoreType) => StructureTypes | any;
  setByLabel?: boolean;
  shouldFetchOnMount?: boolean;
  skipFields?: string[];
  storedData?: StructureEntities[];
}

const withReduxData = <P extends WithReduxDataProps>(BaseComponent: FC<AutocompleteProps>) => {
  const AutocompleteWrappedWithReduxData: FC<P> = ({
    disabled,
    dispatchFn,
    fetchOn,
    groupedNamesConfig,
    isGroupedOptions,
    labelKey,
    name,
    onDismountCallback,
    onSelectCallback,
    params = {},
    parentField,
    storedData = [],
    relatedFields,
    selector,
    setByLabel,
    shouldFetchOnMount = false,
    skipFields,
    ...rest
  }) => {
    const dispatch = useDispatch();

    const { setFieldValue, setFieldTouched, setErrors, values, errors, touched } = useFormikContext<FormikValues>();
    const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
    const { data, isLoading } = useSelector(selector);
    const { onClick, onClear } = useDropdownSelection({
      data,
      groupedNamesConfig,
      isGroupedOptions,
      isSetByLabel: setByLabel,
      name,
      onSelectCallback,
      options: filteredOptions,
      relatedFields,
      setFieldValue,
    });

    const providedData: StructureEntities[] = storedData.length ? storedData : data;

    const skip = skipFields?.length
      ? Object.entries(values).some(([key, val]) => {
          // check if at least one parent value is empty
          return skipFields.includes(key) && !val;
        })
      : false;

    useEffect(() => {
      if (shouldFetchOnMount) {
        dispatch(dispatchFn(params));
      }

      return () => {
        if (onDismountCallback) {
          dispatch(onDismountCallback());
        }
      };
    }, []); //eslint-disable-line

    const previousParentVal = useRef<{ [key: string]: any }>(
      (parentField || []).reduce((obj, item) => {
        return {
          ...obj,
          [item]: values[item],
        };
      }, {}),
    );

    useEffect(() => {
      parentField?.forEach((f) => {
        if (values[f] !== previousParentVal.current[f]) {
          if (values[f] === '') {
            dispatch(onDismountCallback?.());
            return;
          }
        }
      });
    }, [JSON.stringify(values)]); //eslint-disable-line

    useEffect(() => {
      const keysData = Object.keys(values);
      const valuesData = Object.values(values);
      keysData.map((data, index) => setFieldValue(data, valuesData[index]))
    }, [values, errors, setErrors, setFieldValue]);

    useEffect(() => {
      if (!skip && Object.keys(values).length) {
        dispatch(dispatchFn(params));
      }
    }, [JSON.stringify(params), JSON.stringify(fetchOn)]); //eslint-disable-line

    useEffect(() => {
      setFilteredOptions(prepareOptions(providedData));
    }, [providedData]); //eslint-disable-line

    const prepareOptions = (data: StructureTypes[]) => {
      if (providedData?.length > 0) {
        return mapStructureToOptions(data, (el: StructureBase) => ({
          label: el[labelKey],
          value: el.id,
          group: el.group || '',
        }));
      }
      return NO_DATA_OPTION;
    };

    const onChange = (value: string) => {
      if (!value) {
        onClear();
        setFilteredOptions(prepareOptions(providedData));
        return;
      }
      setFilteredOptions(filteredOptions.filter((opt) => opt.label.toLowerCase().includes(value.toLowerCase())));
    };

    const onBlur = () => {
      setFieldTouched(name, true);
    };

    const currentOpt = filteredOptions.find((opt) => opt.value === values[name]);

    const options = !isLoading ? filteredOptions : [];
    const isError = errors[name] && touched[name] && String(errors[name]);

    return (
      <div className="w-100 d-flex justify-content-start align-items-center flex-column mt-2">
        <BaseComponent
          {...rest}
          disabled={disabled}
          error={isError}
          groupedNamesConfig={groupedNamesConfig}
          isGroupedOptions={isGroupedOptions}
          id={name}
          isLoading={isLoading}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onClear={onClear}
          onClick={onClick}
          options={options}
          preserveValue={true}
          value={currentOpt}
          storedData={storedData}
        />
      </div>
    );
  };

  return AutocompleteWrappedWithReduxData;
};

withReduxData.defaultProps = {
  disabled: false,
  fetchOn: {},
  groupedNamesConfig: {},
  isGroupedOptions: false,
  params: {},
  parentField: [],
  setByLabel: false,
  shouldFetchOnMount: false,
};

const AutoCompleteWithReduxData = withReduxData(VfAutoComplete);

export { FormikAutoComplete as default, AutoCompleteWithReduxData };
