import { ChangeEvent, FC, Fragment, useState } from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { handleFormikValueRelation, sortLessonPreview } from '../../../utils/functions';
import PriorityTooltipMessage from '../../../utils/tooltipMessages';

import { FlowOption, FLOW_TYPES, PRIORITIES } from '../../../consts/newLessonForm';
import Tooltip from '../../Tooltip';
import { VfCheckbox, VfHelperText, VfIcon } from '../../vfDesign';
import { Option } from '../../vfDesign/vfRadios';
import styles from '../Shared.module.scss';
import { ProjectLessonType } from '../types';
import { OPERATING_SITE_TYPE_LABELS, ORGANIZATIONAL_UNIT_TYPE_LABELS, PROJECT_TYPE_LABELS } from './labels';

interface StringPair {
  [key: string]: string;
}

interface FormValue extends ProjectLessonType {
  priority: string;
  priorityJustification: string;
  title: string;
}

const TMP_REQUIRED_FIELD = ['projectId', 'projectTypeId', 'workstreamId', 'processId'];

interface Summary {
  currentFlow?: FlowOption;
}

interface CheckboxGroupProps {
  options: Option[];
  onPriorityChange: (value: string) => void;
}

const PriorityCheckboxGroup = ({ options = [], onPriorityChange }: CheckboxGroupProps) => {
  const [selectedValue, setSelectedValue] = useState<string>('');

  const setPriority = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (selectedValue === value) {
      // if the clicked checkbox is already selected, uncheck it
      setSelectedValue('');
      onPriorityChange('');
    } else {
      setSelectedValue(value);
      onPriorityChange(value);
    }
  };

  return (
    <div className={styles.priorityCheckboxes}>
      <div className={styles.priorityCheckboxes__title}>
        Priority{` `}
        <Tooltip text={<PriorityTooltipMessage />}>
          <VfIcon name="info" />
        </Tooltip>
      </div>

      <div className={styles.priorityCheckboxes__wrapper}>
        {options.map((option) => (
          <div key={option.value} className={styles.priorityCheckboxes__checkbox}>
            <VfCheckbox
              key={option.value}
              id={option.value}
              name={option.label}
              value={option.value}
              checked={selectedValue === option.value}
              onChange={setPriority}
            />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export interface IListedValueItemProps {
  className: string;
  value: any;
}

export const ListedValueItem: FC<IListedValueItemProps> = ({ value, className }) => {
  const transformedValue = value.map((item: any) => item?.name || item.replace('_', '-')).join(', ');

  return <p className={className}>{transformedValue}</p>;
};

const ProjectSummary: FC<Summary> = ({ currentFlow }) => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<FormValue>();

  const setTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('title', e.target.value);
  };

  const setPriority = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('priorityJustification', e.target.value);
  };

  const handlePriorityChange = (priority: string) => {
    setFieldValue('priority', priority);
    if (values.priority !== 'HIGH') {
      setFieldValue('priorityJustification', '');
    }
  };

  const onTitleBlur = () => setFieldTouched('title');
  const onPriorityBlur = () => setFieldTouched('priorityJustification');

  const isHighPrioritySet = values.priority === 'HIGH';

  const isTitleError = touched?.title && errors?.title;
  const isPriorityError = isHighPrioritySet && touched?.priorityJustification && errors?.priorityJustification;

  const classes = classNames(styles.summary__textarea, {
    [styles['textarea--error']]: isTitleError || isPriorityError,
  });

  const flowTypeLabels = (() => {
    switch (currentFlow) {
      case FLOW_TYPES.PROJECT:
        return PROJECT_TYPE_LABELS;
      case FLOW_TYPES.OPERATING_SITE:
        return OPERATING_SITE_TYPE_LABELS;
      case FLOW_TYPES.ORGANIZATIONAL_UNIT:
        return ORGANIZATIONAL_UNIT_TYPE_LABELS;
      default:
        return PROJECT_TYPE_LABELS;
    }
  })();

  const renderSummary = Object.entries(values)
    .sort(sortLessonPreview)
    .map(([key, val]) => {
      if (key in flowTypeLabels) {
        if (key === 'owner' || key === 'deputy') {
          if (!val.displayName) {
            return null;
          }

          const errorText = (errors as StringPair)[key];
          const fieldTouched = (touched as StringPair)[key];

          return (
            <Fragment key={key}>
              <div className={styles.pair}>
                <h5 className={styles.summary__label}>{flowTypeLabels[key]}</h5>
                <p key={val.name} className={styles.summary__value}>
                  {val.displayName}
                </p>
              </div>
              {errorText && fieldTouched && <VfHelperText type="error" text={flowTypeLabels[key]} />}
            </Fragment>
          );
        }
        if (val instanceof Array) {
          const errorText = (errors as StringPair)[key];
          const fieldTouched = (touched as StringPair)[key];

          return (
            <Fragment key={key}>
              <div className={styles.pair}>
                <h5 className={styles.summary__label}>{flowTypeLabels[key]}</h5>
                <ListedValueItem value={val} className={styles.summary__value} />
              </div>
              {errorText && fieldTouched && <VfHelperText type="error" text={flowTypeLabels[key]} />}
            </Fragment>
          );
        }

        const errorText = (errors as StringPair)[key];
        const showError = TMP_REQUIRED_FIELD.includes(key) ? errorText && !val : errorText; // tmp solution, formik
        // doesn't update errors
        // even if value added and
        // field only to be
        // required

        return (
          <Fragment key={key}>
            <div className={styles.pair}>
              <p className={styles.summary__label}>{flowTypeLabels[key]}</p>
              <p className={styles.summary__value}>{handleFormikValueRelation(key, val, values)}</p>
            </div>
            {showError && <VfHelperText type="error" text={errorText} />}
          </Fragment>
        );
      }

      return null;
    });

  return (
    <div className={styles.fullscreen}>
      <h5 className={styles.lead}>Review and provide a clear title to your lesson</h5>

      <div className={styles.summary}>{renderSummary}</div>

      <input
        className={classes}
        placeholder="Lesson title * : What are the main subjects of your lesson?"
        onChange={setTitle}
        value={values.title}
        onBlur={onTitleBlur}
      />
      {isTitleError && <VfHelperText type="error" text={isTitleError} />}

      {isHighPrioritySet && (
        <input
          className={classes}
          placeholder="High priority justification * : What is the reason to set high priority?"
          onChange={setPriority}
          value={values.priorityJustification}
          onBlur={onPriorityBlur}
        />
      )}
      {isPriorityError && <VfHelperText type="error" text={isPriorityError} />}

      <PriorityCheckboxGroup options={PRIORITIES} onPriorityChange={handlePriorityChange} />
    </div>
  );
};

export default ProjectSummary;
