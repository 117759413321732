import { AxiosError } from 'axios';
import { compare } from 'fast-json-patch';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LessonDetails } from '../models/lessonDetails';
import { Tag } from '../models/tags';
import { ADUser } from '../models/user';

import { handleApiError, updateLessonDetailsFields } from '../utils/functions';

import { ReduxStoreType } from '.';
import { NOTIFICATION_TYPES } from '../components/vfDesign/vfNotification';
import { IFilterProps } from '../pages/Admin/LessonsWithNoActiveActionTaker/consts';
import Api from '../services/api';
import { partialUpdateLesson, removeLesson, updateLesson } from './lessons';
import { fetchLessonsWithNoAT, updateSingleLessonWithNoAt } from './lessonsWithNoActionTaker';
import { setNotification } from './notifications';

export const sliceName = 'lessonDetails';

export interface LessonDetailsState {
  data: LessonDetails;
  isDeleting: string[];
  isLoading: boolean;
  hasError: boolean;
}

export interface IUpdateLessonTagsParams {
  lessonId: string;
  tags: Tag[];
}

export const fetchLessonDetails = createAsyncThunk(`${sliceName}/fetch`, async (id: string, { dispatch }) => {
  try {
    const response = await Api.get<LessonDetails>(`/lessons/${id}`);

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch lesson details', dispatch, false);

    setTimeout(() => {
      dispatch({
        type: resetState,
      });
    }, 400);

    throw err;
  }
});

export const updateLessonDetails = createAsyncThunk(
  `${sliceName}/update`,
  async (
    {
      lesson,
      shouldUpdateATLesson,
      actionTakerFilter,
    }: {
      lesson: Partial<LessonDetails>;
      shouldUpdateATLesson?: boolean;
      actionTakerFilter?: IFilterProps;
    },
    { getState, dispatch },
  ) => {
    try {
      const state = getState() as ReduxStoreType;
      const lessonToUpdate = updateLessonDetailsFields(lesson);
      const jsonPatchData = compare(state[sliceName].data, lessonToUpdate);
      const response = await Api.patch<LessonDetails>(`/lessons/${lesson.id}`, jsonPatchData, {}, true);

      dispatch(updateLesson(response.data));

      if (shouldUpdateATLesson && actionTakerFilter && lesson?.id) {
        dispatch(fetchLessonsWithNoAT(actionTakerFilter));
      }
      dispatch(setNotification('Lesson was successfully updated', NOTIFICATION_TYPES.SUCCESS));

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not update lesson', dispatch);

      throw err;
    }
  },
);

export interface IBulkUpdateLessonsParams {
  lessonIds: LessonDetails['id'][];
  newActionTakerDeputy?: LessonDetails['actionTakerDeputy'];
  newActionTaker?: LessonDetails['actionTaker'];
  resetActionTakerDeputy?: boolean;
}

interface ILessonUpdated extends Pick<LessonDetails, 'id' | 'title'> {
  failureReason?: string;
}

interface IUserRemoved {
  numberOfLessons: number;
  user: ADUser;
}

export interface IBulkUpdateLessonsResponse {
  lessonsUpdated: ILessonUpdated[];
  lessonsNotUpdated: ILessonUpdated[];
  usersRemovedFromActionTakerRole: IUserRemoved[];
  usersRemovedFromActionTakerDeputyRole: IUserRemoved[];
}

export const bulkUpdateLessons = createAsyncThunk(
  `${sliceName}/update-bulk`,
  async (
    { lessonIds, newActionTakerDeputy, newActionTaker, resetActionTakerDeputy }: IBulkUpdateLessonsParams,
    { dispatch },
  ) => {
    try {
      const params = {
        lessonIds,
        newActionTakerDeputy: resetActionTakerDeputy ? '' : newActionTakerDeputy?.id,
        newActionTaker: newActionTaker?.id,
        resetActionTakerDeputy,
      };

      const response = await Api.post<IBulkUpdateLessonsResponse>(`/lessons/bulk-update`, params);

      const updatedLessonsIds = response.data.lessonsUpdated.map((lesson) => lesson.id);
      dispatch(
        partialUpdateLesson({
          actionTaker: newActionTaker,
          actionTakerDeputy: newActionTakerDeputy,
          lessonIds: updatedLessonsIds,
          resetActionTakerDeputy,
        }),
      );

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not update lessons', dispatch);

      throw err;
    }
  },
);

export const takeAction = createAsyncThunk(
  `${sliceName}/takeAction`,
  async (
    {
      lesson,
      shouldUpdateATLesson,
    }: {
      lesson: Partial<LessonDetails>;
      shouldUpdateATLesson?: boolean;
    },
    { getState, dispatch },
  ) => {
    try {
      const state = getState() as ReduxStoreType;
      const lessonToTakeAction = updateLessonDetailsFields(lesson);
      const jsonPatchData = compare(state[sliceName].data, lessonToTakeAction);
      const response = await Api.patch<LessonDetails>(`/lessons/${lesson.id}/takeaction`, jsonPatchData, {}, true);

      dispatch(updateLesson(response.data));

      if (shouldUpdateATLesson && lesson?.id) {
        dispatch(updateSingleLessonWithNoAt(lesson.id));
      }

      dispatch(setNotification('The action has been performed on lesson', NOTIFICATION_TYPES.SUCCESS));

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not take action on lesson', dispatch);

      throw err;
    }
  },
);

export const deleteLesson = createAsyncThunk(
  `${sliceName}/delete`,
  async (lessonId: string, { getState, dispatch }) => {
    try {
      const response = await Api.delete<LessonDetails>(`/lessons/${lessonId}`);

      dispatch(removeLesson(lessonId));

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not update lesson', dispatch);

      throw err;
    }
  },
);

export const updateLessonTags = createAsyncThunk(
  `${sliceName}/updateTags`,
  async ({ lessonId, tags }: IUpdateLessonTagsParams, { getState, dispatch }) => {
    try {
      const response = await Api.patch<Tag[]>(`/lessons/${lessonId}/tags`, {
        selectedTagIds: tags.map((tag) => tag.id),
      });

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not update lessons tags', dispatch);

      throw err;
    }
  },
);

export const initialState: LessonDetailsState = {
  data: {} as LessonDetails,
  isDeleting: [],
  isLoading: false,
  hasError: false,
};

const resetState = 'RESET_LESSON_DETAILS';

export const lessonDetailsReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    toggleFavoriteInLessonDetails: (state) => {
      state.data.favourite = !state.data.favourite;
    },
    clearLessonDetails: (state) => {
      state.data = {} as LessonDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetState, (state) => {
      state.hasError = false;
    });
    builder.addCase(fetchLessonDetails.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(fetchLessonDetails.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(fetchLessonDetails.rejected, (state) => {
      state.isLoading = false;
      state.hasError = true;
    });

    builder.addCase(updateLessonDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateLessonDetails.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(updateLessonDetails.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(takeAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(takeAction.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(takeAction.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(deleteLesson.pending, (state, { meta: { arg: lessonId } }) => {
      state.isDeleting = [...state.isDeleting, lessonId];
    });
    builder.addCase(deleteLesson.fulfilled, (state, { meta: { arg: lessonId } }) => {
      state.isDeleting = state.isDeleting.filter((id) => id !== lessonId);
    });
    builder.addCase(deleteLesson.rejected, (state, { meta: { arg: lessonId } }) => {
      state.isDeleting = state.isDeleting.filter((id) => id !== lessonId);
    });

    builder.addCase(updateLessonTags.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateLessonTags.fulfilled, (state, { payload }) => {
      state.data.tags = payload;
      state.isLoading = false;
    });
    builder.addCase(updateLessonTags.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { toggleFavoriteInLessonDetails, clearLessonDetails } = lessonDetailsReducer.actions;

export const getLessonDetails = (state: ReduxStoreType): LessonDetailsState => state[sliceName];
export const getLessonDetailsIsLoading = (state: ReduxStoreType): boolean => state[sliceName].isLoading;
export const selectLessonError = (state: ReduxStoreType): boolean => state[sliceName].hasError;

export default lessonDetailsReducer.reducer;
