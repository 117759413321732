import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { InstructionLink, NewInstructionLink } from '../models/InstructionLinks';

import { handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import Api from '../services/api';

export const sliceName = 'instructionLink';

export interface InstructionLinkState {
  data: InstructionLink[];
  isLoading: boolean;
}

export const fetchInstructionLinks = createAsyncThunk(`${sliceName}/fetch`, async (_, { dispatch }) => {
  try {
    const response = await Api.get<InstructionLink[]>('instruction-link');

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch instruction links', dispatch);

    throw err;
  }
});

export const addInstructionLink = createAsyncThunk(
  `${sliceName}/add`,
  async (body: NewInstructionLink, { dispatch }) => {
    try {
      const response = await Api.post<InstructionLink>('instruction-link', body);

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not add instruction links', dispatch);

      throw err;
    }
  },
);

export const removeInstructionLink = createAsyncThunk(`${sliceName}/remove`, async (id: string, { dispatch }) => {
  try {
    const response = await Api.delete<InstructionLink>(`instruction-link/${id}`);

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not remove instruction links', dispatch);

    throw err;
  }
});

export const initialState: InstructionLinkState = {
  data: [],
  isLoading: false,
};

export const instructionLinksReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInstructionLinks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchInstructionLinks.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });

    builder.addCase(fetchInstructionLinks.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(addInstructionLink.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addInstructionLink.fulfilled, (state, { payload }) => {
      state.data = [...state.data, payload];
      state.isLoading = false;
    });

    builder.addCase(addInstructionLink.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(removeInstructionLink.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(removeInstructionLink.fulfilled, (state, { meta: { arg: id } }) => {
      state.data = state.data.filter((data) => data.id !== id);
      state.isLoading = false;
    });

    builder.addCase(removeInstructionLink.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getInstructionLinks = (state: ReduxStoreType): InstructionLinkState => state[sliceName];
export const getInstructionLinksIsLoading = (state: ReduxStoreType): boolean => state[sliceName].isLoading;

export default instructionLinksReducer.reducer;
