import { dashSpacedToCapitalize } from '../../utils/functions';

import { CreateDateOption, CREATE_DATE_TYPES } from '../../consts/lessons';
import { FlowOption, FLOW_TYPES } from '../../consts/newLessonForm';

export interface IFilterLabels {
  [key: string]: string;
}

export interface ILessonTypeOption {
  value: FlowOption;
  label: string;
}

interface ICreateDateTypeOption {
  value: CreateDateOption;
  label: string;
}

export interface IGroupConfingName {
  projectSites: Record<string, string>;
  projectTypesDeparments: Record<string, string>;
}

export const GROUPED_NAMES_CONFIG: IGroupConfingName = {
  projectSites: {
    SITE: 'operatingSiteId',
    PROJECT: 'projectId',
  },
  projectTypesDeparments: {
    DEPARTMENT: 'departmentId',
    PROJECT_TYPE: 'projectTypeId',
  },
};

export const LessonTypeOptions: ILessonTypeOption[] = [
  FLOW_TYPES.PROJECT,
  FLOW_TYPES.OPERATING_SITE,
  FLOW_TYPES.ORGANIZATIONAL_UNIT,
].map((flowType) => ({
  label: dashSpacedToCapitalize(flowType),
  value: flowType,
}));

export const FILTER_LABELS: IFilterLabels = {
  actionTakerDeputyId: 'Action taker deputy',
  actionTakerId: 'Action Taker',
  afterDueDate: 'Due date passed',
  createDateLimitation: 'Date added',
  creatorId: 'Author',
  delayed: 'Delayed',
  departmentId: 'Department',
  favourites: 'Favourites',
  improvementDelivered: 'Improvement',
  olc: 'OLC',
  operatingSiteId: 'Site',
  organizationId: 'Business Unit / Organization',
  origin: 'Lesson experienced in',
  poked: 'Poked',
  priority: 'Priority',
  processId: 'Process',
  projectId: 'Project',
  projectTypeId: 'Project type',
  states: 'Status',
  tags: 'Tag',
  tgPhase: 'Project Phase',
  workstreamId: 'Workstream',
};

export const dateOptions: ICreateDateTypeOption[] = [
  CREATE_DATE_TYPES.LAST_WEEK,
  CREATE_DATE_TYPES.LAST_MONTH,
  CREATE_DATE_TYPES.LAST_YEAR,
  CREATE_DATE_TYPES.CUSTOM_DATE,
].map((dateType) => ({
  label: dashSpacedToCapitalize(dateType),
  value: dateType,
}));

export const ORIGIN_DEPENDENT_FIELDS: string[] = [
  'departmentId',
  'operatingSiteId',
  'organizationId',
  'processId',
  'projectId',
  'projectTypeId',
  'workstreamId',
];

export const ORIGIN_DEPENDENT_FIELDS_NAMES: string[] = [
  'departmentName',
  'operatingSiteName',
  'organizationName',
  'phaseName',
  'processName',
  'projectName',
  'projectTypeName',
  'workstreamName',
];

export const DEPENDEND_FILTER_IDS_FOR_WORKSTREAM_AND_PROCESSES: string[] = ['projectId', 'operatingSiteId'];

export const USER_SELECTION_FIELDS: string[] = ['actionTakerDeputyId', 'actionTakerId', 'creatorId'];
export const USER_SELECTION_FIELDS_NAMES: string[] = ['actionTakerName', 'creatorName', 'actionTakerDeputyName'];

export const FIELDS_TO_REPLACE_ID_WITH_NAME: string[] = [...ORIGIN_DEPENDENT_FIELDS, ...USER_SELECTION_FIELDS];
export const FIELDS_NAMES_ONLY: string[] = [...ORIGIN_DEPENDENT_FIELDS_NAMES, ...USER_SELECTION_FIELDS_NAMES];

export const ORIGIN_DEPENDENT_FIELDS_WITH_NAMES: string[] = [
  ...ORIGIN_DEPENDENT_FIELDS,
  ...ORIGIN_DEPENDENT_FIELDS_NAMES,
];

export const BU_ORGANIZATION_DEPENDENT_FIELDS: string[] = [
  'workstreamId',
  'processId',
  'projectId',
  'departmentId',
  'projectTypeId',
];

export const PROJECT_TYPES_DEPARTMENT_DEPENDENT_FIELDS: string[] = [
  'workstreamId',
  'processId',
  'projectId',
  'operatingSiteId',
];

export const CUSTOM_DATE_DEPENDENT_FIELDS: string[] = ['startDate', 'endDate', 'customDate'];

export const PROJECTS_SITES_DEPENDENT_FIELDS: string[] = ['workstreamId', 'processId'];

export const DELETE_RELATIONS: Record<string, string[]> = {
  departmentId: PROJECT_TYPES_DEPARTMENT_DEPENDENT_FIELDS,
  operatingSiteId: PROJECTS_SITES_DEPENDENT_FIELDS,
  organizationId: BU_ORGANIZATION_DEPENDENT_FIELDS,
  origin: ORIGIN_DEPENDENT_FIELDS,
  projectId: PROJECTS_SITES_DEPENDENT_FIELDS,
  projectTypeId: PROJECT_TYPES_DEPARTMENT_DEPENDENT_FIELDS,
  workstreamId: ['processId'],
  createDateLimitation: CUSTOM_DATE_DEPENDENT_FIELDS,
};
