import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';

import { InstructionLinkType } from '../../../models/InstructionLinks';

import { addInstructionLink, getInstructionLinksIsLoading } from '../../../store/instructionLink';

import Drawer from '../../../components/Drawer';
import { Input } from '../../../components/Forms';
import { VfButton, VfDropdown } from '../../../components/vfDesign';
import { useAppDispatch } from '../../../root';
import styles from './AddLink.module.scss';

export interface AddLinkProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const validationSchema = object().shape({
  url: string()
    .url('Invalid URL format')
    .matches(/^https:\/\//, 'URL must start with https')
    .required('This field is required')
    .max(255, 'Name can be 255 chars at most'),
  name: string().required('This field is required').max(255, 'Link name can be 255 chars at most'),
  type: string().required('This field is required').max(255, 'Link type can be 255 chars at most'),
});

const AddLink: FC<AddLinkProps> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(getInstructionLinksIsLoading);
  const initialValues = { url: '', name: '', type: InstructionLinkType.GUIDE };
  const typeOptions = Object.entries(InstructionLinkType).map(([key, value]) => ({ value: key, label: value }));

  const handleSubmit = async (values: typeof initialValues, { resetForm }: FormikHelpers<typeof initialValues>) => {
    if (values.type !== null) {
      dispatch(addInstructionLink(values));
    }

    resetForm();
    setOpen(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, resetForm, setFieldValue, setFieldTouched, errors, values }) => {
        const formHasErrors = !errors || Object.keys(errors).length > 0;
        const isDisabled = isSubmitting || isLoading || formHasErrors;

        return (
          <Drawer onClose={resetForm} open={open} rightSide setOpen={setOpen} title={'Add link'}>
            <Form className={styles['add-form']}>
              <Input className="mb-2" label="Link name" name="name" />

              <Input className="mb-2" label="URL" name="url" />

              <VfDropdown
                id="type"
                name="type"
                label="Type"
                value={values.type}
                options={typeOptions}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                showFullLabelText
                showMinifiedLabel
                fullWidth
              />

              <div className={styles.submit}>
                <VfButton
                  className={styles['submit__button']}
                  disabled={isDisabled}
                  fullWidth
                  htmlType="submit"
                  type="primary"
                >
                  Submit
                </VfButton>
              </div>
            </Form>
          </Drawer>
        );
      }}
    </Formik>
  );
};

export default AddLink;
