import sanitizeHtml from 'sanitize-html';

const sanitizeComment = (comment: string): string => {
  const newLineToWhiteSpace = comment.replace(/<div>|<\/div>|<br>/g, ' ');

  return sanitizeHtml(newLineToWhiteSpace, {
    allowedTags: [],
    allowedAttributes: {},
  });
};

const getCaretIndex = (elementId: string): number => {
  const element = document.querySelector(`div#${elementId} .mention-textarea`) as HTMLInputElement;
  const isSupported = typeof window.getSelection !== 'undefined';
  let position = 0;

  if (isSupported) {
    const selection = window.getSelection();
    if (selection && selection.rangeCount !== 0) {
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      position = preCaretRange.toString().length;
    }
  }

  element.focus();

  return position;
};

const placeCaretAtEnd = (elementId: string): void => {
  const element = document.querySelector(`div#${elementId} .mention-textarea`) as HTMLInputElement;
  element.focus();

  if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
    const range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false);
    const sel = window.getSelection();

    sel?.removeAllRanges();
    sel?.addRange(range);
  }
};

const getBase64 = async (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const content = (reader.result as string).split('base64,')[1];

      resolve({ fileName: file.name, content });
    };

    reader.onerror = (error) => reject(error);
  });

export { getBase64, getCaretIndex, placeCaretAtEnd, sanitizeComment };
