import { FC } from 'react';

import { FormikErrors } from 'formik';
import { v4 } from 'uuid';

import {
  Description,
  More,
  OperatingSiteBasics,
  OrganizationUnitBasis,
  ProjectBasics,
  Recommendation,
  Summary,
  Tags,
  Workstream,
} from '../../components/NewLessonForm/steps';
import OrganizationalUnitWorkstream from '../../components/NewLessonForm/steps/OrganizationalUnit/Workstream';
import { ProjectFormValues } from '../../components/NewLessonForm/types';
import { Option } from '../../components/vfDesign/vfRadios';

export type FlowOption = 'PROJECT' | 'OPERATING_SITE' | 'ORGANIZATION_UNIT';

const FLOW_TYPES: { [key: string]: FlowOption } = {
  PROJECT: 'PROJECT',
  OPERATING_SITE: 'OPERATING_SITE',
  ORGANIZATIONAL_UNIT: 'ORGANIZATION_UNIT',
};

export interface NewLessonFlow {
  type: FlowOption;
  steps: {
    id: string;
    name?: string;
    render: FC<{ currentFlow?: FlowOption }>;
    validate: (values: ProjectFormValues) => FormikErrors<ProjectFormValues>;
    clearStepInput?: { key: string; initialVal: any }[];
    showMandatory: boolean;
  }[];
}

const NEW_LESSON_FLOW: NewLessonFlow[] = [
  {
    type: FLOW_TYPES.PROJECT,
    steps: [
      {
        id: v4(),
        render: ProjectBasics,
        validate: (values) => {
          const errors: FormikErrors<ProjectFormValues> = {};

          if (!values.organizationId) {
            errors['organizationId'] = 'Field is required.';
          }

          if (!values.projectTypeId) {
            errors['projectTypeId'] = 'Field is required.';
          }

          if (!values.projectId) {
            errors['projectId'] = 'Field is required.';
          }

          return errors;
        },
        showMandatory: true,
      },
      {
        id: v4(),
        render: Tags,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          return errors;
        },
        showMandatory: false,
      },
      {
        id: v4(),
        render: Description,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          if (!values.description) {
            errors['description'] = 'Field is required.';
          }

          if (values?.description && values.description.length > 2000) {
            errors['description'] = 'Description can be 2000 chars at most';
          }

          return errors;
        },
        showMandatory: true,
      },
      {
        id: v4(),
        render: Recommendation,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          if (values?.recommendation && values.recommendation.length > 2000) {
            errors['recommendation'] = 'Recommendation can be 2000 chars at most';
          }

          return errors;
        },
        showMandatory: false,
      },
      {
        id: v4(),
        render: Workstream,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          if (!values.owner?.id && !values.workstreamId) {
            errors['owner'] = 'Either Action Taker or workstream is mandatory';
          }

          return errors;
        },
        clearStepInput: [
          { key: 'owner', initialVal: { id: '', displayName: '', email: '' } },
          { key: 'workstreamId', initialVal: '' },
          { key: 'workstreamName', initialVal: '' },
          { key: 'processId', initialVal: '' },
          { key: 'processName', initialVal: '' },
        ],
        showMandatory: true,
      },
      {
        id: v4(),
        render: More,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};
          if (values?.additionalInformation && values.additionalInformation.length > 2000) {
            errors['additionalInformation'] = 'Comment can be 2000 chars at most';
          }
          return errors;
        },
        showMandatory: false,
      },
      {
        id: v4(),
        render: Summary,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};
          if (!values.organizationId) {
            errors['organizationId'] = 'Field is required.';
          }

          if (!values.projectTypeId) {
            errors['projectTypeId'] = 'Field is required.';
          }

          if (!values.projectId) {
            errors['projectId'] = 'Field is required.';
          }

          if (values?.additionalInformation && values.additionalInformation.length > 2000) {
            errors['additionalInformation'] = 'Comment can be 2000 chars at most';
          }

          if (values?.description && values.description.length > 2000) {
            errors['description'] = 'Description can be 2000 chars at most';
          }

          if (values?.recommendation && values.recommendation.length > 2000) {
            errors['recommendation'] = 'Recommendation can be 2000 chars at most';
          }

          if (
            values?.priority === 'HIGH' &&
            values?.priorityJustification &&
            values.priorityJustification.length > 2000
          ) {
            errors['priorityJustification'] = 'Priority justification can be 2000 chars at most';
          }

          if (!values.title) {
            errors['title'] = 'Field is required.';
          }

          if (values?.priority === 'HIGH' && !values.priorityJustification) {
            errors['priorityJustification'] = 'Field is required.';
          }

          if (values.title && values.title.length > 255) {
            errors['title'] = 'Lesson title can be 255 chars at most';
          }

          return errors;
        },
        showMandatory: true,
      },
    ],
  },
  {
    type: FLOW_TYPES.OPERATING_SITE,
    steps: [
      {
        id: v4(),
        render: OperatingSiteBasics,
        validate: (values) => {
          const errors: FormikErrors<ProjectFormValues> = {};

          if (!values.organizationId) {
            errors['organizationId'] = 'Field is required.';
          }

          if (!values.projectTypeId) {
            errors['projectTypeId'] = 'Field is required.';
          }

          if (!values.operatingSiteId) {
            errors['operatingSiteId'] = 'Field is required.';
          }
          return errors;
        },
        showMandatory: true,
      },
      {
        id: v4(),
        render: Tags,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          return errors;
        },
        showMandatory: false,
      },
      {
        id: v4(),
        render: Description,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          if (!values.description) {
            errors['description'] = 'Field is required.';
          }

          if (values?.description && values.description.length > 2000) {
            errors['description'] = 'Description can be 2000 chars at most';
          }

          return errors;
        },
        showMandatory: true,
      },
      {
        id: v4(),
        render: Recommendation,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          if (values?.recommendation && values.recommendation.length > 2000) {
            errors['recommendation'] = 'Recommendation can be 2000 chars at most';
          }

          return errors;
        },
        showMandatory: false,
      },
      {
        id: v4(),
        render: Workstream,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          if (!values.owner?.id && !values.workstreamId) {
            errors['owner'] = 'Either Action Taker or workstream is mandatory';
          }

          return errors;
        },
        clearStepInput: [
          { key: 'owner', initialVal: { id: '', displayName: '', email: '' } },
          { key: 'workstreamId', initialVal: '' },
          { key: 'workstreamName', initialVal: '' },
          { key: 'processId', initialVal: '' },
          { key: 'processName', initialVal: '' },
        ],
        showMandatory: true,
      },
      {
        id: v4(),
        render: More,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};
          if (values?.additionalInformation && values.additionalInformation.length > 2000) {
            errors['additionalInformation'] = 'Comment can be 2000 chars at most';
          }
          return errors;
        },
        showMandatory: false,
      },
      {
        id: v4(),
        render: Summary,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};
          if (!values.organizationId) {
            errors['organizationId'] = 'Field is required.';
          }

          if (!values.projectTypeId) {
            errors['projectTypeId'] = 'Field is required.';
          }

          if (values?.additionalInformation && values.additionalInformation.length > 2000) {
            errors['additionalInformation'] = 'Comment can be 2000 chars at most';
          }

          if (values?.description && values.description.length > 2000) {
            errors['description'] = 'Description can be 2000 chars at most';
          }

          if (values?.recommendation && values.recommendation.length > 2000) {
            errors['recommendation'] = 'Recommendation can be 2000 chars at most';
          }

          if (!values.title) {
            errors['title'] = 'Field is required.';
          }

          if (values.title && values.title.length > 255) {
            errors['title'] = 'Lesson title can be 255 chars at most';
          }

          return errors;
        },
        showMandatory: true,
      },
    ],
  },
  {
    type: FLOW_TYPES.ORGANIZATIONAL_UNIT,
    steps: [
      {
        id: v4(),
        render: OrganizationUnitBasis,
        validate: (values) => {
          const errors: FormikErrors<ProjectFormValues> = {};

          if (!values.organizationId) {
            errors['organizationId'] = 'Field is required.';
          }

          if (!values.departmentId) {
            errors['departmentId'] = 'Field is required.';
          }

          return errors;
        },
        showMandatory: true,
      },
      {
        id: v4(),
        render: Tags,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          return errors;
        },
        showMandatory: false,
      },
      {
        id: v4(),
        render: Description,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          if (!values.description) {
            errors['description'] = 'Field is required.';
          }

          if (values?.description && values.description.length > 2000) {
            errors['description'] = 'Description can be 2000 chars at most';
          }

          return errors;
        },
        showMandatory: true,
      },
      {
        id: v4(),
        render: Recommendation,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          if (values?.recommendation && values.recommendation.length > 2000) {
            errors['recommendation'] = 'Recommendation can be 2000 chars at most';
          }

          return errors;
        },
        showMandatory: false,
      },
      {
        id: v4(),
        render: OrganizationalUnitWorkstream,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};

          if (!values.owner?.id && !values.workstreamId) {
            errors['owner'] = 'Either Action Taker or workstream is mandatory';
          }

          return errors;
        },
        clearStepInput: [
          { key: 'owner', initialVal: { id: '', displayName: '', email: '' } },
          { key: 'workstreamId', initialVal: '' },
          { key: 'workstreamName', initialVal: '' },
          { key: 'processId', initialVal: '' },
          { key: 'processName', initialVal: '' },
        ],
        showMandatory: true,
      },
      {
        id: v4(),
        render: More,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};
          if (values?.additionalInformation && values.additionalInformation.length > 2000) {
            errors['additionalInformation'] = 'Comment can be 2000 chars at most';
          }
          return errors;
        },
        showMandatory: false,
      },
      {
        id: v4(),
        render: Summary,
        validate: (values) => {
          const errors: FormikErrors<typeof values> = {};
          if (!values.organizationId) {
            errors['organizationId'] = 'Field is required.';
          }

          if (!values.departmentId) {
            errors['departmentId'] = 'Field is required.';
          }

          if (values?.additionalInformation && values.additionalInformation.length > 2000) {
            errors['additionalInformation'] = 'Comment can be 2000 chars at most';
          }

          if (values?.description && values.description.length > 2000) {
            errors['description'] = 'Description can be 2000 chars at most';
          }

          if (values?.recommendation && values.recommendation.length > 2000) {
            errors['recommendation'] = 'Recommendation can be 2000 chars at most';
          }

          if (!values.title) {
            errors['title'] = 'Field is required.';
          }

          if (values.title && values.title.length > 255) {
            errors['title'] = 'Lesson title can be 255 chars at most';
          }

          if (values.priorityJustification && values.priorityJustification.length > 2000) {
            errors['priorityJustification'] = 'Priority justification can be 2000 chars at most';
          }

          return errors;
        },
        showMandatory: true,
      },
    ],
  },
];

export const PRIORITIES: Option[] = [
  {
    label: 'Low',
    value: 'LOW',
  },

  {
    label: 'Medium',
    value: 'MEDIUM',
  },
  {
    label: 'High',
    value: 'HIGH',
  },
];

export { NEW_LESSON_FLOW as default, FLOW_TYPES };
