import { FC } from 'react';

import useInfoElement from '../../utils/hooks/useInfoElement';

import HomeTile from '../../components/HomeTile';
import { InfoElementNames } from '../../components/Tooltip/consts';
import { STATE_TAGNAMES } from '../../consts/lessons';
import styles from './Home.module.scss';
import { ReactComponent as AddLesson } from './addLesson.svg';
import { ReactComponent as AllLessons } from './allLessons.svg';
import homeImage1 from './images/home1.svg';
import homeImage3 from './images/home2.svg';
import homeImage2 from './images/home3.svg';
import { ReactComponent as MyLessons } from './myLessons.svg';
import { ReactComponent as TakeActions } from './takeActions.svg';

const Home: FC = () => {
  const [{ popupText: addLessonTooltipText }] = useInfoElement(InfoElementNames.ADD_LESSON);
  const [{ popupText: myLessonsTooltipText }] = useInfoElement(InfoElementNames.MY_LESSONS);
  const [{ popupText: takeActionTooltipText }] = useInfoElement(InfoElementNames.TAKE_ACTION);
  const [{ popupText: allLessonsTooltipText }] = useInfoElement(InfoElementNames.ALL_LESSONS);

  return (
    <>
      <div className={styles.homepage}>
        <div className="vf-container">
          <div className="vf-row">
            <div className="vf-col">
              <div className={styles.centeredContainer}>
                <h1 className={styles.headline}>How do we learn a lesson?</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="vf-container">
          <div className={styles['homepage__group-wrapper']}>
            <div className={styles['homepage__group']}>
              <img className={styles.image} src={homeImage1} alt="Home information" />
              <p>Experience in daily work</p>
            </div>
            <div className={styles['homepage__group']}>
              <img className={styles.image} src={homeImage2} alt="Home information" />
              <p>Identify & register lesson</p>
            </div>
            <div className={styles['homepage__group']}>
              <img className={styles.image} src={homeImage3} alt="Home information" />
              <p>Improve business processes</p>
            </div>
          </div>
          <div className={styles['homepage__group-flow-wrapper']}>
            <div className={styles['homepage__group-flow-step']}>
              <span className={styles['homepage__group-flow-step-arrow']} />

              <span className={styles['homepage__group-flow-step-indicator']}>I</span>
            </div>
            <div className={styles['homepage__group-flow-step']}>
              <span className={styles['homepage__group-flow-step-arrow']} />

              <span className={styles['homepage__group-flow-step-indicator']}>II</span>
              <span className={styles['homepage__group-flow-step-content']}>Continuous improvement</span>
            </div>
            <div className={styles['homepage__group-flow-step']}>
              <span className={styles['homepage__group-flow-step-arrow']} />

              <span className={styles['homepage__group-flow-step-indicator']}>III</span>
            </div>
          </div>
        </div>

        <div className="vf-container">
          <div className={styles.column__no__wrap__xl}>
            <HomeTile buttonText="Start" link="new-lesson" text="Add Lesson" tooltipText={addLessonTooltipText}>
              <AddLesson />
            </HomeTile>

            <HomeTile
              buttonText="Go"
              link={`/lessons/${STATE_TAGNAMES.TAKE_ACTION}`}
              text="Take Action"
              tooltipText={takeActionTooltipText}
            >
              <TakeActions />
            </HomeTile>

            <HomeTile
              buttonText="See all"
              link={`/lessons/${STATE_TAGNAMES.MY_LESSONS}`}
              text="My Submitted Lessons"
              tooltipText={myLessonsTooltipText}
            >
              <MyLessons />
            </HomeTile>

            <HomeTile
              buttonText="Go"
              link={`/lessons/${STATE_TAGNAMES.ALL_LESSONS}`}
              text="All Lessons"
              tooltipText={allLessonsTooltipText}
            >
              <AllLessons />
            </HomeTile>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
