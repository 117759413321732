import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { useState, useEffect, useRef } from 'react';

type EventData = {
  [key: string]: string;
}

export default function useCustomEvent(
  reactPlugin: ReactPlugin,
  eventName: string,
  eventData: EventData = {},
  skipFirstRun: boolean = true,
) {
  const [data, setData] = useState(eventData);
  const firstRun = useRef(skipFirstRun);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;

      return;
    }

    reactPlugin.trackEvent({ name: eventName }, { ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactPlugin, data, eventName]);

  return setData;
}

export { useCustomEvent };
