import { FC } from 'react';
import DatePicker from 'react-date-picker';

import classNames from 'classnames';
import { addSeconds } from 'date-fns';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { VfHelperText } from '..';
import VfOutlineLabel from '../vfOutlineLabel';
import './vf-calendar.scss';

type CalendarValue = Date | null;
export type RangeCalendarValue = [Date | null, Date] | Date | null;

const fixTimeZones = (val: CalendarValue, range: boolean = false): CalendarValue => {
  if (!val) {
    return val;
  }

  if (!range && val.toDateString() === new Date().toDateString()) {
    val.setUTCHours(23, 59, 59, 999);
  }

  const timeZoneOffset = val.getTimezoneOffset() * 60;

  return addSeconds(val, Math.abs(timeZoneOffset));
};

interface VfCalendarProps {
  calendarIcon?: JSX.Element;
  clearable?: boolean;
  customPlaceholder?: string;
  disabled?: boolean;
  error?: string;
  label?: string;
  language?: string;
  maxDate?: Date;
  minDate?: Date;
  name: string;
  rangePicker?: boolean;
  setFieldTouched: (name: string, value: boolean) => void;
  setFieldValue: (name: string, val: RangeCalendarValue) => void;
  value: Date | Date[] | null;
}

const VfCalendar: FC<VfCalendarProps> = ({
  calendarIcon = undefined,
  clearable = true,
  customPlaceholder = '',
  disabled = false,
  error = '',
  label = '',
  language = 'en',
  maxDate = undefined,
  minDate = undefined,
  name,
  rangePicker = false,
  setFieldTouched,
  setFieldValue,
  value = null,
}) => {
  const handleChange = (val: CalendarValue) => {
    setFieldValue(name, fixTimeZones(val));
  };

  const handleRangeChange = (val: RangeCalendarValue) => {
    if (Array.isArray(val)) {
      const startDate = fixTimeZones(val[0], true);

      setFieldValue(name, [startDate, val[1]]);
    } else {
      setFieldValue(name, val);
    }
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const inputClasses = classNames('vf-calendar__input', {
    'vf-calendar__input--error': error,
  });

  const props = {
    calendarIcon,
    className: inputClasses,
    clearIcon: clearable ? undefined : null,
    dayPlaceholder: 'dd',
    disabled,
    format: 'dd/MM/y',
    handleBlur,
    id: name,
    locale: language,
    maxDate,
    minDate,
    monthPlaceholder: 'mm',
    onBlur: handleBlur,
    onChange: handleChange,
    showLeadingZeros: true,
    value,
    yearPlaceholder: 'yyyy',
  };

  const rangeProps = {
    ...props,
    onChange: handleRangeChange,
  };

  return (
    <>
      {label && (
        <label htmlFor={name} className="my-2">
          {label}
        </label>
      )}

      <div className="vf-calendar">
        <VfOutlineLabel show={!!customPlaceholder} label={customPlaceholder} disabled={disabled} />
        {!rangePicker && <DatePicker {...props} />}
        {rangePicker && <DateRangePicker {...rangeProps} />}
        {error && (
          <div className="d-flex justify-content-center">
            <VfHelperText type="error" text={error} />
          </div>
        )}
      </div>
    </>
  );
};

export default VfCalendar;
