import React, { FC, MouseEvent } from 'react';

import classNames from 'classnames';
import { bool, func, string } from 'prop-types';

import IconConfig from './config';

interface IconProps {
  asButton?: boolean;
  className?: string;
  color?: string;
  disabled?: boolean;
  name: string;
  onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
  withBadge?: boolean;
  withOutline?: boolean;
}

const Icon: FC<IconProps> = ({
  asButton,
  className,
  color,
  disabled,
  name,
  onClick,
  withBadge,
  withOutline,
  ...rest
}) => {
  const config = IconConfig.find((icon) => icon.name === `icon-${name}`);

  const modificationClass = classNames(`icon-${name}`, color, className, {
    badge: withBadge,
    disabled,
    outlined: withOutline,
    pointer: asButton,
  });

  const onClickHandler = disabled ? undefined : onClick;

  const renderPaths = [...new Array(config ? config.pathCount : 0).keys()].map((index) => (
    <span key={`path${index + 1}`} className={`path${index + 1}`} />
  ));

  return (
    <span onClick={onClickHandler} className={modificationClass} {...rest}>
      {renderPaths}
    </span>
  );
};

Icon.propTypes = {
  asButton: bool,
  className: string,
  color: string,
  disabled: bool,
  name: string.isRequired,
  onClick: func,
  withBadge: bool,
  withOutline: bool,
};

Icon.defaultProps = {
  asButton: false,
  className: '',
  color: '',
  disabled: false,
  onClick: () => false,
  withBadge: false,
  withOutline: false,
};

export default Icon;
