import { ChangeEventHandler, FC, FormEvent, useState } from 'react';

import classNames from 'classnames';

import styles from './vfTextarea.module.scss';

export interface VfTextareaProps {
  className?: string;
  cols?: number;
  disabled?: boolean;
  error?: string;
  fullWidth?: boolean;
  id: string;
  label?: string;
  onBlur?: ChangeEventHandler | null;
  onChange?: ChangeEventHandler | null;
  placeholder?: string;
  rounded?: boolean;
  rows?: number;
  textareaStyles?: string;
  underline?: boolean;
  value?: string;
  warning?: string;
}

const VfTextarea: FC<VfTextareaProps> = ({
  className,
  cols,
  disabled,
  error,
  fullWidth,
  id,
  label,
  onBlur,
  onChange,
  placeholder,
  rounded,
  rows,
  textareaStyles,
  underline,
  value,
  warning,
}) => {
  const [val, setVal] = useState('');

  const elementValue = value ? value : val;

  const inputClasses = classNames(
    'vf-input',
    'w-100',
    {
      'vf-input--disabled': disabled,
      'vf-input--warning': warning,
      'vf-input--error': error,
      'vf-input--data': elementValue.length > 0,
      [styles['vf-input--data']]: elementValue.length > 0,
      [styles.rounded]: rounded,
      'vf-input--underline': underline,
    },
    styles.input,
    textareaStyles,
  );

  const containerClasses = classNames('vf-input-container', { 'w-100': fullWidth }, className);

  const localOnChangeHandler = (event: FormEvent<HTMLTextAreaElement>) => {
    setVal(event.currentTarget.value);
  };

  const localOnBlurHandler = (event: FormEvent<HTMLTextAreaElement>) => {
    setVal(event.currentTarget.value);
  };

  const onChangeHandler = onChange ? onChange : localOnChangeHandler;
  const onBlurHandler = onBlur ? onBlur : localOnBlurHandler;

  return (
    <div className={containerClasses}>
      <textarea
        disabled={disabled}
        className={inputClasses}
        cols={cols}
        data-tooltip={label}
        id={id}
        name={id}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        placeholder={placeholder}
        rows={rows}
        value={elementValue}
      />

      <label htmlFor={id} className={styles.label}>
        {label}
      </label>

      {warning && <span className="infotext">{warning}</span>}

      {error && <span className="infotext">{error}</span>}
    </div>
  );
};

VfTextarea.defaultProps = {
  className: '',
  cols: 50,
  disabled: false,
  error: '',
  fullWidth: false,
  label: '',
  onBlur: null,
  onChange: null,
  placeholder: '',
  rounded: false,
  rows: 4,
  textareaStyles: '',
  underline: false,
  value: '',
  warning: '',
};

export default VfTextarea;
