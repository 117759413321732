import { createContext, FC, ReactNode, useState } from 'react';

import { Form, Formik, FormikErrors } from 'formik';

import { addLesson, LessonCreateParams } from '../../store/lessons';

import NEW_LESSON_FLOW, { FlowOption, NewLessonFlow } from '../../consts/newLessonForm';
import { useAppDispatch } from '../../root';
import { ProjectFormValues } from './types';

interface ContextState {
  config: NewLessonFlow[];
  currentFlowType: FlowOption | null;
  currentStep: number;
  finished: boolean;
  resetFlowType?: () => void;
  resetForm?: () => void;
  setCurrentFlowType?: (flowType: FlowOption) => void;
  setCurrentStep?: (step: number) => void;
  setFinished?: () => void;
}

export const initialContextState = {
  config: NEW_LESSON_FLOW,
  currentFlowType: null,
  currentStep: 0,
  finished: false,
};

// Order in this const has influence on order in NewLessonPreview component, please leave it that way.
export const initialValues: Partial<ProjectFormValues> = {
  additionalInformation: '',
  departmentId: '',
  deputy: {
    id: '',
  },
  description: '',
  operatingSiteId: '',
  organizationId: '',
  organizationName: '',
  owner: {
    id: '',
  },
  priority: '',
  processId: '',
  projectId: '',
  projectTypeId: '',
  projectTypeName: '',
  recommendation: '',
  tags: [],
  tgPhase: [],
  title: '',
  workstreamId: '',
};

export const NewLessonContext = createContext<ContextState>(initialContextState);

export const findCurrentFlow = (option: FlowOption | null) => (flow: NewLessonFlow) => flow.type === option;

interface ProviderProps {
  children: ReactNode;
}

const NewLessonFormProvider: FC<ProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<ContextState>(initialContextState);

  const setCurrentStep = (step: number) => {
    setState({
      ...state,
      currentStep: step,
    });
  };

  const setCurrentFlowType = (flowType: FlowOption) => {
    setState({
      ...state,
      finished: false,
      currentFlowType: flowType,
      currentStep: 1,
    });
  };

  const resetFlowType = () => {
    setState({
      ...state,
      currentFlowType: null,
      currentStep: 0,
    });
  };

  const setFinished = () => {
    setState({
      ...state,
      finished: true,
    });
  };

  const resetForm = () => {
    setState(initialContextState);
  };

  const onSubmit = async (values: ProjectFormValues) => {
    dispatch(addLesson({ lesson: values, flowType: state.currentFlowType } as LessonCreateParams));
  };

  const validate = (values: ProjectFormValues): FormikErrors<ProjectFormValues> => {
    const errors: FormikErrors<ProjectFormValues> = {};

    const flow = state.config.find(findCurrentFlow(state.currentFlowType));

    return flow?.steps[state.currentStep - 1]?.validate(values) || errors;
  };

  return (
    <NewLessonContext.Provider
      value={{
        config: state.config,
        currentFlowType: state.currentFlowType,
        currentStep: state.currentStep,
        finished: state.finished,
        resetFlowType,
        resetForm,
        setCurrentFlowType,
        setCurrentStep,
        setFinished,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        validateOnMount={false}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <Form>{children}</Form>
      </Formik>
    </NewLessonContext.Provider>
  );
};

export default NewLessonFormProvider;
