import { GroupedOptions, IOption, IOptionWithGroup } from './models';

export const NO_DATA: string = 'No data';
export const NO_DATA_OPTION: IOption[] = [{ label: NO_DATA, value: NO_DATA }];

export const groupingOptions = (options: IOptionWithGroup[]) =>
  options.reduce((options: GroupedOptions, currentOption: IOptionWithGroup) => {
    const groupName: string = currentOption.group || 'others';

    if (options[groupName]) {
      options[groupName] = [...options[groupName], currentOption];
    } else {
      options[groupName] = [currentOption];
    }

    return options;
  }, {});

export const checkIfNeedGrouping = (options: IOption[], isGroupedOptions: boolean = false): boolean =>
  Boolean(isGroupedOptions && options.length && options[0].value !== NO_DATA_OPTION[0].value);
