import { FC } from 'react';

import classNames from 'classnames';

import styles from './vf-outline-label.module.scss';

interface VfOutlineLabelProps {
  disabled?: boolean;
  label?: string;
  openUp?: boolean;
  show?: boolean;
}

const VfOutlineLabel: FC<VfOutlineLabelProps> = ({ show, label, disabled, openUp }) => {
  const classes = classNames(styles['vf-outline-label '], {
    [styles['vf-outline-label--show']]: !!show,
    [styles['vf-outline-label--disabled']]: disabled,
    [styles['vf-outline-label--open-up']]: openUp,
  });

  return <div className={classes}>{label}</div>;
};

VfOutlineLabel.defaultProps = {
  disabled: false,
  openUp: false,
  show: false
};

export default VfOutlineLabel;
