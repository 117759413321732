import { FC } from 'react';

import { ADUser } from '../../../../models/user';

import Spinner from '../../../Spinner';
import { UserChip } from '../../../UserManagment';
import styles from './workshops-participant-section.module.scss';

export interface ParticipantSectionProps {
  participants?: ADUser[];
  isLoading: boolean;
}

const ParticipantSection: FC<ParticipantSectionProps> = ({ participants, isLoading }) => {
  const participantsToRender = participants?.map((participant: ADUser) => {
    return (
      <UserChip
        id={participant.id}
        name={participant.displayName}
        onDelete={() => {}}
        showDelete={false}
        key={participant.id}
      />
    );
  });

  return (
    <div className={styles['participant-section__container']}>
      <h4>PARTICIPANTS</h4>
      {isLoading ? (
        <div className={styles['spinner__container']}>
          <Spinner />
        </div>
      ) : (
        participantsToRender
      )}
    </div>
  );
};

export default ParticipantSection;
