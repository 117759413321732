import { FC, useEffect, useState } from 'react';

import { ADUser } from '../../models/user';

import IconButton from '../IconButton';
import UserSelector from '../UserSelector';
import { VfHelperText } from '../vfDesign';
import styles from './user-managment.module.scss';

interface UserChipProps {
  id: string;
  name: string;
  onDelete: (id: string) => void;
  showDelete?: boolean;
}

export const UserChip: FC<UserChipProps> = ({ id, name, onDelete, showDelete = true }) => {
  const handleDelete = (): void => {
    onDelete(id);
  };

  return (
    <div className={styles['user-chip']}>
      <span>{name}</span>
      {showDelete && <IconButton icon="close" onClick={handleDelete} />}
    </div>
  );
};

interface UserManagementProps {
  error?: any; // TODO: handle any
  name?: string;
  placeholder?: string;
  setUsers: (users: Partial<ADUser>[]) => void;
  users: ADUser[];
}

const UserManagement: FC<UserManagementProps> = ({
  error = '',
  name = 'userManagment',
  placeholder = '',
  users = [],
  setUsers,
}) => {
  const [isError, setError] = useState(error);

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error]);

  const handleDelete = (id: string): void => {
    const newUsers = users.filter((user) => user.id !== id);

    setUsers(newUsers);
  };

  const handleAdd = (_: string, newUser: Partial<ADUser>) => {
    const usersIds: string[] = users.map((user) => user.id);

    if (newUser.id && usersIds.includes(newUser.id)) {
      setError('Participant is already assigned');
    } else {
      setUsers([...users, newUser]);
      setError('');
    }
  };

  const renderUsers = users.map(({ id, displayName }: ADUser) => (
    <UserChip key={`user-chip-${id}`} name={displayName} id={id} onDelete={handleDelete} />
  ));

  return (
    <div>
      <UserSelector name={name} onSelect={handleAdd} placeholder={placeholder} />
      <div className={styles['selected-users']}>{renderUsers}</div>
      {Boolean(isError) && (
        <div className="d-flex justify-content-center">
          <VfHelperText type="error" text={isError} />
        </div>
      )}
    </div>
  );
};

export default UserManagement;
