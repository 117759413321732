import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Message, MessageResponse, MessagesPaginatedResponse } from '../models/messages';

import { handleApiError, sortArrayOfObjects, unique } from '../utils/functions';

import { ReduxStoreType } from '.';
import API from '../services/api';

export const sliceName = 'messages';

// Thunks

const SILENT_GET = true;

export const fetchMessages = createAsyncThunk<
  Promise<void>,
  {
    page: number;
  },
  {
    state: ReduxStoreType;
  }
>(`${sliceName}/fetchMessages`, async ({ page }, { dispatch, getState }) => {
  try {
    const {
      messages: { items },
    } = getState();

    dispatch(getMessages());

    const response = await API.get<MessagesPaginatedResponse>('/notifications', { page: page }, SILENT_GET);

    const { content = [], totalElements } = response.data;
    const u = unique<MessageResponse>([...items, ...content], 'id');
    const sorted = sortArrayOfObjects(u, 'createDate', 'DESC');

    dispatch(getMessagesFulfilled({ items: sorted, totalElements }));
  } catch (err) {
    handleApiError(err as AxiosError, '`We could not fetch new notifications.', dispatch);

    throw err;
  }
});

export const eraseMessages = createAsyncThunk<
  Promise<void>,
  {
    notificationIds: string[];
  },
  {
    state: ReduxStoreType;
  }
>(`${sliceName}/removeMessages`, async ({ notificationIds }, { dispatch, getState }) => {
  try {
    const {
      messages: { items, totalElements, isRemoving },
    } = getState();

    API.patch('/notifications', {
      operationType: 'DELETE',
      notificationIds,
    });

    const payload = items.filter((item) => !notificationIds.includes(item.id));
    const newTotalElements = totalElements - notificationIds.length;
    const removedIds = isRemoving.filter((id) => !notificationIds.includes(id));

    dispatch(
      removeMessagesFulfilled({
        items: payload,
        totalElements: newTotalElements,
        removedIds,
      }),
    );
  } catch (err) {
    handleApiError(err as AxiosError, '`We could not fetch new notifications.', dispatch);

    throw err;
  }
});

export const markMessagesAsRead = createAsyncThunk<
  Promise<void>,
  {
    notificationIds: string[];
  },
  {
    state: ReduxStoreType;
  }
>(`${sliceName}/removeMessages`, async ({ notificationIds }, { dispatch, getState }) => {
  try {
    const {
      messages: { items },
    } = getState();

    API.patch('/notifications', {
      operationType: 'MARK_READ',
      notificationIds,
    });

    const payload = items.map((item) =>
      notificationIds.includes(item.id)
        ? {
            ...item,
            markedRead: true,
          }
        : item,
    );

    dispatch(markAsReadFulfilled({ items: payload }));
  } catch (err) {
    handleApiError(err as AxiosError, '`We could not fetch new notifications.', dispatch);

    throw err;
  }
});

interface MessagesReducer {
  items: Message[];
  isLoading: boolean;
  totalElements: number;
  isRemoving: string[];
}

export const initialState: MessagesReducer = {
  items: [],
  isLoading: false,
  totalElements: 0,
  isRemoving: [],
};

export const messagesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getMessages: (state) => {
      state.isLoading = true;
    },
    getMessagesFulfilled: (state, action) => {
      state.isLoading = false;
      state.items = action.payload.items;
      state.totalElements = action.payload.totalElements;
    },
    removeMessages: (state, action) => {
      state.isRemoving = [...state.isRemoving, ...action.payload.ids];
    },
    removeMessagesFulfilled: (state, action) => {
      state.items = action.payload.items;
      state.totalElements = action.payload.totalElements;
      state.isRemoving = action.payload.removedIds;
    },
    getMessagesError: (state) => {
      state.isLoading = false;
    },
    removeMessagesError: (state) => {
      state.isRemoving = [];
    },
    markAsReadFulfilled: (state, action) => {
      state.items = action.payload.items;
    },
    clearMessages: (state) => {
      state.items = [];
      state.isLoading = false;
      state.totalElements = 0;
      state.isRemoving = [];
    },
  },
});

export const {
  getMessages,
  getMessagesFulfilled,
  removeMessages,
  removeMessagesFulfilled,
  getMessagesError,
  removeMessagesError,
  markAsReadFulfilled,
  clearMessages,
} = messagesSlice.actions;

export const selectMessages = (state: ReduxStoreType) => ({ messages: state.messages.items });

export const selectMessagesAreLoading = (state: ReduxStoreType) => state.messages.isLoading;

export const selectUnreadMessagesCount = ({ messages: { items } }: ReduxStoreType) =>
  items.filter((item) => !item.markedRead).length;

export const selectTotalElements = ({ messages: { totalElements } }: ReduxStoreType) => totalElements;
export const selectIsRemoving = ({ messages: { isRemoving } }: ReduxStoreType) => isRemoving;

export default messagesSlice.reducer;
