import { FC } from 'react';
import { useSelector } from 'react-redux';

import { getLessonsIsPublishing, togglePublishLesson } from '../../../../store/workshopSpaceLessons';

import { useAppDispatch } from '../../../../root';
import Tooltip from '../../../Tooltip';
import { VfButton as Button, VfInputSpinner, VfSwitch } from '../../../vfDesign';

interface PublishedSwitchProps {
  disabled?: boolean;
  hasButtonStyles?: boolean;
  id: string;
  published?: boolean;
  readyForPublication?: boolean;
  workshopId: string;
}

const PublishedSwitch: FC<PublishedSwitchProps> = ({
  disabled = false,
  hasButtonStyles = false,
  id,
  published = false,
  readyForPublication = false,
  workshopId,
}) => {
  const dispatch = useAppDispatch();
  const publishingIds = useSelector(getLessonsIsPublishing);

  const handleClick = (id: string, checked: boolean) => {
    dispatch(togglePublishLesson({ workshopId, id, checked }));
  };

  const showLoader = publishingIds.includes(id);

  if (hasButtonStyles) {
    const showDisabled = showLoader || disabled || (!readyForPublication && !published);

    const PublishButton = () => {
      const label = published ? 'Unpublish' : 'Publish';

      return (
        <Button
          onClick={() => handleClick} // TODO - verify why it's done this way (TS raises an error if onClick returns a function)
          type={published ? 'primary' : 'secondary'}
          className="ml-1"
          disabled={showDisabled}>
          {showLoader ? <VfInputSpinner /> : label}
        </Button>
      );
    };

    return (
      <>
        {readyForPublication && <PublishButton />}
        {!readyForPublication && (
          <Tooltip text="Please fill all mandatory fields to publish.">
            <PublishButton />
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <div className="d-flex align-items-center">
      {readyForPublication && (
        <VfSwitch onClick={handleClick} checked={published} name={id} disabled={showLoader || disabled} />
      )}
      {!readyForPublication && (
        <Tooltip text="Please fill all mandatory fields to publish.">
          <VfSwitch onClick={handleClick} checked={published} name={id} disabled />
        </Tooltip>
      )}
      {showLoader && <VfInputSpinner />}
    </div>
  );
};

export default PublishedSwitch;
