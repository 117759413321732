const icons = [
  { name: 'icon-vf-document--status-update', pathCount: 3 },
  { name: 'icon-vf-document--document-template--working-copy', pathCount: 4 },
  { name: 'icon-vf-document--register--working-copy', pathCount: 2 },
  { name: 'icon-vf-document--non-standard--working-copy', pathCount: 2 },
  { name: 'icon-vf-document--deliverable--working-copy', pathCount: 3 },
  { name: 'icon-vf-excel', pathCount: 3 },
  { name: 'icon-vf-feedback', pathCount: 5 },
];

export default icons;
