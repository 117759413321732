import React, { FC } from 'react';

import { Parameters } from '../../models/messages';

import styles from './_notification_center.module.scss';
import MessageParameter from './messageParameter';

interface MessageParametersProps {
  id: string;
  items: Parameters[];
}

const MessageParameters: FC<MessageParametersProps> = ({ id, items }) => (
  <div className={styles['message-item__parameters']}>
    {items.map(({ name, value }) => (
      <MessageParameter key={`item-${id}-parameter-${name}`} name={name} value={value} />
    ))}
  </div>
);

MessageParameters.defaultProps = {
  items: [],
};

export default MessageParameters;
