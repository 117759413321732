import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Form, FormikProps } from 'formik';

import { LessonDetails } from '../../../models/lessonDetails';
import { Organization, OrganizationIds } from '../../../models/structure';
import { ADUser } from '../../../models/user';

import { deleteLesson, getLessonDetails } from '../../../store/lessonsDetails';
import {
  clearProcesses,
  clearProjects,
  clearWorkstreams,
  fetchOrganizationContainers,
  fetchOrganizationDepartments,
  fetchOrganizations, // fetchPhases,
  fetchProcesses,
  fetchProcessesByDepartment,
  fetchProjects,
  fetchProjectTypes,
  fetchSites,
  fetchWorkstreams,
  fetchWorkstreamsByDepartment,
  getDepartments,
  getOrganizationContainers,
  getOrganizations, // getPhases,
  getProcesses,
  getProjects,
  getProjectTypes,
  getSites,
  getWorkstreams,
} from '../../../store/structure';
import { getIsAdmin, getUserId } from '../../../store/user';

import { dashSpacedToCapitalize, formatDate } from '../../../utils/functions';
import usePhases from '../../../utils/hooks/usePhases';
import PriorityTooltipMessage from '../../../utils/tooltipMessages';
import ActionTakerPrompt from '../utils/ActionTakerPrompt';

import { TabIndex } from '..';
import { LESSON_STATUS, STATE_ICONS } from '../../../consts/lessons';
import { PRIORITIES } from '../../../consts/newLessonForm';
import { dispatchConfirmationEvent } from '../../../events/helpers';
import { useAppDispatch } from '../../../root';
import DrawerFooter from '../../FlexDrawer/Footer';
import { Input, MultiDropdown } from '../../Forms';
import { AutoCompleteWithReduxData } from '../../Forms/Autocomplete';
import EnhancedFormikDropdown, { StructureEntities } from '../../Forms/Dropdown';
import Textarea from '../../Forms/Textarea';
import IconButton from '../../IconButton';
import StatusIcon from '../../StatusIcon';
import Tooltip from '../../Tooltip';
import UserSelector from '../../UserSelector';
import { VfButton, VfIcon, VfRadios } from '../../vfDesign';
import { ExpandableItem } from '../../vfDesign/vfExpendable';
import styles from '../lessonDetails.module.scss';

interface InfoTabProps extends FormikProps<LessonDetails> {
  isLegacyLesson: boolean;
  isFullScreen?: boolean;
  isOperatingSite: boolean;
  isOrganizationUnit: boolean;
  isProject: boolean;
  onClose: () => void;
  tabNameCallback: (tabName: TabIndex) => void;
  lessonDetails: LessonDetails;
}

// TODO: This component is tooo damn high
const tooltipText = 'Some fields below may be blocked because lesson comes from Workshops';

const InfoTab: FC<InfoTabProps> = ({
  onClose,
  isLegacyLesson,
  isFullScreen = false,
  isOperatingSite,
  isOrganizationUnit,
  isProject,
  isSubmitting,
  lessonDetails,
  tabNameCallback,
  resetForm,
  setFieldValue,
  values,
  validateForm,
  errors,
}) => {
  const dispatch = useAppDispatch();
  const [editActionTaker, setEditActionTaker] = useState(false);
  const [editActionTakerDeputy, setEditActionTakerDeputy] = useState(false);
  const [editAuthor, setEditAuthor] = useState(false);
  const [actionTakerSetManually, setActionTakerSetManually] = useState(lessonDetails?.actionTakerSetManually);
  const [userRights, setUserRights] = useState(false);
  const isAdmin = useSelector(getIsAdmin);
  const userId = useSelector(getUserId);
  const lessonData = useSelector(getLessonDetails);
  const { phasesWithoutNoOption: phases, isLoading: phasesAreLoading } = usePhases();

  const isActionTakerDeputy = userId === lessonData?.data?.actionTakerDeputy?.id;
  const isActionTaker = userId === lessonData?.data?.actionTaker?.id;

  const canEditActionTaker = isAdmin || isActionTaker;
  const canEditActionTakerDeputy = isAdmin || isActionTaker || isActionTakerDeputy;

  const toggleActionTakerEdit = () => {
    setEditActionTaker(!editActionTaker);
  };

  const toggleActionTakerDeputyEdit = () => {
    setEditActionTakerDeputy(!editActionTakerDeputy);
  };

  const toggleLessonAuthorEdit = () => {
    setEditAuthor(!editAuthor);
  };

  useEffect(() => {
    tabNameCallback(TabIndex.INFO);
  }, [tabNameCallback]);

  useEffect(() => {
    if (isOrganizationUnit) {
      dispatch(fetchOrganizationContainers());
    } else {
      dispatch(fetchOrganizations());
    }
  }, [isOrganizationUnit, dispatch]);

  useEffect(() => {
    setUserRights(lessonDetails?.userRights?.canEditLesson);
  }, [lessonDetails]);

  const onSubmitHandler = (option: StructureEntities) => {
    setTimeout(() => validateForm());
  };

  const closeDrawer = () => {
    resetForm();
    onClose();
  };

  const setPriority = (priority: string) => {
    setFieldValue('priority', priority);

    if (values.priority !== 'HIGH') {
      setFieldValue('priorityJustification', '');
    }
  };

  const setOwner = (item: { owner: { id: string } }) => {
    if (item?.owner && !actionTakerSetManually) {
      setFieldValue('owner', item.owner);
      setFieldValue('actionTaker', item.owner);
      setFieldValue('actionTakerId', item.owner.id);
    }
    onSubmitHandler(null);
  };

  const onUserSelect = (name: string, user: Partial<ADUser>) => {
    setFieldValue(name, user);
    if (name === 'actionTaker') {
      setEditActionTaker(false);
      setActionTakerSetManually(true);
    } else {
      setEditActionTakerDeputy(false);
    }
  };

  const onActionTakerSelect = (name: string, user: Partial<ADUser>) => {
    dispatchConfirmationEvent(
      () => onUserSelect(name, user),
      () => (
        <ActionTakerPrompt
          newTaker={user?.displayName}
          previousTaker={values.actionTaker?.displayName}
          process={values.processName}
          workstream={values.workstreamName}
        />
      ),
    );
  };

  const onLessonDelete = () => {
    dispatch(deleteLesson(lessonDetails.id));
    onClose();
  };

  const handleDelete = () => {
    dispatchConfirmationEvent(onLessonDelete, () => <p>Are you sure you want to delete lesson?</p>);
  };

  const handleActionTakerDeputyDelete = () => {
    setFieldValue('actionTakerDeputy', null);
  };

  const handleOrganizationSelect = (organization: Organization): void => {
    if (isOrganizationUnit) {
      return;
    }

    if (isOperatingSite && organization.id === OrganizationIds.offshore) {
      setFieldValue('projectTypeId', 'ASSET');
    }

    if (organization.id === OrganizationIds.onshore) {
      setFieldValue('projectTypeId', 'ONSHORE');
    }

    setTimeout(() => validateForm());
  };

  const formHasErrors = !errors || Object.keys(errors).length > 0;
  const isDisabled = isSubmitting || formHasErrors || !userRights;
  const canEdit = isSubmitting || !userRights;
  const isFromWorkShop = lessonDetails?.workshopId ? true : false;
  const isImprovement = values?.improvementDelivered && values?.state === LESSON_STATUS.DONE;
  const showProjectTypeSelector = !(
    (isOperatingSite && values?.organizationId === OrganizationIds.offshore) ||
    values?.organizationId === OrganizationIds.onshore
  );
  const isHighPrioritySet = values?.priority === 'HIGH';

  const actionTakerContainerStyles = values?.actionTaker?.id ? styles['lesson-owner'] : styles['no-owner'];
  const actionTakerDeputyContainerStyles = values?.actionTakerDeputy?.id ? styles['lesson-owner'] : styles['no-owner'];
  const isAuthorTakerEdited = editAuthor || editActionTaker || editActionTakerDeputy;
  const authorsStyles =
    isAuthorTakerEdited && !isFullScreen ? styles['lesson-details__authors-column'] : styles['lesson-details__authors'];
  const lessonAuthorStyles =
    editAuthor && !isFullScreen ? styles['lesson-details__owner-column'] : styles['lesson-details__owner'];
  const actionTakerStyles =
    editActionTaker && !isFullScreen ? styles['lesson-details__owner-column'] : styles['lesson-details__owner'];
  const actionTakerDeputyStyles =
    canEditActionTakerDeputy && !isFullScreen
      ? styles['lesson-details__owner-column']
      : styles['lesson-details__owner'];

  const nonOrganizationUnitDropdowns = (
    <>
      <EnhancedFormikDropdown
        disabled={canEdit || isFromWorkShop}
        dispatchFn={fetchOrganizations}
        fullWidth
        setByLabel
        label="Business Unit"
        labelKey="name"
        name="organizationId"
        selector={getOrganizations}
        relatedFields={['projectTypeId', 'projectId', 'workstreamId', 'processId']}
        showMinifiedLabel
        onSelectCallback={handleOrganizationSelect}
      />

      {showProjectTypeSelector && (
        <EnhancedFormikDropdown
          disabled={canEdit || isFromWorkShop}
          dispatchFn={fetchProjectTypes}
          fullWidth
          setByLabel
          label="Project type"
          labelKey="name"
          name="projectTypeId"
          selector={getProjectTypes}
          params={{
            organizationId: values?.organizationId,
          }}
          relatedFields={['projectId', 'workstreamId', 'processId']}
          skipFields={['organizationId']}
          showMinifiedLabel
          onSelectCallback={onSubmitHandler}
        />
      )}

      {isProject && (
        <>
          {isLegacyLesson && (
            <div className="ml-1">
              <div className={styles['lesson-details__headline']}>Project</div>
              <div>{values.projectName}</div>
            </div>
          )}

          {!isLegacyLesson && (
            <AutoCompleteWithReduxData
              disabled={canEdit || isFromWorkShop}
              dispatchFn={fetchProjects}
              label="Project"
              labelKey="name"
              name="projectId"
              selector={getProjects}
              onDismountCallback={clearProjects}
              params={{
                organizationId: values?.organizationId,
                projectTypeId: values?.projectTypeId,
              }}
              parentField={['projectTypeId', 'organizationId']}
              relatedFields={[]}
              skipFields={['organizationId', 'projectTypeId']}
              onSelectCallback={onSubmitHandler}
              setByLabel
            />
          )}
        </>
      )}
      {isOperatingSite && (
        <EnhancedFormikDropdown
          disabled={canEdit}
          dispatchFn={fetchSites}
          fullWidth
          label="Site"
          labelKey="name"
          name="operatingSiteId"
          params={{
            organizationId: values?.organizationId,
            projectTypeId: values?.projectTypeId,
          }}
          relatedFields={['workstreamId', 'processId']}
          selector={getSites}
          skipFields={['organizationId', 'projectTypeId']}
          showMinifiedLabel
          onSelectCallback={onSubmitHandler}
          setByLabel
        />
      )}
      <AutoCompleteWithReduxData
        dispatchFn={fetchWorkstreams}
        label="Workstream"
        labelKey="name"
        name="workstreamId"
        selector={getWorkstreams}
        disabled={canEdit}
        fetchOn={{
          siteId: values?.operatingSiteId,
          projectTypeId: values?.projectTypeId,
        }}
        params={{
          organizationId: values?.organizationId,
          projectTypeId: values?.projectTypeId,
          lessonOrigin: lessonDetails?.origin,
        }}
        onDismountCallback={clearWorkstreams}
        parentField={['operatingSiteId', 'projectTypeId']}
        relatedFields={['processId']}
        skipFields={['organizationId', 'projectTypeId', 'operatingSiteId']}
        onSelectCallback={setOwner}
      />
      <AutoCompleteWithReduxData
        disabled={canEdit}
        dispatchFn={fetchProcesses}
        label="Process"
        labelKey="name"
        name="processId"
        selector={getProcesses}
        parentField={['workstreamId']}
        params={{
          organizationId: values?.organizationId,
          projectTypeId: values?.projectTypeId,
          workstreamId: values?.workstreamId,
          lessonOrigin: lessonDetails?.origin,
        }}
        onDismountCallback={clearProcesses}
        skipFields={['organizationId', 'projectTypeId', 'workstreamId']}
        onSelectCallback={setOwner}
      />
      {isProject && (
        <MultiDropdown
          className="mt-2"
          isDisabled={!values.projectId}
          isLoading={phasesAreLoading}
          label="Project Phase"
          name="tgPhase"
          options={phases}
        />
      )}
    </>
  );
  const organizationUnitDropdowns = (
    <>
      <EnhancedFormikDropdown
        disabled={canEdit}
        dispatchFn={fetchOrganizationContainers}
        fullWidth
        label="Organization"
        labelKey="name"
        name="organizationId"
        relatedFields={['projectTypeId', 'projectId', 'workstreamId', 'processId']}
        selector={getOrganizationContainers}
        showMinifiedLabel={false}
        setByLabel
        onSelectCallback={onSubmitHandler}
      />

      <EnhancedFormikDropdown
        disabled={canEdit}
        dispatchFn={fetchOrganizationDepartments}
        fullWidth
        label="Department"
        labelKey="name"
        name="departmentId"
        params={{
          organizationId: values?.organizationId,
        }}
        relatedFields={['workstreamId', 'processId']}
        selector={getDepartments}
        skipFields={['organizationId']}
        showMinifiedLabel={false}
        setByLabel
        onSelectCallback={onSubmitHandler}
      />

      <AutoCompleteWithReduxData
        disabled={canEdit}
        dispatchFn={fetchWorkstreamsByDepartment}
        label="Workstream"
        labelKey="name"
        name="workstreamId"
        onSelectCallback={setOwner}
        params={{
          organizationId: values?.organizationId,
          departmentId: values?.departmentId,
          lessonOrigin: lessonDetails?.origin,
        }}
        onDismountCallback={clearWorkstreams}
        parentField={['departmentId', 'organizationId']}
        relatedFields={['processId', 'processName']}
        selector={getWorkstreams}
        setByLabel={true}
        skipFields={['organizationId', 'departmentId']}
      />

      <AutoCompleteWithReduxData
        disabled={canEdit}
        dispatchFn={fetchProcessesByDepartment}
        label="Process"
        labelKey="name"
        name="processId"
        onSelectCallback={setOwner}
        parentField={['workstreamId', 'departmentId', 'organizationId']}
        params={{
          organizationId: values?.organizationId,
          departmentId: values?.departmentId,
          workstreamId: values?.workstreamId,
          lessonOrigin: lessonDetails?.origin,
        }}
        onDismountCallback={clearProcesses}
        selector={getProcesses}
        setByLabel={true}
        skipFields={['organizationId', 'departmentId', 'workstreamId']}
      />
    </>
  );

  const ToolTipMessage = () => {
    if (isFromWorkShop) {
      return (
        <div className={styles['workshop__lesson__icons-container']}>
          <Tooltip text={tooltipText} position="softRight" className={styles['lesson-details__tooltip']}>
            <VfIcon name="info" />
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  return (
    <Form>
      <div className={styles['lesson-details']}>
        <div className={styles['lesson-details__statuses']}>
          <StatusIcon
            iconName={`progress--${STATE_ICONS[values?.state]}`}
            title="Status"
            subtitle={dashSpacedToCapitalize(values?.state)}
          />
          {isImprovement && <StatusIcon iconName="progress--done" title="Improvement" />}
        </div>
        <div className="mt-2">
          <ExpandableItem
            title="Author and Action Taker"
            hideHint
            isEditable={true}
            usedItems="yes"
            defaultExpanded={true}
            showOverflow
          >
            <div className={authorsStyles}>
              <div className={lessonAuthorStyles}>
                <div className={styles['lesson-details__headline']}>Lesson Author</div>
                <div className={actionTakerContainerStyles}>
                  {values?.creator?.displayName}
                  {isAdmin && <IconButton icon="pencil" onClick={toggleLessonAuthorEdit} />}
                </div>
                {editAuthor && (
                  <div className="mt-2" style={{ width: '100%' }}>
                    <UserSelector onSelect={onUserSelect} name="creator" />
                  </div>
                )}
              </div>

              <div className={actionTakerStyles}>
                <div className={styles['lesson-details__headline']}>Action Taker</div>
                <div className={actionTakerContainerStyles}>
                  {values?.actionTaker?.displayName}
                  {canEditActionTaker && <IconButton icon="pencil" onClick={toggleActionTakerEdit} />}
                </div>
                {editActionTaker && (
                  <div className="mt-2" style={{ width: '100%' }}>
                    <UserSelector onSelect={onActionTakerSelect} name="actionTaker" />
                  </div>
                )}
              </div>

              <div className={actionTakerDeputyStyles}>
                <div className={styles['lesson-details__headline']}>Action Taker Deputy</div>
                <div className={actionTakerDeputyContainerStyles}>
                  {values?.actionTakerDeputy?.displayName}
                  {values?.actionTakerDeputy?.displayName ? (
                    <IconButton icon="trash" onClick={handleActionTakerDeputyDelete} />
                  ) : null}
                  {canEditActionTakerDeputy && <IconButton icon="pencil" onClick={toggleActionTakerDeputyEdit} />}
                </div>
                {editActionTakerDeputy && (
                  <div className="mt-2">
                    <UserSelector onSelect={onUserSelect} name="actionTakerDeputy" />
                  </div>
                )}
              </div>
            </div>
          </ExpandableItem>
        </div>
        <div className="d-flex justify-content-around">
          <div>
            <div className={styles['lesson-details__headline']}>Entry date</div>
            <div>{formatDate(lessonDetails?.createDate)}</div>
          </div>
          <div>
            <div className={styles['lesson-details__headline']}>Lesson origin</div>
            <div>{lessonDetails?.workshopName ? lessonDetails?.workshopName : 'created'}</div>
          </div>
        </div>
        <div className="mt-2">
          <div className={styles['lesson-details__headline']}>Description</div>
          <div className="mt-2">
            <Input label="Lesson title" name="title" disabled={canEdit} />
          </div>
          <div className="mt-2">
            <Textarea name="description" label="What happened" disabled={canEdit} />
          </div>
          <div className="mt-2">
            <Textarea name="recommendation" label="How to deal with it" disabled={canEdit} />
          </div>
        </div>

        <div className="mt-2">
          <ExpandableItem
            title="Categorization"
            hideHint
            isEditable={true}
            usedItems="yes"
            showOverflow
            defaultExpanded={true}
            toolTip={<ToolTipMessage />}
          >
            <div className={styles['lesson-details__categorization']}>
              {isOrganizationUnit && organizationUnitDropdowns}
              {!isOrganizationUnit && nonOrganizationUnitDropdowns}
              <div className="m-2">
                <div className={styles['lesson-details__headline']}>
                  Priority{` `}
                  <Tooltip text={<PriorityTooltipMessage />}>
                    <VfIcon name="info" />
                  </Tooltip>
                </div>

                <VfRadios
                  disabled={canEdit}
                  onBlur={() => {}}
                  onChange={setPriority}
                  value={values?.priority}
                  options={PRIORITIES}
                  className={styles['lesson-details__priorities']}
                />

                {isHighPrioritySet && (
                  <div className="mt-2">
                    <Input label="Reason to set high priority *" name="priorityJustification" disabled={canEdit} />
                  </div>
                )}
              </div>
            </div>
          </ExpandableItem>
        </div>
        {isAdmin && (
          <div className={styles['lesson-details__delete-button']} style={{ maxWidth: '500px', width: 'unset' }}>
            <VfButton outline="secondary" onClick={handleDelete} fullWidth className="w-100">
              Delete
            </VfButton>
          </div>
        )}
      </div>
      <DrawerFooter>
        <VfButton outline="secondary" onClick={closeDrawer}>
          Cancel
        </VfButton>
        <VfButton type="primary" htmlType="submit" disabled={isDisabled}>
          Update
        </VfButton>
      </DrawerFooter>
    </Form>
  );
};

export default InfoTab;
