import { FC } from 'react';

import { VfIcon } from '../vfDesign';
import IconButton from './IconButton';
import styles from './flexDrawer.module.scss';
import classNames from 'classnames';

export interface DrawerHeaderProps {
  clearAllTitle?: string;
  headline: string;
  isFullScreen?: boolean;
  isOpen?: boolean;
  isTriggeredActionTaker?: boolean;
  onClearAll?: () => void;
  onClose: () => void;
  onMaximize?: () => void;
}

const DrawerHeader: FC<DrawerHeaderProps> = ({
  clearAllTitle = 'Clear all',
  headline,
  isFullScreen = false,
  isOpen = false,
  isTriggeredActionTaker = false,
  onClearAll,
  onClose,
  onMaximize = () => null,
}) => {
  const iconButtonClassNames = isTriggeredActionTaker ? "orange" : "white";

  return (
    <div className={classNames(styles['drawer__header'], isTriggeredActionTaker && 'wrapper-orange-background')}>
      <div className={styles['drawer__header-row']}>
        {isOpen && (
          <IconButton onClick={onMaximize} color={iconButtonClassNames}>
            <VfIcon name={isFullScreen ? 'minimize' : 'maximize'} />
          </IconButton>
        )}

        <IconButton onClick={onClose} color={iconButtonClassNames}>
          <VfIcon name="chevron--full" />
        </IconButton>

        <span className={styles['drawer__header-title']}>{headline}</span>
      </div>
      {onClearAll && (
        <div onClick={onClearAll} className={styles['drawer__header-clear']}>
          {clearAllTitle}
        </div>
      )}
    </div>
  );
};

export default DrawerHeader;
