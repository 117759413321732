import { FC } from 'react';

import './_expandable-item.scss';

interface ItemsCountProps {
  itemCount: number;
}

const ItemsCount: FC<ItemsCountProps> = ({ itemCount }) => (
  <>{itemCount > 0 && <span className="expandable-item__count">{`(${itemCount})`}</span>}</>
);

ItemsCount.defaultProps = {
  itemCount: 0,
};

export default ItemsCount;
