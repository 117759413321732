import { FC } from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { Organization, OrganizationIds } from '../../../../models/structure';

import {
  clearProjectTypes,
  clearSites,
  fetchOrganizations,
  fetchProjectTypes,
  fetchSites,
  getOrganizations,
  getProjectTypes,
  getSites,
} from '../../../../store/structure';

import useInfoElement from '../../../../utils/hooks/useInfoElement';

import { AutoCompleteWithReduxData } from '../../../Forms/Autocomplete';
import EnhancedFormikDropdown from '../../../Forms/Dropdown';
import Tooltip from '../../../Tooltip';
import { InfoElementNames } from '../../../Tooltip/consts';
import styles from '../../Shared.module.scss';

interface FormValues {
  organizationId: string;
  projectTypeId: string;
  operatingSiteId: string;
}

const OperatingSiteBasics: FC = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const [{ popupText: tooltipText }] = useInfoElement(InfoElementNames.OPERATING_SITE);

  const tooltipParagraphStyles = classNames(styles.pair__labelRequired, styles.pair__paragraph__withTooltip);
  const tooltipDivStyles = classNames(styles.pair, styles.pair__withTooltip);

  const handleOrganizationSelect = (organization: Organization) => {
    if (organization.id === OrganizationIds.onshore) {
      setFieldValue('projectTypeId', 'ONSHORE');
      setFieldValue('projectTypeName', 'Onshore');
    }

    if (organization.id === OrganizationIds.offshore) {
      setFieldValue('projectTypeId', 'ASSET');
      setFieldValue('projectTypeName', 'Asset');
    }
  };

  const showProjectTypeSelector = [OrganizationIds.solarAndBatteries, ''].includes(values.organizationId);

  return (
    <div className={styles.content}>
      <div className={styles.pair}>
        <p className={styles.pair__labelRequired}>Which Business Unit?</p>

        <EnhancedFormikDropdown
          dispatchFn={fetchOrganizations}
          fullWidth
          label="Organization"
          labelKey="name"
          name="organizationId"
          onSelectCallback={handleOrganizationSelect}
          relatedFields={['projectTypeId', 'projectId', 'workstreamId', 'processId', 'operatingSiteId']}
          selector={getOrganizations}
          setByLabel
          showMinifiedLabel={false}
        />
      </div>

      {showProjectTypeSelector && (
        <div className={styles.pair}>
          <p className={styles.pair__labelRequired}>What type of project?</p>

          <AutoCompleteWithReduxData
            dispatchFn={fetchProjectTypes}
            label="Project type"
            labelKey="name"
            name="projectTypeId"
            params={{
              organizationId: values['organizationId'],
            }}
            relatedFields={['workstreamId', 'processId', 'operatingSiteId']}
            selector={getProjectTypes}
            onDismountCallback={clearProjectTypes}
            disabled={!values.organizationId}
            skipFields={['organizationId']}
            setByLabel
          />
        </div>
      )}

      <div className={tooltipDivStyles}>
        <Tooltip text={tooltipText} position='bottom'>
          <p className={tooltipParagraphStyles}>Which site(s)?</p>
        </Tooltip>

        <AutoCompleteWithReduxData
          dispatchFn={fetchSites}
          label="Site"
          labelKey="name"
          disabled={!values.projectTypeId}
          name="operatingSiteId"
          params={{
            organizationId: values['organizationId'],
            projectTypeId: values['projectTypeId'],
          }}
          relatedFields={['workstreamId', 'processId']}
          selector={getSites}
          onDismountCallback={clearSites}
          skipFields={['organizationId', 'projectTypeId']}
          setByLabel
        />
      </div>
    </div>
  );
};

export default OperatingSiteBasics;
