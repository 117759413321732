import { FC, useState, useRef, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import styles from '../../LessonsList/Lesson.module.scss';

import UserSelector from '../../../components/UserSelector';
import VfButton from '../../../components/vfDesign/vfButton';
import classNames from 'classnames';
import { ADUser } from '../../../models/user';
import { Label, RowLabel } from '../../LessonsList/Lesson';
import { setNewActionTaker, getLessonsWithNoAT } from '../../../store/lessonsWithNoActionTaker';
import { useAppDispatch } from '../../../root';
import { useOutsideClick } from '../../../utils/hooks';
import { TabIndex } from '../../../components/LessonDetails';

interface LessonProps {
  actionTaker: Partial<ADUser>;
  description: string;
  id: string;
  projectName: string;
  title: string;
  setLessonId: (id: string, tabName: TabIndex) => void;
}

const LessonWithNoActiveActionTaker: FC<LessonProps> = ({
  actionTaker,
  description,
  id,
  projectName,
  title,
  setLessonId,
}) => {
  const dispatch = useAppDispatch();
  const { isEditATLoading } = useSelector(getLessonsWithNoAT);

  const cardRef = useRef<HTMLDivElement>(null);

  const [isActionTakerEdited, setIsActionTakerEdited] = useState(false);
  const [newActionTakerId, setNewActionTakerId] = useState<string>('');
  const [selectedActionTaker, setActionTaker] = useState<Partial<ADUser>>({ id: '' });

  const isDisabled = isEditATLoading;

  const handleOpenDrawer = (e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    if (isDisabled) return;

    setLessonId(id, TabIndex.INFO);
  };

  useOutsideClick(cardRef, () => {
    if (isActionTakerEdited) {
      cancelActionTaker();
    }
  });

  const lessonClasses = classNames(
    'vf-row mb-3 pl-2 pt-3 pb-3 pr-2',
    styles.lessonWithoutAT,
  );

  const actionsClasses =
    classNames(
      'vf-col-sm-12 vf-col-lg-4 d-flex flex-fill justify-content-center align-items-center',
      styles.actions,
    );

  const buttonStyles = {
    marginTop: '10px',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    width: '170px',
  };

  const editActionTakerClasses = 'd-flex justify-content-center align-items-center';

  const onActionTakerSelect = (name: string, user: Partial<ADUser>) => {
    setActionTaker(user);
    setNewActionTakerId(user.id ? user.id : '');
  };

  const submitActionTaker = () => {
    dispatch(setNewActionTaker({ lessonId: id, actionTakerId: newActionTakerId }));
    cancelActionTaker();
  };
  const cancelActionTaker = () => {
    setIsActionTakerEdited(false);
    setNewActionTakerId('');
    setActionTaker({ id: '' });
  };

  return (
    <div className={styles.wrapper} id={`lesson-${id}`}>
      <div
        className={lessonClasses}
        style={{
          zIndex: isActionTakerEdited ? 100 : 1,
          opacity: isEditATLoading ? .5 : 1,
          pointerEvents: isEditATLoading ? 'none' : 'all',
        }}
        ref={cardRef}
      >
        <div className="vf-col-sm-12 vf-col-lg-8">

          <div className="vf-row">
            <div className="vf-col-11 mb-2">
              <h3 className={styles.title}>
                <>{title}</>
              </h3>
            </div>
          </div>

          <div className="vf-row">
            <Label name="ACTION TAKER" text={actionTaker?.displayName} />
            <Label
              name={'PROJECT'}
              text={projectName ? projectName : '------'}
            />
          </div>

          <div className="vf-row">
            <RowLabel name="DESCRIPTION" text={description} />
          </div>
        </div>

        <div className={actionsClasses}>
          {isActionTakerEdited && (
            <div
              className={editActionTakerClasses}
              style={{ flexDirection: 'column' }}>
              <div className="vf-row">
                <div className={styles.rowLabel}> Set new Action Taker</div>
              </div>
              <div className="vf-row">
                <div style={{ width: '300px' }}>
                  <UserSelector
                    className="w-100"
                    onSelect={onActionTakerSelect}
                    name="actionTaker"
                    value={selectedActionTaker}
                  />
                </div>
              </div>
              <div
                className="vf-row"
                style={buttonStyles}>
                <VfButton
                  type="tertiary"
                  onClick={() => cancelActionTaker()}
                  size="small"
                >
                  Cancel
                </VfButton>
                <VfButton
                  type="primary"
                  onClick={() => submitActionTaker()}
                  size="small"
                >
                  Save
                </VfButton>
              </div>
            </div>
          )}
          {!isActionTakerEdited && (
            <div className={styles.actionButtons}>
              <VfButton
                type="secondary"
                onClick={() => setIsActionTakerEdited(true)}
              >
                Edit Action Taker
              </VfButton>
              <VfButton outline="secondary" onClick={handleOpenDrawer}>
                Lesson Details
              </VfButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LessonWithNoActiveActionTaker.defaultProps = {};

export default LessonWithNoActiveActionTaker;
