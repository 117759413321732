import { StructureTypes } from '../store/structure';

import { IOption } from '../components/vfDesign/vfDropdown/models';
import { ADUser } from './user';

export interface Organization {
  id: string;
  name: string;
}

export interface Site {
  id: string;
  name: string;
  projectTypeId: string;
}

export interface OrganizationContainer extends Organization {
  container: string;
}

export interface Department {
  id: string;
  name: string;
}

export interface ProjectType {
  id: string;
  name: string;
}

export interface Project {
  id: string;
  name: string;
}

export interface Phase {
  id: string;
  name: string;
}

export interface Workstream {
  businessId: string;
  id: string;
  name: string;
  processes: Process[] | [];
  owner: ADUser;
}

export interface Process {
  businessId: string;
  id: string;
  name: string;
  owner: ADUser;
}

export interface StructureBase {
  id: string;
  [key: string]: string;
}

export enum OrganizationIds {
  offshore = 'OFFSHORE',
  onshore = 'ONSHORE',
  solarAndBatteries = 'SOLAR_AND_BATTERIES',
}

export interface ImprovementType extends IOption {}

export const mapStructureToOptions = (structure: StructureTypes[] | undefined, mappingFunction: Function): IOption[] =>
  structure ? structure.map((el) => mappingFunction(el)) : [];
