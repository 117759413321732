import { FC, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Form, Formik, FormikHelpers } from 'formik';

import { IAdvancedFilters } from '../../models/filterSettings';

import { getAdvancedFilters, setAdvancedFilters } from '../../store/filterSettings';
import { clearLessons, setInitialTotalPages, setRestartPages } from '../../store/lessons';

import { convertSearchQueryToObject, mergeObjectsBasedOnProps } from '../../utils/functions';

import { ReportsContext } from '../../context';
import { useAppDispatch } from '../../root';
import Drawer from '../FlexDrawer';
import DrawerFooter from '../FlexDrawer/Footer';
import DrawerHeader from '../FlexDrawer/Header';
import { VfButton } from '../vfDesign';
import FilterForms from './filterForms';

export interface LessonsFilterProps {
  isOpen: boolean;
  onClose: () => void;
  open: boolean;
  side: string;
}

const LessonFilters: FC<LessonsFilterProps> = ({ isOpen, onClose, open, side }) => {
  const dispatch = useAppDispatch();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { isSelectedAll, handleSelectAll } = useContext(ReportsContext);
  const initialValues = useSelector(getAdvancedFilters);

  const onSubmit = (values: Partial<IAdvancedFilters>, { resetForm }: FormikHelpers<IAdvancedFilters>): void => {

    let preparedValues = {
      ...values
    };

    if (values.customDate && values.customDate.length >= 2) {
      preparedValues.startDate = values.customDate[0];
      preparedValues.endDate = values.customDate[1];
    }

    if (!values.customDate) {
      preparedValues.startDate = null;
      preparedValues.endDate = null;
    }

    dispatch(setRestartPages());

    dispatch(setInitialTotalPages());
    dispatch(setAdvancedFilters(preparedValues));
    dispatch(clearLessons([]));

    if (isSelectedAll) {
      handleSelectAll?.([]);
    }
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (search) {
      const params = convertSearchQueryToObject(search);
      const filtersFromParams = mergeObjectsBasedOnProps<IAdvancedFilters>(initialValues, params);

      dispatch(
        setAdvancedFilters({
          ...filtersFromParams,
          states: params?.states && typeof params.states === 'string' ? [params.states] : [],
        }),
      );
      history.replace(pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, errors, dirty, resetForm, values }) => {
        const formHasErrors = !errors || Object.keys(errors).length > 0;
        const isDisabled = isSubmitting || formHasErrors || !dirty;

        const handleClose = () => {
          resetForm();
          onClose();
        };

        return (
          <Drawer
            isMounted={open}
            isOpen={isOpen}
            delayTime={400}
            onClose={handleClose}
            side={side}
            onOutsideClick={handleClose}
          >
            <Form>
              <DrawerHeader onClose={handleClose} headline="Filters" />

              <FilterForms />

              <DrawerFooter>
                <VfButton outline="secondary" onClick={handleClose}>
                  Cancel
                </VfButton>
                <VfButton type="primary" htmlType="submit" disabled={isDisabled}>
                  Apply
                </VfButton>
              </DrawerFooter>
            </Form>
          </Drawer>
        );
      }}
    </Formik>
  );
};

export default LessonFilters;
