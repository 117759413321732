import { FileWithPath } from 'react-dropzone';

import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getBase64, handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import { NOTIFICATION_TYPES } from '../components/vfDesign/vfNotification';
import Api from '../services/api';
import { fetchLessonDetails } from './lessonsDetails';
import { setNotification } from './notifications';
import { fetchFilteredLessonInWorkshop } from './workshopSpaceLessons';

export const sliceName = 'attachments';

export interface AttachmentsState {
  isUploading: boolean;
  isDeleting: string[];
}

export interface UploadAttachmentsParams {
  files: FileWithPath[];
  id: string;
  fromWorkshop?: boolean;
  workshopId?: string;
}

export interface DeleteAttachmentsParams {
  attachmentId: string;
  id: string;
  fromWorkshop?: boolean;
  workshopId?: string;
}

export const uploadAttachments = createAsyncThunk(
  `${sliceName}/upload`,
  async ({ files, id, fromWorkshop, workshopId }: UploadAttachmentsParams, { dispatch }) => {
    try {
      for (const file of files) {
        const fileBase64 = await getBase64(file);

        await Api.post<string>(`/attachments/lesson/${id}`, fileBase64);
      }

      dispatch(setNotification('All files were successfully uploaded', NOTIFICATION_TYPES.SUCCESS));

      if (fromWorkshop) {
        const payload = {
          id: workshopId,
          body: {},
        };

        await dispatch(fetchFilteredLessonInWorkshop(payload));
      }

      await dispatch(fetchLessonDetails(id));
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not upload some attachments', dispatch);

      throw err;
    }
  },
);

export const deleteAttachment = createAsyncThunk(
  `${sliceName}/delete`,
  async ({ id, attachmentId, fromWorkshop, workshopId }: DeleteAttachmentsParams, { dispatch }) => {
    try {
      await Api.delete<string>(`/attachments/lesson/${id}/attachments/${attachmentId}`);
      await dispatch(fetchLessonDetails(id));
      
      if (fromWorkshop) {
        const payload = {
          id: workshopId,
          body: {},
        };
        await dispatch(fetchFilteredLessonInWorkshop(payload));
      }

      return attachmentId;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not remove attachment', dispatch);

      throw err;
    }
  },
);

export const initialState: AttachmentsState = {
  isUploading: false,
  isDeleting: [],
};

export const attachmentsReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadAttachments.pending, (state) => {
      state.isUploading = true;
    });

    builder.addCase(uploadAttachments.fulfilled, (state) => {
      state.isUploading = false;
    });

    builder.addCase(uploadAttachments.rejected, (state) => {
      state.isUploading = false;
    });

    builder.addCase(
      deleteAttachment.pending,
      (
        state,
        {
          meta: {
            arg: { attachmentId },
          },
        },
      ) => {
        state.isDeleting = [...state.isDeleting, attachmentId];
      },
    );

    builder.addCase(deleteAttachment.fulfilled, (state, { payload }) => {
      state.isDeleting = state.isDeleting.filter((id: string) => id !== payload);
    });

    builder.addCase(deleteAttachment.rejected, (state, { payload }) => {
      state.isDeleting = state.isDeleting.filter((id: string) => id !== payload);
    });
  },
});

export const getAttachmentIsUploading = (state: ReduxStoreType): boolean => state[sliceName].isUploading;
export const getAttachmentIsDeleting =
  (state: ReduxStoreType) =>
  (attachmentId: string): boolean =>
    state[sliceName].isDeleting.includes(attachmentId);

export default attachmentsReducer.reducer;
