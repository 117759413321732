// MSAL imports
import { FC } from 'react';

import { MsalProvider } from '@azure/msal-react';

import { msalInstance } from '../../../services/auth';

const AuthProvider: FC = ({ children }) => {
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export default AuthProvider;
