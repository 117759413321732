import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';

import { fetchInstructionLinks } from '../../../store/instructionLink';

import BackToPage from '../../../components/BackToPage';
import { VfButton } from '../../../components/vfDesign';
import styles from './InstructionLinks.module.scss';
import AddLink from './addLink';
import InstructionLinksList from './instructionLinksList';

const InstructionLinks: FC = () => {
  const dispatch = useDispatch();
  const pageClasses = classNames(styles['instruction-links'], 'd-flex flex-fill ');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchInstructionLinks());
  }, [dispatch]);

  const addButtonHandler = () => {
    setOpen(true);
  };

  return (
    <>
      <BackToPage />
      <div className={pageClasses}>
        <h2 className={styles['title-page']}>Guide & Video Tutorials links </h2>
        <AddLink open={open} setOpen={setOpen} />
        <InstructionLinksList />
        <div className="vf-container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="vf-row mt-2">
            <div className="vf-col d-flex justify-content-end">
              <VfButton onClick={addButtonHandler} type="secondary">
                Add link
              </VfButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructionLinks;
