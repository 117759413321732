import { FC } from 'react';

import classNames from 'classnames';

import styles from './flexDrawer.module.scss';

// TODO: Remove because we have 2 icon buttons

export interface IconButtonProps {
  className?: string;
  color?: string;
  disabled?: boolean;
  noHover?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const IconButton: FC<IconButtonProps> = ({ children, className, color, disabled, noHover, onClick, ...rest }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      onClick(e);
    }
  };
  const buttonClasses = classNames(styles['icon-button'], styles[`icon-button--background-${color}`], {
    [styles['icon-button--no-hover']]: noHover,
  });

  return (
    <div className={`m-1 ${className}`}>
      <button type="button" onClick={handleClick} className={buttonClasses} disabled={disabled} {...rest}>
        {children}
      </button>
    </div>
  );
};

export default IconButton;
