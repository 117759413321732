import React, { FC, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { Formik, FormikErrors } from 'formik';
import { from, Subscription } from 'rxjs';

import { useCookie, useOutsideClick } from '../../utils/hooks';

import Api from '../../services/api';
import { Textarea } from '../Forms';
import { VfButton, VfIcon } from '../vfDesign';
import Icon from '../vfDesign/vfIcon';
import Loader from '../vfDesign/vfLoader';
import styles from './feedback-form.module.scss';

export const FEEDBACK_COOKIE = 'FEEDBACK_USED_BEFORE';

const initialState = {
  isLoading: false,
  success: false,
  error: false,
};

interface FeedbackFormProps {
  isOpened: boolean;
  closeFeedback: () => void;
  tooltipOnly: boolean;
}

const FeedbackForm: FC<FeedbackFormProps> = ({ isOpened, closeFeedback, tooltipOnly }) => {
  const [, setFeedbackCookie] = useCookie(FEEDBACK_COOKIE, 'false');

  const ref = useRef<HTMLDivElement>(null);
  const subscriptionRef = useRef<Subscription | null>(null);

  const [feedbackState, setFeedbackState] = useState(initialState);

  useEffect(() => {
    if (!isOpened) {
      setFeedbackState(initialState);

      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    }

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, [isOpened]);

  useOutsideClick(ref, () => {
    if (!tooltipOnly && isOpened) {
      closeFeedback();
    }
  });

  const initialValues = {
    message: '',
  };

  const validateFeedbackForm = (values: typeof initialValues): FormikErrors<typeof initialValues> => {
    const errors: FormikErrors<typeof initialValues> = {};

    if (!values.message) {
      errors.message = 'This field is required';
    }

    return errors;
  };

  const handleFeedbackSubmit = (values: typeof initialValues) => {
    setFeedbackState({
      ...initialState,
      isLoading: true,
    });

    const onSuccess = () => {
      setFeedbackCookie('true', { expires: 30 });
      setFeedbackState({
        ...feedbackState,
        isLoading: false,
        success: true,
      });
    };

    const onError = () => {
      setFeedbackState({
        ...feedbackState,
        isLoading: false,
        error: true,
      });
    };

    subscriptionRef.current = from(
      Api.post('/feedback', {
        message: values.message,
      }),
    ).subscribe({
      next: onSuccess,
      error: onError,
    });
  };

  const handleCloseTooltip = () => {
    setFeedbackCookie('false', { expires: 30 });
    closeFeedback();
  };

  const classes = classnames(styles['feedback-modal'], {
    [styles['feedback-modal--tooltip-only']]: tooltipOnly,
    [styles['feedback-modal--full']]: !tooltipOnly,
    [styles['feedback-modal--visible']]: isOpened,
  });

  const { isLoading, error, success } = feedbackState;

  const formVisible = !tooltipOnly && !isLoading && !error && !success;

  const formLoading = !tooltipOnly && isLoading;

  const formError = !tooltipOnly && !isLoading && error;

  const formSuccess = !tooltipOnly && !isLoading && success;

  if (!isOpened) {
    return null;
  }

  return (
    <div className={classes} ref={ref}>
      {tooltipOnly && (
        <>
          <p>Click here to share feedback</p>
          <VfIcon name="close" asButton onClick={handleCloseTooltip} />
        </>
      )}

      {formLoading && (
        <div className={styles['element-centered']}>
          <Loader noLogo notFixed />
        </div>
      )}

      {formSuccess && (
        <div className={styles['element-centered']}>
          <Icon name="vf-handshake" className={styles['feedback-icon']} />
          <p className={styles['feedback-header']}>Thanks for your feedback on Lesson Learn Portal!</p>
          <p className={styles['feedback-result-message']}>
            We will review it and use it for further development of the tool.
          </p>
          <div className={styles['feedback-utils']}>
            <VfButton type="tertiary" onClick={closeFeedback}>
              Close
            </VfButton>
          </div>
        </div>
      )}

      {formError && (
        <div className={styles['element-centered']}>
          <Icon name="vf-status--error" className={styles['feedback-icon']} />
          <p className={styles['feedback-header']}>Something went Wrong</p>
          <p className={styles['feedback-result-message']}>Please try again later</p>
          <div className={styles['feedback-utils']}>
            <VfButton type="tertiary" onClick={closeFeedback}>
              Close
            </VfButton>
          </div>
        </div>
      )}

      {formVisible && (
        <Formik initialValues={initialValues} onSubmit={handleFeedbackSubmit} validate={validateFeedbackForm}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ height: '260px' }}>
              <div className="d-flex justify-content-around align-items-center">
                <p>Share your feedback</p>
                <a href="mailto:llpportal@vattenfall.com">
                  <VfIcon name="vf-mail" asButton className={styles['mail__icon']} />
                </a>
              </div>
              <Textarea
                className={styles['feedback-form__text-area']}
                label="My feedback"
                name="message"
                placeholder="Tell us about… problems you struggled with during LLP usage?
            Any obstacles that you identified?
            Any ideas to improve the tool? Any missing features?"
              />
              <div className={styles['feedback-utils']}>
                <VfButton type="primary" htmlType="submit">
                  Send
                </VfButton>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

FeedbackForm.defaultProps = {
  closeFeedback: () => {},
  isOpened: false,
  tooltipOnly: true,
};

export default FeedbackForm;
