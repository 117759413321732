import { FC } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import classNames from 'classnames';

import VfIcon from '../vfIcon';
import './_vf-chips.scss';

export interface VfChipProps {
  className?: string;
  color?: string;
  icon?: string;
  id: string;
  label: string;
  link?: string;
  onClick?: (id: string) => void;
  onRemove?: (id: string, label: string) => void;
  unremovable?: boolean;
}

export const VfChip: FC<VfChipProps> = ({
  className = '',
  color = '',
  icon = '',
  id,
  label,
  link = '',
  onClick,
  onRemove,
  unremovable = false,
}) => {
  const handleLink = () => {
    if (link) {
      const linkModified = /^https?:/.test(link) ? link : `https://${link}`;

      window.open(linkModified, '_blank');
    }
  };

  const handleRemove = () => {
    if (onRemove) {
      onRemove(id, label);
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const classes = classNames(
    'vf-chip',
    `vf-chip--${color}`,
    `vf-chip--${unremovable}`,
    { 'vf-chip--clickable': onClick },
    className,
  );

  return (
    <div className={classes} onClick={handleClick}>
      {link && (
        <>
          <VfIcon name="link" color="blue" />
          <span className="vf-chip__label vf-chip__label--link ml-1" onClick={handleLink}>
            {label}
          </span>
        </>
      )}
      {!link && (
        <span className="vf-chip__label">
          {label} {icon && <VfIcon name={icon} color="white" />}
        </span>
      )}
      {!unremovable && <VfIcon name="close" className="vf-chip__remove-icon" onClick={handleRemove} asButton />}
    </div>
  );
};

export interface ChipEntity {
  label: string;
  color?: string;
  value: string | any; // TODO: handle any
  unremovable?: boolean;
}

export interface VfChipsProps {
  chips: ChipEntity[];
  onClick?: (id: string | string[]) => void;
  onRemove?: (id: string, label: string) => void;
  disabled?: boolean;
  clearAll: () => void;
}

const VfChips: FC<VfChipsProps> = ({ chips, disabled, onClick, clearAll, onRemove }) => (
  <div className="vf-chips">
    {chips.length > 0 && (
      <TransitionGroup component={null}>
        {chips.map((chip) => (
          <CSSTransition key={`chip-${chip.label}`} timeout={300} classNames="vf-chip-container">
            <VfChip
              id={chip.value}
              label={chip.label}
              unremovable={disabled || chip.unremovable}
              onClick={onClick}
              color={chip.color}
              onRemove={onRemove}
              link={chip.value.url}
            />
          </CSSTransition>
        ))}
        {!disabled && (
          <div onClick={clearAll} className="vf-chips__clear-all">
            Clear all
          </div>
        )}
      </TransitionGroup>
    )}
  </div>
);

VfChips.defaultProps = {
  chips: [],
  disabled: false,
  onClick: undefined,
  onRemove: () => {},
};

export default VfChips;
