import { FC } from 'react';

import styles from './vfInputSpinner.module.scss';

interface InputSpinnerProps {
  classes?: string;
}

const InputSpinner: FC<InputSpinnerProps> = ({ classes = '' }) => {
  return (
    <svg className={classes} width="24px" height="24px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle className={styles.path} fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
    </svg>
  );
};

InputSpinner.defaultProps = {
  classes: '',
};

export default InputSpinner;
