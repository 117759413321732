import { FC } from 'react';
import BackToPage from '../../components/BackToPage';

import styles from './Page404.module.scss';

const Page404: FC = () => {
  return (
    <>
      <BackToPage />

      <div className={styles.wrapper}>404</div>
    </>
  );
};

export default Page404;
