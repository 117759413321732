import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';

import { ADUser } from '../../../models/user';

import { useBoolean, useFindAdUser, useOutsideClick } from '../../../utils/hooks';

import VfButton from '../vfButton';
import { NO_DATA } from '../vfDropdown/helpers';
import VfHelperText from '../vfHelperText';
import VfLoader from '../vfLoader';
import { getCaretIndex, sanitizeComment } from './helpers';
import MentionItem from './mentionItem';
import styles from './mentions.module.scss';
import TagedUser, { TagedUserData } from './tagedUser';

const BACKSPACE = 8;
const ARROW_KEYS = {
  arrowLeft: 37,
  arrowUp: 38,
  arrowRight: 39,
  arrowDown: 40,
};
const DROPDOWN_KEY_CODES = {
  enter: 13,
  escape: 27,
  space: 32,
};

export interface InitialOffset {
  top: number;
  left: number;
}

export interface IndexIndicator {
  [key: string]: number;
}

export interface SelectionProp {
  selectionText: string;
  selectionStart: number;
  selectionEnd: number;
}

const INITIAL_OFFSET: InitialOffset = { top: 0, left: 0 };
const REGEX = /(?:^|\s)(@([^\s@]*))(?:$|\s)/;

export interface VfMentionsProps {
  comment?: string;
  elementId: string;
  helperText?: string;
  maxLength?: number;
  onSave: (html: string, text: string, usersIds: string[]) => void;
  readOnly?: boolean;
  useOnSaveButton?: boolean;
  onChange?: (html: string, text: string, usersIds: string[]) => void;
}

const getHTMLTag = (name: string): string =>
  `<span style="color: #1964A3;text-decoration: underline;">${name}</span>&nbsp;`;

const maxCharMsg = (maxLength: number): string => `Comment must be ${maxLength} characters at most.`;

const inRange = (x: number, min: number = 0, max: number = 0): boolean => {
  return x >= min && x <= max;
};

const VfMentions: FC<VfMentionsProps> = ({
  comment,
  elementId,
  helperText,
  maxLength,
  onSave,
  readOnly,
  useOnSaveButton,
  onChange,
}) => {
  const [openDropdown, setOpenDropdown, setCloseDropdown] = useBoolean(false);
  const [users, isLoading, searchForUser, resetUsers] = useFindAdUser();
  const text = useRef<HTMLDivElement | any>(comment || '');
  const ref = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState<InitialOffset>(INITIAL_OFFSET);
  const [tagedUsers, setTagedUsers] = useState<TagedUser[]>([]);
  const [searchTag, setSearchTag] = useState<string>('');
  const [searchIndex, setSearchIndex] = useState<number>(0);
  const [tagedIndexes, setTagedIndexes] = useState<IndexIndicator>({});
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (comment) {
      text.current = comment;
    }
  }, [comment]);

  const resetCommentStates = () => {
    setOffset(INITIAL_OFFSET);
    setCloseDropdown();
    setSearchTag('');
    setSearchIndex(0);
  };

  useOutsideClick(ref, () => {
    resetCommentStates();
  });

  const handleMaxChar = (comment: string): void => {
    if (maxLength && comment.length > maxLength) {
      const maxCharErrorMsg = maxCharMsg(maxLength);
      setError(maxCharErrorMsg);
    } else {
      setError('');
    }
  };

  const handleSave = (): void => {
    const sanitizedComment = sanitizeComment(text.current);
    const usersIds = tagedUsers.map((user) => user.id);
    const unique = [...new Set(usersIds)];

    handleMaxChar(sanitizedComment);

    if (!error) {
      onSave(text.current, sanitizedComment, unique);
    }
  };

  const handleChange = (e: ContentEditableEvent): void => {
    const { value } = e.target;
    text.current = value;

    const sanitizedComment = sanitizeComment(value);

    handleMaxChar(sanitizedComment);

    const searched = sanitizedComment.match(REGEX);
    if (searched && searched[2].length >= 3) {
      const position = window.getSelection();
      const { offsetTop } = (position?.focusNode?.parentNode as HTMLElement) || 0;
      const offsetLeft = offset.left || (position?.anchorOffset || 0) * 5;
      const index = getCaretIndex(elementId);

      setSearchIndex(index);
      setOffset({ top: offsetTop + 30, left: offsetLeft });
      setOpenDropdown();
      searchForUser(searched[2]);
      setSearchTag(searched[2]);
    }

    if (!useOnSaveButton) {
      handleSave();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    const { which } = e;

    if (Object.values(ARROW_KEYS).includes(which)) {
      return;
    }

    if (Object.values(DROPDOWN_KEY_CODES).includes(which)) {
      resetCommentStates();
    }

    const index = getCaretIndex(elementId);
    const foundTagedUsers = findTagedUsers(index);
    const { selectionText, selectionStart, selectionEnd } = getMarkedSelection();

    if (selectionText) {
      selectionProcess(selectionText, selectionStart, selectionEnd, foundTagedUsers, false, index);
    } else if (which === BACKSPACE) {
      const userToDeleteIndex = tagedUsers.findIndex((user) => {
        const indexRange = user.getIndexRange();
        return index >= indexRange[0] && index <= indexRange[1];
      });

      if (userToDeleteIndex > -1) {
        deleteUser(userToDeleteIndex);
      }
      if (foundTagedUsers) {
        recalculateIndexes(index, true);
      }
    } else if (foundTagedUsers) {
      recalculateIndexes(index);
    }
  };

  const handleSelect = (id: string, name: string): void => {
    const start = searchIndex - searchTag.length;
    const user = new TagedUser(id, name, start);
    const foundTagedUsers = findTagedUsers(start);

    if (foundTagedUsers) {
      recalculateIndexes(start, false, name.length - searchTag.length);
    }

    setTagedUsers([...tagedUsers, user]);
    setTagedIndexes({ ...tagedIndexes, [id]: start });

    const replaced = text.current.replace(`@${searchTag}`, getHTMLTag(name));

    text.current = replaced;
    resetCommentStates();
    resetUsers();
  };

  const onCut = (): void => {
    const { selectionText, selectionStart, selectionEnd } = getMarkedSelection();
    const index = getCaretIndex(elementId);
    const foundTagedUsers = findTagedUsers(index);

    selectionProcess(selectionText, selectionStart, selectionEnd, foundTagedUsers, false, index);
  };

  const onPaste = async () => {
    const { selectionText, selectionStart, selectionEnd } = getMarkedSelection();
    const index = getCaretIndex(elementId);
    const foundTagedUsers = findTagedUsers(index);

    selectionProcess(selectionText, selectionStart, selectionEnd, foundTagedUsers, false, index);

    const clipboardCopyText = await navigator.clipboard.readText();
    recalculateIndexes(index, false, clipboardCopyText.length);
  };

  const findTagedUsers = (index: number = 0): boolean =>
    index > 0 && !!Object.keys(tagedIndexes).length && Object.values(tagedIndexes).some((taged) => taged >= index);

  const recalculateIndexes = (index: number, decrement = false, textLength: number = 1): void => {
    const ids: string[] = [];

    for (let id in tagedIndexes) {
      if (tagedIndexes[id] >= index) {
        ids.push(id);
      }
    }
    tagedUsers.forEach((user) => {
      if (ids.includes(user.id)) {
        decrement ? user.decrementRanges(textLength) : user.incrementRanges(textLength);
        tagedIndexes[user.id] = decrement ? tagedIndexes[user.id] - textLength : tagedIndexes[user.id] + textLength;
      }
    });
  };

  const getMarkedSelection = () => {
    const selection = window.getSelection();
    const selectionText = selection?.toString();
    let selectionStart,
      selectionEnd = 0;

    if (selectionText) {
      const start = selection?.anchorOffset || 0;
      const end = selection?.focusOffset || 0;

      if (selectionStart === selectionEnd) {
        selectionStart = 0;
        selectionEnd = 0;
      } else if (start < end) {
        selectionStart = start;
        selectionEnd = end;
      } else {
        selectionStart = start;
        selectionEnd = end;
      }
    }

    return {
      selectionText,
      selectionStart,
      selectionEnd,
    };
  };

  const selectionProcess = (
    selectionText: string = '',
    selectionStart: number = 0,
    selectionEnd: number = 0,
    foundTagedUsers: boolean = false,
    isBackspace: boolean = false,
    index: number = 0,
  ): void => {
    const usersToDeleteIndexes: number[] = [];

    if (selectionText === text.current) {
      text.current = '';
      setTagedIndexes({});
      setTagedUsers([]);

      return;
    }

    tagedUsers.forEach((user, i) => {
      const indexRange = user.getIndexRange();

      if (
        inRange(indexRange[0], selectionStart, selectionEnd) ||
        inRange(indexRange[1], selectionStart, selectionEnd)
      ) {
        usersToDeleteIndexes.push(i);
      }
    });

    if (usersToDeleteIndexes.length) {
      usersToDeleteIndexes.forEach((userToDeleteIndex) => {
        deleteUser(userToDeleteIndex);
      });
    }
    if (foundTagedUsers) {
      const deletedTextLength = isBackspace ? selectionText.length : selectionText.length + 1;

      recalculateIndexes(index, true, deletedTextLength);
    }
  };

  const deleteUser = (userIndex: number) => {
    const { id, name, indexRange } = tagedUsers[userIndex].extractData() as TagedUserData;
    const textToreplace = text.current;
    const lastChar = textToreplace.slice(-1);
    const replaced = textToreplace.replace(getHTMLTag(name), '');
    const diff = indexRange[1] - indexRange[0];
    const users = [...tagedUsers];

    text.current = `${replaced}${lastChar}`;

    users.splice(userIndex, 1);
    users.forEach((user) => {
      if (user.startRange >= indexRange[0]) {
        // if another taged user is after deleted user
        user.removeByDiff(diff);
      }
    });
    setTagedUsers(users);

    delete tagedIndexes[id];
  };

  const mentions = users.map((user: ADUser) => (
    <MentionItem
      key={`mention-item-${user.id}`}
      name={user.displayName}
      value={user.id}
      onClick={handleSelect}
      isActive={tagedUsers.map((u) => u.id).includes(user.id)}
    />
  ));

  return (
    <div className={styles['vf-mentions']} id={elementId}>
      {readOnly && (
        <div className={styles['vf-mentions__read-only']} dangerouslySetInnerHTML={{ __html: text.current }} />
      )}

      {!readOnly && (
        <>
          <ContentEditable
            className={`${styles['vf-mentions__textarea']} mention-textarea`}
            html={text.current}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onCut={onCut}
            onPaste={onPaste}
            spellCheck
          />
          <div className={styles['vf-mentions__save']}>
            <span className={styles['at-help']}>{helperText}</span>
            {useOnSaveButton && (
              <VfButton type="primary" onClick={handleSave} disabled={!!error}>
                Save
              </VfButton>
            )}
          </div>
        </>
      )}

      {error && (
        <div className={styles['vf-mentions__error']}>
          <VfHelperText text={error} type="error" />
        </div>
      )}

      {openDropdown && (
        <div ref={ref}>
          <div className={styles['vf-mentions__dropdown']} style={{ top: offset.top, left: offset.left }}>
            {isLoading && <VfLoader relative notFixed noLogo />}
            {!isLoading && users.length > 0 && <>{mentions}</>}
            {!isLoading && users.length === 0 && <div>{NO_DATA}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

VfMentions.defaultProps = {
  comment: '',
  helperText: 'use "@" to mention a user.',
  maxLength: 0,
  readOnly: false,
  useOnSaveButton: false,
};

export default VfMentions;
