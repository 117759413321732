import { FC } from 'react';
import classNames from 'classnames';

import styles from './loader.module.scss';

export interface VfLoaderProps {
  isLoading?: boolean;
  relative?: boolean;
  noLogo?: boolean;
  notFixed?: boolean;
  mask?: boolean;
  className?: string;
}

const Loader: FC<VfLoaderProps> = ({ isLoading, noLogo, notFixed, relative, mask, className }) => {
  const classes = classNames([styles.loader__wrapper], className, {
    [styles['loader__wrapper--fixed']]: !notFixed,
    [styles['loader__wrapper--relative']]: relative,
    [styles['loader__wrapper--mask']]: mask,
  });

  if (!isLoading) {
    return null;
  }

  return (
    <div className={classes}>
      {noLogo ? null : <img src="/images/VfLogo.svg" height="64px" alt="Vattenfall Logo" />}

      <svg className={styles.spinner}width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle className={styles.path} fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
      </svg>
    </div>
  );
};

Loader.defaultProps = {
  isLoading: true,
  relative: false,
  noLogo: false,
  notFixed: false,
  mask: false,
  className: '',
};

export default Loader;
