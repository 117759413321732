import { VfButton } from '../../vfDesign';
import crashImage from './crash.png';
import styles from './error-page.module.scss';

const ErrorPage = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className={styles['error-page']}>
      <div className={styles['error-page__content']}>
        <h2>Oh no!</h2>
        <h2> Something went wrong.</h2>
        <img src={crashImage} alt="something wnet wrong" height={360} />
        <p>Please try to refresh this page.</p>
      </div>
      <div className={styles['error-page__button']}>
        <VfButton type="primary" onClick={refreshPage}>
          Reload
        </VfButton>
      </div>
    </div>
  );
};

export default ErrorPage;
