import { FC, useContext, useEffect, useState } from 'react';

import { useFormik, FormikHelpers, validateYupSchema, yupToFormErrors } from 'formik';
import * as yup from 'yup';

import { ADUser } from '../../../models/user';

import { bulkUpdateLessons, IBulkUpdateLessonsParams, IBulkUpdateLessonsResponse } from '../../../store/lessonsDetails';

import { ReportsContext } from '../../../context';
import { useAppDispatch } from '../../../root';
import Drawer from '../../FlexDrawer';
import DrawerHeader from '../../FlexDrawer/Header';
import BulkUpdateLessonsConfirmation from './BulkUpdateLessonsConfirmation';
import BulkUpdateLessonsForm from './BulkUpdateLessonsForm';
import { IFormikValues } from './types';
import { useBulkUpdateLessonsPermissions } from './useBulkUpdateLessonsPermissions';

const editActionTakerValidationSchema = yup.object().shape({
  actionTaker: yup.object().required('Action taker is required'),
  actionTakerDeputy: yup.object().nullable(),
});

interface BulkUpdateLessonsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const BulkUpdateLessonsDrawer: FC<BulkUpdateLessonsDrawerProps> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const { selected } = useContext(ReportsContext);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [editActionTaker, setEditActionTaker] = useState(false);
  const [editActionTakerDeputy, setEditActionTakerDeputy] = useState(false);
  const [updateStatus, setUpdateStatus] = useState<IBulkUpdateLessonsResponse | null>();
  const { canBulkEditActionTaker, canBulkEditActionTakerDeputy } = useBulkUpdateLessonsPermissions(selected);

  const [isUpdating, setIsUpdating] = useState(false);
  const { dirty, errors, handleSubmit, resetForm, setFieldValue, values } = useFormik<IFormikValues>({
    initialValues: { actionTaker: undefined, actionTakerDeputy: undefined },
    onSubmit: async (values: IFormikValues, { resetForm }: FormikHelpers<IFormikValues>) => {
      const params: IBulkUpdateLessonsParams = { lessonIds: selected.map((lesson) => lesson.id) };

      if (editActionTaker) {
        params.newActionTaker = values.actionTaker;
      }
      if (editActionTakerDeputy) {
        params.newActionTakerDeputy = values.actionTakerDeputy;
        if (!values.actionTakerDeputy) {
          params.resetActionTakerDeputy = true;
        }
      }

      setIsUpdating(true);
      const result = await dispatch(bulkUpdateLessons(params));
      setIsUpdating(false);

      if (result?.payload) {
        setUpdateStatus(result.payload as IBulkUpdateLessonsResponse);
        resetForm();
        setIsConfirmation(false);
        setEditActionTakerDeputy(false);
        setEditActionTaker(false);
      }
    },
    validate: (currentValues) => {
      if (editActionTaker) {
        try {
          validateYupSchema(currentValues, editActionTakerValidationSchema, true);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }
      return {};
    },
  });

  const getIsSubmitDisabled = () => {
    if (editActionTaker && editActionTakerDeputy) {
      return dirty && Object.keys(errors).length > 0;
    }
    if (editActionTaker) {
      return dirty && !!errors.actionTaker;
    }
    if (editActionTakerDeputy) {
      return false;
    }
    return !isUpdating;
  };

  useEffect(() => {
    setEditActionTakerDeputy(false);
    setEditActionTaker(false);
    setIsConfirmation(false);
    setUpdateStatus(null);
    resetForm();
  }, [isOpen, resetForm]);

  const handleUpdate = () => setIsConfirmation(true);
  const handleEditChanges = () => setIsConfirmation(false);

  const handleChange = (name: string, value?: Partial<ADUser>) => setFieldValue(name, !!value?.id ? value : undefined);

  const toggleActionTakerEdit = () => {
    handleChange('actionTaker', undefined);
    setEditActionTaker(!editActionTaker);
  };

  const toggleActionTakerDeputyEdit = () => {
    handleChange('actionTakerDeputy', undefined);
    setEditActionTakerDeputy(!editActionTakerDeputy);
  };

  return (
    <Drawer delayTime={400} isMounted={isOpen} isOpen={isOpen} onClose={onClose} side="right">
      <DrawerHeader headline="Update lessons" onClose={onClose} />
      {isConfirmation ? (
        <BulkUpdateLessonsConfirmation
          editActionTaker={editActionTaker}
          editActionTakerDeputy={editActionTakerDeputy}
          isSubmitDisabled={getIsSubmitDisabled()}
          onClose={onClose}
          onConfirm={handleSubmit}
          onEditChanges={handleEditChanges}
          selectedLessonsCount={selected.length}
        />
      ) : (
        <BulkUpdateLessonsForm
          canBulkEditActionTaker={canBulkEditActionTaker}
          canBulkEditActionTakerDeputy={canBulkEditActionTakerDeputy}
          editActionTaker={editActionTaker}
          editActionTakerDeputy={editActionTakerDeputy}
          errors={errors}
          isSubmitDisabled={getIsSubmitDisabled()}
          onChange={handleChange}
          onClose={onClose}
          onSubmit={handleSubmit}
          onUpdate={handleUpdate}
          toggleActionTakerDeputyEdit={toggleActionTakerDeputyEdit}
          toggleActionTakerEdit={toggleActionTakerEdit}
          updateStatus={updateStatus}
          values={values}
        />
      )}
    </Drawer>
  );
};

export default BulkUpdateLessonsDrawer;
