import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Message } from '../../models/messages';

import {
  eraseMessages,
  fetchMessages,
  markMessagesAsRead,
  selectIsRemoving,
  selectMessages,
  selectMessagesAreLoading,
  selectTotalElements,
} from '../../store/messages';

import { usePrevPropsAndState, useBoolean } from '../../utils/hooks';

import Drawer from '../FlexDrawer';
import DrawerFooter from '../FlexDrawer/Footer';
import DrawerHeader from '../FlexDrawer/Header';
import { VfButton, VfLoader } from '../vfDesign';
import NotificationsDrawer from './NotificationDrawer';
import styles from './_notification_center.module.scss';
import MessageItem from './messageItem';

const DEFAULT_PAGE = 1;
const TEN_MINUTES = 600000;
const DEFAULT_PAGE_SIZE = 30;

interface NotificationCenterProps {
  isOpen: boolean;
  onClose: () => void;
}

const NotificationCenter: FC<NotificationCenterProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { messages } = useSelector(selectMessages);
  const totalElements = useSelector(selectTotalElements);
  const isLoading = useSelector(selectMessagesAreLoading);
  const areRemoving = useSelector(selectIsRemoving);
  const { prevProps } = usePrevPropsAndState<Message[]>(messages);
  const [isSettingsOpen, openSettingsDrawer, closeSettingsDrawer] = useBoolean(false);

  const scrollDown = Array.isArray(prevProps) && messages.length > prevProps.length;

  const [page, setPage] = useState(DEFAULT_PAGE);

  const hasMore = messages.length < totalElements;

  useEffect(() => {
    dispatch(fetchMessages({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    const callForNewMessages = () => {
      if (!isOpen) {
        dispatch(fetchMessages({ page: 1 }));
      }
    };

    const interval = setInterval(callForNewMessages, TEN_MINUTES);

    return () => clearInterval(interval);
  }, [isOpen, dispatch]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (messages.length > 0) {
          const newItemIndex = messages.length - DEFAULT_PAGE_SIZE;
          const { id } = messages[newItemIndex];
          const el = document.getElementById(`notification-item-${id}`);
          (el as HTMLDivElement)?.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 100);
    }
  }, [scrollDown]); // eslint-disable-line

  const fetchMoreData = () => {
    if (!hasMore) {
      return;
    }
    const nextPage = page + 1;

    setPage(nextPage);
    dispatch(fetchMessages({ page: nextPage }));
  };

  const handleRemoveAll = () => {
    const itemsIds = messages.map((item) => item.id);

    dispatch(eraseMessages({ notificationIds: itemsIds }));
  };

  const handleRemove = (id: string) => {
    dispatch(eraseMessages({ notificationIds: [id] }));
  };

  const renderNotifications = messages.map((item) => (
    <MessageItem
      key={`notification-item-${item.id}`}
      {...item}
      onRemove={handleRemove}
      removeDisabled={areRemoving.includes(item.id)}
    />
  ));

  const handleClose = () => {
    const itemsIds = messages.filter((item) => !item.markedRead).map((item) => item.id);

    if (itemsIds.length) {
      dispatch(markMessagesAsRead({ notificationIds: itemsIds }));
    }
    onClose();
  };

  return (
    <>
      <NotificationsDrawer drawerOpen={isSettingsOpen} handleCloseDrawer={closeSettingsDrawer} />
      <Drawer isMounted={isOpen} isOpen={isOpen} onClose={handleClose} side="right" delayTime={400}>
        <DrawerHeader
          headline="Notifications"
          onClose={handleClose}
          onClearAll={handleRemoveAll}
          clearAllTitle="Remove All"
        />

        <div className={styles['notification-center']}>
          {renderNotifications}
          {isLoading && <VfLoader noLogo notFixed />}

          {hasMore && (
            <div className={styles['notification-center__footer']}>
              <VfButton onClick={fetchMoreData} type="tertiary" disabled={isLoading}>
                Click to load more
              </VfButton>
            </div>
          )}
          {messages.length === 0 && (
            <div className={styles['notification-center__footer']}>
              <span>No new notifications</span>
            </div>
          )}
        </div>
        <DrawerFooter>
          <VfButton outline="secondary" className="ml-5" onClick={openSettingsDrawer}>
            Notification settings
          </VfButton>
        </DrawerFooter>
      </Drawer>
    </>
  );
};

NotificationCenter.defaultProps = {
  isOpen: false,
};

export default NotificationCenter;
