import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchTagCategories, getTags } from '../../store/tags';
import VfSpinner from '../../components/Spinner';

import { TagCategoryList } from './list';
import { TagCategoryEditor } from './editor';

interface TagEditorParams {
  category: string;
}

export const TagEditorPage: FC = () => {
  const { category } = useParams<TagEditorParams>();
  const dispatch = useDispatch();
  const tagCategories = useSelector(getTags);

  useEffect(() => {
    dispatch(fetchTagCategories());
  }, [dispatch]);


  if (!category) {
    return <TagCategoryList />;
  } else if (tagCategories?.length) {
    return <TagCategoryEditor category={category} />;
  } else {
    return <div className="d-flex flex-fill justify-content-center align-items-center mb-2">
      <VfSpinner />
    </div>;
  }
};
export default TagEditorPage;
