import { FC, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { getUserDetails } from '../../store/user';

import { useCookie } from '../../utils/hooks';

import { VfIcon } from '../vfDesign';
import firstTimeVideo from './assets/VattenfallLessonsLearnedPortalAnimation.mp4';
import styles from './first-time-banner.module.scss';

export const FIRST_TIME_BANNER = 'FIRST_TIME_BANNER';

const FirstTimeBanner: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { data, isLoading } = useSelector(getUserDetails);
  const [isOpen, setIsOpen] = useCookie(FIRST_TIME_BANNER, 'true');
  const [muted, setMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    return () => {
      setIsPlaying(false);
      setMuted(true);
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    if (isPlaying) {
      setTimeout(() => {
        setMuted(false);
      }, 400);
    }
  }, [isPlaying]);

  const closeModal = () => {
    setIsOpen(JSON.stringify(false), {
      expires: 30,
    });
    setIsPlaying(false);
  };

  const handleError = (err: Error) => {
    console.error(err);
  };

  const handleOnLoad = () => {
    setIsPlaying(true);
  };

  const classes = classNames(styles['first-time-banner'], {
    [styles['first-time-banner--opened']]: JSON.parse(isOpen as string) && !isLoading && !!data,
  });

  return (
    <div className={classes} ref={ref}>
      <VfIcon name="close" className={styles['first-time-banner__close-icon']} onClick={closeModal} />
      <div className={styles['first-time-banner__header']}>
        Hello {data?.displayName} <br /> Welcome to the new Lessons Learned Portal
      </div>

      <ReactPlayer
        url={firstTimeVideo}
        className={styles['first-time-banner__video']}
        muted={muted}
        playing={isPlaying}
        onReady={handleOnLoad}
        onError={handleError}
        controls
      />

      <div className={styles['first-time-banner__content']}>
        In this portal you can share your lessons so that the correct person can take action. The new portal has the
        same functionalities as the previous one, but it has a better user interface, user experience, tracking of
        submitted lessons and many other advantages.
        <br />
        <br />
        Go check it out ourself and help BA Wind continuously improve!
      </div>
    </div>
  );
};

export default FirstTimeBanner;
