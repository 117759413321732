import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import Api from '../services/api';

const sliceName = 'reports';

export interface IGraphData {
  businessUnitFilter: Array<string>;
  dataGrouping: string | string[];
  dataOrder: string | string[];
  departmentFilter: Array<string>;
  organizationFilter: Array<string>;
  projectTypeFilter: Array<string>;
}

export const fetchReportsData = createAsyncThunk(`${sliceName}/reports`, async (params: IGraphData, { dispatch }) => {
  try {
    const response = await Api.post<IReportsData>('/graphs', params);

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch reports data', dispatch);

    throw err;
  }
});

export interface IReportsDataEntry {
  id: string;
  values: {
    TO_DO?: number;
    IN_PROGRESS?: number;
    DONE?: number;
  };
  summary: number;
}

interface IReportsData {
  dataGrouping: string;
  dataOrder: string;
  entries: Array<IReportsDataEntry>;
}

interface IReportsState {
  data: IReportsData;
  isLoading: boolean;
}

export const initialState: IReportsState = {
  isLoading: false,
  data: {
    dataGrouping: '',
    dataOrder: '',
    entries: [],
  },
};

export const reportsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReportsData.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchReportsData.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });

    builder.addCase(fetchReportsData.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getReportsData = (state: ReduxStoreType) => state[sliceName].data.entries;

export default reportsSlice.reducer;
