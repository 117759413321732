/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { useCustomEvent } from '../../utils/hooks';

interface Props {
  children: ReactNode;
}

enum EVENTS {
  exitPage = 'EXIT_PAGE',
  source = 'SOURCE',
}

const TrackAIWrapper: FC<Props> = ({ children }) => {
  const { search, pathname } = useLocation();
  const appInsights = useAppInsightsContext();
  const trackExitPageEvent = useCustomEvent(appInsights, EVENTS.exitPage);
  const trackSourceEvent = useCustomEvent(appInsights, EVENTS.source);

  const getSource = (): string => {
    if (search.includes('?referal=VfLLPemail')) {
      return 'EMAIL';
    }

    if (document.referrer) {
      return document.referrer;
    }

    return 'OPENED_DIRECTLY';
  };

  const getLeavingPage = (): void => {
    trackExitPageEvent({ pathname });
  };

  useEffect(() => {
    const source = getSource();

    trackSourceEvent({ source });
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', getLeavingPage);

    return () => {
      window.removeEventListener('beforeunload', getLeavingPage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <>{children}</>;
};

export default TrackAIWrapper;
