import { FC } from 'react';

import NewLessonFormProvider from './Context';
import Form from './Form';
import styles from './Shared.module.scss';
import Preview from './preview';

const NewLessonForm: FC = () => {

  return (
    <NewLessonFormProvider>
      <div className={styles.wrapper}>
        <Form />

        <Preview />
      </div>
    </NewLessonFormProvider>
  );
};

export default NewLessonForm;
