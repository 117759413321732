import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FormikValues, useFormikContext } from 'formik';

import {
  IOrganizationOrContainerItem,
  IProjectOrSiteItem,
  IProjectTypeOrDepartmentItem,
} from '../../models/filterSettings';
import {
  mapStructureToOptions,
  Organization,
  Process,
  Project,
  ProjectType,
  StructureBase,
  Workstream,
} from '../../models/structure';

import { StructureTypes } from '../../store/structure';

import { useDropdownSelection } from '../../utils/hooks';

import { useAppDispatch } from '../../root';
import { ReduxStoreType } from '../../store';
import { VfDropdown } from '../vfDesign';
import { NO_DATA, NO_DATA_OPTION } from '../vfDesign/vfDropdown/helpers';
import { IOption } from '../vfDesign/vfDropdown/models';

export type StructureEntities =
  | Organization
  | ProjectType
  | Project
  | Workstream
  | Process
  | IOrganizationOrContainerItem
  | IProjectTypeOrDepartmentItem
  | IProjectOrSiteItem
  | any; // TODO: handle any

export interface Param {
  [key: string]: string | undefined;
}

const checkAllValues = (params: Param | undefined) => {
  if (params === undefined) {
    return true;
  }

  return Object.values(params).every((value) => Boolean(value));
};

export interface EnhancedFormikDropdownParams {
  clearOption?: boolean;
  disabled?: boolean;
  dispatchFn: Function;
  fullWidth: boolean;
  groupedNamesConfig?: Record<string, string>;
  isGroupedOptions?: boolean;
  label: string;
  labelKey: string;
  name: string;
  onSelectCallback?: (option: StructureEntities) => void;
  params?: Param;
  relatedFields?: string[];
  storedData?: StructureEntities[];
  selector: (state: ReduxStoreType) => StructureTypes | any;
  setByLabel?: boolean;
  showMinifiedLabel?: boolean;
  skipFields?: string[];
}

const EnhancedFormikDropdown: FC<EnhancedFormikDropdownParams> = ({
  clearOption,
  disabled,
  dispatchFn,
  fullWidth,
  groupedNamesConfig,
  isGroupedOptions,
  label,
  labelKey,
  name,
  onSelectCallback,
  params,
  relatedFields,
  selector,
  setByLabel,
  showMinifiedLabel,
  skipFields,
  storedData = [],
}) => {
  const dispatch = useAppDispatch();
  const { errors, touched, setFieldValue, setFieldTouched, values } = useFormikContext<FormikValues>();
  const { data, isLoading } = useSelector(selector);

  const providedData: StructureEntities[] = storedData.length ? storedData : data;

  const dropdownOptions: IOption[] =
    providedData?.length > 0
      ? mapStructureToOptions(providedData, (el: StructureBase) => ({
          label: el[labelKey],
          value: el.id,
          group: el.group || '',
        }))
      : NO_DATA_OPTION;

  const { onClick } = useDropdownSelection({
    data: providedData,
    groupedNamesConfig,
    isGroupedOptions,
    isSetByLabel: setByLabel,
    name,
    onSelectCallback,
    options: dropdownOptions,
    relatedFields,
    setFieldValue,
  });

  const skip = skipFields?.length
    ? Object.entries(values).some(([key, val]) => {
        // check if at least one parent value is empty
        return skipFields.includes(key) && !val;
      })
    : false;

  useEffect(() => {
    if (!skip && Object.keys(values).length) {
      if (checkAllValues(params)) {
        dispatch(dispatchFn(params));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params), skip]);

  const setHandler = (_: string, value: string) => {
    onClick(_, { value });
  };

  const onBlur = () => {
    setFieldTouched(name, true);
  };
  const showNoData = !showMinifiedLabel && !isLoading && data?.length <= 0;
  const dropdownDisabled = disabled || showNoData || skip;
  const isError = errors[name] && touched[name] && String(errors[name]);

  return (
    <VfDropdown
      className="mt-2"
      clearOption={clearOption}
      disabled={dropdownDisabled}
      error={isError}
      fullWidth={fullWidth}
      isGroupedOptions={isGroupedOptions}
      id={name}
      isLoading={isLoading}
      label={showNoData ? NO_DATA : label}
      name={name}
      onBlur={onBlur}
      onChange={setHandler}
      options={dropdownOptions}
      showFullLabelText
      showMinifiedLabel={showMinifiedLabel}
      value={values[name]}
    />
  );
};

EnhancedFormikDropdown.defaultProps = {
  clearOption: false,
  disabled: false,
  fullWidth: false,
  groupedNamesConfig: {},
  isGroupedOptions: false,
  onSelectCallback: undefined,
  params: undefined,
  relatedFields: [],
  setByLabel: false,
  showMinifiedLabel: false,
  skipFields: [],
};

export default EnhancedFormikDropdown;
