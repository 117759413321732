import { FC } from 'react';

import EventSystemProvider from '../../events/context';
import ConfirmationModal from '../Confirmation';
import NeedHelp from '../NeedHelp';
import AuthWrapper from './Auth';
import ErrorBoundry from './ErrorBoundary';
import InsightsWrapper from './Insights';
import Notifications from './Notifications';
import ReduxWrapper from './Redux';
import RouterWrapper from './Router';
import TrackAIWrapper from './TrackAIWrapper';

const AppWrappers: FC = ({ children }) => (
  <ErrorBoundry>
    <InsightsWrapper>
      <ReduxWrapper>
        <EventSystemProvider>
          <RouterWrapper>
            <TrackAIWrapper>
              <AuthWrapper>{children}</AuthWrapper>
            </TrackAIWrapper>
          </RouterWrapper>
        </EventSystemProvider>
        <ConfirmationModal />
        <NeedHelp />
        <Notifications />
      </ReduxWrapper>
    </InsightsWrapper>
  </ErrorBoundry>
);

export default AppWrappers;
