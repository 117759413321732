import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Lesson as ILesson } from '../../../models/lesson';
import { Workshops, WorkshopSpace } from '../../../models/workshop';

// redux
import { fetchWorkshop, getCurrentWorkshop } from '../../../store/workshopDetails';
import {
  clearCurrentLesson,
  clearWorkshopLessonState,
  fetchFilteredLessonInWorkshop,
  getLessonsInWorkshopState,
  setCurrentLesson,
  setWorkshopDetailsPage,
  setWorkshopDetailsSort,
} from '../../../store/workshopSpaceLessons';

import { useBoolean } from '../../../utils/hooks';

import { VfFab } from '../../../components/vfDesign';
// components
import Lesson from '../../../pages/LessonsList/Lesson';
import { useAppDispatch } from '../../../root';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import { VfDropdown } from '../../vfDesign';
import { ORDER } from '../../vfDesign/vfTable/headers';
import WorkshopLessonDrawer from '../WorkshopLessonDrawer';
import ParticipantSection from './ParticipantSection';
import ProjectInfo from './ProjectInfo';
import styles from './workshop.module.scss';

interface WorkshopProps {
  workshop: Workshops;
  data: WorkshopSpace;
  workshopSpaceId: string;
}

const SORT_OPTIONS = [
  { label: 'Title', value: 'TITLE' },
  { label: 'Position in workshop', value: 'POSITION_IN_WORKSHOP' },
  { label: 'Workstream', value: 'WORKSTREAM' },
  { label: 'Process', value: 'PROCESS' },
  { label: 'Author', value: 'AUTHOR' },
];

const SORT_ORDER = [
  { label: 'ASC', value: ORDER.ASC },
  { label: 'DESC', value: ORDER.DESC },
];

const Workshop = ({ workshop, data, workshopSpaceId }: WorkshopProps) => {
  const dispatch = useAppDispatch();
  const { current, isCurrentLoading } = useSelector(getCurrentWorkshop);
  const [open, setOpen, onClose] = useBoolean(false);
  const [passedTab, setPassedTab] = useState(0);
  const [sortOption, setSortOption] = useState({ label: 'Title', value: 'TITLE' });
  const { lessons, areLessonsLoading, page, sort, totalPages } = useSelector(getLessonsInWorkshopState);

  const { id } = workshop;

  const canUserViewLessons = current?.userRights?.canViewWorkshopLessons;

  useEffect(() => {
    dispatch(fetchWorkshop(id));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(clearWorkshopLessonState());
    };
  }, [dispatch]);

  useEffect(() => {
    const payload = {
      id,
      body: {},
    };
    canUserViewLessons && dispatch(fetchFilteredLessonInWorkshop(payload));
  }, [dispatch, id, page, sort.sortBy, sort.order, canUserViewLessons]);

  const setPage = (page: number): void => {
    dispatch(setWorkshopDetailsPage(page));
  };

  const addLessonToWorkshop = () => {
    setOpen();
  };

  const handleOnClose = () => {
    dispatch(clearCurrentLesson());
    onClose();
  };

  const handleChangeSort = (name: string, val: string) => {
    setSortOption({ label: val, value: val });
    const [sortBy, order] = val.split(/ \((.*)\)/);
    dispatch(setWorkshopDetailsSort({ sortBy, order }));
  };

  const canCreateLesson = current?.userRights?.canCreateLessons;

  const handleOpenDrawer = (lessonId: string) => {
    const lessonToOpen = lessons?.find((item: ILesson) => item.id === lessonId);
    dispatch(setCurrentLesson(lessonToOpen));
    setOpen();
  };

  const sortingOptionsFormatted = SORT_OPTIONS.flatMap((option) =>
    SORT_ORDER.map((direction) => ({
      label: `${option.label} (${direction.label})`,
      value: `${option.value} (${direction.value})`,
    })),
  );

  const workshopLessons = lessons?.map((lesson: ILesson, i: any) => (
    <Lesson
      actionTaker={lesson.actionTaker}
      actionTakerDeputy={lesson.actionTakerDeputy}
      description={lesson.description}
      favourite={lesson.favourite}
      id={lesson.id}
      isWorkshopLesson={true}
      key={`workshop-lesson-${i}`}
      name={lesson.title}
      lastActionRequestTime={lesson.lastActionRequestTime}
      priority={lesson.priority}
      process={lesson.processName}
      project={lesson.projectName}
      published={lesson.published}
      readyForPublication={lesson.readyForPublication}
      recommendation={lesson.recommendation}
      setLessonId={handleOpenDrawer}
      showDeleteOption={lesson.userRights?.canDeleteLesson}
      showPublishOption={lesson.userRights?.canPublishLesson}
      state={lesson.state}
      workshopId={lesson.workshopId}
      workshopName={lesson.workshopName}
    />
  ));

  return (
    <div className="vf-container">
      <WorkshopLessonDrawer
        workshopId={current?.id}
        open={open}
        onClose={handleOnClose}
        workshop={data}
        side="right"
        onOutsideClick={handleOnClose}
        passedTab={passedTab}
        setPassedTab={setPassedTab}
      />

      <div className={styles['workshop__container']}>
        <div className="d-flex flex-column">
          <ProjectInfo
            businessUnit={data.organizationName}
            facilitators={current?.facilitators}
            organizationName={data.organizationName}
            performedFrom={workshop.startDate}
            performedTo={workshop.endDate}
            projectId={data.projectId}
            projectTypeId={data.projectTypeId}
            status={workshop.workshopState}
            title={data.projectName}
            type={data.projectTypeName}
            workshopSpaceId={workshopSpaceId}
          />
        </div>
        <div className="d-flex mt-2 align">
          <ParticipantSection participants={current?.participants} isLoading={isCurrentLoading} />
        </div>
      </div>

      {workshopLessons?.length > 1 && (
        <div className="vf-row mt-1 justify-content-end">
          <div className="vf-col-lg-4 vf-col-sm-12">
            <VfDropdown
              id="sortBy"
              isLoading={areLessonsLoading}
              label="Sort by"
              name="sortBy"
              onBlur={() => {}} // TODO: handle blur as optional in VfDropdown
              onChange={handleChangeSort}
              options={sortingOptionsFormatted}
              value={sortOption.label}
            />
          </div>
        </div>
      )}
      <div className="vf-row mt-3">
        <div className="vf-col">
          <div className="vf-container">
            <InfiniteScroll
              isLoading={areLessonsLoading}
              page={page}
              totalPages={totalPages}
              noItemsMessage="No lessons found"
              onFetchMore={setPage}
            >
              {workshopLessons}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      {canCreateLesson && <VfFab onClick={addLessonToWorkshop} />}
    </div>
  );
};

export default Workshop;
