import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { InfoElement } from '../models/infoElement';

import { handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import Api from '../services/api';

export const sliceName = 'infoElement';

export interface InfoElementState {
  data: InfoElement[];
  isLoading: boolean;
}

export const exportData = createAsyncThunk('exportData', async (_, { dispatch }) => {
  try {
    const response = await Api.get('export/excel', {}, false, { responseType: 'blob' });

    const objectUrl = URL.createObjectURL(response.data as Blob);
    const file = document.createElement('a');

    file.download = 'LLP_Data';
    file.href = objectUrl;
    file.click();
  } catch (error) {
    handleApiError(error as AxiosError, 'We could not export data', dispatch);
  }
});

export const fetchInfoElements = createAsyncThunk(`${sliceName}/fetch`, async (_, { dispatch }) => {
  try {
    const response = await Api.get<InfoElement[]>('info-elements');

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch info elements', dispatch);

    throw err;
  }
});

export const addInfoElement = createAsyncThunk(
  `${sliceName}/add`,
  async ({ name, ...body }: InfoElement, { dispatch }) => {
    try {
      const response = await Api.put<InfoElement>(`info-elements/${name}`, body);
      await dispatch(fetchInfoElements());

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not add info element', dispatch);

      throw err;
    }
  },
);

export const removeInfoElement = createAsyncThunk(`${sliceName}/remove`, async (name: string, { dispatch }) => {
  try {
    const response = await Api.delete<InfoElement>(`info-elements/${name}`);
    await dispatch(fetchInfoElements());

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not remove info element', dispatch);

    throw err;
  }
});

export const initialState: InfoElementState = {
  data: [],
  isLoading: false,
};

export const infoElementsReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInfoElements.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchInfoElements.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });

    builder.addCase(fetchInfoElements.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(addInfoElement.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addInfoElement.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(addInfoElement.rejected, (state, { error }) => {
      state.isLoading = false;
    });

    builder.addCase(removeInfoElement.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(removeInfoElement.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(removeInfoElement.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getInfoElements = (state: ReduxStoreType): InfoElementState => state[sliceName];
export const getInfoElementByName =
  (state: ReduxStoreType) =>
  (name: string): InfoElement =>
    state[sliceName].data.find((el) => el.name === name) || {
      name: '',
      popupText: '',
    };

export default infoElementsReducer.reducer;
