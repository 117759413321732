interface ProjectTypeLabels {
  [key: string]: string;
}

const PROJECT_TYPE_LABELS: ProjectTypeLabels = {
  additionalInformation: 'Additional information',
  deputy: 'Action Taker',
  description: 'Description',
  files: 'Files',
  organizationName: 'Business Unit',
  owner: 'Action Taker',
  processId: 'Process',
  projectId: 'Project',
  projectTypeName: 'Project type',
  recommendation: 'Your recommendation',
  tags: 'Tags',
  tgPhase: 'Project phase',
  type: 'Experienced in',
  workstreamId: 'Workstream',
};

const OPERATING_SITE_TYPE_LABELS: ProjectTypeLabels = {
  additionalInformation: 'Additional information',
  deputy: 'Action Taker',
  description: 'Description',
  files: 'Files',
  organizationName: 'Business Unit',
  owner: 'Action Taker',
  processId: 'Process',
  recommendation: 'Your recommendation',
  operatingSiteName: 'Site',
  tags: 'Tags',
  type: 'Experienced in',
  workstreamId: 'Workstream',
};

const ORGANIZATIONAL_UNIT_TYPE_LABELS: ProjectTypeLabels = {
  additionalInformation: 'Additional information',
  departmentName: 'Department',
  deputy: 'Action Taker',
  description: 'Description',
  files: 'Files',
  organizationName: 'Business Unit',
  owner: 'Action Taker',
  processId: 'Process',
  recommendation: 'Your recommendation',
  tags: 'Tags',
  type: 'Experienced in',
  workstreamId: 'Workstream',
};

interface ProjectTypeOrder {
  [key: string]: number;
}

const PROJECT_TYPE_ORDER: ProjectTypeOrder = {
  type: 0,
  organizationName: 1,
  organizationId: 2,
  departmentName: 3,
  departmentId: 3,
  projectTypeName: 4,
  projectTypeId: 5,
  operatingSiteId: 6,
  operatingSiteName: 6,
  projectId: 7,
  projectName: 8,
  tgPhase: 8,
  tags: 9,
  description: 10,
  recommendation: 11,
  workstreamId: 12,
  workstreamName: 13,
  processId: 14,
  processName: 15,
  additionalInformation: 16,
  comment: 17,
  owner: 18,
  deputy: 19,
  files: 20,
  title: 22,
};

export { PROJECT_TYPE_LABELS, PROJECT_TYPE_ORDER, OPERATING_SITE_TYPE_LABELS, ORGANIZATIONAL_UNIT_TYPE_LABELS };
