import { FC, MouseEvent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import { ADUser } from '../../models/user';

import { getLessons, toggleFavourite, triggerActionTaker } from '../../store/lessons';
import { deleteLesson, getLessonDetails } from '../../store/lessonsDetails';
import { setNotification } from '../../store/notifications';
import { getIsAdmin } from '../../store/user';
import { deleteLessonFromWorkshop, togglePublishLesson } from '../../store/workshopSpaceLessons';

import { dashSpacedToCapitalize, isMobile } from '../../utils/functions';

import Icon from '../../components/Icon';
import { TabIndex } from '../../components/LessonDetails';
import Tooltip from '../../components/Tooltip';
import PublishedSwitch from '../../components/Workshop/Workshop/PublishedSwitch';
import { VfButton as Button, VfCheckbox, VfIcon } from '../../components/vfDesign';
import { NOTIFICATION_TYPES } from '../../components/vfDesign/vfNotification';
import { STATE_COLORS, STATE_ICONS } from '../../consts/lessons';
import { ReportsContext } from '../../context';
import { dispatchConfirmationEvent } from '../../events/helpers';
import styles from './Lesson.module.scss';

interface LabelProps {
  name: string;
  text?: string;
}

export const Label: FC<LabelProps> = ({ name, text }) => {
  const classes = classNames('vf-col', styles.label);

  if (!text) {
    return null;
  }
  return (
    <div className={classes}>
      <div className={styles.name}>{name}</div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export const RowLabel: FC<LabelProps> = ({ name, text }) => {
  const nameClasses = classNames('vf-col', styles.rowName);
  const textClasses = classNames('vf-col', styles.rowText);

  if (!text) {
    return null;
  }
  return (
    <div className={styles.rowLabel}>
      <div className={nameClasses}>{name}</div>
      <div className={textClasses}>{text}</div>
    </div>
  );
};

interface LessonProps {
  actionTaker: Partial<ADUser>;
  actionTakerDeputy: Partial<ADUser>;
  delayed?: boolean;
  description?: string;
  favourite: boolean;
  id: string;
  isWorkshopLesson?: boolean;
  lastActionRequestTime?: string;
  name: string;
  priority: string;
  process?: string;
  project?: string;
  published?: boolean;
  readyForPublication?: boolean;
  recommendation?: string;
  setLessonId: (id: string, tabName: TabIndex) => void;
  showDeleteOption?: boolean;
  showPublishOption?: boolean;
  state: string;
  workshopId?: string;
  workshopName?: string;
}

const Lesson: FC<LessonProps> = ({
  actionTaker,
  actionTakerDeputy,
  delayed,
  description,
  favourite,
  id,
  isWorkshopLesson,
  lastActionRequestTime,
  name,
  priority,
  process,
  project,
  published = false,
  readyForPublication,
  recommendation,
  setLessonId,
  showDeleteOption,
  showPublishOption,
  state,
  workshopId,
  workshopName,
}) => {
  const dispatch = useDispatch();
  const { isEnabled, isSelected, handleToggleSelected } = useContext(ReportsContext);

  const { isDeleting } = useSelector(getLessonDetails);
  const { isLoading } = useSelector(getLessons);
  const lessonClasses = classNames(
    'vf-row mb-3 pt-3 pb-3 pr-2',
    styles.lesson,
    lastActionRequestTime && 'lesson-item-border',
  );
  const mainIconClasses = classNames(
    'vf-col-lg-1 d-flex flex-fill justify-content-center align-items-center flex-direction-column',
    styles['icon-main'],
  );
  const titleIconClasses = classNames('vf-col-1', styles['icon-title']);
  const actionsClasses =
    isWorkshopLesson || showDeleteOption
      ? classNames('vf-col-sm-12 vf-col-lg-2 d-flex flex-fill', styles.actions, styles.workshopActions)
      : classNames(
          'vf-col-sm-12 vf-col-lg-3 d-flex flex-fill justify-content-center align-items-center',
          styles.actions,
        );

  const isDisabled = isDeleting.includes(id);
  const isAdmin = useSelector(getIsAdmin);

  const handleOpenDrawer = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isDisabled) return;

    setLessonId(id, TabIndex.INFO);
  };

  const handleOpenDrawerTakeAction = (e: MouseEvent<HTMLDivElement>) => {
    if (isDisabled) return;

    setLessonId(id, TabIndex.TAKE_ACTION);
    e.stopPropagation();
  };

  const onLessonDelete = () => {
    if (isWorkshopLesson && workshopId) {
      const payload = {
        id: id,
        workshopId: workshopId,
      };
      dispatch(deleteLessonFromWorkshop(payload));
    } else dispatch(deleteLesson(id));
  };

  const handleDelete = (e: MouseEvent<HTMLDivElement>) => {
    if (isDisabled) return;

    e.stopPropagation();
    dispatchConfirmationEvent(onLessonDelete, () => <p> 'Are you sure you want to delete lesson?</p>);
  };

  const handlePublish = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isWorkshopLesson && workshopId) {
      dispatch(togglePublishLesson({ workshopId, id, checked: !published }));
    }
  };

  const handleToggleFavourite = (e: MouseEvent<HTMLSpanElement>) => {
    if (isDisabled || isLoading) return;

    e.stopPropagation();
    dispatch(
      toggleFavourite({
        lessonId: id,
      }),
    );
  };

  const isFromWorkshop = workshopId !== undefined;

  const selected = isSelected?.({ id, name });

  const handleSelection = () => {
    handleToggleSelected?.({ id, name });
  };

  const handleShareLesson = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    const textToClipboard = String(`${window.location.href}/${id}`);
    navigator.clipboard.writeText(textToClipboard);
    dispatch(setNotification('Link to lesson was successfully copied to clipboard.', NOTIFICATION_TYPES.SUCCESS));
  };

  const handleTriggerActionTaker = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    dispatch(triggerActionTaker({ lessonId: id }));
  };

  return (
    <div className={styles.wrapper} id={`lesson-${id}`}>
      {isEnabled && (
        <VfCheckbox onChange={handleSelection} disabled={!published} className={styles.checkbox} checked={selected} />
      )}
      <div className={lessonClasses} onClick={handleOpenDrawer}>
        <div className={mainIconClasses}>
          <Tooltip text={`Status: ${dashSpacedToCapitalize(state)}`} position="bottom">
            <Icon name={`progress--${STATE_ICONS[state]}`} withOutline color={STATE_COLORS[state]} />
          </Tooltip>
        </div>

        <div className="vf-col-sm-12 vf-col-lg-8">
          <div className="vf-row">
            <div className={titleIconClasses}>
              <Icon name={`progress--${STATE_ICONS[state]}`} withOutline color={STATE_COLORS[state]} />
            </div>

            <div className="vf-col-11 mb-2">
              <h3 className={`${styles.title} ${delayed ? styles.titleDelayed : ''}`}>
                {!isWorkshopLesson && priority === 'HIGH' && (
                  <Tooltip text="High priority" position="bottom">
                    <VfIcon name="vf-status--alert" color="red" />
                  </Tooltip>
                )}
                {name}
              </h3>
            </div>
          </div>

          <div className="vf-row">
            <Label name="ACTION TAKER" text={actionTaker?.displayName} />
            <Label name="ACTION TAKER DEPUTY" text={actionTakerDeputy?.displayName ? actionTakerDeputy?.displayName : '------' } />

            <Label
              name={isWorkshopLesson ? 'PROCESS' : 'PROJECT'}
              text={isWorkshopLesson ? process || '------' : project || '------'}
            />
          </div>

          <div className="vf-row">
            <RowLabel name="DESCRIPTION" text={description} />
          </div>

          <div className="vf-row">
            <RowLabel name="RECOMMENDATION" text={recommendation} />
          </div>
        </div>
        {!isWorkshopLesson && (
          <Tooltip text="Copy link to lesson to the clipboard" position="softTop">
            <VfIcon name="share" className={styles['share-button']} onClick={handleShareLesson} />
          </Tooltip>
        )}
        {!isWorkshopLesson && (
          <Tooltip text={favourite ? "Remove lesson as not favourite" : "Add lesson as favourite"} position="softTop">
            <VfIcon
              name={`${favourite ? 'star-full' : 'star-outlined'}`}
              color={favourite ? 'yellow' : 'black'}
              className={styles['favorite-button']}
              onClick={handleToggleFavourite}
            />
          </Tooltip>
        )}

        {!isWorkshopLesson && isFromWorkshop && !isMobile() && (
          <Tooltip text={workshopName as string} position="softTop">
            <VfIcon name="info" className={styles['workshop-indicator']} />
          </Tooltip>
        )}

        {isAdmin && !isWorkshopLesson && (
          <Tooltip text="Trigger Action Taker" position="softTop">
            <VfIcon
              name={`schedule-send`}
              color={'black'}
              className={styles['workshop-indicator-trigger-action']}
              onClick={handleTriggerActionTaker}
              disabled={Boolean(lastActionRequestTime)}
            />
          </Tooltip>
        )}

        {!isWorkshopLesson && isFromWorkshop && isMobile() && (
          <span className={styles['workshop-span']}>{workshopName}</span>
        )}

        <div className={actionsClasses}>
          {showPublishOption && (
            <div onClick={handlePublish}>
              <PublishedSwitch
                hasButtonStyles
                disabled={false}
                id={id}
                published={published}
                readyForPublication={readyForPublication}
                workshopId={id}
              />
            </div>
          )}

          {!isWorkshopLesson && (
            <div onClick={handleOpenDrawerTakeAction}>
              <Button outline="dark" size="small" disabled={isDisabled}>
                Take Action
              </Button>
            </div>
          )}
          {showDeleteOption && (
            <div onClick={handleDelete}>
              <Button type="primary" disabled={isDisabled} deleteButton>
                Delete
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Lesson.defaultProps = {
  showDeleteOption: false,
};

export default Lesson;
