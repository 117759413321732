import { FC } from 'react';

import classNames from 'classnames';
import styles from './vf-fab.module.scss';

import { VfIcon } from '..';

interface VfFabProps {
  disabled?: boolean;
  onClick: () => void;
}

const VfFab: FC<VfFabProps> = ({ disabled, onClick }) => {
  const classes = classNames(styles['add-round-button'], {
    [styles['add-round-button--disabled']]: disabled,
  });

  return (
    <button type="button" disabled={disabled} onClick={onClick} puppet-data="add-button" className={classes}>
      <VfIcon name="vf-plus" />
    </button>
  );
};

VfFab.defaultProps = {
  disabled: false,
};

export default VfFab;
