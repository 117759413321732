import { FC } from 'react';
import { Switch } from 'react-router-dom';

import { DelayedWrapper } from '../utils/hoc/withDelayedUnmount';
import { useCookie } from '../utils/hooks';

import FirstTimeBanner, { FIRST_TIME_BANNER } from '../components/FirstTimeBanner/FirstTimeBanner';
import PrivateRoute from '../components/Wrappers/Auth/PrivateRoute';
import Route from '../components/Wrappers/Auth/Route';
import config from './config';

const Routes: FC = () => {
  const [isOpen] = useCookie(FIRST_TIME_BANNER, 'true');

  const isFirstTimeBannerOpened = JSON.parse(isOpen as string);

  const routes = config.map((r) =>
    r.private ? (
      <PrivateRoute key={r.id} component={r.component} exact={r.exact} path={r.url} withLayout={r.withLayout} />
    ) : (
      <Route key={r.id} component={r.component} exact={r.exact} path={r.url} withLayout={r.withLayout} />
    ),
  );

  return (
    <>
      <Switch>{routes}</Switch>
      <DelayedWrapper isMounted={isFirstTimeBannerOpened} isOpen={isFirstTimeBannerOpened}>
        <FirstTimeBanner />
      </DelayedWrapper>
    </>
  );
};

export default Routes;
