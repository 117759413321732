import { FC, useCallback, useRef, useState } from 'react';

import classNames from 'classnames';

import { useOutsideClick } from '../../../utils/hooks';

import { VfInputSpinner } from '..';
import VfHelperText from '../vfHelperText';
import VfOutlineLabel from '../vfOutlineLabel';
import { checkIfNeedGrouping } from './helpers';
import { IOption } from './models';
import './vf-dropdown.scss';
import VfRegularOptions from './vfRegularOptions';

interface VfDropdownProps {
  className?: string;
  clearOption?: boolean;
  disabled?: boolean;
  error?: string | boolean;
  fullWidth?: boolean;
  isGroupedOptions?: boolean;
  id: string;
  isLoading?: boolean;
  label?: string;
  name: string;
  onBlur: Function;
  onChange: (name: string, val: string) => void;
  options: IOption[];
  showMinifiedLabel?: boolean;
  showFullLabelText?: boolean;
  tabIndex?: number;
  type?: 'standard' | 'underline';
  value: string;
}

const VfDropdown: FC<VfDropdownProps> = ({
  className,
  clearOption,
  disabled,
  error,
  fullWidth,
  isGroupedOptions,
  id,
  isLoading,
  label,
  name,
  onBlur,
  onChange,
  options,
  showMinifiedLabel,
  showFullLabelText,
  tabIndex,
  type,
  value,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [toTop, setToTop] = useState(false);

  const openOptionsToTopRef = useCallback((node) => {
    if (node !== null) {
      const elementTop = node.getBoundingClientRect().top;
      const elementBottom = node.getBoundingClientRect().bottom;

      if (elementTop < 0 && elementBottom <= window.innerHeight) {
        setToTop(true);
      } else {
        setToTop(false);
      }
    }
  }, []);

  const classes = classNames(
    'vf-dropdown',
    {
      on: open && options.length,
      'error-layout': !!error,
      disabled,
      'vf-dropdown--fullwidth': fullWidth,
    },
    `vf-dropdown--${type}`,
    className,
  );

  const dropdownClasses = classNames('vf-dropdown-list', {
    'vf-dropdown-list--open-up': toTop,
  });

  const dropdownLabelClasses = classNames('vf-dropdown-label', {
    'vf-dropdown-label--open-up': toTop,
    'vf-dropdown-label--empty': !showMinifiedLabel,
  });

  const toggleShowOptions = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  const closeOptions = () => setOpen(false);

  const handleBlur = () => {
    if (!error && open) {
      onBlur(name);
    }
  };

  useOutsideClick(ref, () => {
    if (open) {
      handleBlur();
      closeOptions();
    }
  });

  const handleClick = (val: string) => {
    onChange(name, val);
    closeOptions();
  };

  const triggerOpenDropdown = () => {
    if (!isLoading) {
      handleBlur();
      toggleShowOptions();
    }
  };

  const onClearOption = () => {
    handleClick('');
  };

  const selectedOption = (() => {
    if (!value) {
      return '';
    }

    const selected = options.find((option) => option.value === value);

    return selected && selected.label ? selected.label : '';
  })();

  const isGrouped = checkIfNeedGrouping(options, isGroupedOptions);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <div
        className={classes}
        data-control={`vf-dropdown-data${disabled ? '-disabled' : ''}`}
        id={id}
        onClick={triggerOpenDropdown}
        ref={ref}
        tabIndex={tabIndex}
      >
        {showMinifiedLabel && <VfOutlineLabel show label={label} disabled={disabled} openUp={toTop} />}

        <label className={dropdownLabelClasses}>
          {selectedOption || label}

          {isLoading && <VfInputSpinner classes="vf-dropdown__spinner" />}
        </label>

        <div className={dropdownClasses} ref={openOptionsToTopRef}>
          {clearOption && (
            <div className="vf-dropdown-list-item" onClick={onClearOption}>
              <label className="vf-dropdown-option">----</label>
            </div>
          )}

          <VfRegularOptions
            options={options}
            handleClick={handleClick}
            isShowFullText={showFullLabelText}
            isGroupedOptions={isGrouped}
          />
        </div>
      </div>

      {error && <VfHelperText type="error" text={error} />}
    </div>
  );
};

VfDropdown.defaultProps = {
  className: '',
  clearOption: false,
  disabled: false,
  error: '',
  fullWidth: false,
  isGroupedOptions: false,
  isLoading: false,
  label: 'Select Option',
  showMinifiedLabel: false,
  showFullLabelText: false,
  tabIndex: 0,
  type: 'standard',
  value: '',
};

export default VfDropdown;
