import { FC, useContext, useRef, useState } from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { ADUser } from '../../../models/user';

import {
  dashSpacedToCapitalize,
  handleFormikValueRelation,
  isMobile,
  sortLessonPreview,
} from '../../../utils/functions';
import { useOutsideClick } from '../../../utils/hooks';

import { FLOW_TYPES } from '../../../consts/newLessonForm';
import { findCurrentFlow, NewLessonContext } from '../Context';
import sharedStyles from '../Shared.module.scss';
import { ListedValueItem } from '../steps/Summary';
import { OPERATING_SITE_TYPE_LABELS, ORGANIZATIONAL_UNIT_TYPE_LABELS, PROJECT_TYPE_LABELS } from '../steps/labels';
import { ProjectFormValues } from '../types';
import styles from './preview.module.scss';

const NewLessonPreview: FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { currentStep, currentFlowType, finished, config } = useContext(NewLessonContext);

  const [opened, setOpened] = useState(false);
  const [clickedOnce, setClickedOnce] = useState(false);

  const { values } = useFormikContext<ProjectFormValues>();

  useOutsideClick(wrapperRef, () => {
    setOpened(false);
  });

  const steps = config.find(findCurrentFlow(currentFlowType))?.steps;

  if (finished || currentStep === 0 || currentStep === steps?.length) {
    return null;
  }

  const setOpen = () => {
    setOpened(true);
    setClickedOnce(true);
  };

  const flowTypeLabels = (() => {
    switch (currentFlowType) {
      case FLOW_TYPES.PROJECT:
        return PROJECT_TYPE_LABELS;
      case FLOW_TYPES.OPERATING_SITE:
        return OPERATING_SITE_TYPE_LABELS;
      case FLOW_TYPES.ORGANIZATIONAL_UNIT:
        return ORGANIZATIONAL_UNIT_TYPE_LABELS;
      default:
        return PROJECT_TYPE_LABELS;
    }
  })();

  const mapValues = Object.entries(values)
    .sort(sortLessonPreview)
    .map(([key, val]) => {
      if (key in flowTypeLabels && val) {
        if (key === 'owner' || key === 'deputy') {
          if (!(val as Partial<ADUser>)?.displayName) {
            return null;
          }
          return (
            <div className={styles['label-wrapper']} key={key}>
              <h5 className={styles['label']}>{flowTypeLabels[key]}</h5>
              <p className={styles['value']}>{(val as Partial<ADUser>)?.displayName}</p>
            </div>
          );
        }
        if (val instanceof Array) {
          return (
            <div className={styles['label-wrapper']} key={key}>
              <h5 className={styles['label']}>{flowTypeLabels[key]}</h5>
              <ListedValueItem value={val} className={`${styles['value']} ${styles['no-elipsis']}`} />
            </div>
          );
        }

        return (
          <div className={styles['label-wrapper']} key={key}>
            <h5 className={styles['label']}>{flowTypeLabels[key]}</h5>
            <p className={styles['value']}>{handleFormikValueRelation(key, val, values)}</p>
          </div>
        );
      }

      return null;
    });

  const classes = classNames(styles['preview-wrapper'], {
    [styles['open']]: opened,
  });

  const previewClasses = classNames(styles['preview'], {
    [styles['bounce']]: isMobile() && !clickedOnce,
  });

  return (
    <div className={sharedStyles.preview}>
      <div className={classes} ref={wrapperRef} onClick={setOpen}>
        <div className={previewClasses}>
          <h5 className={styles['header']}>My lesson</h5>

          {currentStep > 0 && (
            <>
              <div className={styles['label-wrapper']}>
                <h5 className={styles['label']}>{flowTypeLabels.type}</h5>
                <p className={styles['value']}>{dashSpacedToCapitalize(currentFlowType as string)}</p>
              </div>
              {mapValues}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewLessonPreview;
