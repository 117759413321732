import { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LegendItem,
  ChartEvent,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Formik } from 'formik';

import { IAdvancedFilters } from '../../models/filterSettings';

import { getAdvancedFilters } from '../../store/filterSettings';
import { getReportsData } from '../../store/reports';
import { getUserDetails } from '../../store/user';

import { LESSON_STATUS } from '../../consts/lessons';
import ReportsFilters from './ReportsFilters/reportsFilters';
import styles from './reports.module.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface IContext {
  label: string;
}

const DATASET_INDEX_MAP: Record<number, string> = {
  0: LESSON_STATUS.TO_DO,
  1: LESSON_STATUS.IN_PROGRESS,
  2: LESSON_STATUS.DONE,
};

const Reports: FC = () => {
  const initialValues = useSelector(getAdvancedFilters);
  const reportEntries = useSelector(getReportsData);
  const { data } = useSelector(getUserDetails);

  const entryLabels = reportEntries.map((entry) => [entry.id, `Total items: ${entry.summary}`]);

  const getDesireData = (status: 'TO_DO' | 'IN_PROGRESS' | 'DONE') =>
    reportEntries.map((entry) => entry.values?.[status]);

  const entryData = {
    labels: entryLabels,

    datasets: [
      {
        label: 'To Do',
        data: getDesireData('TO_DO'),
        backgroundColor: 'rgb(0, 110, 173)',
      },
      {
        label: 'In Progress',
        data: getDesireData('IN_PROGRESS'),
        backgroundColor: 'rgb(255, 217, 0)',
      },
      {
        label: 'Done',
        data: getDesireData('DONE'),
        backgroundColor: 'rgb(0, 175, 52)',
      },
    ],
  };

  const getOptions = ({
    departmentId,
    departmentName,
    operatingSiteId,
    operatingSiteName,
    organizationId,
    organizationName,
    projectTypeId,
    projectTypeName,
  }: IAdvancedFilters) => ({
    maxBarThickness: 32,
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: '#ffffff',
      },
      tooltip: {
        callbacks: {
          title: (context: Array<IContext>) => {
            return context[0].label.split(',')[0];
          },
        },
      },
    },
    onHover: (event: any, chartElement: any[]) => {
      // TODO: handle any
      event.native.target.style.cursor = chartElement && chartElement[0] ? 'pointer' : 'default';
    },
    onClick: (evt: ChartEvent, element: LegendItem[]) => {
      if (element?.length > 0) {
        const { datasetIndex } = element[0];

        if (datasetIndex !== undefined && DATASET_INDEX_MAP[datasetIndex] !== undefined) {
          const filters = {
            actionTakerId: data?.id || '',
            actionTakerName: data?.displayName || '',
            departmentId,
            departmentName,
            operatingSiteId,
            operatingSiteName,
            organizationId,
            organizationName,
            projectTypeId,
            projectTypeName,
            states: DATASET_INDEX_MAP[datasetIndex],
          };
          const params = new URLSearchParams(filters).toString();
          const url = `${window.origin}/lessons/all_lessons?${params}`;

          window.open(url, '_blank');
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        display: false,
      },
      y: {
        stacked: true,
      },
    },
  });

  const barContainerHeight = (reportEntries.length || 1) * 50;

  return (
    <div className={styles.reports}>
      <div className="vf-container">
        <div className="vf-row">
          <div className="vf-col">
            <h1 className={styles['reports__headline']}>Reports</h1>
            <p className={styles['reports__text']}>
              Choose at least one of the filters below to see the specific Business Unit statistics
            </p>
          </div>
        </div>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
          {(formik) => {
            const options: any = getOptions(formik.values); //TODO: handle any

            return (
              <>
                <div className="vf-row">
                  <div className="vf-col">
                    <div className={styles['reports__filters']}>
                      <ReportsFilters {...formik} />
                    </div>
                  </div>
                </div>

                <div className={styles['reports__bar']} style={{ height: `${barContainerHeight}px` }}>
                  <Bar options={options} data={entryData} />
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
export default Reports;
