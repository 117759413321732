import { ChangeEvent, FC, useEffect, useState } from 'react';

import { useField } from 'formik';

import VfTextarea from '../vfDesign/vfTextarea';

export interface TextareaProps {
  label: string;
  name: string;
  textareaStyles?: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
}

const Textarea: FC<TextareaProps> = ({ name, label, textareaStyles, className, disabled, placeholder }) => {
  const [field, meta, helpers] = useField<string>(name);
  const { setValue, setTouched } = helpers;
  const { error } = meta;
  const [localValue, setLocalValue] = useState(field.value || '');

  useEffect(() => {
    if (field?.value !== localValue) {
      setLocalValue(field.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setLocalValue(e.currentTarget.value);
  };

  const onBlur = () => {
    setValue(localValue);
    setTouched(true);
  };

  return (
    <VfTextarea
      id={name}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      value={localValue}
      label={label}
      placeholder={placeholder}
      className={className}
      error={error}
      textareaStyles={textareaStyles}
    />
  );
};

Textarea.defaultProps = {
  textareaStyles: '',
  className: '',
  placeholder: '',
  disabled: false,
};

export default Textarea;
