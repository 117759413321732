import { ReactElement } from 'react';

export const EVENT_TYPES = {
  CLEAR_FROM_QUEUE: 'CLEAR_FROM_QUEUE',
  CONFIRM_MODAL_EVENT: 'CONFIRM_MODAL_EVENT',
  TOAST_MODAL_EVENT: 'TOAST_MODAL_EVENT',
  QUEUE: 'QUEUE_EVENT',
};

export const EVENT_DEFAULTS = {
  CONFIRMATION: {
    HEADER: () => <p>Are you sure, you want to discard your changes?</p>,
  },
  TOAST: {
    HEADER: 'Need help?',
    POSITION: 'center',
  },
};

export interface ICustomEventDetails {
  id: string;
  event?: EventBase;
  timeout?: number;
}

export interface EventQueue {
  bubbles: boolean;
  detail: ICustomEventDetails;
}

export interface EventBase {
  id: string;
  event: CustomEvent;
  type: string;
}

export interface ConfirmationEvent {
  detail: {
    id: string;
    callback: (value: CustomEvent) => void;
    header: ReactElement;
  };
}

export interface ToastEvent {
  detail: {
    id: string;
    callback: (value: CustomEvent) => void;
    header: string;
    position: 'center' | 'left' | 'right';
  };
}

export type CreateConfirmationEvent = [CustomEvent, string];
export type CreateToastEvent = [CustomEvent, string];
