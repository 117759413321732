import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { User } from '../models/user';

import { handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import { IOption } from '../components/vfDesign/vfDropdown/models';
import Api from '../services/api';
import appInsights from '../services/insights';

export interface UserReducerState {
  data: User | null;
  isLoading: boolean;
  olcs: IOption[];
}

export const sliceName = 'user';

export const fetchUserDetails = createAsyncThunk(sliceName, async (_, { dispatch }) => {
  try {
    const response = await Api.get<User>('users/me');

    appInsights.setAuthenticatedUserContext(response.data.id);

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch user details', dispatch);

    throw err;
  }
});

export const fetchUserOlcs = createAsyncThunk(`${sliceName}/olcs`, async (_, { dispatch }) => {
  try {
    const response = await Api.get<string[]>('users/olcs');
    const mappedData = response.data.map((option) => {
      return { value: option, label: option };
    });
    return mappedData;
  } catch (error) {
    handleApiError(error as AxiosError, 'We could not fetch user olcs', dispatch);
  }
});

export const initialState: UserReducerState = {
  data: null,
  isLoading: false,
  olcs: [],
};

export const userReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUserDetails.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });

    builder.addCase(fetchUserDetails.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchUserOlcs.pending, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchUserOlcs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.olcs = payload as IOption[];
    });

    builder.addCase(fetchUserOlcs.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getUserDetails = (state: ReduxStoreType) => state[sliceName];
export const getUserId = (state: ReduxStoreType) => state[sliceName].data?.id;
export const getUserPreferences = (state: ReduxStoreType) =>
  state[sliceName].data?.userPreferences || { feedbackProvided: false };
export const getIsAdmin = (state: ReduxStoreType) => state[sliceName].data?.adminAccount;
export const getOlcs = (state: ReduxStoreType) => state[sliceName].olcs;

export default userReducer.reducer;
