import { FC } from 'react';

import classNames from 'classnames';

import VfHelperText from '../vfHelperText';
import './_vf-radios.scss';

export interface Option {
  label: string;
  value: string;
}

export interface VfRadioButtonProps {
  checked?: boolean;
  disabled?: boolean;
  onBlur: () => void;
  onChange: (value: string) => void;
  label?: string;
  value: string;
}

export const VfRadioButton: FC<VfRadioButtonProps> = ({ checked, disabled, label, onBlur, onChange, value }) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <div className="vf-radios__button">
      <input
        id={value}
        type="radio"
        value={value}
        checked={checked}
        onBlur={onBlur}
        onChange={handleChange}
        disabled={disabled}
      />

      {label && <label htmlFor={value}>{label}</label>}
    </div>
  );
};

VfRadioButton.defaultProps = {
  checked: false,
  disabled: false,
  label: ' ',
};

export interface VfRadiosProps {
  className?: string;
  error?: string | boolean;
  options: Option[];
  disabled?: boolean;
  onBlur: () => void;
  onChange: (value: string) => void;
  value: string;
}

const VfRadios: FC<VfRadiosProps> = ({ options, value, onChange, onBlur, error, disabled, className }) => {
  const classes = classNames('vf-radios', className);
  const radios = options.map((item) => (
    <VfRadioButton
      key={`radio-${item.value}`}
      value={item.value}
      label={item.label}
      checked={item.value === value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  ));

  return (
    <div className={classes}>
      {radios}
      {error && <VfHelperText type="error" text={error} />}
    </div>
  );
};

VfRadios.defaultProps = {
  disabled: false,
  onBlur: () => {},
  error: '',
  className: '',
  value: '',
};

export default VfRadios;
