import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ADUser } from '../../../../models/user';

import { getCurrentWorkshop, getUserRights, setCurrentWorkshop } from '../../../../store/workshopDetails';

import { formatDate } from '../../../../utils/functions';
import { useBoolean } from '../../../../utils/hooks';

import { useAppDispatch } from '../../../../root';
import Spinner from '../../../Spinner';
import { UserChip } from '../../../UserManagment';
import { VfIcon } from '../../../vfDesign';
import WorkshopDetailsDrawer from '../../WorkshopDetailsDrawer';
import styles from './workshops-project-info.module.scss';

interface WorkshopSpaceDetailItemProps {
  label: string;
  value: string;
}

export const WorkshopSpaceDetailItem: FC<WorkshopSpaceDetailItemProps> = ({ label, value }) => (
  <div>
    <h5>{label}</h5>
    <span>{value}</span>
  </div>
);

interface ProjectInfoProps {
  title: string;
  type: string;
  businessUnit: string;
  facilitators: ADUser[];
  status: string;
  performedFrom: string;
  performedTo: string;
  organizationName: string;
  workshopSpaceId: string;
  projectId: string;
  projectTypeId: string;
}

const ProjectInfo: FC<ProjectInfoProps> = ({
  facilitators,
  organizationName,
  performedFrom,
  performedTo,
  projectId,
  projectTypeId,
  status,
  title,
  type,
  workshopSpaceId,
}) => {
  const dispatch = useAppDispatch();
  const { isCurrentLoading } = useSelector(getCurrentWorkshop);
  const [open, setOpen, onClose] = useBoolean(false);
  const userRights = useSelector(getUserRights);
  const isUserAllowToEditWorkshop = userRights?.canEditWorkshop;

  const handleEditWorkshop = () => {
    dispatch(setCurrentWorkshop());
    setOpen();
  };

  const facilitatorsToRender = facilitators?.map((facilitator: ADUser) => {
    return (
      <UserChip
        id={facilitator.id}
        name={facilitator.displayName}
        onDelete={() => {}}
        showDelete={false}
        key={facilitator.id}
      />
    );
  });

  return (
    <div className={styles['project-info__container']}>
      {isCurrentLoading ? (
        <Spinner />
      ) : (
        <>
          <WorkshopDetailsDrawer
            open={open}
            onClose={onClose}
            workshopSpaceId={workshopSpaceId}
            projectId={projectId}
            isEditMode
            projectTypeId={projectTypeId}
          />
          <div className={styles['project-info__container--col']}>
            <WorkshopSpaceDetailItem label="PROJECT" value={title} />
            <WorkshopSpaceDetailItem label="PROJECT TYPE" value={type} />
            <WorkshopSpaceDetailItem label="BUSINESS UNIT" value={organizationName} />
          </div>
          <div className={styles['project-info__container--col']}>
            <WorkshopSpaceDetailItem
              label="PERFORMED AT"
              value={`${formatDate(performedFrom)} - ${formatDate(performedTo)}`}
            />
            <WorkshopSpaceDetailItem label="STATUS" value={status} />
            <div>
              <h5>FACILITATOR</h5>
              {facilitatorsToRender}
            </div>
          </div>
          <div className={styles['project-info__container--col']}>
            {isUserAllowToEditWorkshop && (
              <VfIcon
                asButton
                name="pencil"
                onClick={handleEditWorkshop}
                className={styles['project-info__container--icon']}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectInfo;
