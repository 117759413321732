import { v4 } from 'uuid';

import { createSlice, createAction } from '@reduxjs/toolkit';

import { Notification } from '../models/notification';

import { ReduxStoreType } from '.';
import { NotificationType } from '../components/vfDesign/vfNotification';

export interface NotificationsState {
  items: Notification[];
}

export const sliceName = 'notifications';

export const initialState: NotificationsState = {
  items: [],
};

export const setNotification = createAction(`${sliceName}/add`, (message: string, type: NotificationType) => ({
  payload: {
    type,
    message,
    id: v4(),
    date: new Date().toISOString(),
  },
}));

export const notificationsReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearNotification: (state, { payload }) => {
      state.items = state.items.filter((item) => item.id !== payload);
    },
    clearAllNotification: (state) => {
      state.items = initialState.items;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setNotification, (state, { payload }) => {
      state.items = [...state.items, payload];
    });
  },
});

export const { clearNotification, clearAllNotification } = notificationsReducer.actions;

export const getNotifications = (state: ReduxStoreType) => state[sliceName].items;

export default notificationsReducer.reducer;
