import { ChangeEvent, FC, useEffect, useState } from 'react';

import { useField } from 'formik';

import VfInput from '../vfDesign/vfInput';

export interface InputProps {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  type?: string;
}

const Input: FC<InputProps> = ({ name, label, className, disabled, type = 'textfield' }) => {
  const [field, meta, helpers] = useField<string>(name);
  const { setValue, setTouched } = helpers;
  const { error, touched } = meta;

  const [localValue, setLocalValue] = useState(field.value || '');

  useEffect(() => {
    if (field?.value !== localValue) {
      setLocalValue(field.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setLocalValue(e.currentTarget.value);
  };

  const onBlur = () => {
    setValue(localValue);
    setTouched(true);
  };
  return (
    <VfInput
      id={name}
      className={className}
      onChange={onChange}
      onBlur={onBlur}
      value={localValue}
      label={label}
      disabled={disabled}
      error={touched && error ? error : undefined}
      type={type}
    />
  );
};

Input.defaultProps = {
  className: '',
  disabled: false,
};

export default Input;
