import { FC, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import VfSpinner from '../../components/Spinner';

interface IInfiniteScroll {
  children: ReactElement[];
  displayEndMessage?: boolean;
  EndMessage?: FC;
  isLoading: boolean;
  noItemsMessage?: string;
  onFetchMore: Function;
  page: number;
  Spinner?: FC;
  totalPages: number;
}

const DefaultSpinner = () => (
  <div className="d-flex flex-fill justify-content-center align-items-center mb-2">
    <VfSpinner />
  </div>
);

const DefaultEndMessage = () => (
  <p style={{ textAlign: 'center' }}>
    <b>Yay! You have seen it all</b>
  </p>
);

const VfInfiniteScroll = ({
  children,
  displayEndMessage,
  EndMessage,
  isLoading,
  noItemsMessage,
  onFetchMore,
  page,
  Spinner,
  totalPages,
}: IInfiniteScroll) => {
  const onNextPage = () => {
    const nextPage = page + 1;

    if (nextPage <= totalPages) {
      onFetchMore(nextPage);
    }
  };

  const showNoItemsMessage = !isLoading && children?.length === 0;
  const endMessage = page < totalPages && (EndMessage ? <EndMessage /> : <DefaultEndMessage />);

  if (isLoading && !children.length) {
    return Spinner ? <Spinner /> : <DefaultSpinner />;
  } else {
    return (
      <div>
        <InfiniteScroll
          dataLength={children.length}
          next={onNextPage}
          hasMore={isLoading || page < totalPages}
          style={{ overflow: 'initial' }}
          loader={Spinner ? <Spinner /> : <DefaultSpinner />}
          endMessage={displayEndMessage && endMessage}
        >
          {children}
        </InfiniteScroll>

        {showNoItemsMessage && <p className="d-flex justify-content-center align-items-center ">{noItemsMessage}</p>}
      </div>
    );
  }
};

export default VfInfiniteScroll;
