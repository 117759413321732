import { useCallback, useEffect, useState } from 'react';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const useDebouncedValue = (debounceAmount: number = 0): [string, (newValue: string) => void] => {
  const subject = new BehaviorSubject('');
  const [queryValue, setQueryValue] = useState(subject.value);
  const [query] = useState(subject);

  const setQuery = useCallback(
    (newValue: string): void => {
      query.next(newValue);
    },
    [query],
  );

  useEffect(() => {
    let subscription = query.pipe(debounceTime(debounceAmount)).subscribe((result) => {
      setQueryValue(result);
    });
    return () => subscription.unsubscribe();
  }, [debounceAmount, query]);

  return [queryValue, setQuery];
};

export default useDebouncedValue;
