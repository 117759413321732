import { FC } from 'react';
import { Provider } from 'react-redux';

import store from '../../root';
import Api from '../../services/api';

const ReduxWrapper: FC = ({ children }) => {
  Api.setStore(store);

  return <Provider store={store}>{children}</Provider>;
};

export default ReduxWrapper;
