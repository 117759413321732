import { FC } from 'react';

import { useField } from 'formik';

import { VfMultipleDropdown } from '../vfDesign/vfMulitpleDropdown';

export interface IFormikMultiDropdownProps {
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  label: string;
  name: string;
  options?: any;
  shrinkLabel?: boolean;
  withNoOption?: boolean;
}
const FormikMultiDropdown: FC<IFormikMultiDropdownProps> = ({
  className = '',
  isDisabled = false,
  isLoading = false,
  name,
  label,
  options = [],
  shrinkLabel = true,
  withNoOption = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, meta, helpers] = useField<string[]>(name);
  const { setValue, setTouched } = helpers;
  const { error, touched, value } = meta;

  const onChange = (_: string, selected: string[]) => {
    if (isDisabled) {
      return;
    }

    setValue(selected);
  };

  const onBlur = () => {
    setTouched(true);
  };

  const dropdownOptions = options.map((option: any) => ({
    label: option.name,
    value: option.id,
  }));

  return (
    <VfMultipleDropdown
      className={className}
      disabled={isDisabled}
      error={(touched && error) || ''}
      id={name}
      isLoading={isLoading}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={dropdownOptions}
      shrinkLabel={shrinkLabel}
      value={value || []}
      withNoOption={withNoOption}
    />
  );
};

export default FormikMultiDropdown;
