import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LessonStates, WorkshopType } from '../models/dictionary';

import { handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import { IOption } from '../components/vfDesign/vfDropdown/models';
import { LESSON_STATUS } from '../consts/lessons';
import Api from '../services/api';

const sliceName = 'dictionary';

const TAG_STATES = 'states';
const TAG_WORKSHOP_TYPES = 'workshopTypes';
const TAG_REPORTS_GROUPING = 'reports/grouping';
const TAG_REPORTS_ORDER = 'reports/order';

export type WorkshopTypeStore = Record<string, WorkshopType[]>;

export type GetWorkshopType = {
  workshopTypes: IOption[];
  workshopTypesIsLoading: boolean;
};

export type IFetchWorkshopTypes = {
  organizationId: string;
  projectTypeId: string;
};

export interface DictionaryReducerState {
  [TAG_STATES]: {
    data: LessonStates[];
    isLoading: boolean;
  };
  [TAG_WORKSHOP_TYPES]: {
    data: WorkshopTypeStore;
    isLoading: boolean;
  };
  [TAG_REPORTS_GROUPING]: {
    data: string[];
    isLoading: boolean;
  };
  [TAG_REPORTS_ORDER]: {
    data: string[];
    isLoading: boolean;
  };
}

export const fetchGraphOrdering = createAsyncThunk(`${sliceName}/${TAG_REPORTS_ORDER}`, async (_, { dispatch }) => {
  try {
    const response = await Api.get<string[]>('dictionary/graph-ordering');
    return response.data;
  } catch (error) {
    handleApiError(error as AxiosError, 'We could not fetch graph order', dispatch);
  }
});

export const fetchGraphGrouping = createAsyncThunk(`${sliceName}/${TAG_REPORTS_GROUPING}`, async (_, { dispatch }) => {
  try {
    const response = await Api.get<string[]>('dictionary/graph-grouping');
    return response.data;
  } catch (error) {
    handleApiError(error as AxiosError, 'We could not fetch graph grouping', dispatch);
  }
});

export const fetchDictionaryStates = createAsyncThunk(`${sliceName}/${TAG_STATES}`, async (_, { dispatch }) => {
  try {
    const response = await Api.get<LessonStates[]>('dictionary/states');

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch statuses', dispatch);

    throw err;
  }
});

export const initialState: DictionaryReducerState = {
  [TAG_STATES]: {
    data: [],
    isLoading: false,
  },
  [TAG_WORKSHOP_TYPES]: {
    data: {},
    isLoading: false,
  },
  [TAG_REPORTS_ORDER]: {
    data: [],
    isLoading: false,
  },
  [TAG_REPORTS_GROUPING]: {
    data: [],
    isLoading: false,
  },
};

export const dictionaryReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDictionaryStates.pending, (state) => {
      state[TAG_STATES].isLoading = true;
    });

    builder.addCase(fetchDictionaryStates.fulfilled, (state, { payload }) => {
      state[TAG_STATES].data = payload;
      state[TAG_STATES].isLoading = false;
    });

    builder.addCase(fetchDictionaryStates.rejected, (state) => {
      state[TAG_STATES].isLoading = false;
    });
    builder.addCase(fetchGraphOrdering.pending, (state) => {
      state[TAG_REPORTS_ORDER].isLoading = true;
    });
    builder.addCase(fetchGraphOrdering.fulfilled, (state, { payload }) => {
      state[TAG_REPORTS_ORDER].isLoading = false;
      state[TAG_REPORTS_ORDER].data = payload as string[];
    });
    builder.addCase(fetchGraphOrdering.rejected, (state) => {
      state[TAG_REPORTS_GROUPING].isLoading = false;
    });
    builder.addCase(fetchGraphGrouping.pending, (state) => {
      state[TAG_REPORTS_GROUPING].isLoading = true;
    });
    builder.addCase(fetchGraphGrouping.fulfilled, (state, { payload }) => {
      state[TAG_REPORTS_GROUPING].isLoading = false;
      state[TAG_REPORTS_GROUPING].data = payload as string[];
    });
    builder.addCase(fetchGraphGrouping.rejected, (state) => {
      state[TAG_REPORTS_GROUPING].isLoading = false;
    });
  },
});
export const getGraphGrouping = (state: ReduxStoreType) => state[sliceName][TAG_REPORTS_GROUPING];
export const getGraphOrder = (state: ReduxStoreType) => state[sliceName][TAG_REPORTS_ORDER];
export const getDictionaryStates = (state: ReduxStoreType) => state[sliceName][TAG_STATES];
export const getInProgressOptions = (state: ReduxStoreType) =>
  state[sliceName][TAG_STATES].data
    .find((status) => status.id === LESSON_STATUS.IN_PROGRESS)
    ?.details.map(({ label, id }) => ({ label, value: id })) || [];
export const getDoneOptions = (state: ReduxStoreType) =>
  state[sliceName][TAG_STATES].data
    .find((status) => status.id === LESSON_STATUS.DONE)
    ?.details.map(({ label, id }) => ({ label, value: id })) || [];

export const getWorkshopTypes = (state: ReduxStoreType): GetWorkshopType => {
  const { organizationId } = state.workshopDetails.data;
  const workshopTypes =
    state[sliceName][TAG_WORKSHOP_TYPES].data[organizationId]?.map((type) => ({ label: type.name, value: type.id })) ||
    [];

  return {
    workshopTypes,
    workshopTypesIsLoading: state[sliceName][TAG_WORKSHOP_TYPES].isLoading,
  };
};

export default dictionaryReducer.reducer;
