import React, { useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import {
  fetchNotificationSettings,
  getNotificationsSettings,
  INotificationSettingsPayload,
  updateNotificationSettings,
} from '../../../store/notificationsSettings';

import Drawer from '../../FlexDrawer';
import DrawerFooter from '../../FlexDrawer/Footer';
import DrawerHeader from '../../FlexDrawer/Header';
import { VfButton, VfCheckbox, VfSwitch } from '../../vfDesign';
import styles from './_notification_drawer.module.scss';

interface NotificationsDrawerProps {
  drawerOpen: boolean;
  handleCloseDrawer: () => void;
}

const NOTIFICATION_FREQUENCY = {
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
  NONE: 'NONE',
  WEEKLY: 'WEEKLY',
};

const NotificationsDrawer: FC<NotificationsDrawerProps> = ({ drawerOpen, handleCloseDrawer }) => {
  const dispatch = useDispatch();
  const settings = useSelector(getNotificationsSettings);

  const initialValues = {
    notifyAboutAssignmentToLesson: settings?.notificationSettings?.notifyAboutAssignmentToLesson,
    notifyAboutActionTakenOnMyLesson: settings?.notificationSettings?.notifyAboutActionTakenOnMyLesson,
    notifyAboutAnyActionTakenOnMyLesson: settings?.notificationSettings?.notifyAboutAnyActionTakenOnMyLesson,
    frequency: settings?.notificationSettings?.frequency,
  };

  useEffect(() => {
    if (!!settings) {
      dispatch(fetchNotificationSettings());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onSubmit = (values: INotificationSettingsPayload): void => {
    dispatch(updateNotificationSettings(values));
    handleCloseDrawer();
  };

  return (
    <>
      <Drawer isMounted={drawerOpen} isOpen={drawerOpen} onClose={handleCloseDrawer} side="right">
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
          {(notificationForm) => {
            const { handleSubmit, setFieldValue, values, dirty } = notificationForm;
            const { frequency: valueNotificationFrequency } = values;
            const universalHandleChange = (name: string, val: boolean) => {
              setFieldValue(name, val);
            };

            const setNotificationFrequency = (value: any) =>
              setFieldValue('frequency', valueNotificationFrequency === value ? NOTIFICATION_FREQUENCY.NONE : value);

            const setMonthly = () => setNotificationFrequency(NOTIFICATION_FREQUENCY.MONTHLY);

            const setDaily = () => setNotificationFrequency(NOTIFICATION_FREQUENCY.DAILY);

            const setWeekly = () => setNotificationFrequency(NOTIFICATION_FREQUENCY.WEEKLY);

            return (
              <form onSubmit={handleSubmit}>
                <DrawerHeader headline="Notification settings" onClose={handleCloseDrawer} />
                <div className={styles['notification-container']}>
                  <p>Set notification email on:</p>
                  <div className={styles['notification-container__switches']}>
                    <VfSwitch
                      onChange={universalHandleChange}
                      name={'notifyAboutAssignmentToLesson'}
                      title={'Lessons assigned to you'}
                      checked={values.notifyAboutAssignmentToLesson}
                    />
                    <VfSwitch
                      onChange={universalHandleChange}
                      name={'notifyAboutActionTakenOnMyLesson'}
                      title={'Action taken on your lesson'}
                      checked={values.notifyAboutActionTakenOnMyLesson}
                    />
                    <VfSwitch
                      onChange={universalHandleChange}
                      name={'notifyAboutAnyActionTakenOnMyLesson'}
                      title={'Changes made to your lesson'}
                      checked={values.notifyAboutAnyActionTakenOnMyLesson}
                    />
                  </div>
                  <div>
                    <p className="mt-2">How often:</p>
                    <div className="d-flex">
                      <VfCheckbox
                        checked={valueNotificationFrequency === NOTIFICATION_FREQUENCY.DAILY}
                        id="daily"
                        name="daily"
                        onChange={setDaily}
                      />
                      <span className="ml-2 checkbox-title">Daily</span>
                    </div>
                    <div className="d-flex mt-2">
                      <VfCheckbox
                        checked={valueNotificationFrequency === NOTIFICATION_FREQUENCY.WEEKLY}
                        id="weekly"
                        name="weekly"
                        onChange={setWeekly}
                      />
                      <span className="ml-2 checkbox-title">Weekly</span>
                    </div>
                    <div className="d-flex mt-2">
                      <VfCheckbox
                        checked={valueNotificationFrequency === NOTIFICATION_FREQUENCY.MONTHLY}
                        id="monthly"
                        name="monthly"
                        onChange={setMonthly}
                      />
                      <span className="ml-2 checkbox-title">Monthly</span>
                    </div>
                  </div>
                </div>
                <DrawerFooter>
                  <VfButton type="secondary" onClick={handleCloseDrawer}>
                    Cancel
                  </VfButton>
                  <VfButton type="primary" htmlType="submit" disabled={!dirty}>
                    Submit
                  </VfButton>
                </DrawerFooter>
              </form>
            );
          }}
        </Formik>
      </Drawer>
    </>
  );
};

export default NotificationsDrawer;
