export interface TagedUserData {
  id: string;
  name: string;
  indexRange: number[];
}

class TagedUser {
  readonly _id: string = '';
  readonly _name: string = '';
  _startRange: number = 0;
  _endRange: number = 0;

  constructor(id: string, name: string, startPoint: number) {
    this._id = id;
    this._name = name;
    this._startRange = startPoint;
    this._endRange = startPoint + name.length;
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get startRange(): number {
    return this._startRange;
  }

  get endRange(): number {
    return this._endRange;
  }

  set startRange(start: number) {
    this._startRange = start;
  }

  set endRange(start: number) {
    this._endRange = start;
  }

  extractData(): TagedUserData {
    return { name: this._name, id: this._id, indexRange: [this._startRange, this._endRange] };
  }

  getIndexRange(): number[] {
    return [this._startRange, this._endRange];
  }

  getRangeDiff(): number {
    return this._endRange - this._startRange;
  }

  removeByDiff(diff: number): void {
    this._endRange = this.endRange - diff;
    this._startRange = this.startRange - diff;
  }

  incrementRanges(value: number = 1): void {
      this._endRange = this._endRange + value;
      this._startRange = this._startRange + value;
  }

  decrementRanges(value: number = 1): void {
    this._endRange = this._endRange - value;
    this._startRange = this._startRange - value;
  }
}

export default TagedUser;
