/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';

import { formatBytes } from '../../utils/functions';

import Icon from '../Icon';
import IconButton from '../IconButton';
import Tooltip from '../Tooltip';
import { VfButton, VfHelperText } from '../vfDesign';
import './upload-file.scss';

interface AddedFileRowProps {
  onRemove: (path: string) => void;
  path: string;
  size: number;
}

export const AddedFileRow: FC<AddedFileRowProps> = ({ onRemove, path, size }) => {
  const removeFile = () => {
    onRemove(path);
  };

  return (
    <li>
      <p>{`${path} (${formatBytes(size)})`}</p>

      <IconButton icon="close" onClick={removeFile} />
    </li>
  );
};

AddedFileRow.defaultProps = {
  size: 0,
};

interface UploadFileProps {
  attachments?: FileWithPath[];
  closeInput: () => void;
  maxFileUpload: number;
  uploadCallback: (files: FileWithPath[]) => void;
  disabled?: boolean;
}

const UploadFile: FC<UploadFileProps> = ({ maxFileUpload, uploadCallback, attachments, disabled }) => {
  const [files, setFiles] = useState<FileWithPath[]>(attachments || []);
  const [error, setError] = useState<string>('');

  const cannotUploadMoreMsg = `Max. ${maxFileUpload > 1 ? `${maxFileUpload} files` : `${maxFileUpload} file`}`;

  const onDropCallback = (acceptedFiles: any[], rejectedFiles: any[]): void => {
    // TODO: handle any, cannot use fileWithPath due to some issues https://github.com/react-dropzone/react-dropzone/issues/981
    setError('');
    if (maxFileUpload && ([...files, ...acceptedFiles].length > maxFileUpload || rejectedFiles.length)) {
      setError(cannotUploadMoreMsg);

      return;
    }

    const acceptedFilesPaths = acceptedFiles.map((file) => file.path);
    const myFilesPaths = files.map((file) => file.path);
    const alreadyAdded = files.filter((file) => acceptedFilesPaths.includes(file.path));
    const filesToAdd = acceptedFiles.filter((file) => !myFilesPaths.includes(file.path));

    if (alreadyAdded.length > 0) {
      const moreThanOne = alreadyAdded.length > 1 ? 'Files have been' : 'File has been';
      const alreadyAddedPaths = alreadyAdded.map((file) => file.path).join(', ');

      setError(`${moreThanOne} already added: ${alreadyAddedPaths}`);
    }

    const filesToSet = [...files, ...filesToAdd];

    uploadCallback(filesToSet);
    setFiles(filesToSet);
  };

  const onDrop = useCallback(onDropCallback, [
    cannotUploadMoreMsg,
    setError,
    maxFileUpload,
    uploadCallback,
    files.length,
  ]);

  const { getInputProps, getRootProps, open } = useDropzone({
    maxFiles: maxFileUpload,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const onRemoveHandler = (path: string) => {
    const newFiles = files.filter((file: FileWithPath) => file.name !== path);

    if (maxFileUpload && newFiles.length <= maxFileUpload) {
      setError('');
    }

    uploadCallback([...newFiles]);
    setFiles(newFiles);
  };

  const renderedFiles = files.map((file) => (
    <AddedFileRow key={`file-upload-${file.name}`} onRemove={onRemoveHandler} path={file.name} size={file.size} />
  ));

  return (
    <div className="dropzone-container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />

        <div className="dropzone__drag-title">
          <h5>
            Drag & drop files
            <Tooltip text="Please only add C1 and C2 level documents" position="bottom">
              <Icon name={`info`} color={'blue'} className={'info-icon-small'} />
            </Tooltip>
          </h5>
        </div>

        {maxFileUpload > 0 && <em>{cannotUploadMoreMsg}</em>}

        {files.length > 0 && <div className="added-files">{renderedFiles}</div>}

        <div className="dropzone__or">or</div>

        <VfButton outline="secondary" onClick={open} disabled={disabled}>
          {files.length === 0 ? 'Attach from drive' : 'Select other files'}
        </VfButton>
      </div>
      {error && (
        <div>
          <VfHelperText type="error" text={error} />
        </div>
      )}
    </div>
  );
};

UploadFile.defaultProps = {
  maxFileUpload: 0,
  attachments: [],
  disabled: false,
};

export default UploadFile;
