import { FC, useRef } from 'react';

import classNames from 'classnames';

import useOutsideClick from '../../utils/hooks/useOutsideClick';

import styles from './Drawer.module.scss';

export interface DrawerProps {
  onClose?: () => void;
  open: boolean;
  rightSide?: boolean;
  setOpen?: (open: boolean) => void;
  title?: string;
}

const Drawer: FC<DrawerProps> = ({ children, rightSide, onClose, open, setOpen, title }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const classes = classNames(styles.menu, { [styles.opened]: open, [styles.right]: rightSide });

  useOutsideClick(ref, () => {
    if (open && setOpen) {
      setOpen(false);

      if (onClose) {
        onClose();
      }
    }
  });

  return (
    <aside className={classes} role="menu">
      <nav className={styles.list} ref={ref}>
        <h2 className={styles.title}>{title}</h2>
        {children}
      </nav>
      <label className={styles.overlay}></label>
    </aside>
  );
};

Drawer.defaultProps = {
  onClose: () => {},
  rightSide: false,
  setOpen: () => {},
  title: 'Navigation',
};

export default Drawer;
