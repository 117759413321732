import { FC, ReactNode, useState, useEffect, MouseEvent, ComponentType } from 'react';

import classNames from 'classnames';

import { VfDivider } from '..';
import './_expandable-item.scss';

type OnClear = () => void;

export interface WrappedWithExpandableProps {
  asTableRow?: boolean;
  colSpan?: number;
  defaultExpanded: boolean;
  expandCallback?: (isExpanded: boolean) => void;
  hideHint?: boolean;
  isEditable?: boolean;
  onClear?: OnClear | boolean;
  showDivider?: boolean;
  showOverflow?: boolean;
  title: string;
  usedItems?: string | Array<Object>;
  toolTip?: ReactNode;
}

export interface InjectedComponentProps extends WrappedWithExpandableProps {
  isExpanded: boolean;
  iconClasses: string;
}

const withExpandable = (WrappedComponent: ComponentType<InjectedComponentProps>) => {
  const WrappedWithExpandable: FC<WrappedWithExpandableProps> = ({
    children,
    asTableRow,
    colSpan,
    defaultExpanded,
    expandCallback,
    hideHint,
    isEditable,
    onClear,
    showDivider,
    showOverflow,
    title,
    usedItems,
    toolTip,
  }) => {
    const [isExpanded, setExpanded] = useState<boolean>(defaultExpanded);
    const toggleExpanded = () => {
      setExpanded(!isExpanded);

      if (expandCallback) {
        expandCallback(isExpanded);
      }
    };

    useEffect(() => {
      setExpanded(defaultExpanded || false);
    }, [defaultExpanded]);

    const expandableListClasses = classNames('expandable-item__data', {
      'expandable-item__data--show': isExpanded && !showOverflow,
      'expandable-item__data--show-with-overflow': isExpanded && showOverflow,
    });

    const iconClasses = classNames('expandable-item__indicator', {
      'expandable-item__indicator--open': isExpanded,
    });

    const handleClear = (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      if (typeof onClear === 'function') {
        onClear();
      }
    };

    if (asTableRow) {
      return (
        <tr className="expandable-item">
          <td colSpan={colSpan} className={expandableListClasses}>
            {children}
          </td>

          {showDivider && <VfDivider />}
        </tr>
      );
    }

    return (
      <div className="expandable-item">
        <div className="expandable-item--toggle" onClick={toggleExpanded} puppet-data={`expand:${title}`}>
          <WrappedComponent
            {...{
              asTableRow,
              colSpan,
              defaultExpanded,
              expandCallback,
              hideHint,
              isEditable,
              onClear,
              showDivider,
              showOverflow,
              title,
              usedItems,
              toolTip,
            }}
            isExpanded={isExpanded}
            iconClasses={iconClasses}
          />
          {onClear && (
            <span onClick={handleClear} className="expandable-item__clear">
              Clear
            </span>
          )}
        </div>

        <div className={expandableListClasses}>{children}</div>

        {showDivider && <VfDivider />}
      </div>
    );
  };

  WrappedWithExpandable.defaultProps = {
    asTableRow: false,
    colSpan: 1,
    defaultExpanded: false,
    expandCallback: () => {},
    hideHint: false,
    isEditable: false,
    onClear: false,
    showDivider: true,
    showOverflow: false,
    usedItems: [],
  };

  return WrappedWithExpandable;
};

export default withExpandable;
