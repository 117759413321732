/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import Api from '../services/api';

export interface INotificationSettingsPayload {
  frequency?: string;
  notifyAboutActionTakenOnMyLesson?: boolean;
  notifyAboutAssignmentToLesson?: boolean;
  notifyAboutAnyActionTakenOnMyLesson?: boolean;
}

export const sliceName = 'notificationsSettings';

export const fetchNotificationSettings = createAsyncThunk(`${sliceName}/fetch`, async (_, { dispatch }) => {
  try {
    const response = await Api.get<INotificationSettingsPayload>(`users/me/notifications`);

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch notification settings', dispatch, false);

    throw err;
  }
});

export const updateNotificationSettings = createAsyncThunk(
  `${sliceName}/update`,
  async (
    { notifyAboutAssignmentToLesson, notifyAboutActionTakenOnMyLesson, notifyAboutAnyActionTakenOnMyLesson, frequency }: INotificationSettingsPayload,
    { dispatch },
  ) => {
    const payload = {
      notifyAboutAssignmentToLesson,
      notifyAboutActionTakenOnMyLesson,
      notifyAboutAnyActionTakenOnMyLesson,
      frequency,
    };
    try {
      const response = await Api.patch<any>(`users/me/notifications/`, payload);
      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not update notification settings', dispatch, false);
    }
  },
);

type NotificationType = string;

type Project = {
  id: string;
  name: string;
};

interface NotificationSystemStore {
  allowedProjects: Project[];
  assignedProjects: Project[];
  data: object;
  id: string;
  isLoading: boolean;
  notificationTypes: NotificationType[];
  notificationSettings: {
    frequency?: string;
    notifyAboutActionTakenOnMyLesson?: boolean;
    notifyAboutAssignmentToLesson?: boolean;
    notifyAboutAnyActionTakenOnMyLesson?: boolean;
  };
}

export const initialState: NotificationSystemStore = {
  allowedProjects: [],
  assignedProjects: [],
  data: {},
  id: '',
  isLoading: false,
  notificationTypes: [],
  notificationSettings: {
    frequency: '',
    notifyAboutActionTakenOnMyLesson: false,
    notifyAboutAssignmentToLesson: false,
    notifyAboutAnyActionTakenOnMyLesson: false,
  },
};

export const notificationsSettings = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getSettings: (state) => {
      state.isLoading = true;
    },

    getSettingsFulfilled: (state, { payload }) => {
      const { notificationTypes, id, allowedProjects, notificationFrequency } = payload;

      state.isLoading = false;
      state.id = id;
      state.notificationTypes = notificationTypes;
      state.allowedProjects = allowedProjects;
      state.notificationSettings.frequency = notificationFrequency;
    },
    getSettingsError: (state) => {
      state.isLoading = false;
    },
    updateSettings: (state) => {
      state.isLoading = true;
    },
    updateSettingsFulfilled: (state, { payload }) => {
      state.isLoading = false;
      const { notificationTypes, id, allowedProjects, notificationFrequency } = payload;

      state.isLoading = false;
      state.id = id;
      state.notificationTypes = notificationTypes;
      state.allowedProjects = allowedProjects;
      state.notificationSettings.frequency = notificationFrequency;
    },
    updateSettingsError: (state) => {
      state.isLoading = false;
    },
    getAssignedProjects: (state) => {
      state.isLoading = true;
    },
    getAssignedProjectsFulfilled: (state, { payload }) => {
      state.assignedProjects = payload;
      state.isLoading = false;
    },
    getAssignedProjectsError: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationSettings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNotificationSettings.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.notificationSettings = payload;
    });
    builder.addCase(fetchNotificationSettings.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateNotificationSettings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateNotificationSettings.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.notificationSettings = payload;
    });
    builder.addCase(updateNotificationSettings.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  getAssignedProjects,
  getAssignedProjectsError,
  getAssignedProjectsFulfilled,
  getSettings,
  getSettingsError,
  getSettingsFulfilled,
  updateSettings,
  updateSettingsError,
  updateSettingsFulfilled,
} = notificationsSettings.actions;

export const getNotificationsSettings = (state: ReduxStoreType) => state.notificationsSettings;

export const getNotificationsTypes = (state: ReduxStoreType) => state.notificationsSettings.notificationTypes;

export const getProjects = (state: ReduxStoreType) => state.notificationsSettings.assignedProjects;

export const notificationSettings = (state: ReduxStoreType) => state.notificationsSettings.notificationSettings;

export default notificationsSettings.reducer;
