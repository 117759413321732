import { FC } from 'react';

import classNames from 'classnames';

import VfIcon from '../vfIcon';
import VfPopover from '../vfPopover';
import { ActionOption } from './actions';
import { useActionMenu } from './tableHelpers';

interface SelectedRowsProps {
  actions?: ActionOption[];
  selected?: string[];
}

const SelectedRows: FC<SelectedRowsProps> = ({ selected = [], actions = [] }) => {
  const [actionMenuVisible, toggleActionMenu, closeActionMenu] = useActionMenu();

  const selectedActions = actions.map(({ label, method, disabled = null }) => {
    const selectAction = () => {
      method(selected);
    };

    return (
      <button
        type="button"
        key={`selected-${label}`}
        onClick={selectAction}
        className="table-action"
        disabled={!!disabled}
      >
        {label}
      </button>
    );
  });

  const classes = classNames('selected-rows', {
    'selected-rows--show': selected.length > 0,
  });

  return (
    <div className={classes}>
      <div className="selected-rows__count">
        <span>{selected.length}</span>
        {` selected`}
      </div>
      <div className="selected-rows__actions">
        {actions.length > 0 && (
          <VfPopover
            component={<VfIcon name="action-menu" asButton />}
            onClick={toggleActionMenu}
            isOpen={actionMenuVisible}
            closeDropdown={closeActionMenu}
            className="vf-table__actions wider"
            right
          >
            <div>{selectedActions}</div>
          </VfPopover>
        )}
      </div>
    </div>
  );
};

SelectedRows.defaultProps = {
  selected: [],
  actions: [],
};

export default SelectedRows;
