import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { WorkshopSpace } from '../models/workshop';

import { handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import { ORDER } from '../components/vfDesign/vfTable/headers';
import Api from '../services/api';

export type WorkshopSpacesResponse = {
  content: WorkshopSpace[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged: boolean;
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  sortColumns: string[];
  totalElements: number;
  totalPages: number;
};

export interface ProjectEnvironmentCreateParams {
  projectId: string;
  projectTypeId: string;
  organizationId: string;
}

export interface WorkshopSpacesState {
  current: ProjectEnvironmentCreateParams;
  data: WorkshopSpacesResponse;
  isChanging: boolean;
  isLoading: boolean;

  page: number;
  sort: { sortBy: string; order: ORDER };
}

export const sliceName = 'workshopSpaces';
export const INITIAL_PAGE: number = 1;
export const PAGE_SIZE: number = 30;

export const fetchProjectEnvironments = createAsyncThunk(`${sliceName}/fetch`, async (_, { dispatch, getState }) => {
  try {
    const state = getState() as ReduxStoreType;
    const { page, sort } = state[sliceName];

    const response = await Api.post<WorkshopSpacesResponse>('/workshop-spaces/filter', {
      page,
      pageSize: PAGE_SIZE,
      reverseOrder: sort.order === ORDER.DESC,
      sort: sort.sortBy,
    });

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch project environment spaces', dispatch);

    throw err;
  }
});

export const addProjectEnvironment = createAsyncThunk(
  `${sliceName}/add`,
  async (body: ProjectEnvironmentCreateParams, { dispatch }) => {
    try {
      await Api.post<WorkshopSpace>('/workshop-spaces', body);
      await dispatch(fetchProjectEnvironments());
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not add project environment', dispatch);

      throw err;
    }
  },
);

export const updateProjectEnvironment = createAsyncThunk(
  `${sliceName}/update`,
  async (body: ProjectEnvironmentCreateParams, { dispatch }) => {
    try {
      await Api.patch<WorkshopSpace>('/workshop-spaces', body);

      await dispatch(fetchProjectEnvironments());
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not update project environment', dispatch);

      throw err;
    }
  },
);

export const removeProjectEnvironment = createAsyncThunk(`${sliceName}/remove`, async (id: string, { dispatch }) => {
  try {
    await Api.delete<string>(`/workshop-spaces/${id}`);
    await dispatch(fetchProjectEnvironments());
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not delete project environment', dispatch);

    throw err;
  }
});

export const initialState: WorkshopSpacesState = {
  data: {} as WorkshopSpacesResponse,
  current: { projectId: '' } as ProjectEnvironmentCreateParams,
  isChanging: false,
  isLoading: false,
  page: INITIAL_PAGE,
  sort: { sortBy: 'PROJECTNAME', order: ORDER.ASC },
};

export const WorkshopSpacesReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setWorkshopSpacesPage: (state: WorkshopSpacesState, { payload }) => {
      state.page = payload;
    },
    setWorkshopSpacesSort: (state: WorkshopSpacesState, { payload }) => {
      state.sort = payload;
    },
    setCurrentWorkshopSpace: (state: WorkshopSpacesState, { payload }) => {
      state.current = payload;
    },
    clearCurrentWorkshopSpace: (state: WorkshopSpacesState) => {
      state.current = initialState.current;
    },
    clearWorkshopSpace: (state: WorkshopSpacesState) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectEnvironments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProjectEnvironments.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchProjectEnvironments.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getWorkshopSpaces = (state: ReduxStoreType): WorkshopSpacesState => state[sliceName];
export const currentWorkshopSpaceIsEditing = (state: ReduxStoreType): boolean =>
  state[sliceName].current.projectId !== '';

export const {
  clearCurrentWorkshopSpace,
  clearWorkshopSpace,
  setCurrentWorkshopSpace,
  setWorkshopSpacesPage,
  setWorkshopSpacesSort,
} = WorkshopSpacesReducer.actions;

export default WorkshopSpacesReducer.reducer;
