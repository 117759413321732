interface StateProps {
  [key: string]: string;
}

export enum LESSON_STATUS {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export const STATE_ICONS: StateProps = {
  TO_DO: 'todo',
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
};

export const STATE_COLORS: StateProps = {
  TO_DO: 'blue',
  IN_PROGRESS: 'yellow',
  DONE: 'green',
};

export const STATE_TAGNAMES: StateProps = {
  TAKE_ACTION: 'take_action',
  MY_LESSONS: 'my_submitted_lessons',
  ALL_LESSONS: 'all_lessons',
};
export const FIELDS_NAMES_FOR_ID: StateProps = {
  operatingSiteId: 'operationSiteName',
  departmentId: 'departmentName',
  workstreamId: 'workstreamName',
  processId: 'processName',
  projectId: 'projectName',
};

export type CreateDateOption = 'LAST_WEEK' | 'LAST_MONTH' | 'LAST_YEAR' | 'CUSTOM_DATE';

export const CREATE_DATE_TYPES: { [key: string]: CreateDateOption } = {
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  LAST_YEAR: 'LAST_YEAR',
  CUSTOM_DATE: 'CUSTOM_DATE',
};
