import { FC, useEffect } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useSelector } from 'react-redux';

import { AxiosError } from 'axios';
import { FormikProps } from 'formik';

import { Attachment } from '../../../models/attachment';

import {
  deleteAttachment,
  getAttachmentIsDeleting,
  getAttachmentIsUploading,
  uploadAttachments,
} from '../../../store/attachments';
import { fetchFulfilled, fetching } from '../../../store/headerLoader';
import { WorkshopLessonInitialValues } from '../../../store/workshopDetails';

import { handleApiError } from '../../../utils/functions';
import { useBoolean } from '../../../utils/hooks';

import { TabIndex } from '..';
import config from '../../../config';
import { useAppDispatch } from '../../../root';
import { getToken } from '../../../services/auth';
import DrawerFooter from '../../FlexDrawer/Footer';
import IconButton from '../../IconButton';
import Spinner from '../../Spinner';
import FileUpload from '../../uploadFile';
import { VfButton, VfIcon } from '../../vfDesign';
import InputSpinner from '../../vfDesign/vfInputSpinner';
import styles from '../lessonDetails.module.scss';

export interface FileRowProps {
  attachmentId: string;
  fromWorkshop?: boolean;
  isEditable: boolean;
  lessonId: string;
  name: string;
  onClose: () => void;
  workshopId?: string;
}

const FileRow: FC<FileRowProps> = ({
  attachmentId,
  fromWorkshop,
  isEditable = false,
  lessonId,
  name,
  onClose,
  workshopId,
}) => {
  const dispatch = useAppDispatch();
  const isDeleting = useSelector(getAttachmentIsDeleting)(attachmentId);
  const [isDownloading, showIsDownloading, hideIsDownloading] = useBoolean(false);

  const handleDelete = (): void => {
    dispatch(deleteAttachment({ id: lessonId, attachmentId, fromWorkshop, workshopId }));
    if (fromWorkshop) {
      onClose();
    }
  };

  const handleFile = async () => {
    try {
      if (isDownloading) {
        return;
      }

      showIsDownloading();
      dispatch(fetching());
      const token = await getToken();

      const response = await fetch(`${config.API_URL}/attachments/lesson/${lessonId}/${attachmentId}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      const file = await response.blob();

      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.href = URL.createObjectURL(file);
      link.download = name;
      link.click();

      dispatch(fetchFulfilled());
      hideIsDownloading();
    } catch (err) {
      hideIsDownloading();
      handleApiError(err as AxiosError, `We could not download ${name}`, dispatch);

      throw err;
    }
  };

  const showLoader = isDeleting || isDownloading;

  return (
    <div className={styles['related__document']}>
      <div className={styles['line__document']} />
      <VfIcon name="file" />
      <p onClick={handleFile}>{name}</p>
      {showLoader && <InputSpinner classes="" />}
      {isEditable && <IconButton icon="trash" color="red" onClick={handleDelete} disabled={isDeleting} />}
    </div>
  );
};

export interface DocsTabProps {
  attachments: Attachment[];
  fromWorkshop?: boolean;
  lessonId: string;
  onClose: () => void;
  tabNameCallback?: (tabName: TabIndex) => void;
  userRight: boolean;
  workshopId?: string;
}

const DocsTab: FC<DocsTabProps & FormikProps<WorkshopLessonInitialValues>> = ({
  attachments,
  fromWorkshop = false,
  lessonId,
  onClose,
  setFieldValue,
  tabNameCallback,
  userRight,
  values,
  workshopId,
}) => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(getAttachmentIsUploading);

  useEffect(() => {
    if (tabNameCallback) {
      tabNameCallback(TabIndex.FILES);
    }
  }, [tabNameCallback]);

  const handleFiles = (files: FileWithPath[]): void => {
    dispatch(uploadAttachments({ files, id: lessonId, fromWorkshop, workshopId }));
    setFieldValue('filesUpload', []);

    if (fromWorkshop) {
      onClose();
    }
  };

  const uploadFiles = (value: FileWithPath[]) => {
    setFieldValue('filesUpload', value);
  };

  const renderAttachments = attachments?.map((file) => (
    <FileRow
      attachmentId={file.id}
      fromWorkshop={fromWorkshop}
      isEditable={userRight}
      key={`file-element-${file.id}`}
      lessonId={lessonId}
      name={file.name}
      onClose={onClose}
      workshopId={workshopId}
    />
  ));

  const isDisabled = isLoading || !userRight || !lessonId;
  const isSubmitDisabled = isDisabled || values?.filesUpload.length <= 0;

  return (
    <>
      <div className={styles['docs']}>
        {isLoading && (
          <div className={styles['lesson-details__spinner-wrapper']}>
            <Spinner />
          </div>
        )}
        {!isLoading && (
          <>
            {renderAttachments}
            <FileUpload
              attachments={values.filesUpload}
              closeInput={() => {}}
              maxFileUpload={10}
              uploadCallback={uploadFiles}
              disabled={isDisabled}
            />
          </>
        )}
      </div>
      <DrawerFooter>
        <VfButton outline="secondary" onClick={onClose}>
          Cancel
        </VfButton>
        <VfButton type="primary" onClick={() => handleFiles(values.filesUpload)} disabled={isSubmitDisabled}>
          Send files
        </VfButton>
      </DrawerFooter>
    </>
  );
};

export default DocsTab;
