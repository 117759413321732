/* eslint-disable no-console */
import { FC, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

// MSAL imports
import { InteractionRequiredAuthError } from 'msal';

import { useAccount, useMsal } from '@azure/msal-react';

import { loginScopes } from '../../../services/auth';

interface Props {
  noRedirect?: boolean;
}

const SilentLogin: FC<Props> = ({ noRedirect }) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (account && inProgress === 'none') {
      instance
        .acquireTokenSilent({
          scopes: loginScopes,
          account,
        })
        .then(() => {}) // TODO: check if we need this
        .catch((error: InteractionRequiredAuthError | string): void | PromiseLike<void> | Error => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect({ scopes: loginScopes });
          }

          return new Error(error);
        });
    }
  }, [account, inProgress, instance]);

  return noRedirect ? null : <Redirect to="/home" />;
};

SilentLogin.defaultProps = {
  noRedirect: false,
};

export default SilentLogin;
