import { FC } from 'react';

import classNames from 'classnames';

import styles from './vfLinearProgress.module.scss';

export interface VfLinearProgressProps {
  isLoading: boolean;
}

const VfLinearProgress: FC<VfLinearProgressProps> = ({ isLoading }) => {
  const containerClasses = classNames([styles['linear-progress__container']], {
    [styles['linear-progress__container--show']]: isLoading,
  });

  const indicatorClass = classNames({
    [styles['linear-progress__indicator']]: isLoading,
  });

  return (
    <div className={containerClasses}>
      <div className={indicatorClass} />
    </div>
  );
};

export default VfLinearProgress;
