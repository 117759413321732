import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useFormikContext } from 'formik';

import { IAdvancedFilters } from '../../models/filterSettings';
import { ADUser } from '../../models/user';

import { getDictionaryStates } from '../../store/dictionary';
import { fetchUserOlcs, getOlcs } from '../../store/user';

import { replaceFieldIdWIthName } from '../../utils/functions';

import { CREATE_DATE_TYPES, STATE_TAGNAMES } from '../../consts/lessons';
import { PRIORITIES } from '../../consts/newLessonForm';
import { paramsProps } from '../../pages/LessonsList';
import { useAppDispatch } from '../../root';
import UserSelector from '../UserSelector';
import { VfAutoComplete, VfCalendar, VfCheckbox, VfDropdown } from '../vfDesign';
import { IOption } from '../vfDesign/vfDropdown/models';
import { ExpandableItem } from '../vfDesign/vfExpendable';
import { VfMultipleDropdown } from '../vfDesign/vfMulitpleDropdown';
import { dateOptions, FILTER_LABELS } from './consts';
import DynamicForms from './dynamicForms';
import styles from './lessons-filters.module.scss';
import TagFilters from './tagFilters';

const FilterForms: FC = () => {
  const dispatch = useAppDispatch();
  const olcs = useSelector(getOlcs);
  const [olcsFilter, setOlcsFilter] = useState<string>('');
  const { setFieldValue, setFieldTouched, values, errors, touched } = useFormikContext<IAdvancedFilters>();
  const { tabName } = useParams<paramsProps>();
  const stateList = useSelector(getDictionaryStates);

  useEffect(() => {
    dispatch(fetchUserOlcs());
  }, [dispatch]);

  const dateSelection = (field: string, value: string): void => {
    if (value !== 'CUSTOM_DATE') {
      setFieldValue('startDate', null);
      setFieldValue('endDate', null);
      setFieldValue('customDate', null);
    }

    setFieldValue(field, value);
  };

  const userSelection = (field: string, user: Partial<ADUser>): void => {
    const fieldNameToSet = replaceFieldIdWIthName(field);

    setFieldValue(field, user.id);
    setFieldValue(fieldNameToSet, user.displayName || '');
  };

  const searchForImprovement = (): void => {
    setFieldValue('improvementDelivered', values.improvementDelivered ? '' : 'Yes');
  };

  const searchForFavorites = (): void => {
    setFieldValue('favourites', values.favourites ? '' : 'Yes');
  };

  const searchForActionTakerWaiting = (): void => {
    setFieldValue('afterDueDate', values.afterDueDate ? '' : 'Yes');
  };

  const searchForLessonsDelayed = (): void => {
    setFieldValue('delayed', values.delayed ? '' : 'Yes');
  };

  const searchForLessonsWithActionTakerPoked = (): void => {
    setFieldValue('poked', values.poked ? '' : 'Yes');
  };

  const handleOlcSelect = (value: string): void => {
    setFieldValue('olc', value);
    setOlcsFilter(value.toUpperCase());
  };

  const handleOlcClick = (name: string, value: IOption) => {
    setFieldValue(name, value.value.toString().toUpperCase());
  };

  const inMyLessons = tabName === STATE_TAGNAMES.MY_LESSONS;
  const inTakeAction = tabName === STATE_TAGNAMES.TAKE_ACTION;
  const showCustomDatePicker = values.createDateLimitation === CREATE_DATE_TYPES.CUSTOM_DATE;

  const filteredOlcs = olcs
    .filter((olc) => olc.label.includes(olcsFilter))
    .map((filteredOlc) => ({
      label: filteredOlc.label,
      value: filteredOlc.value,
    }));

  const stateOptions = stateList.data?.map((item) => {
    return { label: item.label, value: item.id };
  });

  return (
    <div className={styles.filters}>
      <DynamicForms />

      <ExpandableItem title="Tags" hideHint isEditable={true} usedItems="yes" showOverflow defaultExpanded={false}>
        {values.organizationId ? (
          <TagFilters />
        ) : (
          <span style={{ marginLeft: '10px' }}>Please choose Business Unit/Organization first</span>
        )}
      </ExpandableItem>

      <VfDropdown
        id="createDateLimitation"
        name="createDateLimitation"
        options={dateOptions}
        onChange={dateSelection}
        value={values.createDateLimitation}
        onBlur={() => {}}
        label={FILTER_LABELS.createDateLimitation}
        showMinifiedLabel
        clearOption
        className="my-2"
      />

      {showCustomDatePicker && (
        <div className={styles.calendarWrapper}>
          <div className={styles.innerWrapper}>
            <VfCalendar
              customPlaceholder="Custom date"
              error={errors.customDate && touched.customDate ? errors.customDate : undefined}
              name="customDate"
              rangePicker
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              value={values.customDate || null}
            />
          </div>
        </div>
      )}

      <VfDropdown
        id="priority"
        name="priority"
        options={PRIORITIES}
        onChange={setFieldValue}
        value={values.priority}
        onBlur={() => {}}
        label={FILTER_LABELS.priority}
        showMinifiedLabel
        clearOption
        className="my-1"
      />
      <div className={styles.olc}>
        <VfAutoComplete
          id="olc"
          name="olc"
          options={filteredOlcs}
          onChange={handleOlcSelect}
          label={FILTER_LABELS.olc}
          preserveValue
          onClick={handleOlcClick}
          initialValue={values.olc}
        />
      </div>

      <div className="mx-2 my-2">
        <VfMultipleDropdown
          id="states"
          label={FILTER_LABELS.states}
          name="states"
          onBlur={() => setFieldTouched('states', true)}
          onChange={setFieldValue}
          options={stateOptions}
          value={values.states}
        />
      </div>

      <div className={styles.userSelection}>
        <UserSelector
          onSelect={userSelection}
          name="creatorId"
          placeholder={FILTER_LABELS.creatorId}
          value={{ id: values.creatorId, displayName: values.creatorName }}
          disabled={inMyLessons}
        />
      </div>

      <div className={styles.userSelection}>
        <UserSelector
          onSelect={userSelection}
          name="actionTakerId"
          placeholder={FILTER_LABELS.actionTakerId}
          value={{ id: values.actionTakerId, displayName: values.actionTakerName }}
          disabled={inTakeAction}
        />
      </div>
      <div className={styles.userSelection}>
        <UserSelector
          onSelect={userSelection}
          name="actionTakerDeputyId"
          placeholder={FILTER_LABELS.actionTakerDeputyId}
          value={{ id: values.actionTakerDeputyId, displayName: values.actionTakerDeputyName }}
          disabled={inTakeAction}
        />
      </div>

      <div className={styles.improvement}>
        <div className={styles.improvement__row}>
          <VfCheckbox
            id="improvementDelivered"
            onChange={searchForImprovement}
            name="improvementDelivered"
            checked={!!values.improvementDelivered}
          />
          <span>Lesson improved</span>
        </div>
      </div>

      <div className={styles.improvement}>
        <div className={styles.improvement__row}>
          <VfCheckbox id="favourites" onChange={searchForFavorites} name="favourites" checked={!!values.favourites} />
          <span>Favourites</span>
        </div>
      </div>

      <div className={styles.improvement}>
        <div className={styles.improvement__row}>
          <VfCheckbox
            id="afterDueDate"
            onChange={searchForActionTakerWaiting}
            name="afterDueDate"
            checked={!!values.afterDueDate}
          />
          <span>Due date passed</span>
        </div>
      </div>

      <div className={styles.improvement}>
        <div className={styles.improvement__row}>
          <VfCheckbox id="delayed" onChange={searchForLessonsDelayed} name="delayed" checked={!!values.delayed} />
          <span>Lesson with High Priority & delayed</span>
        </div>
      </div>

      <div className={styles.improvement}>
        <div className={styles.improvement__row}>
          <VfCheckbox
            id="poked"
            onChange={searchForLessonsWithActionTakerPoked}
            name="poked"
            checked={!!values.poked}
          />
          <span>Lesson with Action Taker Poked</span>
        </div>
      </div>
    </div>
  );
};

export default FilterForms;
