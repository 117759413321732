import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Form, Formik, FormikHelpers } from 'formik';
import { object, array, string } from 'yup';
import 'yup/lib/locale';

import { ADUser, User } from '../../../models/user';

import {
  addWorkshop,
  clearEditMode,
  editWorkshop,
  fetchParticipants,
  getCurrentWorkshop,
  getParticipants,
  getWorkshopSpace,
  WorkshopCreateInitialValues,
} from '../../../store/workshopDetails';

import { WORKSHOP_STATUSES } from '../../../consts/workshops';
import { useAppDispatch } from '../../../root';
import Drawer from '../../FlexDrawer';
import DrawerFooter from '../../FlexDrawer/Footer';
import DrawerHeader from '../../FlexDrawer/Header';
import { Input } from '../../Forms';
import UserManagement from '../../UserManagment';
import { VfButton, VfCalendar, VfDropdown } from '../../vfDesign';
import { ExpandableItem } from '../../vfDesign/vfExpendable';
import styles from './workshops-details-drawer.module.scss';

export interface WorkshopDetailsDrawerProps {
  isEditMode?: boolean;
  onClose: () => void;
  open: boolean;
  projectId: string;
  projectTypeId: string;
  workshopSpaceId?: string;
}

interface Param {
  id: string;
}

const validationSchema = object().shape({
  performedAt: array().nullable().required('Date is required'),
  participants: array().min(1, 'At least one participant is required'),
  facilitators: array().min(1, 'At least one facilitator is required'),
  name: string().required('Workshop Type is required').max(255, 'Provided name is too long'),
});

const WorkshopDetailsDrawer: FC<WorkshopDetailsDrawerProps> = ({
  isEditMode = false,
  onClose,
  open = false,
  projectId,
  projectTypeId,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<Param>();

  const {
    data: { organizationId },
  } = useSelector(getWorkshopSpace);
  const { current } = useSelector(getCurrentWorkshop);
  const { participants: participantsFromProject } = useSelector(getParticipants);

  const initialValues: WorkshopCreateInitialValues = {
    facilitators: isEditMode ? current?.facilitators : [],
    participants: isEditMode ? (current?.participants as ADUser[]) : participantsFromProject,
    // TODO : handle any below
    performedAt: isEditMode ? [current?.startDate as any, current?.endDate as any] : null,
    name: isEditMode ? current?.name : '',
    workshopState: isEditMode ? current?.workshopState : '',
  };

  useEffect(() => {
    if (projectId) {
      const payload = {
        organizationId,
        projectTypeId,
        projectId,
      };
      dispatch(fetchParticipants(payload));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, projectId]);

  const handleSubmit = async (values: typeof initialValues, { resetForm }: FormikHelpers<typeof initialValues>) => {
    const preparedValues = {
      startDate: values.performedAt![0],
      endDate: values.performedAt![1],
      facilitators: values.facilitators?.map((facilitator) => facilitator.id),
      participants: values.participants?.map((participant) => participant.id),
      workshopSpaceId: id,
      name: values.name,
      workshopState: values.workshopState || null,
    };

    if (isEditMode) {
      const payload = {
        id: current.id,
        workshopEnvId: id,
        body: preparedValues,
      };

      dispatch(editWorkshop(payload));
    } else {
      const payload = {
        id,
        body: preparedValues,
      };

      dispatch(addWorkshop(payload));
    }
    dispatch(clearEditMode());
    resetForm();
    onClose();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, resetForm, errors, setFieldValue, setFieldTouched, values, touched, dirty }) => {
        const formHasErrors = !errors || Object.keys(errors).length > 0;
        const isDisabled = isSubmitting || formHasErrors || !dirty;

        const handleClose = () => {
          dispatch(clearEditMode());
          resetForm();
          onClose();
        };

        const handleUsers =
          (field: string) =>
          (users: Partial<User>[]): void => {
            setFieldValue(field, users);
          };

        return (
          <Drawer
            delayTime={400}
            isMounted={open}
            isOpen={open}
            onClose={handleClose}
            onOutsideClick={handleClose}
            side="right"
          >
            <DrawerHeader onClose={onClose} headline={`${isEditMode ? 'Edit' : 'Create new'}  workshop`} />
            <Form>
              <div className={styles['drawer-form']}>
                <div className={styles['drawer-form__inputs']}>
                  <Input name="name" className="my-2" label="Workshop type *" />
                  <VfCalendar
                    customPlaceholder="Performed at *"
                    error={errors.performedAt && touched.performedAt ? errors.performedAt : undefined}
                    name="performedAt"
                    rangePicker
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    value={values.performedAt}
                  />
                  {isEditMode && (
                    <VfDropdown
                      className="my-2"
                      fullWidth
                      id="workshopState"
                      label="Status"
                      name="workshopState"
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      options={WORKSHOP_STATUSES}
                      showFullLabelText
                      showMinifiedLabel
                      value={values.workshopState}
                    />
                  )}
                </div>
                <ExpandableItem
                  defaultExpanded
                  hideHint
                  isEditable
                  showOverflow
                  title="Facilitators *"
                  usedItems={values.facilitators}
                >
                  <UserManagement
                    error={errors.facilitators && touched.facilitators ? errors.facilitators : undefined}
                    placeholder="Add facilitator"
                    setUsers={handleUsers('facilitators')}
                    users={values.facilitators}
                  />
                </ExpandableItem>
                <ExpandableItem
                  defaultExpanded
                  hideHint
                  isEditable
                  showOverflow
                  title="Participants *"
                  usedItems={values.participants}
                >
                  <UserManagement
                    error={errors.participants && touched.participants ? errors.participants : undefined}
                    placeholder="Add participant"
                    setUsers={handleUsers('participants')}
                    users={values.participants}
                  />
                </ExpandableItem>
              </div>
              <DrawerFooter>
                <VfButton outline="secondary" onClick={handleClose}>
                  Cancel
                </VfButton>
                <VfButton type="primary" htmlType="submit" disabled={isDisabled}>
                  {isEditMode ? 'Update' : 'Create'}
                </VfButton>
              </DrawerFooter>
            </Form>
          </Drawer>
        );
      }}
    </Formik>
  );
};

export default WorkshopDetailsDrawer;
