import { FC } from 'react';

import { Textarea } from '../../Forms';
import styles from '../Shared.module.scss';

const ProjectRecommendation: FC = () => {
  return (
    <div className={styles.content}>
      <h5 className={styles.lead}>Do you have a recommendation on how to deal with this lesson?</h5>

      <div>
        <Textarea name="recommendation" label="What is your proposed solution?" textareaStyles={styles.textarea} />

        <p className={styles.helper}>Max. 2000 characters</p>
      </div>
    </div>
  );
};

export default ProjectRecommendation;
