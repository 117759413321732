import { useState } from 'react';

import { StructureEntities } from '../../../components/Forms/Dropdown';
import { NO_DATA } from '../../../components/vfDesign/vfDropdown/helpers';
import { IOption } from '../../../components/vfDesign/vfDropdown/models';
import { replaceFieldIdWIthName } from '../../functions';
import { handleRelatedFields, handleSelectCallback } from './helper';

interface UseDropdownSelectionProps {
  data: StructureEntities[];
  groupedNamesConfig?: Record<string, string>;
  isGroupedOptions?: boolean;
  name: string;
  onSelectCallback?: (option: StructureEntities) => void | null;
  relatedFields?: string[];
  isSetByLabel?: boolean;
  setFieldValue: (name: string, value: string) => void;
  options: IOption[];
}

const useDropdownSelection = ({
  data = [],
  groupedNamesConfig = {},
  isGroupedOptions = false,
  isSetByLabel = false,
  name,
  onSelectCallback = undefined,
  options = [],
  relatedFields = [],
  setFieldValue,
}: UseDropdownSelectionProps) => {
  const [formikName, setFormikName] = useState<string>(name);

  const setLabel = (name: string, value: string) => {
    const option = options.find((option) => option.value === value);
    const fieldNameToSet = replaceFieldIdWIthName(name);

    if (option) {
      setFieldValue(fieldNameToSet, option.label);
    }
  };

  const getFormikName = (selectedValue: string): string => {
    if (isGroupedOptions) {
      const selectedOption: any = options.find((option) => option.value === selectedValue); // TODO: handle any

      if (selectedOption?.group && groupedNamesConfig) {
        const newName = groupedNamesConfig[selectedOption.group] || name;

        setFormikName(newName);
        if (newName !== name) {
          setFieldValue(name, '');
        }

        return newName;
      }
    }

    return name;
  };

  const onClick = (_: string, value: { value: string }) => {
    if (value?.value === NO_DATA) {
      return;
    }

    const selectedFieldName = getFormikName(value?.value);

    setFieldValue(selectedFieldName, value?.value);
    handleRelatedFields(relatedFields, setFieldValue, isSetByLabel);
    handleSelectCallback(onSelectCallback, data, value?.value);

    if (isSetByLabel) {
      // Saves also 'Name' (label) of an item - in case of saving workstreamId, this will save workstreamName as well.
      setLabel(selectedFieldName, value?.value);
    }
  };

  const onClear = () => {
    setFieldValue(formikName, '');
    handleRelatedFields(relatedFields, setFieldValue, isSetByLabel);
    handleSelectCallback(onSelectCallback, data);

    if (isSetByLabel) {
      // Saves also 'Name' (label) of an item - in case of saving workstreamId, this will save workstreamName as well.
      setLabel(formikName, '');
    }
  };

  return { onClick, onClear };
};

export default useDropdownSelection;
