import { ChangeEvent, FC } from 'react';

import classNames from 'classnames';

import VfCheckbox from '../vfCheckbox';
import VfIcon from '../vfIcon';
import VfPopover from '../vfPopover';
import TableActions, { ActionOption } from './actions';
import { IGNORED_DEFAULT_CELL, IGNORED_ONROWCLICK_CELL, useActionMenu } from './tableHelpers';

export interface RowObject {
  [key: string]: () => void | string; // TODO: tmp workaround
}

export interface RowProps {
  isSelected?: boolean;
  onRowClick?: () => void;
  onRowSelect?: (e: ChangeEvent<HTMLInputElement>) => void;
  row: RowObject;
  rowActions: ActionOption[];
  withCheckboxes?: boolean;
  withRowClick?: boolean;
}

const Row: FC<RowProps> = ({ row, withRowClick, onRowClick, rowActions, withCheckboxes, isSelected, onRowSelect }) => {
  const [actionMenuVisible, toggleActionMenu, closeActionMenu] = useActionMenu();

  const columns = Object.keys(row).filter((item) => !IGNORED_DEFAULT_CELL.includes(item));

  const renderColumns = columns.map((column, index) => {
    return (
      <td key={index}>
        <span className="row-item">{row[column]}</span>
        {typeof row[column] === 'function' && row[column]()}
      </td>
    );
  });

  const handleClick = (e: any) => {
    // TODO: handle any
    closeActionMenu();

    const eventTarget = e?.target;

    // TODO: tmp. workaround for vfswitch not working in table row with onRowClick, e.stopPropagation not working
    if (onRowClick && !IGNORED_ONROWCLICK_CELL.includes(eventTarget.id) && eventTarget?.type !== 'checkbox') {
      onRowClick();
    }
  };

  const classes = classNames({
    'with-click': withRowClick,
    'is-selected': isSelected,
  });

  return (
    <tr onClick={handleClick} className={classes}>
      {withCheckboxes && onRowSelect && (
        <td className="with-checkbox">
          <VfCheckbox id="check_all" name="check_all" checked={isSelected} onChange={onRowSelect} />
        </td>
      )}
      {renderColumns}
      {rowActions.length > 0 && (
        <td className="with-action">
          <VfPopover
            id={`action-menu:${row.id || ''}`}
            component={<VfIcon name="vf-action-menu" asButton />}
            onClick={toggleActionMenu}
            isOpen={actionMenuVisible}
            closeDropdown={closeActionMenu}
            className="vf-table__actions"
            right
          >
            <TableActions options={rowActions} row={row.item} />
          </VfPopover>
        </td>
      )}
    </tr>
  );
};

Row.defaultProps = {
  isSelected: false,
  onRowClick: () => false,
  onRowSelect: () => false,
  withCheckboxes: false,
  withRowClick: false,
};

export default Row;
