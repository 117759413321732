import { useDispatch, useSelector } from 'react-redux';

import { fetchInstructionLinks, getInstructionLinks } from '../../store/instructionLink';

import { callOnIdleTime } from '../../utils/functions';

import { dispatchToastEvent } from '../../events/helpers';
import ToastMessage from '../Toast';
import './need-help.scss';
import { useEffect } from 'react';
import { InstructionLinkType } from '../../models/InstructionLinks';

const NeedHelp = () => {
  const { data } = useSelector(getInstructionLinks);
  const dispatch = useDispatch();

  const showHelperToast = () => {
    dispatchToastEvent(() => {}, undefined, 'left');
  };

  useEffect(() => {
    dispatch(fetchInstructionLinks());
  }, [dispatch])

  callOnIdleTime(showHelperToast);

  const renderHelpOptions = data.map((option, index) => (
    <div
      key={index}
      onClick={() =>
        // @ts-ignore
        window.open(option.url, '_blank', option.type === InstructionLinkType.GUIDE ? 'fullscreen=yes' : '')
      }
      className="need-help-link"
    >
      {option.name}
    </div>
  ));

  return (
    <ToastMessage>
      <>{renderHelpOptions}</>
    </ToastMessage>
  );
};

export default NeedHelp;
