import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';

import { Organization, OrganizationIds } from '../../models/structure';

import {
  clearProjects,
  fetchOrganizations,
  fetchProjects,
  fetchProjectTypes,
  getOrganizations,
  getProjects,
  getProjectTypes,
} from '../../store/structure';
import {
  addProjectEnvironment,
  clearCurrentWorkshopSpace,
  currentWorkshopSpaceIsEditing,
  getWorkshopSpaces,
  ProjectEnvironmentCreateParams,
  updateProjectEnvironment,
} from '../../store/workshopSpace';

import Drawer from '../../components/FlexDrawer';
import DrawerFooter from '../../components/FlexDrawer/Footer';
import DrawerHeader from '../../components/FlexDrawer/Header';
import { AutoCompleteWithReduxData } from '../../components/Forms/Autocomplete';
import EnhancedFormikDropdown from '../../components/Forms/Dropdown';
import { VfButton } from '../../components/vfDesign';
import { useAppDispatch } from '../../root';
import styles from './workshops.module.scss';

export interface WorkshopDrawerProps {
  onClose: () => void;
  open: boolean;
}

const validationSchema = object().shape({
  organizationId: string().required('Organization is required'),
  projectTypeId: string().required('Project Type is required'),
  projectId: string().required('Project is required'),
});

const WorkshopDrawer: FC<WorkshopDrawerProps> = ({ open = false, onClose }) => {
  const dispatch = useAppDispatch();
  const isEditMode = useSelector(currentWorkshopSpaceIsEditing);
  const { isChanging, current } = useSelector(getWorkshopSpaces);

  const initialValues: ProjectEnvironmentCreateParams = {
    organizationId: current.organizationId || '',
    projectId: current.projectId || '',
    projectTypeId: current.projectTypeId || '',
  };

  const handleSubmit = async (values: typeof initialValues, { resetForm }: FormikHelpers<typeof initialValues>) => {
    if (isEditMode) {
      dispatch(updateProjectEnvironment(values));
    } else {
      dispatch(addProjectEnvironment(values));
    }

    clearCurrentWorkshopSpace();
    resetForm();
    onClose();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, resetForm, errors, setFieldValue, validateForm, values, dirty, touched }) => {
        const formHasErrors = !errors || Object.keys(errors).length > 0;
        const isDisabled = isSubmitting || isChanging || formHasErrors || (isEditMode && !dirty);

        const handleClose = () => {
          resetForm();
          onClose();
        };

        const handleOrganizationSelect = (organization: Organization): void => {
          if (organization.id === OrganizationIds.onshore) {
            setFieldValue('projectTypeId', 'ONSHORE');
          }

          setTimeout(() => validateForm());
        };

        return (
          <Drawer
            delayTime={400}
            isMounted={open}
            isOpen={open}
            onClose={handleClose}
            onOutsideClick={handleClose}
            side="right"
          >
            <DrawerHeader onClose={handleClose} headline={`${isEditMode ? 'Edit' : 'Create'} new environment`} />
            <Form>
              <div className={styles['drawer-form']}>
                <EnhancedFormikDropdown
                  dispatchFn={fetchOrganizations}
                  fullWidth
                  setByLabel
                  label="Business Unit"
                  labelKey="name"
                  name="organizationId"
                  selector={getOrganizations}
                  relatedFields={['projectTypeId', 'projectId']}
                  showMinifiedLabel
                  onSelectCallback={handleOrganizationSelect}
                />

                {values.organizationId !== OrganizationIds.onshore && (
                  <EnhancedFormikDropdown
                    dispatchFn={fetchProjectTypes}
                    fullWidth
                    setByLabel
                    label="Project type"
                    labelKey="name"
                    name="projectTypeId"
                    selector={getProjectTypes}
                    params={{
                      organizationId: values['organizationId'],
                    }}
                    relatedFields={['projectId']}
                    skipFields={['organizationId']}
                    showMinifiedLabel
                  />
                )}
                <AutoCompleteWithReduxData
                  dispatchFn={fetchProjects}
                  label="Project"
                  labelKey="name"
                  name="projectId"
                  selector={getProjects}
                  onDismountCallback={clearProjects}
                  params={{
                    organizationId: values['organizationId'],
                    projectTypeId: values['projectTypeId'],
                  }}
                  parentField={['projectTypeId', 'organizationId']}
                  relatedFields={[]}
                  skipFields={['organizationId', 'projectTypeId']}
                  setByLabel
                />
              </div>
              <DrawerFooter>
                <VfButton outline="secondary" onClick={handleClose}>
                  Cancel
                </VfButton>
                <VfButton type="primary" htmlType="submit" disabled={isDisabled}>
                  {isEditMode ? 'Update' : 'Create'}
                </VfButton>
              </DrawerFooter>
            </Form>
          </Drawer>
        );
      }}
    </Formik>
  );
};

export default WorkshopDrawer;
