import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useFormikContext } from 'formik';

import { getUserDetails } from '../../store/user';

import { getNameFromDisplayName } from '../../utils/functions';
import useInfoElement from '../../utils/hooks/useInfoElement';

import { STATE_TAGNAMES } from '../../consts/lessons';
import { FlowOption } from '../../consts/newLessonForm';
import Spinner from '../Spinner';
import Tooltip from '../Tooltip';
import { InfoElementNames } from '../Tooltip/consts';
import { VfButton } from '../vfDesign';
import { findCurrentFlow, initialValues, NewLessonContext } from './Context';
import styles from './Shared.module.scss';

const FormRenderer: FC = () => {
  const history = useHistory();
  const { data, isLoading } = useSelector(getUserDetails);
  const [{ popupText: addProjectLessonText }] = useInfoElement(InfoElementNames.ADD_PROJECT_LESSON);
  const [{ popupText: addSiteLessonText }] = useInfoElement(InfoElementNames.ADD_SITE_LESSON);
  const [{ popupText: addOrganizationalLessonText }] = useInfoElement(InfoElementNames.ADD_ORGANIZATIONAL_LESSON);
  const { currentStep, config, currentFlowType, setCurrentFlowType, finished, resetForm } =
    useContext(NewLessonContext);

  const { setValues, setTouched } = useFormikContext();
  const displayName = getNameFromDisplayName(data?.displayName);

  const setFlowType = (flowType: FlowOption) => () => {
    setCurrentFlowType && setCurrentFlowType(flowType);
  };

  const handleResetForm = () => {
    resetForm && resetForm();
    setValues({ ...initialValues });
    setTouched({});
  };

  const handleGoToMyLessons = () => {
    history.push(`/lessons/${STATE_TAGNAMES.MY_LESSONS}`);
  };

  if (finished) {
    return (
      <div className={styles.fullscreen}>
        {isLoading && <Spinner />}

        {!isLoading && <h5 className={styles.headline}>Thank you {displayName}</h5>}

        <h6 className={styles.lead}>What do you want to do next?</h6>

        <div>
          <VfButton type="secondary" onClick={handleResetForm} className="mr-1">
            Add new lesson
          </VfButton>

          <VfButton type="secondary" onClick={handleGoToMyLessons}>
            Go to My lessons
          </VfButton>
        </div>
      </div>
    );
  }

  if (!currentFlowType) {
    return (
      <div className={styles.fullscreen}>
        <h5 className={styles.lead}>Have you experienced this lesson in...</h5>

        <div className="d-flex flex-column flex-lg-row flex-wrap">
          <Tooltip text={addProjectLessonText} position='bottom'>
            <VfButton
              type="secondary"
              onClick={setFlowType('PROJECT')}
              className={`mr-md-1 mt-1 ${styles.origin__button}`}
            >
              Project
            </VfButton>
          </Tooltip>

          <Tooltip text={addSiteLessonText} position='bottom'>
            <VfButton
              type="secondary"
              onClick={setFlowType('OPERATING_SITE')}
              className={`mr-md-1 mt-1 ${styles.origin__button}`}
            >
              Operating site
            </VfButton>
          </Tooltip>

          <Tooltip text={addOrganizationalLessonText} position='bottom'>
            <VfButton
              type="secondary"
              onClick={setFlowType('ORGANIZATION_UNIT')}
              className={`mt-1 ${styles.origin__button}`}
            >
              Organizational unit
            </VfButton>
          </Tooltip>
        </div>
      </div>
    );
  }

  const step = config.find(findCurrentFlow(currentFlowType))?.steps[currentStep - 1];

  if (step) {
    return <step.render currentFlow={currentFlowType} />;
  }

  console.error('No steps provided for given flow type.');

  return (
    <div className={styles.content}>
      <p>No steps provided for given flow type.</p>
    </div>
  );
};

export default FormRenderer;
