import { FC, useState, useRef, useEffect } from 'react';

import classNames from 'classnames';
import { fromEvent, Subject, Subscription } from 'rxjs';

import { DelayedWrapper } from '../../utils/hoc/withDelayedUnmount';
import { useOutsideClick } from '../../utils/hooks';

import { EVENT_DEFAULTS, EVENT_TYPES } from '../../events/types';
import Portal from '../Portal';
import { VfButton, VfDivider } from '../vfDesign';
import styles from './confirmation.module.scss';

const ConfirmationModal: FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const subscriptionRef = useRef<Subscription | null>(null);
  const callbackSubject = new Subject();
  const callbackRef = useRef<any>(null); // TODO: handle any

  const [opened, setOpened] = useState(false);
  const [headerText, setHeaderText] = useState(EVENT_DEFAULTS.CONFIRMATION.HEADER);

  const setClosed = () => {
    setOpened(false);
  };

  const resetConfirmation = () => {
    setClosed();

    setTimeout(() => {
      callbackRef.current = null;
      setHeaderText(EVENT_DEFAULTS.CONFIRMATION.HEADER);
    }, 1000);
  };

  const successFunction = (event: any) => {
    // TODO: handle any
    setHeaderText(event.detail.header);
    callbackRef.current = event.detail.callback;
    setOpened(true);
  };

  const errorFunction = (error: Error) => {
    throw error;
  };

  useEffect(() => {
    subscriptionRef.current = fromEvent(document, EVENT_TYPES.CONFIRM_MODAL_EVENT).subscribe(
      successFunction,
      errorFunction,
    );

    callbackSubject.subscribe(() => {
      resetConfirmation();
    });

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }

      callbackSubject.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackSubject]);

  const callbackWithLog = () => {
    callbackRef.current();
    callbackSubject.next('');
  };

  useOutsideClick(ref, () => {
    if (opened) {
      setClosed();
    }
  });

  const classes = classNames(styles['confirmation-modal__wrapper'], {
    [styles['open']]: opened,
  });

  return (
    <Portal id="modal-root">
      <DelayedWrapper isMounted={opened}>
        <div className={classes}>
          <div className={styles['confirmation-modal']} ref={ref}>
            <div className={styles['confirmation-modal__header']}>{headerText}</div>

            <VfDivider />
            <div className={styles['confirmation-modal__utils']}>
              <VfButton type="primary" onClick={callbackWithLog} id="confirmation:yes">
                Confirm
              </VfButton>
              <VfButton type="secondary" onClick={setClosed} id="confirmation:no">
                Cancel
              </VfButton>
            </div>
          </div>
        </div>
      </DelayedWrapper>
    </Portal>
  );
};

export default ConfirmationModal;
