import { FC, MouseEvent } from 'react';

import DropdownGroupedOptions from './dropdownGroupedOptions';
import DropdownOptions from './dropdownOptions';
import { IOptionWithGroup, IOption } from './models';
import './vf-dropdown.scss';

export interface IVfRegularOptionsProps {
  handleClick: (id: string) => void;
  isGroupedOptions?: boolean;
  isShowFullText?: boolean;
  options: IOption[] | IOptionWithGroup[];
  restrictByText?: boolean;
  text?: string;
}

export const VfRegularOptions: FC<IVfRegularOptionsProps> = ({
  handleClick,
  isGroupedOptions = false,
  isShowFullText,
  options,
  restrictByText,
  text,
}) => {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const {
      currentTarget: { id },
    } = e;

    handleClick(id);
  };

  const filteredOptions = options.filter(({ label }) => {
    if (restrictByText) {
      return text && label.toLowerCase().startsWith(text.toLowerCase());
    }

    return true;
  });

  return (
    <>
      {
        <>
          {!isGroupedOptions && (
            <DropdownOptions options={filteredOptions} onClick={onClick} isShowFullText={isShowFullText} />
          )}
          {isGroupedOptions && (
            <DropdownGroupedOptions
              options={filteredOptions as IOptionWithGroup[]}
              onClick={onClick}
              isShowFullText={isShowFullText}
            />
          )}
        </>
      }
    </>
  );
};

VfRegularOptions.defaultProps = {
  isGroupedOptions: false,
  restrictByText: false,
  isShowFullText: false,
  text: '',
};

export default VfRegularOptions;
