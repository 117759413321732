import { FC } from 'react';

import classNames from 'classnames';
import { v4 } from 'uuid';

import './_vf-checkbox.scss';

export interface VfCheckboxProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  onChange: (e?: any) => void;
  value?:string;
}

const VfCheckbox: FC<VfCheckboxProps> = ({ id, name, checked, onChange, className, disabled, value }) => {
  const classes = classNames('vf-checkbox', className);

  return (
    <div className={classes}>
      <input
        id={id}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={() => {}}
        onClick={onChange}
        disabled={disabled}
        value={value}
      />
    </div>
  );
};

VfCheckbox.defaultProps = {
  checked: false,
  className: '',
  disabled: false,
  id: v4(),
};

export default VfCheckbox;
