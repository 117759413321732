import { FC } from 'react';

import DrawerFooter from '../../FlexDrawer/Footer';
import { VfButton } from '../../vfDesign';
import styles from './bulkUpdateLessonsDrawer.module.scss';

interface BulkUpdateLessonsConfirmationProps {
  editActionTaker: boolean;
  editActionTakerDeputy: boolean;
  isSubmitDisabled: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onEditChanges: () => void;
  selectedLessonsCount: number;
}

const BulkUpdateLessonsConfirmation: FC<BulkUpdateLessonsConfirmationProps> = ({
  editActionTaker,
  editActionTakerDeputy,
  isSubmitDisabled,
  onConfirm,
  onClose,
  onEditChanges,
  selectedLessonsCount,
}) => {
  const getConfirmationMessage = () => {
    let fields = '';
    if (editActionTaker) fields += 'action taker';
    if (editActionTakerDeputy) fields += fields ? ' and action taker deputy' : 'action taker deputy';
    const lessonsText = selectedLessonsCount === 1 ? 'lesson' : 'lessons';
    return `Are you sure you want to update ${fields} for ${selectedLessonsCount} ${lessonsText}?`;
  };

  return (
    <>
      <div className={styles['bulk-lesson-details']}>{getConfirmationMessage()}</div>
      <DrawerFooter>
        <div className={styles['bulk-lesson-details__cancel-action-buttons']}>
          <VfButton outline="secondary" onClick={onClose}>
            Close
          </VfButton>
          <VfButton outline="secondary" onClick={onEditChanges}>
            Edit changes
          </VfButton>
        </div>
        <VfButton
          className={styles['bulk-lesson-details__submit-button']}
          type="primary"
          htmlType="button"
          onClick={onConfirm}
          disabled={isSubmitDisabled}
        >
          Confirm
        </VfButton>
      </DrawerFooter>
    </>
  );
};

export default BulkUpdateLessonsConfirmation;
