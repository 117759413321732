import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import type { InfoElement as InfoElementType } from '../../../models/infoElement';

import { fetchInfoElements, getInfoElements, removeInfoElement } from '../../../store/infoElement';

import BackToPage from '../../../components/BackToPage';
import IconButton from '../../../components/IconButton';
import Spinner from '../../../components/Spinner';
import { VfButton } from '../../../components/vfDesign';
import { useAppDispatch } from '../../../root';
import styles from './InfoElements.module.scss';
import Add from './addEdit';

interface InfoElementProps extends Partial<InfoElementType> {
  editHandler: () => void;
  isRemoving: boolean;
  removeHandler: () => void;
}

const Legend = () => {
  return (
    <>
      <div className={styles.section}>
        <p className="font-weight-bold">Name</p>
      </div>

      <div className={styles.section}>
        <p className="font-weight-bold">Popup text</p>
      </div>

      <div className={styles.section}>
        <p className="font-weight-bold">Edit</p>
      </div>

      <div className={styles.section}>
        <p className="font-weight-bold">Delete</p>
      </div>
    </>
  );
};

export const InfoElement: FC<InfoElementProps> = ({ editHandler, isRemoving, name, removeHandler, popupText }) => {
  return (
    <>
      <div className={styles.section}>
        <p className={styles.paragraph__break_words}>{name}</p>
      </div>

      <div className={styles.section}>
        <p className={styles.paragraph__break_words}>{popupText}</p>
      </div>

      <div className={styles.section}>
        <IconButton icon="pencil" onClick={editHandler} />
      </div>

      <div className={styles.section}>
        <IconButton icon="trash" onClick={removeHandler} disabled={isRemoving} />
      </div>
    </>
  );
};

const InfoElements: FC = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useSelector(getInfoElements);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState('');

  useEffect(() => {
    dispatch(fetchInfoElements());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageClasses = classNames(styles['info-elements'], 'd-flex flex-fill ');

  const addButtonHandler = () => {
    setEdit('');
    setOpen(true);
  };

  const editButtonHandler = (name: string) => () => {
    setEdit(name);
    setOpen(true);
  };

  const removeButtonHandler = (name: string) => () => {
    dispatch(removeInfoElement(name));
  };

  const infoElements = data?.map(({ name, popupText }) => (
    <InfoElement
      editHandler={editButtonHandler(name)}
      isRemoving={isLoading}
      key={`infoElement-${name}`}
      name={name}
      removeHandler={removeButtonHandler(name)}
      popupText={popupText}
    />
  ));

  return (
    <>
      <BackToPage />

      {isLoading && (
        <div className="d-flex flex-fill justify-content-center align-items-center vf-vh-100 vf-vw-100">
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <div className={pageClasses}>
          <Add name={edit} open={open} setOpen={setOpen} />

          <div className="vf-container">
            <div className="vf-row">
              <div className="vf-col">
                <h1 className={styles.headline}>Info elements</h1>
              </div>
            </div>
          </div>

          <div className="vf-container">
            <div className="vf-row mt-2">
              <div className="vf-col">
                <div className={styles.list}>
                  <Legend />
                  {infoElements}
                </div>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className="vf-container">
              <div className="vf-row mt-2">
                <div className="vf-col">
                  <div className="d-flex flex-fill justify-content-center align-items-center">
                    <Spinner />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="vf-container flex-grow-1 d-flex flex-column justify-content-end">
            <div className="vf-row mt-2">
              <div className="vf-col d-flex justify-content-end">
                <VfButton onClick={addButtonHandler} type="secondary">
                  Add info element
                </VfButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoElements;
