import { FC } from 'react';

import { useFormikContext } from 'formik';

import { StructureBase } from '../../../models/structure';
import { ADUser } from '../../../models/user';

import {
  clearProcesses,
  clearWorkstreams,
  fetchProcesses,
  fetchWorkstreams,
  getProcesses,
  getWorkstreams,
} from '../../../store/structure';

import { FlowOption } from '../../../consts/newLessonForm';
import { AutoCompleteWithReduxData } from '../../Forms/Autocomplete';
import UserSelector from '../../UserSelector';
import { VfDivider, VfHelperText } from '../../vfDesign';
import styles from '../Shared.module.scss';
import { ProjectLessonType } from '../types';

interface FormValues extends ProjectLessonType {
  processName: string;
  workstreamName: string;
  owner: Partial<ADUser>;
}

interface ProjectWorkstreamProps {
  currentFlow?: FlowOption;
}

const ProjectWorkstream: FC<ProjectWorkstreamProps> = ({ currentFlow }) => {
  const { setFieldValue, values, errors } = useFormikContext<FormValues>();
  const setOwnerFromWorkstream = (item: StructureBase) => {
    if (item?.owner) {
      setFieldValue('owner', item.owner);
    } else {
      setFieldValue('owner', '');
    }
  };
  const setOwnerFromProcess = (item: StructureBase) => {
    if (item?.owner) {
      setFieldValue('owner', item.owner);
      return;
    }
  };

  const handleSelectOwner = (_:string, val: Partial<ADUser>) => {
    setFieldValue('owner', val);
  };

  const isOwnerDisabled = !!values['workstreamId'];
  return (
    <div className={styles.content}>
      <h5 className={styles.lead}>Great, that helps a lot.</h5>

      <div className={styles.pair}>
        <p className={styles.pair__labelRequired}>To which project workstream does this lesson relate?</p>

        <AutoCompleteWithReduxData
          dispatchFn={fetchWorkstreams}
          label="Workstream"
          labelKey="name"
          name="workstreamId"
          onSelectCallback={setOwnerFromWorkstream}
          params={{
            organizationId: values['organizationId'],
            projectTypeId: values['projectTypeId'],
            lessonOrigin: currentFlow,
          }}
          relatedFields={['processId', 'processName']}
          selector={getWorkstreams}
          onDismountCallback={clearWorkstreams}
          setByLabel
          skipFields={['organizationId', 'projectTypeId']}
        />
      </div>

      <div className={styles.pair}>
        <p className={styles.pair__label}>To which project process does this lesson relate?</p>

        <AutoCompleteWithReduxData
          dispatchFn={fetchProcesses}
          label="Process"
          labelKey="name"
          name="processId"
          onSelectCallback={setOwnerFromProcess}
          params={{
            organizationId: values['organizationId'],
            projectTypeId: values['projectTypeId'],
            workstreamId: values['workstreamId'],
            lessonOrigin: currentFlow,
          }}
          selector={getProcesses}
          onDismountCallback={clearProcesses}
          disabled={!values.workstreamId}
          setByLabel
          skipFields={['organizationId', 'projectTypeId', 'workstreamId']}
        />
      </div>

      <VfDivider />
      {!isOwnerDisabled && <p>OR</p>}

      <div className={styles.pair}>
        <p className={styles['pair__labelRequired--center']}>
          Which person (Action Taker) needs to act on your lesson?
        </p>

        {isOwnerDisabled && values['owner']?.displayName && (
          <p className={styles['pair__value--center']}>{values['owner']?.displayName || 'Not selected'}</p>
        )}
        {(!isOwnerDisabled || (isOwnerDisabled && !values['owner']?.displayName)) && (
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <UserSelector name="owner" onSelect={handleSelectOwner} value={values['owner']} />
            {errors['owner'] && <VfHelperText type="error" text={errors['owner']} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectWorkstream;
