import { FC } from 'react';

import ItemsCount from './itemsCount';
import withExpandable from './withExpandable';
import './_expandable-item.scss';

export const getItemsHint = (usedItems: Array<Object> | string | undefined) => {
  if (Array.isArray(usedItems)) {
    return usedItems.length;
  }

  return usedItems ? 1 : 0;
};

interface ExpandableFilterItemProps {
  hideItemCount?: boolean,
  iconClasses: string,
  title: string,
  usedItems?: Array<Object> | string | undefined;
}

const ExpandableFilterItem: FC<ExpandableFilterItemProps> = ({ hideItemCount, iconClasses, title, usedItems }) => {
  const itemCount = getItemsHint(usedItems);

  return (
    <>
      <span className={iconClasses} />
      <span className="expandable-item__title">
        {title}
        {!hideItemCount && <ItemsCount itemCount={itemCount} />}
      </span>
    </>
  );
};


ExpandableFilterItem.defaultProps = {
  hideItemCount: false,
  usedItems: [],
};

export default withExpandable(ExpandableFilterItem);
