import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FormikProps } from 'formik';

import {
  FilterItemWithSelectionVector,
  IAdvancedFilters,
  IOrganizationOrContainerItem,
} from '../../../models/filterSettings';

import { getGraphGrouping, getGraphOrder, fetchGraphOrdering, fetchGraphGrouping } from '../../../store/dictionary';
import {
  fetchOrganizationsAndContainers,
  fetchProjectTypesAndDepartments,
  getOrganizationsAndContainers,
  getProjectTypesAndDepartments,
} from '../../../store/filterSettings';
import { fetchReportsData, IGraphData } from '../../../store/reports';

import { replaceFieldIdWIthName } from '../../../utils/functions';

import EnhancedFormikDropdown from '../../../components/Forms/Dropdown';
import {
  BU_ORGANIZATION_DEPENDENT_FIELDS,
  FIELDS_TO_REPLACE_ID_WITH_NAME,
  GROUPED_NAMES_CONFIG,
  PROJECT_TYPES_DEPARTMENT_DEPENDENT_FIELDS,
} from '../../../components/LessonsFilters/consts';
import { FLOW_TYPES } from '../../../consts/newLessonForm';
import { useAppDispatch } from '../../../root';
import styles from '../reports.module.scss';

const ReportsFilters: FC<FormikProps<IAdvancedFilters>> = ({ values, setFieldValue, errors, touched }) => {
  const dispatch = useAppDispatch();
  const { data: organizationOptions } = useSelector(getOrganizationsAndContainers);
  const { data: storedProjectTypes } = useSelector(getProjectTypesAndDepartments);
  const [filteredProjectTypes, setFilteredProjectTypes] = useState(storedProjectTypes);

  const setParentsValue = (item: FilterItemWithSelectionVector) => {
    // This check if the item there is a parent or a children thats why selection vector is used to filter (on child)
    if (item.selectionVector?.organizationId === null) {
      setFilteredProjectTypes(storedProjectTypes.filter((project) => project.organizationId === item.id));
    } else {
      setFilteredProjectTypes(
        storedProjectTypes.filter((project) => project.organizationId === item.selectionVector?.organizationId),
      );
    }

    Object.entries(item?.selectionVector || {}).forEach(([parentId, parentValue]: [string, string]) => {
      if (parentValue !== null || parentId === 'origin') {
        setFieldValue(parentId, parentValue);
      }
      if (parentId === 'organizationId') {
        const selectedOrganization: IOrganizationOrContainerItem | undefined = organizationOptions.find(
          (item) => item.id === parentValue,
        );

        setFieldValue('organizationName', selectedOrganization?.name || '');
      } else if (FIELDS_TO_REPLACE_ID_WITH_NAME.includes(parentId)) {
        const nameField = replaceFieldIdWIthName(parentId);

        setFieldValue(nameField, parentValue);
      }
    });
  };

  const setDictionaryData = (fieldName: string, value: string) => {
    setFieldValue(fieldName, value);
  };

  useEffect(() => {
    if (!values?.dataGroupingId && !values?.dataOrderId) {
      setFieldValue('dataGroupingId', 'BY_PROCESS');
      setFieldValue('dataOrderId', 'ALPHABETICAL_ASC');
    }
  }, [setFieldValue, values?.dataGroupingId, values?.dataOrderId]);

  useEffect(() => {
    if (values?.dataGroupingId?.length && values?.dataOrderId?.length) {
      const params: IGraphData = {
        dataGrouping: values.dataGroupingId,
        dataOrder: values.dataOrderId,
        organizationFilter: [values.organizationId],
        businessUnitFilter: [values.departmentId],
        projectTypeFilter: [values.projectTypeId],
        departmentFilter: [values.departmentId],
      };
      dispatch(fetchReportsData(params));
    }
  }, [dispatch, values]);

  const isOrganizationUnit = values.origin === FLOW_TYPES.ORGANIZATIONAL_UNIT;
  const getProjectTypeOrDepartmentFormikName = () => (isOrganizationUnit ? 'departmentId' : 'projectTypeId');

  return (
    <>
      <div className={styles['reports__filter']}>
        <EnhancedFormikDropdown
          clearOption
          dispatchFn={fetchOrganizationsAndContainers}
          fullWidth
          isGroupedOptions
          label="Business Unit / Organization"
          labelKey="name"
          name="organizationId"
          onSelectCallback={setParentsValue}
          relatedFields={BU_ORGANIZATION_DEPENDENT_FIELDS}
          selector={getOrganizationsAndContainers}
          setByLabel
          showMinifiedLabel
        />
        <EnhancedFormikDropdown
          clearOption
          dispatchFn={fetchProjectTypesAndDepartments}
          fullWidth
          groupedNamesConfig={GROUPED_NAMES_CONFIG.projectTypesDeparments}
          isGroupedOptions
          label="Project type / Department"
          labelKey="name"
          name={getProjectTypeOrDepartmentFormikName()}
          onSelectCallback={setParentsValue}
          relatedFields={PROJECT_TYPES_DEPARTMENT_DEPENDENT_FIELDS}
          selector={getProjectTypesAndDepartments}
          setByLabel
          showMinifiedLabel
          storedData={filteredProjectTypes}
        />
      </div>
      <div className={styles['reports__filter']}>
        <EnhancedFormikDropdown
          dispatchFn={fetchGraphGrouping}
          fullWidth
          label="Graph group"
          labelKey="label"
          name="dataGroupingId"
          onSelectCallback={(value) => setDictionaryData('dataGroupingId', value.id)}
          selector={getGraphGrouping}
          setByLabel
          showMinifiedLabel
        />
        <EnhancedFormikDropdown
          dispatchFn={fetchGraphOrdering}
          fullWidth
          label="Graph order"
          labelKey="label"
          name="dataOrderId"
          onSelectCallback={(value) => setDictionaryData('dataOrderId', value.id)}
          selector={getGraphOrder}
          setByLabel
          showMinifiedLabel
        />
      </div>
    </>
  );
};

export default ReportsFilters;
