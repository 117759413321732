import React, { FC, ReactNode } from 'react';

import './vf-helper-text.module.scss';

interface VfHelperTextProps {
  text?: string | ReactNode;
  type: 'link' | 'metadata' | 'success' | 'warning' | 'error';
}

const VfHelperText: FC<VfHelperTextProps> = ({ text, type }) => (
  <div className="helper-text">
    <span className={`vf-utility-typo--${type}`}>{text}</span>
  </div>
);

VfHelperText.defaultProps = {
  text: '',
};

export default VfHelperText;
