import { createSlice } from '@reduxjs/toolkit';

import { ReduxStoreType } from '.';

export interface LoaderState {
  isFetching: boolean;
}

export const sliceName = 'headerLoader';

export const initialState: LoaderState = {
  isFetching: false,
};

export const headerLoaderReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    fetching: (state) => {
      state.isFetching = true;
    },
    fetchFulfilled: (state) => {
      state.isFetching = false;
    },
  },
});

export const { fetching, fetchFulfilled } = headerLoaderReducer.actions;

export const getIsFetching = (state: ReduxStoreType) => state[sliceName].isFetching;

export default headerLoaderReducer.reducer;
