import React, { FC, ReactNode } from 'react';

import classNames from 'classnames';

import styles from './Tooltip.module.scss';

enum PositionProps {
  bottom = 'bottom',
  left = 'left',
  right = 'right',
  softRight = 'soft__right',
  softTop = 'soft__top',
  softBottom = 'soft__bottom',
  top = 'top',
}

interface TooltipProps {
  text: string | ReactNode;
  disabled?: boolean;
  position?: keyof typeof PositionProps;
  className?: string;
}

const Tooltip: FC<TooltipProps> = ({ children, disabled, position = 'top', text, className }) => {
  const tooltipClasses = classNames(
    styles.tooltip__text,
    styles[`tooltip__text--${PositionProps[position]}`],
    className,
  );

  if (!text) {
    return <>{children}</>;
  }

  return (
    <div className={styles.tooltip}>
      {!disabled && <div className={tooltipClasses}>{text || ''}</div>}

      {children}
    </div>
  );
};
Tooltip.defaultProps = {
  disabled: false,
  className: '',
};
export default Tooltip;
