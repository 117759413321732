import { FC, MouseEvent, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FormikErrors, useFormik } from 'formik';

import { generateLessonsReport } from '../../store/lessons';

import { ReportsContext } from '../../context';
import Drawer from '../FlexDrawer';
import DrawerFooter from '../FlexDrawer/Footer';
import DrawerHeader from '../FlexDrawer/Header';
import Icon from '../Icon';
import { VfButton, VfCheckbox } from '../vfDesign';
import { ExpandableItem } from '../vfDesign/vfExpendable';
import Loader from '../vfDesign/vfLoader';
import style from './reports-drawer.module.scss';

interface IReportsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const ReportsDrawer: FC<IReportsDrawerProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { selected, handleRemoveSelected } = useContext(ReportsContext);

  const handleClose = () => {
    onClose();
  };

  const renderLessons = selected?.map((l) => {
    const handleRemove = (e: MouseEvent<HTMLSpanElement>) => {
      handleRemoveSelected?.({ id: l.id, name: l.name });
      e.stopPropagation();
    };

    return (
      <div className={style.lesson} key={l.id}>
        <p>{l.name}</p>
        <Icon color="red" name="trash" className="mr-1" onClick={handleRemove} />
      </div>
    );
  });

  const initialValues = {
    exportActionTakerInfo: false,
    exportCategorizationInfo: false,
    exportStatusDetailsInfo: false,
  };

  const onSubmit = async (values: typeof initialValues) => {
    setIsLoading(true);
    const payload = {
      ...values,
      lessonIds: selected.map((l) => l.id),
    };

    await dispatch(generateLessonsReport(payload));

    setIsLoading(false);

    formik.resetForm();
    handleClose();
  };

  const validate = (values: typeof initialValues): FormikErrors<typeof initialValues> => {
    const errors: FormikErrors<typeof initialValues> = {};

    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const submit = () => {
    formik.handleSubmit();
  };

  const handleExportActionTakerChange = () => {
    formik.setFieldValue('exportActionTakerInfo', !formik.values.exportActionTakerInfo);
  };

  const handleCategorizationInfo = () => {
    formik.setFieldValue('exportCategorizationInfo', !formik.values.exportCategorizationInfo);
  };

  const handleExportStatusDetailsInfo = () => {
    formik.setFieldValue('exportStatusDetailsInfo', !formik.values.exportStatusDetailsInfo);
  };

  const disabled = selected.length === 0 || formik.isSubmitting;

  return (
    <Drawer
      isMounted={isOpen}
      isOpen={isOpen}
      delayTime={400}
      onClose={handleClose}
      side={'right'}
      onOutsideClick={handleClose}
    >
      <DrawerHeader onClose={handleClose} headline="Lesson Report" />

      <div className={style.content}>
        {isLoading && (
          <div className={style['element-centered']}>
            <Loader noLogo notFixed />
          </div>
        )}

        {!isLoading && (
          <div className="mt-2">
            <ExpandableItem
              title={`Lessons (${selected.length})`}
              hideHint
              isEditable={true}
              usedItems={selected}
              defaultExpanded={false}
              showOverflow
            >
              {selected.length === 0 && <p>No lessons selected</p>}
              {renderLessons}
            </ExpandableItem>
            <div className={style.improvement}>
              <div className={style.improvement__row}>
                <VfCheckbox
                  id="exportActionTakerInfo"
                  onChange={handleExportActionTakerChange}
                  name="exportActionTakerInfo"
                  checked={formik.values.exportActionTakerInfo}
                />
                <span>Export action taker info</span>
              </div>
            </div>
            <div className={style.improvement}>
              <div className={style.improvement__row}>
                <VfCheckbox
                  id="exportCategorizationInfo"
                  onChange={handleCategorizationInfo}
                  name="exportCategorizationInfo"
                  checked={formik.values.exportCategorizationInfo}
                />
                <span>Export categorization info</span>
              </div>
            </div>
            <div className={style.improvement}>
              <div className={style.improvement__row}>
                <VfCheckbox
                  id="exportStatusDetailsInfo"
                  onChange={handleExportStatusDetailsInfo}
                  name="exportStatusDetailsInfo"
                  checked={formik.values.exportStatusDetailsInfo}
                />
                <span>Export status detail info</span>
              </div>
            </div>
          </div>
        )}
      </div>

      <DrawerFooter>
        <VfButton outline="secondary" onClick={handleClose}>
          Cancel
        </VfButton>
        <VfButton type="primary" onClick={submit} disabled={disabled}>
          Generate
        </VfButton>
      </DrawerFooter>
    </Drawer>
  );
};

export { ReportsDrawer };
