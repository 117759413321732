import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Tooltip from '../Tooltip';
import { VfButton } from '../vfDesign';
import styles from './HomeTile.module.scss';

export interface HomeTileProps {
  text?: string;
  buttonText?: string;
  link?: string;
  tooltipText: string;
}

const HomeTile: FC<HomeTileProps> = ({ buttonText, children, link = 'home', text, tooltipText }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(link);
  };

  return (
    <div className="vf-col">
      <div className={styles.hometile}>
        <div className={styles.functional}>
          <h3 className={styles.text}>{text}</h3>
          <Tooltip text={tooltipText} position='bottom'>
            <VfButton onClick={handleClick} type="secondary" noArrow className={styles.button__fullsize}>
              {buttonText}
            </VfButton>
          </Tooltip>
        </div>

        <div className={styles.image}>{children}</div>
      </div>
    </div>
  );
};

HomeTile.defaultProps = {
  text: '',
  buttonText: '',
  link: 'home',
};

export default HomeTile;
