import React, { FC, ReactNode } from 'react';

import { upperCamelCaseToSpaced } from '../../utils/functions';

import styles from './_notification_center.module.scss';

interface MessageParameterProps {
  name: string;
  value: ReactNode;
}

const MessageParameter: FC<MessageParameterProps> = ({ name, value }) => {
  return (
    <div className={styles['message-parameter']}>
      <span className={styles['message-parameter__name']}>
        {upperCamelCaseToSpaced(name)}
        :&nbsp;
      </span>

      <span className={styles['message-parameter__value']}>{value}</span>
    </div>
  );
};

export default MessageParameter;
