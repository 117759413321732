import { FC, MouseEvent } from 'react';

import classNames from 'classnames';

export interface IconProps {
  className?: string;
  color?: string;
  name: string;
  withOutline?: boolean;
  onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
}

const Icon: FC<IconProps> = ({ className, color, name, withOutline, onClick }) => {
  const classes = classNames('icon', `icon-${name}`, className, color, {
    outlined: withOutline,
  });

  return <span className={classes} onClick={onClick} />;
};

Icon.defaultProps = {
  className: '',
  withOutline: false,
  onClick: () => {},
};

export default Icon;
