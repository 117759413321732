import { FC } from 'react';
import { Route as RouteRRD } from 'react-router-dom';

import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { reactPlugin } from '../../../services/insights';
import Layout from '../Layout';

interface Props {
  component: FC;
  exact: boolean;
  path: string;
  withLayout?: boolean;
}

const Route: FC<Props> = ({ component, exact, path, withLayout }) => {
  if (withLayout) {
    return (
      <Layout>
        <RouteRRD component={component} exact={exact} path={path} />
      </Layout>
    );
  }

  return <RouteRRD component={component} exact={exact} path={path} />;
};

Route.defaultProps = {
  withLayout: true,
};

export default withAITracking(reactPlugin, Route);
