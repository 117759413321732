import React, { FC, useState, useRef, useEffect, ReactNode } from 'react';

import classNames from 'classnames';
import { fromEvent, Subject, Subscription } from 'rxjs';

import { DelayedWrapper } from '../../utils/hoc/withDelayedUnmount';
import { useOutsideClick } from '../../utils/hooks';

import { EVENT_DEFAULTS, EVENT_TYPES } from '../../events/types';
import Portal from '../Portal';
import { VfDivider, VfIcon } from '../vfDesign';
import './toast.scss';

interface ToastMessageProps {
  children?: ReactNode;
}

const ToastMessage: FC<ToastMessageProps> = ({ children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const subscriptionRef = useRef<Subscription | null>(null);
  const callbackSubject = new Subject();
  const callbackRef = useRef<any>(null); // TODO: handle any

  const [opened, setOpened] = useState(false);
  const [position, setPosition] = useState(EVENT_DEFAULTS.TOAST.POSITION);
  const [headerText, setHeaderText] = useState(EVENT_DEFAULTS.TOAST.HEADER);

  const setClosed = () => {
    setOpened(false);
  };

  const resetConfirmation = () => {
    setClosed();

    setTimeout(() => {
      callbackRef.current = null;
      setHeaderText(EVENT_DEFAULTS.TOAST.HEADER);
      setPosition(EVENT_DEFAULTS.TOAST.POSITION);
    }, 1000);
  };

  const successFunction = (event: any) => {
    // TODO: handle any
    setHeaderText(event.detail.header);
    setPosition(event.detail.position);
    callbackRef.current = event.detail.callback;
    setOpened(true);
  };

  const errorFunction = (error: Error) => {
    throw error;
  };

  useEffect(() => {
    subscriptionRef.current = fromEvent(document, EVENT_TYPES.TOAST_MODAL_EVENT).subscribe(
      successFunction,
      errorFunction,
    );

    callbackSubject.subscribe(() => {
      resetConfirmation();
    });

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }

      callbackSubject.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackSubject]);

  useOutsideClick(ref, () => {
    if (opened) {
      setClosed();
    }
  });

  const classes = classNames('toast__wrapper', {
    open: opened,
    [`toast__wrapper--${position}`]: position,
  });

  return (
    <Portal id="modal-root">
      <DelayedWrapper isMounted={opened}>
        <div className={classes}>
          <div className="toast" ref={ref}>
            <div className="toast__close">
              <VfIcon name="close" asButton onClick={setClosed} />
            </div>

            <p className="toast__header">{headerText}</p>

            <div className="toast__body">{children}</div>

            <VfDivider />
          </div>
        </div>
      </DelayedWrapper>
    </Portal>
  );
};

export default ToastMessage;
