import React, { FC, useState } from 'react';

import { Tag } from '../../../models/tags';

import { sortArrayOfObjects } from '../../../utils/functions';

import { VfChip, VfLoader } from '..';
import VfDivider from '../vfDivider';
import VfInput from '../vfInput';

export interface TagCloudItemProps {
  id: string;
  isSelected?: boolean;
  name: string;
  onSelect: (id: string) => void;
}

export const VfTagCloudItem: FC<TagCloudItemProps> = ({ id, name, onSelect, isSelected = false }) => {
  const color = isSelected ? 'selected' : undefined;
  const icon = isSelected ? 'progress--done' : undefined;

  return <VfChip id={id} label={name} onClick={onSelect} color={color} unremovable icon={icon} />;
};

export interface TagCloudItemsGroupProps {
  isSelected?: boolean;
  onSelect: (id: string) => void;
  searchValue?: string;
  tags: Tag[];
}

export const TagCloudItemsGroup: FC<TagCloudItemsGroupProps> = ({
  isSelected = false,
  onSelect,
  searchValue = '',
  tags = [],
}) => {
  const renderTags = sortArrayOfObjects(tags, 'name')
    .filter((tag: Tag) => tag.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
    .map((tag: Tag) => (
      <VfTagCloudItem key={tag.id} id={tag.id} name={tag.name} onSelect={onSelect} isSelected={isSelected} />
    ));

  return <div className="vf-chips">{renderTags}</div>;
};

export interface IVfTagCloudProps {
  isLoading?: boolean;
  onTagsChange: (tags: Tag[]) => void;
  readonly?: boolean;
  tags: Tag[];
  usedTags: Tag[];
}

const VfTagCloud: FC<IVfTagCloudProps> = ({
  isLoading = false,
  onTagsChange,
  readonly = false,
  tags = [],
  usedTags = [],
}) => {
  const [searchValue, setSearchValue] = useState('');
  const usedTagsIds = usedTags.map((tag) => tag.id);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setSearchValue(value);
  };

  const handleSelect = (id: string) => {
    if (readonly) {
      return;
    }

    if (usedTagsIds.includes(id)) {
      const updatedTags = usedTags.filter((tag) => tag.id !== id);

      onTagsChange(updatedTags);
    } else {
      const newUsedTag = tags.find((tag) => tag.id === id);

      if (newUsedTag) {
        onTagsChange([...usedTags, newUsedTag]);
      }
    }
  };

  const freeUseTags = tags.filter((tag) => !usedTagsIds.includes(tag.id));

  return (
    <div>
      <VfInput
        icon="search"
        id="searchInput"
        onChange={onSearch}
        placeholder="Search tag by name"
        rounded
        value={searchValue}
      />
      <VfDivider />
      {isLoading && <VfLoader />}

      {!isLoading && (
        <>
          <TagCloudItemsGroup tags={usedTags} searchValue={searchValue} onSelect={handleSelect} isSelected />
          <TagCloudItemsGroup tags={freeUseTags} searchValue={searchValue} onSelect={handleSelect} />
        </>
      )}
    </div>
  );
};

export default VfTagCloud;
