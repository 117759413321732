import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Canceler } from 'axios';

import { User } from '../models/user';

import { fetchFulfilled, fetching } from '../store/headerLoader';

import config from '../config';
import { RootStoreType } from '../root';
import { getToken } from './auth';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export class Api {
  protected readonly axios: AxiosInstance;
  protected store: RootStoreType | null;

  public constructor() {
    this.store = null;
    this.axios = axios.create({
      baseURL: config.API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public setStore(store: RootStoreType) {
    this.store = store;
  }

  public async getUser(): Promise<AxiosResponse<User>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<User>> => {
      const response = await this.axios.get<User>('/users/me', { headers: { Authorization: `Bearer ${token}` } });

      return response;
    };

    try {
      const token = await getToken();
      const response = await apiCall(token);

      return response;
    } catch (error) {
      return this.handleError<User>(error as AxiosError, apiCall, true);
    }
  }

  public async get<T>(
    route: string,
    params?: object,
    silent: boolean = false,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    if (cancel !== undefined) cancel();
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const response = await this.axios.get<T>(route, {
        headers: { Authorization: `Bearer ${token}` },
        params,
        cancelToken: new CancelToken((c) => (cancel = c)),
        ...config,
      });

      return response;
    };

    try {
      !silent && this.store?.dispatch(fetching());
      const token = await getToken();
      const response = await apiCall(token);
      this.store?.dispatch(fetchFulfilled());
      return response;
    } catch (error) {
      this.store?.dispatch(fetchFulfilled());
      return this.handleError<T>(error as AxiosError, apiCall);
    }
  }

  public async getFile<T = BlobPart>(route: string, params?: object): Promise<AxiosResponse<T>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const response = await this.axios.get<T>(route, {
        headers: { Authorization: `Bearer ${token}` },
        params,
        responseType: 'arraybuffer',
      });

      return response;
    };

    try {
      this.store?.dispatch(fetching());
      const token = await getToken();
      const response = await apiCall(token);
      this.store?.dispatch(fetchFulfilled());
      return response;
    } catch (error) {
      this.store?.dispatch(fetchFulfilled());
      return this.handleError<T>(error as AxiosError, apiCall);
    }
  }

  public async post<T>(route: string, data: any, params?: object): Promise<AxiosResponse<T>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const response = await this.axios.post<T>(route, data, { headers: { Authorization: `Bearer ${token}` }, params });

      return response;
    };

    try {
      this.store?.dispatch(fetching());
      const token = await getToken();
      const response = await apiCall(token);
      this.store?.dispatch(fetchFulfilled());

      return response;
    } catch (error) {
      this.store?.dispatch(fetchFulfilled());
      return this.handleError<T>(error as AxiosError, apiCall);
    }
  }

  public async patch<T>(route: string, data: any, params?: object, asJsonPath?: boolean): Promise<AxiosResponse<T>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const headers = { Authorization: `Bearer ${token}` };

      const patchHeaders = asJsonPath
        ? {
            ...headers,
            'Content-Type': 'application/json-patch+json',
          }
        : headers;

      const response = await this.axios.patch<T>(route, data, {
        headers: patchHeaders,
        params,
      });

      return response;
    };

    try {
      this.store?.dispatch(fetching());
      const token = await getToken();
      const response = await apiCall(token);
      this.store?.dispatch(fetchFulfilled());

      return response;
    } catch (error) {
      this.store?.dispatch(fetchFulfilled());
      return this.handleError<T>(error as AxiosError, apiCall);
    }
  }

  public async put<T>(route: string, data: any, params?: object): Promise<AxiosResponse<T>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const response = await this.axios.put<T>(route, data, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });

      return response;
    };

    try {
      this.store?.dispatch(fetching());
      const token = await getToken();
      const response = await apiCall(token);
      this.store?.dispatch(fetchFulfilled());

      return response;
    } catch (error) {
      this.store?.dispatch(fetchFulfilled());
      return this.handleError<T>(error as AxiosError, apiCall);
    }
  }

  public async delete<T>(route: string, params?: object): Promise<AxiosResponse<T>> {
    const apiCall = async (token: string | undefined): Promise<AxiosResponse<T>> => {
      const response = await this.axios.delete<T>(route, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });

      return response;
    };

    try {
      this.store?.dispatch(fetching());
      const token = await getToken();
      const response = await apiCall(token);
      this.store?.dispatch(fetchFulfilled());

      return response;
    } catch (error) {
      this.store?.dispatch(fetchFulfilled());
      return this.handleError<T>(error as AxiosError, apiCall);
    }
  }

  private async handle401<T>(callback: (token?: string) => Promise<AxiosResponse<T>>, login: boolean) {
    if (login) {
      try {
        const token = await getToken();
        return callback(token);
      } catch (error) {
        throw error;
      }
    }
    try {
      const token = await getToken();
      return callback(token);
    } catch (error) {
      throw error;
    }
  }

  handleError<T>(error: AxiosError<T>, callback: (token?: string) => Promise<AxiosResponse<T>>, login = false) {
    if (error?.message?.includes('401')) {
      return this.handle401<T>(callback, login);
    }

    throw error;
  }
}

export default new Api();
