/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { useBoolean, useOutsideClick } from '../../../utils/hooks';

import { VfIcon } from '..';
import { StructureEntities } from '../../Forms/Dropdown';
import { checkIfNeedGrouping } from '../vfDropdown/helpers';
import VfRegularOptions from '../vfDropdown/vfRegularOptions';
import VfHelperText from '../vfHelperText';
import InputSpinner from '../vfInputSpinner';
import styles from './autocomplete.module.scss';

const MIN_CHAR_TRIGGER = 3;

export interface Option {
  label: string;
  value: string | boolean;
}

export interface AutocompleteProps {
  className?: string;
  disableClearButton?: boolean;
  disabled?: boolean;
  error?: string | boolean;
  id: string;
  initFocus?: boolean;
  initialValue?: string;
  isGroupedOptions?: boolean;
  isLoading?: boolean;
  label?: string;
  name: string;
  noMaxChar?: boolean;
  onBlur?: (name: string) => void;
  onChange: (value: string) => void;
  onClick: (name: string, value: any, clear?: boolean) => void;
  options: Option[];
  preserveValue: boolean;
  storedData?: StructureEntities[];
  value?: Option;
}

const VfAutoComplete: FC<AutocompleteProps> = ({
  className,
  disableClearButton,
  disabled,
  error,
  id,
  initFocus,
  initialValue,
  isGroupedOptions,
  isLoading,
  label,
  name,
  noMaxChar,
  onBlur,
  onChange,
  onClick,
  options,
  preserveValue,
  storedData = [],
  value,
}) => {
  const [text, setText] = useState<string>(initialValue!);
  const [open, setOpen] = useState<boolean>(false);
  const [openToTop, setOpenToTop, setOpenToBottom] = useBoolean(false);
  const [focused, setFocused] = useState<boolean>(initFocus!);
  const [intError, setIntError] = useState<string>('');

  const dropdownIsOpened = open && !!options.length;

  useEffect(() => {
    if (focused && options.length) {
      setOpen(true);
    }
  }, [focused, options]);

  const toggleShowOptions = () => {
    if (disabled) return;

    setOpen(!open);
  };

  const closeOptions = () => {
    setOpen(false);

    if (value?.label) {
      setText(value.label);
    }
  };

  const inputClasses = classNames(
    styles['vf-input'],
    styles['vf-input--indent'],
    {
      [styles['vf-input--data']]: !!text,
      [styles['vf-input--disabled']]: disabled,
      [styles['vf-input--error']]: !!error,
      [styles['vf-input--opened']]: dropdownIsOpened && !error && !openToTop,
      [styles['vf-input--opened-up']]: dropdownIsOpened && !error && openToTop,
    },
    className,
  );

  const expandableIconClasses = classNames(styles['vf-input__expandable-icon'], {
    [styles['vf-input__expandable-icon--opened']]: dropdownIsOpened,
  });

  const autoCompleteDropdownClasses = classNames(styles['autocomplete__dropdown'], {
    [styles['autocomplete__dropdown--open-up']]: openToTop,
  });

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

  const autoCompleteOptionsRef = useCallback((node) => {
    if (node !== null) {
      const windowHeight = window.innerHeight;
      const menuHeight = Math.min(500, options.length * 35);
      const instOffsetWithMenu = node.getBoundingClientRect().bottom + menuHeight;

      if (instOffsetWithMenu >= windowHeight) {
        setOpenToTop();
        return;
      }
      setOpenToBottom();
    }
  }, []);

  useEffect(() => {
    if (value && value.label) {
      setText(value.label);
      return;
    }
    if (initialValue) {
      setText(initialValue);
      return;
    }
    setText('');
  }, [value]);

  const handleClick = (val: string) => {
    const selected = options.find((option) => option.value === val);

    onClick(name, selected);
    if (preserveValue && selected) {
      setText(selected.label);
    } else {
      setText('');
      onChange('');
    }
    toggleShowOptions();
  };

  const handleOnChange = useCallback(
    (e) => {
      const { value: val } = e.target;

      setFocused(true);
      setText(val);

      if (noMaxChar) {
        onChange(val);

        return;
      }

      if (val.length >= MIN_CHAR_TRIGGER || val.length === 0) {
        onChange(val);
        setIntError('');
      } else {
        setIntError('Minimum 3 characters required');
      }
    },
    [onChange],
  );

  const clearValue = () => {
    setText('');
    onChange('');
    onClick(name, {}, true);
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur(name);
    }

    setFocused(false);
  };

  useOutsideClick(ref, () => {
    closeOptions();
  });

  const isGrouped = checkIfNeedGrouping(options, isGroupedOptions);
  const showClearIcon = !disableClearButton && text && !isLoading;

  return (
    <>
      <div className={styles['autocomplete']} ref={ref}>
        <div className={styles['vf-input-container']} onClick={toggleShowOptions}>
          <input
            type="text"
            autoComplete="off"
            id={id}
            name={name}
            className={inputClasses}
            onChange={handleOnChange}
            onBlur={handleBlur}
            value={text}
            disabled={disabled}
          />

          <label htmlFor={id}>{label}</label>

          {showClearIcon && <VfIcon name="close" className={styles['vf-input__clear-icon']} onClick={clearValue} />}

          {isLoading && <InputSpinner classes={styles['vf-input__spinner']} />}

          {!isLoading && <span className={expandableIconClasses} />}
        </div>

        {dropdownIsOpened && (
          <div className={autoCompleteDropdownClasses} ref={autoCompleteOptionsRef}>
            <VfRegularOptions options={options} handleClick={handleClick} isGroupedOptions={isGrouped} />
          </div>
        )}

        {error && <VfHelperText type="error" text={error} />}
      </div>
      {intError && focused && (
        <div>
          <VfHelperText type="error" text={intError} />
        </div>
      )}
    </>
  );
};

VfAutoComplete.defaultProps = {
  className: '',
  disableClearButton: false,
  disabled: false,
  error: undefined,
  initFocus: false,
  initialValue: '',
  isGroupedOptions: false,
  isLoading: false,
  label: 'Search',
  noMaxChar: false,
  onBlur: () => {},
  onClick: () => {},
  options: [],
  preserveValue: true,
  value: { label: '', value: '' },
};

export default VfAutoComplete;
