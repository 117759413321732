import { FC } from 'react';

import classNames from 'classnames';

import './_vf-divider.scss';

interface VfDividerProps {
  noMargin?: boolean;
}

const VfDivider: FC<VfDividerProps> = ({ noMargin }) => {
  const className = classNames('divider', {
    'divider--nomargin': noMargin,
  });

  return <div className={className} />;
};

VfDivider.defaultProps = {
  noMargin: false,
};

export default VfDivider;
