import { FC, ReactNode } from 'react';
import { Router } from 'react-router-dom';

import { history } from '../../services/insights';

interface Props {
  children: ReactNode;
}

const RouterWrapper: FC<Props> = ({ children }) => <Router history={history}>{children}</Router>;

export default RouterWrapper;
