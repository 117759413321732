import { FC, useEffect } from 'react';

import { ADUser } from '../../models/user';

import { useFindAdUser } from '../../utils/hooks';
import useDebouncedValue from '../../utils/hooks/useDebouncedValue';

import VfAutoComplete, { Option } from '../vfDesign/vfAutocomplete';

export const stubValue = { label: '', value: '' };

interface UserSelectorProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  name: string;
  onSelect: (name: string, item: Partial<ADUser>) => void;
  placeholder?: string;
  value?: Partial<ADUser>;
}

const UserSelector: FC<UserSelectorProps> = ({ className, disabled, name, onSelect, placeholder, value, error }) => {
  const [users, isLoading, searchForUser] = useFindAdUser();
  const [queryValue, setQuery] = useDebouncedValue(300);

  useEffect(() => {
    searchForUser(queryValue);
  }, [searchForUser, queryValue]);

  const onChange = (value: string) => {
    setQuery(value);
  };

  const onClick = (name: string, item: Option, clear: boolean = false) => {
    if (!item) {
      return;
    }

    if (clear) {
      onSelect(name, {
        id: '',
      });
      setQuery('');
      return;
    }

    const ADUser = users.find((user) => user.id === item.value);

    if (ADUser) {
      onSelect(name, ADUser);
      setQuery('');
    }
  };

  const options = users.map((user) => ({
    label: user.displayName,
    value: user.id,
  }));

  const currentValue = {
    label: value?.displayName || '',
    value: value?.id || '',
  };

  return (
    <VfAutoComplete
      id={`user-selector-${name}`}
      isLoading={isLoading}
      label={placeholder}
      name={name}
      error={error}
      onBlur={() => {}}
      onChange={onChange}
      onClick={onClick}
      options={options}
      preserveValue={false}
      className={className}
      value={currentValue.value ? currentValue : stubValue}
      initFocus
      disabled={disabled}
    />
  );
};

UserSelector.defaultProps = {
  className: '',
  disabled: false,
  placeholder: 'Search for users',
};

export default UserSelector;
