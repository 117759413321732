import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useFormikContext } from 'formik';

import { Tag } from '../../models/tags';

import { fetchTagsByOrganizationId, getTagsByOrganizationId } from '../../store/tags';

import { useAppDispatch } from '../../root';
import { VfTagCloud } from '../vfDesign';

const TagFilters: FC = () => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue } = useFormikContext<any>(); // TODO: handle any
  const tags = useSelector(getTagsByOrganizationId)(values.organizationId);

  useEffect(() => {
    dispatch(fetchTagsByOrganizationId(values.organizationId));
  }, [values.organizationId, dispatch]);

  const handleChange = (usedTags: Tag[]) => {
    setFieldValue('tags', usedTags);
  };

  return <VfTagCloud onTagsChange={handleChange} usedTags={values.tags} tags={tags} />;
};

export default TagFilters;
