import { FC } from 'react';

import { useFormikContext } from 'formik';

import { Textarea } from '../../Forms';
import styles from '../Shared.module.scss';
import { ProjectLessonType } from '../types';

const getHeaderText = (
  departmentName: string | undefined,
  projectName: string | undefined,
  operatingSiteName: string | undefined,
) => {
  if (departmentName) {
    return `Describe what happened in ${departmentName} department:`;
  }

  if (operatingSiteName) {
    return `Describe what happened in ${operatingSiteName} site:`;
  }

  if (projectName) {
    return `Describe what happened in ${projectName} project:`;
  }

  return `Describe what happened in current project`;
};

const placeholder = `
Explain the event? 
What was the root cause?
What was the impact?
Which roles were involved?
`;

const ProjectDescription: FC = () => {
  const {
    values: { projectName, departmentName, operatingSiteName },
  } = useFormikContext<ProjectLessonType>();

  const headerText = getHeaderText(departmentName, projectName, operatingSiteName);

  return (
    <div className={styles.content}>
      <h5 className={styles.lead}>{headerText}</h5>

      <div>
        <Textarea
          name="description"
          label="&nbsp;&nbsp;My description *"
          placeholder={placeholder}
          textareaStyles={styles.textarea}
        />

        <p className={styles.helper}>Max. 2000 characters</p>
      </div>
    </div>
  );
};

export default ProjectDescription;
