import { FC } from 'react';

import classNames from 'classnames';

import styles from './mentions.module.scss';

export interface MentionItemProps {
  isActive?: boolean;
  name: string;
  value: string;
  onClick: (value: string, name: string) => void;
}

const MentionItem: FC<MentionItemProps> = ({ name, value, isActive, onClick }) => {
  const avatar = name
    .split(' ')
    .slice(0, 2)
    .map((arg) => arg[0])
    .join('');

  const itemClassNames = classNames(styles.mention__item, {
    [styles['mention__item--active']]: isActive,
  });

  const handleClick = () => {
    onClick(value, name);
  };

  return (
    <div className={itemClassNames} onClick={handleClick}>
      <div className={styles["mention__item-avatar"]}>{avatar}</div>
      {name}
    </div>
  );
};

MentionItem.defaultProps = {
  isActive: false,
};

export default MentionItem;
