import { useEffect, FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { Formik } from 'formik';

import { fetchDictionaryStates, getDictionaryStates } from '../../../store/dictionary';
import { fetchOrganizationsAndContainers, getOrganizationsAndContainers } from '../../../store/filterSettings';
import { getLessonsWithNoAT } from '../../../store/lessonsWithNoActionTaker';

import { unique } from '../../../utils/functions';

import EnhancedFormikDropdown from '../../../components/Forms/Dropdown';
import { FILTER_LABELS } from '../../../components/LessonsFilters/consts';
import { stubValue } from '../../../components/UserSelector';
import { VfAutoComplete } from '../../../components/vfDesign';
import { Option } from '../../../components/vfDesign/vfAutocomplete';
import { VfMultipleDropdown } from '../../../components/vfDesign/vfMulitpleDropdown';
import { useAppDispatch } from '../../../root';
import styles from './actionTakers.module.scss';
import { IFilterProps, initialFilters } from './consts';

export interface IInitialValues extends IFilterProps {
  actionTakerName: string;
  organizationName: string;
}

const initialValues: IInitialValues = {
  ...initialFilters,
  actionTakerName: '',
  organizationName: '',
};

export interface IActionTakersFiltersProps {
  setFilters: (filters: IFilterProps) => void;
}

const ActionTakersFilters: FC<IActionTakersFiltersProps> = ({ setFilters }) => {
  const dispatch = useAppDispatch();
  const stateList = useSelector(getDictionaryStates);
  const [searchUserText, setSearchUserText] = useState('');
  const { lessons, isLoading } = useSelector(getLessonsWithNoAT);

  useEffect(() => {
    dispatch(fetchDictionaryStates());
  }, [dispatch]);

  const onSubmit = (values: IInitialValues) => {
    setFilters({
      actionTakerId: values.actionTakerId,
      organizationId: values.organizationId,
      states: values.states,
    });
  };

  const stateOptions = stateList.data?.map((item) => {
    return { label: item.label, value: item.id };
  });

  const actionTakers =
    lessons?.map(({ actionTaker }) => ({
      label: actionTaker.displayName,
      value: actionTaker.id,
    })) || [];

  const actionTakerOptions = unique(actionTakers, 'value').filter((item) =>
    item.label?.toLowerCase().includes(searchUserText.toLowerCase()),
  );

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
      {(filterForm) => {
        const { handleSubmit, setFieldValue, values, setFieldTouched, submitForm } = filterForm;

        const userSelection = (field: string, user: Option): void => {
          const name = user?.label || '';
          setFieldValue('actionTakerName', name);
          setFieldValue('actionTakerId', user?.value || '');
          setSearchUserText(name);

          submitForm();
        };

        const onOrganizationChange = () => {
          submitForm();
        };

        const onStatusChange = (name: string, newStates: string[]) => {
          setFieldValue(name, newStates);
          submitForm();
        };

        const searchedActionTaker = {
          label: values.actionTakerName,
          value: values.actionTakerId,
        };

        return (
          <form onSubmit={handleSubmit}>
            <div className={`${styles.filters} mb-2 px-2`}>
              <EnhancedFormikDropdown
                clearOption
                dispatchFn={fetchOrganizationsAndContainers}
                fullWidth
                isGroupedOptions
                label={FILTER_LABELS.organizationId}
                labelKey="name"
                name="organizationId"
                onSelectCallback={onOrganizationChange}
                selector={getOrganizationsAndContainers}
                setByLabel
                showMinifiedLabel
              />
              <VfMultipleDropdown
                id="states"
                label={FILTER_LABELS.states}
                name="states"
                onBlur={() => setFieldTouched('states', true)}
                onChange={onStatusChange}
                options={stateOptions}
                value={values.states}
                className="mt-2"
              />
              <div className="mt-2">
                <VfAutoComplete
                  id={`user-selector`}
                  label="Select Action Taker"
                  name="actionTaker"
                  onChange={setSearchUserText}
                  onClick={userSelection}
                  options={actionTakerOptions as Option[]}
                  preserveValue={false}
                  isLoading={isLoading}
                  value={searchedActionTaker.value ? searchedActionTaker : stubValue}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ActionTakersFilters;
