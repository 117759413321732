// MSAL imports
import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  EventType,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';

import config from '../config';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginScopes: Array<string> = config.MSAL_SCOPES;

const msalConfig: Configuration = {
  auth: {
    clientId: config.MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${config.MSAL_TENANT}`,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    iframeHashTimeout: 30000, // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2213
  },
};

const loginRequest: RedirectRequest = {
  scopes: loginScopes,
};

const silentRequest: SilentRequest = {
  ...loginRequest,
};

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as AuthenticationResult).account) {
    const account = (event.payload as AuthenticationResult).account;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance
  .handleRedirectPromise()
  .then(() => {
    // Check if user signed in
    const account = msalInstance.getActiveAccount();
    if (!account) {
      // redirect anonymous user to login page
      msalInstance.loginRedirect();
    }
  })
  .catch((err) => {
    // TODO: Handle errors
    console.log(err);
  });

const getToken = async () => {
  try {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      msalInstance.loginRedirect();
    }

    const authResult = await msalInstance.acquireTokenSilent({ ...silentRequest, account: account as AccountInfo });

    return authResult.accessToken;
  } catch (err) {
    login();
  }
};

const login = () => {
  try {
    msalInstance.loginRedirect(loginRequest);
  } catch (err) {
    console.error(err);
  }
};

export { msalInstance, msalConfig, getToken, login, loginRequest, silentRequest };
