import { FC, MouseEvent } from 'react';

import classNames from 'classnames';

import styles from './VfButton.module.scss';

interface VFButtonProps {
  className?: string;
  deleteButton?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  htmlType?: 'button' | 'reset' | 'submit';
  id?: string;
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  outline?: 'dark' | 'secondary' | '';
  size?: 'small' | 'normal' | 'large';
  type?: 'primary' | 'secondary' | 'tertiary' | '';
  noArrow?: boolean;
}

const VfButton: FC<VFButtonProps> = ({
  children,
  className,
  deleteButton,
  disabled,
  fullWidth,
  htmlType,
  onClick,
  outline,
  size,
  type,
  noArrow,
  ...rest
}) => {
  const buttonClasses = classNames(
    'vf-btn',
    styles['vf-btn'],
    {
      [styles['vf-btn--sm']]: size === 'small',
      'vf-btn--lg': size === 'large',
      [`vf-btn--${type}`]: !!type,
      [`vf-btn--outline-${outline}`]: !!outline,
      'vf-btn--full-width': fullWidth,
      [styles['vf-btn--no-arrow']]: noArrow,
      [styles['vf-btn--delete']]: deleteButton,
    },
    className,
  );

  return (
    <button {...rest} type={htmlType} onClick={onClick} disabled={disabled} className={buttonClasses}>
      {children}
    </button>
  );
};

VfButton.defaultProps = {
  className: '',
  deleteButton: false,
  disabled: false,
  fullWidth: false,
  htmlType: 'button',
  onClick: () => {},
  outline: '',
  size: 'normal',
  type: '',
  noArrow: false,
};

export default VfButton;
