export enum InfoElementNames {
  ADD_LESSON = 'add-lesson',
  ADD_ORGANIZATIONAL_LESSON = 'add-organizational-lesson',
  ADD_PROJECT_LESSON = 'add-project-lesson',
  ADD_SITE_LESSON = 'add-site-lesson',
  ALL_LESSONS = 'all-lessons',
  MY_LESSONS = 'my-lessons',
  OPERATING_SITE = 'lesson-operating-site',
  ORGANIZATIONAL_UNIT = 'lesson-organizational-unit',
  PROJECT = 'lesson-project',
  TAKE_ACTION = 'take-action',
}
