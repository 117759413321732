import React, { FC, MouseEvent, useRef, useState } from 'react';

import classNames from 'classnames';

import { useOutsideClick } from '../../../utils/hooks';

import VfHelperText from '../vfHelperText';
import VfInputSpinner from '../vfInputSpinner';
import VfOutlineLabel from '../vfOutlineLabel';
import styles from './vf-multiple-dropdown.module.scss';

interface IVfOption {
  label: string;
  value: string;
}

interface IVfCheckboxOptions {
  handleClick: (is: string) => void;
  options: IVfOption[];
  selectedOptions: string[];
  withNoOption?: boolean;
}

export const NO_OPTION = 'NO_OPTION';

export const VfCheckboxOptions: FC<IVfCheckboxOptions> = ({
  handleClick,
  options,
  selectedOptions,
  withNoOption = false,
}) => {
  const renderOptions = options?.map(({ label, value }, index) => {
    const isDisabled = Boolean(withNoOption && value !== NO_OPTION && selectedOptions.includes(NO_OPTION));
    const onClick = (e: MouseEvent<HTMLDivElement>) => {
      if (isDisabled) return;

      e.stopPropagation();
      const {
        currentTarget: { id },
      } = e;

      handleClick(id);
    };

    return (
      <div key={`option-${value}`} className="vf-dropdown-list-item" id={value} onClick={onClick}>
        <input
          id={value}
          type="checkbox"
          name="vf-dropdown-group"
          checked={selectedOptions.includes(value)}
          value={value}
          onChange={() => {}}
          disabled={isDisabled}
        />
        <label className={styles['vf-dropdown-option']}>{label}</label>
      </div>
    );
  });

  return <>{renderOptions}</>;
};

interface IVfDropdown {
  className?: string;
  disabled?: boolean;
  error?: string;
  id: string;
  isLoading?: boolean;
  label?: string;
  name: string;
  onBlur: (name?: string) => void;
  onChange: (name: string, newValue: string[]) => void;
  onlyCheckboxes?: boolean;
  options: IVfOption[];
  shrinkLabel?: boolean;
  value: string[];
  withNoOption?: boolean;
}

const VfMultipleDropdown: FC<IVfDropdown> = ({
  className = '',
  disabled = false,
  error = '',
  id,
  isLoading = false,
  label = 'Select Options',
  name,
  onBlur,
  onChange,
  onlyCheckboxes = false,
  options,
  shrinkLabel = true,
  value,
  withNoOption = false,
}) => {
  const [open, setOpen] = useState(onlyCheckboxes);
  const toggleShowOptions = () => {
    if (disabled) return;
    setOpen(!open);
  };
  const closeOptions = () => setOpen(false);

  const ref = useRef<HTMLDivElement>(null);

  const classes = classNames(
    'vf-dropdown',
    'w-100',
    {
      on: (open && options.length) || onlyCheckboxes,
      'error-layout': !!error,
      disabled,
    },
    className,
  );

  const listClasses = classNames('vf-dropdown-list', {
    [styles['vf-dropdown-list--only-checkboxes']]: onlyCheckboxes,
    [styles['vf-dropdown-list--disabled']]: disabled,
  });

  const handleBlur = () => {
    if (!error && open) {
      onBlur(name);
    }
  };

  useOutsideClick(ref, () => {
    if (open) {
      closeOptions();
    }
  });

  const handleCheck = (val: string) => {
    onBlur();

    if (withNoOption && val === NO_OPTION) {
      const newValue = value.includes(val) ? [] : [val];
      onChange(name, newValue);
    } else {
      const newValue = value.includes(val) ? value.filter((item) => item !== val) : [...value, val];

      onChange(name, newValue);
    }
  };

  const triggerOpenDropdown = () => {
    handleBlur();
    toggleShowOptions();
  };

  const selectedOption = () => {
    if (!value.length) {
      return label;
    }

    if (value.length === 1) {
      const selected = options.find((option) => option.value === value[0]);
      return selected && selected.label ? selected.label : label;
    }

    if (value.length < options.length) {
      return `${value.length} Selected`;
    }

    if (value.length === options.length) {
      return 'All Selected';
    }
    return label;
  };

  return (
    <>
      <div id={id} className={classes} data-control={`vf-dropdown-data${disabled ? '-disabled' : ''}`} ref={ref}>
        {shrinkLabel && <VfOutlineLabel show label={label} disabled={disabled} />}
        {!onlyCheckboxes && (
          <label className="vf-dropdown-label" onClick={triggerOpenDropdown}>
            {selectedOption()}
            {isLoading && <VfInputSpinner classes="vf-dropdown__spinner" />}
          </label>
        )}

        <div className={listClasses}>
          <VfCheckboxOptions
            handleClick={handleCheck}
            options={options}
            selectedOptions={value}
            withNoOption={withNoOption}
          />
        </div>
      </div>
      {error && <VfHelperText type="error" text={error} />}
    </>
  );
};

export { VfMultipleDropdown };
