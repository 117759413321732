import { createContext, FC, ReactNode, useEffect, useRef, useState } from 'react';

import { fromEvent, Subscription } from 'rxjs';

import { EventBase, EVENT_TYPES } from '../types';

export interface ContextState {
  dispatchEventByType?: (type: string, panicHandler: () => void) => void;
  dispatchEventByID?: (id: string, panicHandler: () => void) => void;
  removeEventFromQueueByID?: (id: string) => void;
  removeEventFromQueueByType?: (type: string) => void;
}

export const EventSystemContext = createContext<ContextState>({});

interface ProviderProps {
  children: ReactNode;
}

const EventSystemProvider: FC<ProviderProps> = ({ children }) => {
  const subscriptionRef = useRef<Subscription | null>(null);
  const clearSubscriptionRef = useRef<Subscription | null>(null);

  const [queuedEvents, setQueuedEvents] = useState<EventBase[]>([]);

  const successHandler = (event: any) => {
    // TODO: handle any
    if (event.detail.timeout) {
      setTimeout(() => {
        document.dispatchEvent(event.detail.event);
      }, event.detail.timeout);
    }

    setQueuedEvents([
      ...queuedEvents,
      {
        id: event.detail.id,
        event: event.detail.event,
        type: event.detail.event.type,
      },
    ]);
  };

  // Removes Event from queue
  const removeEventFromQueueByType = (type: string) => {
    const events = queuedEvents.filter((e) => e.type === type);

    setQueuedEvents(events);
  };

  const removeEventFromQueueByID = (id: string) => {
    const events = queuedEvents.filter((e) => e.id === id);

    setQueuedEvents(events);
  };

  const successClearHandler = (event: any) => {
    removeEventFromQueueByID(event.detail.id);
  };

  const errorHandler = (error: Error) => {
    throw error;
  };

  useEffect(() => {
    subscriptionRef.current = fromEvent(document, EVENT_TYPES.QUEUE).subscribe(successHandler, errorHandler);

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearSubscriptionRef.current = fromEvent(document, EVENT_TYPES.CLEAR_FROM_QUEUE).subscribe(
      successClearHandler,
      errorHandler,
    );

    return () => {
      if (subscriptionRef.current) {
        clearSubscriptionRef?.current?.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Dispatches Queued Event and remove it from queue or fire panic handler.
  const dispatchEventByType = (type: string, panicHandler: () => void) => {
    const event = queuedEvents.find((q) => q.type === type);

    if (!event) {
      panicHandler();

      return;
    }

    document.dispatchEvent(event.event);
    removeEventFromQueueByType(type);
  };

  const dispatchEventByID = (id: string, panicHandler: () => void) => {
    const event = queuedEvents.find((q) => q.id === id);

    if (!event) {
      panicHandler();

      return;
    }

    document.dispatchEvent(event.event);
    removeEventFromQueueByID(id);
  };

  return (
    <EventSystemContext.Provider
      value={{
        dispatchEventByID,
        dispatchEventByType,
        removeEventFromQueueByID,
        removeEventFromQueueByType,
      }}
    >
      {children}
    </EventSystemContext.Provider>
  );
};

export default EventSystemProvider;
