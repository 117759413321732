import { FC } from 'react';

import classNames from 'classnames';

const NavTypeItems = {
  text: {
    last: 'Last',
    next: 'Next',
    prev: 'Prev',
    first: 'First',
  },
  icons: {
    last: <span aria-hidden="true">&raquo;</span>,
    next: <span aria-hidden="true">&rsaquo;</span>,
    prev: <span aria-hidden="true">&lsaquo;</span>,
    first: <span aria-hidden="true">&laquo;</span>,
  },
};

const MAX_PAGES_IN_PAGINATION_ROW = 10;
const HALF_OF_MAX_AVAILABLE = Math.floor(MAX_PAGES_IN_PAGINATION_ROW / 2);
const ACTIVE_PAGE_ON_MAX_AVAILABLE = HALF_OF_MAX_AVAILABLE + 1;
const LAST_AVAILABLE_PAGES = MAX_PAGES_IN_PAGINATION_ROW - 1;
const AVAILABLE_PREV_PAGES = ACTIVE_PAGE_ON_MAX_AVAILABLE - 1;
const AVAILABLE_NEXT_PAGES = ACTIVE_PAGE_ON_MAX_AVAILABLE - (HALF_OF_MAX_AVAILABLE % 2 === 0 ? 1 : 2);

export const getPages = (totalPages: number, currentPage: number): number[] => {
  let startPage: number;
  let endPage: number;

  if (totalPages <= MAX_PAGES_IN_PAGINATION_ROW) {
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= ACTIVE_PAGE_ON_MAX_AVAILABLE) {
    startPage = 1;
    endPage = MAX_PAGES_IN_PAGINATION_ROW;
  } else if (currentPage + AVAILABLE_NEXT_PAGES >= totalPages) {
    startPage = totalPages - LAST_AVAILABLE_PAGES;
    endPage = totalPages;
  } else {
    startPage = currentPage - AVAILABLE_PREV_PAGES;
    endPage = currentPage + AVAILABLE_NEXT_PAGES;
  }

  return [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i) || [];
};

interface VfPaginationNumberProps {
  idx: number;
  changePage: (page: number) => void;
  currentPage: number;
}

export const VfPaginationNumber: FC<VfPaginationNumberProps> = ({ currentPage, idx, changePage }) => {
  const classes = classNames('vf-page-item', {
    active: currentPage === idx,
  });
  const switchPage = () => {
    if (currentPage === idx) {
      return;
    }

    changePage(idx);
  };

  return (
    <li className={classes}>
      <button className="vf-page-link" onClick={switchPage}>
        {idx}
      </button>
    </li>
  );
};

interface VfPaginationProps {
  changePage: (page: number) => void;
  currentPage: number;
  documentView?: string;
  navType?: 'text' | 'icons';
  position?: 'start' | 'center' | 'end';
  totalPages: number;
}

const VfPagination: FC<VfPaginationProps> = ({
  changePage,
  currentPage,
  documentView,
  navType = 'text',
  position,
  totalPages,
}) => {
  const pages = getPages(totalPages, currentPage);

  if (pages.length < 1) {
    return null;
  }

  const paginationClasses = classNames(
    'vf-pagination',
    `vf-pagination--${documentView}`,
    `justify-content-${position}`,
    {},
  );

  const prevClasses = classNames('vf-page-item', {
    disabled: currentPage === 1,
  });

  const nextClasses = classNames('vf-page-item', {
    disabled: currentPage === totalPages,
  });

  const renderPages = pages.map((idx) => (
    <VfPaginationNumber key={`page-indicator-${idx}`} idx={idx} changePage={changePage} currentPage={currentPage} />
  ));

  const goToFirstPage = () => {
    changePage(1);
  };

  const goToPrevPage = () => {
    changePage(currentPage - 1);
  };

  const goToNextPage = () => {
    changePage(currentPage + 1);
  };

  const goToLastPage = () => {
    changePage(totalPages);
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className={paginationClasses}>
        <li className={prevClasses}>
          <button className="vf-page-link" onClick={goToFirstPage} id="firstPagePagination">
            {NavTypeItems[navType].first}
          </button>
        </li>

        <li className={prevClasses}>
          <button className="vf-page-link" onClick={goToPrevPage} id="prevPagePagination">
            {NavTypeItems[navType].prev}
          </button>
        </li>

        {renderPages}

        <li className={nextClasses}>
          <button className="vf-page-link" onClick={goToNextPage} id="nextPagePagination">
            {NavTypeItems[navType].next}
          </button>
        </li>

        <li className={nextClasses}>
          <button className="vf-page-link" onClick={goToLastPage} id="lastPagePagination">
            {NavTypeItems[navType].last}
          </button>
        </li>
      </ul>
    </nav>
  );
};

VfPagination.defaultProps = {
  documentView: '',
  navType: 'text',
  position: 'center',
};

export default VfPagination;
