import { FC } from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { Organization, OrganizationIds } from '../../../../models/structure';

import {
  clearProjects,
  clearProjectTypes,
  fetchOrganizations,
  fetchProjects,
  fetchProjectTypes,
  getOrganizations,
  getProjects,
  getProjectTypes,
} from '../../../../store/structure';

import useInfoElement from '../../../../utils/hooks/useInfoElement';
import usePhases from '../../../../utils/hooks/usePhases';

import { MultiDropdown } from '../../../Forms';
import { AutoCompleteWithReduxData } from '../../../Forms/Autocomplete';
import EnhancedFormikDropdown from '../../../Forms/Dropdown';
import Tooltip from '../../../Tooltip';
import { InfoElementNames } from '../../../Tooltip/consts';
import styles from '../../Shared.module.scss';

interface FormValues {
  organizationId: string;
  projectTypeId: string;
  projectId: string;
  tgPhase: string;
}

const ProjectBasics: FC = () => {
  const { phasesWithoutNoOption: phases, isLoading } = usePhases();
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [{ popupText: tooltipText }] = useInfoElement(InfoElementNames.PROJECT);

  const tooltipParagraphStyles = classNames(styles.pair__labelRequired, styles.pair__paragraph__withTooltip);
  const tooltipDivStyles = classNames(styles.pair, styles.pair__withTooltip);

  const handleOrganizationSelect = (organization: Organization) => {
    if (organization.id === OrganizationIds.onshore) {
      setFieldValue('projectTypeId', 'ONSHORE');
      setFieldValue('projectTypeName', 'Onshore');
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.pair}>
        <p className={styles.pair__labelRequired}>Which Business Unit?</p>

        <EnhancedFormikDropdown
          dispatchFn={fetchOrganizations}
          fullWidth
          label="Organization"
          labelKey="name"
          name="organizationId"
          onSelectCallback={handleOrganizationSelect}
          relatedFields={['projectTypeId', 'projectId', 'workstreamId', 'processId']}
          selector={getOrganizations}
          setByLabel
          showMinifiedLabel={false}
        />
      </div>

      {values.organizationId !== OrganizationIds.onshore && (
        <div className={styles.pair}>
          <p className={styles.pair__labelRequired}>What type of project?</p>

          <AutoCompleteWithReduxData
            label="Project type"
            dispatchFn={fetchProjectTypes}
            labelKey="name"
            name="projectTypeId"
            params={{
              organizationId: values['organizationId'],
            }}
            selector={getProjectTypes}
            disabled={!values.organizationId}
            onDismountCallback={clearProjectTypes}
            relatedFields={['projectId', 'workstreamId', 'processId']}
            setByLabel
            skipFields={['organizationId']}
          />
        </div>
      )}

      <div className={tooltipDivStyles}>
        <Tooltip text={tooltipText} position="bottom">
          <p className={tooltipParagraphStyles}>Which project(s)?</p>
        </Tooltip>

        <AutoCompleteWithReduxData
          dispatchFn={fetchProjects}
          labelKey="name"
          label="Project"
          name="projectId"
          relatedFields={['workstreamId', 'processId']}
          skipFields={['organizationId', 'projectTypeId']}
          params={{
            organizationId: values['organizationId'],
            projectTypeId: values['projectTypeId'],
          }}
          selector={getProjects}
          onDismountCallback={clearProjects}
          disabled={!values.projectTypeId}
          setByLabel
        />
      </div>

      <div className={styles.pair}>
        <p className={styles.pair__notRequired}>Which project phase?</p>

        <MultiDropdown
          className="mt-2"
          isDisabled={!values.projectId}
          isLoading={isLoading}
          label="Project Phase"
          name="tgPhase"
          options={phases}
          shrinkLabel={false}
        />
      </div>
    </div>
  );
};

export default ProjectBasics;
