import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFormikContext } from 'formik';

import {
  FilterItemWithSelectionVector,
  IAdvancedFilters,
  IOrganizationOrContainerItem,
} from '../../models/filterSettings';

import {
  advancedFilterInitials,
  clearProjectAndSites,
  fetchOrganizationsAndContainers,
  fetchProjectAndSites,
  fetchProjectTypesAndDepartments,
  getOrganizationsAndContainers,
  getProjectsAndSites,
  getProjectTypesAndDepartments,
} from '../../store/filterSettings';
import {
  clearProcesses,
  clearWorkstreams,
  fetchProcesses,
  fetchProcessesByDepartment,
  fetchWorkstreams,
  fetchWorkstreamsByDepartment,
  getProcesses,
  getWorkstreams,
} from '../../store/structure';

import { replaceFieldIdWIthName } from '../../utils/functions';
import usePhases from '../../utils/hooks/usePhases';

import { FLOW_TYPES } from '../../consts/newLessonForm';
import { MultiDropdown } from '../Forms';
import { AutoCompleteWithReduxData } from '../Forms/Autocomplete';
import EnhancedFormikDropdown from '../Forms/Dropdown';
import { VfDropdown } from '../vfDesign';
import {
  BU_ORGANIZATION_DEPENDENT_FIELDS,
  FIELDS_TO_REPLACE_ID_WITH_NAME,
  FILTER_LABELS,
  GROUPED_NAMES_CONFIG,
  LessonTypeOptions,
  ORIGIN_DEPENDENT_FIELDS_WITH_NAMES,
  PROJECT_TYPES_DEPARTMENT_DEPENDENT_FIELDS,
  PROJECTS_SITES_DEPENDENT_FIELDS,
} from './consts';

const DynamicForms: FC = () => {
  const { values, setFieldValue } = useFormikContext<IAdvancedFilters>();
  const { data: organizationOptions } = useSelector(getOrganizationsAndContainers);
  const { data: storedProjectTypes } = useSelector(getProjectTypesAndDepartments);
  const { data: storedProjects } = useSelector(getProjectsAndSites);
  const [filteredProjectTypes, setFilteredProjectTypes] = useState(storedProjectTypes);
  const [filteredProjects, setFilteredProjects] = useState(storedProjects);
  const { phases, isLoading } = usePhases();

  const setOrigin = (field: string, value: string) => {
    setFieldValue(field, value);

    const valuesToClear: IAdvancedFilters = advancedFilterInitials;

    ORIGIN_DEPENDENT_FIELDS_WITH_NAMES.forEach((field) => {
      setFieldValue(field, valuesToClear[field]);
    });

    setFilteredProjects(storedProjects.filter((project) => project.selectionVector.origin === value));
  };

  const isOrganizationUnit = values.origin === FLOW_TYPES.ORGANIZATIONAL_UNIT;
  const isOperatingSite = values.origin === FLOW_TYPES.OPERATING_SITE;
  const isProject = values.origin === FLOW_TYPES.PROJECT;

  const getProjectOrSiteFormikName = () => (isOperatingSite ? 'operatingSiteId' : 'projectId');
  const getProjectTypeOrDepartmentFormikName = () => (isOrganizationUnit ? 'departmentId' : 'projectTypeId');

  const isWorkstreamDisabled = !(values.projectTypeId || values.departmentId);

  const setParentsValue = (item: FilterItemWithSelectionVector) => {
    // TODO: temp. solution, rethink to store entire entites in formik (instead of fieldID, fieldName)

    // This check if the item there is a parent or a children thats why selection vector is used to filter (on child)
    if (item.selectionVector?.organizationId === null) {
      setFilteredProjectTypes(storedProjectTypes.filter((project) => project.organizationId === item.id));
      setFilteredProjects(storedProjects.filter((project) => project.organizationId === item.id));
    } else {
      setFilteredProjectTypes(
        storedProjectTypes.filter((project) => project.organizationId === item.selectionVector?.organizationId),
      );
      setFilteredProjects(storedProjects.filter((project) => project.organizationId === item.organizationId));
    }

    Object.entries(item?.selectionVector || {}).forEach(([parentId, parentValue]: [string, string]) => {
      if (parentValue !== null || parentId === 'origin') {
        setFieldValue(parentId, parentValue);
      }

      if (parentId === 'organizationId') {
        const selectedOrganization: IOrganizationOrContainerItem | undefined = organizationOptions.find(
          (item) => item.id === parentValue,
        );

        setFieldValue('organizationName', selectedOrganization?.name || '');
      } else if (FIELDS_TO_REPLACE_ID_WITH_NAME.includes(parentId)) {
        const nameField = replaceFieldIdWIthName(parentId);
        setFieldValue(nameField, parentValue);
      }
    });

    setFieldValue('tags', []); // CLEAR TAGS ON ORGANIZATION CHANGE
  };

  const nonOrganizationUnitDropdowns = (
    <>
      <AutoCompleteWithReduxData
        dispatchFn={fetchWorkstreams}
        disabled={isWorkstreamDisabled}
        label="Workstream"
        labelKey="name"
        name="workstreamId"
        selector={getWorkstreams}
        params={{
          organizationId: values['organizationId'],
          projectTypeId: values['projectTypeId'],
          lessonOrigin: values.origin,
        }}
        relatedFields={['processId']}
        skipFields={['organizationId', 'projectTypeId']}
        onDismountCallback={clearWorkstreams}
        setByLabel
      />
      <AutoCompleteWithReduxData
        disabled={!values.workstreamId}
        dispatchFn={fetchProcesses}
        label="Process"
        labelKey="name"
        name="processId"
        onDismountCallback={clearProcesses}
        selector={getProcesses}
        params={{
          organizationId: values['organizationId'],
          projectTypeId: values['projectTypeId'],
          workstreamId: values['workstreamId'],
        }}
        skipFields={['organizationId', 'projectTypeId', 'workstreamId']}
        setByLabel
      />

      {isProject && (
        <MultiDropdown
          className="mt-2"
          isLoading={isLoading}
          label="Project Phase"
          name="tgPhase"
          options={phases}
          withNoOption
        />
      )}
    </>
  );
  const organizationUnitDropdowns = (
    <>
      <EnhancedFormikDropdown
        dispatchFn={fetchWorkstreamsByDepartment}
        fullWidth
        label="Workstream"
        labelKey="name"
        name="workstreamId"
        params={{
          organizationId: values['organizationId'],
          departmentId: values['departmentId'],
          lessonOrigin: values['origin'],
        }}
        relatedFields={['processId', 'processName']}
        selector={getWorkstreams}
        showMinifiedLabel
        skipFields={['organizationId', 'departmentId']}
        setByLabel
        clearOption
      />

      <EnhancedFormikDropdown
        dispatchFn={fetchProcessesByDepartment}
        fullWidth
        label="Process"
        labelKey="name"
        name="processId"
        params={{
          organizationId: values['organizationId'],
          departmentId: values['departmentId'],
          workstreamId: values['workstreamId'],
          lessonOrigin: values['origin'],
        }}
        selector={getProcesses}
        showMinifiedLabel
        skipFields={['organizationId', 'departmentId', 'workstreamId']}
        setByLabel
        clearOption
      />
    </>
  );

  return (
    <div className="mx-2">
      <VfDropdown
        id="origin"
        name="origin"
        options={LessonTypeOptions}
        onChange={setOrigin}
        value={values.origin}
        onBlur={() => {}}
        label={FILTER_LABELS.origin}
        showMinifiedLabel
        clearOption
      />

      <EnhancedFormikDropdown
        clearOption
        dispatchFn={fetchOrganizationsAndContainers}
        fullWidth
        isGroupedOptions
        label="Business Unit / Organization"
        labelKey="name"
        name="organizationId"
        onSelectCallback={setParentsValue}
        relatedFields={BU_ORGANIZATION_DEPENDENT_FIELDS}
        selector={getOrganizationsAndContainers}
        setByLabel
        showMinifiedLabel
      />

      <EnhancedFormikDropdown
        clearOption
        dispatchFn={fetchProjectTypesAndDepartments}
        fullWidth
        groupedNamesConfig={GROUPED_NAMES_CONFIG.projectTypesDeparments}
        isGroupedOptions
        label="Project type / Department"
        labelKey="name"
        name={getProjectTypeOrDepartmentFormikName()}
        onSelectCallback={setParentsValue}
        relatedFields={PROJECT_TYPES_DEPARTMENT_DEPENDENT_FIELDS}
        selector={getProjectTypesAndDepartments}
        setByLabel
        showMinifiedLabel
        storedData={filteredProjectTypes}
      />

      <AutoCompleteWithReduxData
        onDismountCallback={clearProjectAndSites}
        dispatchFn={fetchProjectAndSites}
        isGroupedOptions
        groupedNamesConfig={GROUPED_NAMES_CONFIG.projectSites}
        label="Project / Site"
        labelKey="name"
        relatedFields={[...PROJECTS_SITES_DEPENDENT_FIELDS, 'departmentId']}
        name={getProjectOrSiteFormikName()}
        selector={getProjectsAndSites}
        setByLabel
        shouldFetchOnMount
        onSelectCallback={setParentsValue}
        storedData={filteredProjects}
      />

      {isOrganizationUnit && organizationUnitDropdowns}
      {!isOrganizationUnit && nonOrganizationUnitDropdowns}
    </div>
  );
};

export default DynamicForms;
