import { FC, MouseEvent } from 'react';

import DropdownOptions from './dropdownOptions';
import { groupingOptions } from './helpers';
import { EntriesOptions, GroupedOptions, IOptionWithGroup } from './models';
import './vf-dropdown.scss';

export interface IDropdownOptions {
  isShowFullText?: boolean;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  options: IOptionWithGroup[];
}

export const DropdownGroupedOptions: FC<IDropdownOptions> = ({ onClick, options, isShowFullText = false }) => {
  const fannedOptions: GroupedOptions = groupingOptions(options);

  const renderGroups = Object.entries(fannedOptions).map(([groupName, groupOptions]: EntriesOptions) => (
    <div key={`group-options-${groupName}`}>
      <span className="grouped-title">{groupName.replace('_', ' ')}</span>
      <DropdownOptions options={groupOptions} onClick={onClick} isShowFullText={isShowFullText} />
    </div>
  ));

  return <>{renderGroups}</>;
};

export default DropdownGroupedOptions;
