import { FC } from 'react';

import { useFormikContext } from 'formik';

import { StructureBase } from '../../../../models/structure';
import { ADUser } from '../../../../models/user';

import {
  fetchProcessesByDepartment,
  fetchWorkstreamsByDepartment,
  getProcesses,
  getWorkstreams,
} from '../../../../store/structure';

import { FlowOption } from '../../../../consts/newLessonForm';
import EnhancedFormikDropdown from '../../../Forms/Dropdown';
import UserSelector from '../../../UserSelector';
import { VfDivider, VfHelperText } from '../../../vfDesign';
import styles from '../../Shared.module.scss';
import { ProjectLessonType } from '../../types';

interface FormValues extends ProjectLessonType {
  processName: string;
  workstreamName: string;
  owner: Partial<ADUser>;
}

interface OrganizationalUnitWorkstreamProps {
  currentFlow?: FlowOption;
}

const OrganizationalUnitWorkstream: FC<OrganizationalUnitWorkstreamProps> = ({ currentFlow }) => {
  const { setFieldValue, values, errors } = useFormikContext<FormValues>();

  const setOwner = (item: StructureBase) => {
    if (item?.owner) {
      setFieldValue('owner', item.owner);
    }
  };

  const handleSelectOwner = (owner: string, val: Partial<ADUser>) => {
    setFieldValue('owner', val);
    setFieldValue('processName', '');
    setFieldValue('processId', '');
    setFieldValue('workstreamName', '');
    setFieldValue('workstreamId', '');
  };

  const isOwnerDisabled = !!values['workstreamId'];

  return (
    <div className={styles.content}>
      <h5 className={styles.lead}>Great, that helps a lot.</h5>

      <div className={styles.pair}>
        <p className={styles.pair__labelRequired}>To which specific unit (organizational department or area/workstream within a team) does this lesson relate to?</p>

        <EnhancedFormikDropdown
          dispatchFn={fetchWorkstreamsByDepartment}
          fullWidth
          label="Workstream"
          labelKey="name"
          name="workstreamId"
          onSelectCallback={setOwner}
          params={{
            organizationId: values['organizationId'],
            departmentId: values['departmentId'],
            lessonOrigin: currentFlow,
          }}
          relatedFields={['processId', 'processName']}
          selector={getWorkstreams}
          setByLabel={true}
          showMinifiedLabel={false}
          skipFields={['organizationId', 'departmentId']}
        />
      </div>

      <div className={styles.pair}>
        <p className={styles.pair__label}>To which sub-unit (organizational department or area/process within a team) does this lesson relate to?</p>

        <EnhancedFormikDropdown
          dispatchFn={fetchProcessesByDepartment}
          fullWidth
          label="Process"
          labelKey="name"
          name="processId"
          onSelectCallback={setOwner}
          disabled={!values.workstreamId}
          params={{
            organizationId: values['organizationId'],
            departmentId: values['departmentId'],
            workstreamId: values['workstreamId'],
            lessonOrigin: currentFlow,
          }}
          selector={getProcesses}
          setByLabel={true}
          showMinifiedLabel={false}
          skipFields={['organizationId', 'departmentId', 'workstreamId']}
        />
      </div>

      <VfDivider />
      <p>OR</p>

      <div className={styles.pair}>
        <p className={styles['pair__labelRequired--center']}>Action Taker:</p>

        {isOwnerDisabled && (
          <>
            <p className={styles['pair__value--center']}>{values['owner']?.displayName || 'Not selected'}</p>
          </>
        )}
        {!isOwnerDisabled && (
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <UserSelector name="owner" onSelect={handleSelectOwner} value={values['owner']} />
            {errors['owner'] && <VfHelperText type="error" text={errors['owner']} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationalUnitWorkstream;
