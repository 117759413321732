import { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { fetchDictionaryStates } from '../../store/dictionary';
import { removeAdvancedFilter } from '../../store/filterSettings';

import { dashSpacedToLowerCase } from '../../utils/functions';

import SearchBar from '../../components/Searchbar';
import { VfTabs } from '../../components/vfDesign';
import { STATE_TAGNAMES } from '../../consts/lessons';
import { ReportsProvider } from '../../context';
import { useAppDispatch } from '../../root';
import List from './List';

interface LessonsListProps {}

export interface paramsProps {
  tabName?: string;
  lessonId?: string;
}

export enum TAB_IDS {
  TAKE_ACTION,
  MY_LESSONS,
  ALL_LESSONS,
}

const LessonsList: FC<LessonsListProps> = () => {
  const dispatch = useAppDispatch();
  const { tabName } = useParams<paramsProps>();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchDictionaryStates());
  }, [dispatch]);

  const tabs = [
    {
      id: TAB_IDS.TAKE_ACTION,
      name: 'Take action',
      component: List,
      route: STATE_TAGNAMES.TAKE_ACTION,
    },
    {
      id: TAB_IDS.MY_LESSONS,
      name: 'My Submitted Lessons',
      component: List,
      route: STATE_TAGNAMES.MY_LESSONS,
    },
    {
      id: TAB_IDS.ALL_LESSONS,
      name: 'All Lessons',
      component: List,
      route: STATE_TAGNAMES.ALL_LESSONS,
    },
  ];

  const currentPassedTab =
    (tabs.find((t) => {
      return t.route === tabName;
    })?.id as number) || TAB_IDS.TAKE_ACTION;

  const setParamCallback = (tab: any) => {
    if (tab.id === TAB_IDS.MY_LESSONS) {
      dispatch(removeAdvancedFilter('creatorId'));
    }

    if (tab.id === TAB_IDS.TAKE_ACTION) {
      dispatch(removeAdvancedFilter('actionTakerId'));
      dispatch(removeAdvancedFilter('actionTakerDeputyId'));
    }

    history.replace(dashSpacedToLowerCase(tab.name));
  };

  return (
    <ReportsProvider>
      <SearchBar />

      <div className="vf-container">
        <div className="vf-row">
          <div className="vf-col">
            <VfTabs tabs={tabs} callback={setParamCallback} passedTab={currentPassedTab} />
          </div>
        </div>
      </div>
    </ReportsProvider>
  );
};

export default LessonsList;
