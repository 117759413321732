import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useFormikContext } from 'formik';

import { Tag } from '../../../models/tags';

import { fetchTagsByOrganizationId, getTagsByOrganizationId } from '../../../store/tags';

import { useAppDispatch } from '../../../root';
import { VfTagCloud } from '../../vfDesign';
import styles from '../Shared.module.scss';

interface FormValues {
  organizationId: string;
  tags: Tag[];
}

const Tags: FC = () => {
  const dispatch = useAppDispatch();
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const tags = useSelector(getTagsByOrganizationId)(values.organizationId);

  useEffect(() => {
    dispatch(fetchTagsByOrganizationId(values.organizationId));
  }, [values.organizationId, dispatch]);

  const handleChange = (tags: Tag[]) => {
    setFieldValue('tags', tags);
  };

  return (
    <div className={styles.content}>
      <h5 className={styles.lead}>Please include relevant tags</h5>

      <div style={{ width: '80%' }}>
        <VfTagCloud tags={tags} usedTags={values.tags} onTagsChange={handleChange} />
      </div>
    </div>
  );
};

export default Tags;
