import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Tag, TagCategory } from '../models/tags';

import { handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import Api from '../services/api';

const sliceName = 'tags';

interface TagsReducerState {
  byOrganizationId: Record<string, Tag[]>;
  categories: TagCategory[];
  isLoading: boolean;
}

export const fetchTagCategories = createAsyncThunk<TagCategory[], void>(
  `${sliceName}/fetchTagCategories`,
  async (_, { dispatch }) => {
    try {
      const response = await Api.get<TagCategory[]>('/tagging/categories'); // TODO type
      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError, 'We could not fetch tag categories', dispatch);
      throw error;
    }
  },
);

interface FetchTagsForCategoryParams {
  category: string;
}

export const fetchTagsForCategory = createAsyncThunk<TagCategory, FetchTagsForCategoryParams>(
  `${sliceName}/fetchTagsForCategory`,
  async ({ category }, { dispatch }) => {
    try {
      const response = await Api.get<TagCategory>(`/tagging/categories/${category}`); // TODO type
      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError, 'We could not fetch tag category', dispatch);
      throw error;
    }
  },
);

interface AddTagToCategoryParams {
  tag: string;
  category: string;
}
export const addTagToCategory = createAsyncThunk<Tag, AddTagToCategoryParams>(
  `${sliceName}/addTagToCategory`,
  async ({ category, tag }, { dispatch }) => {
    try {
      const response = await Api.post<Tag>(`/tagging/categories/${category}/tags`, { name: tag }); // TODO type
      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError, 'We could not add tag to category', dispatch);
      throw error;
    }
  },
);

export const fetchTagsByOrganizationId = createAsyncThunk<TagCategory, string>(
  `${sliceName}/fetchTagsByOrganizationId`,
  async (organizationId: string, { dispatch }) => {
    try {
      const response = await Api.get<TagCategory>(`/tagging/categories-by-organization/${organizationId}`);

      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError, 'We could not fetch tags for organization', dispatch);
      throw error;
    }
  },
);

interface UpdateTagParams extends Tag {
  category: string;
}

export const updateTag = createAsyncThunk<Tag, UpdateTagParams>(
  `${sliceName}/updateTag`,
  async ({ category, ...tag }, { dispatch }) => {
    try {
      const response = await Api.put<Tag>(`/tagging/tags/${tag.id}`, tag); // TODO type
      return response.data;
    } catch (error) {
      handleApiError(error as AxiosError, 'We could not update tag', dispatch);
      throw error;
    }
  },
);
const initialState: TagsReducerState = {
  byOrganizationId: {},
  categories: [],
  isLoading: false,
};

export const tagsReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTagCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTagCategories.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.categories = payload;
    });
    builder.addCase(fetchTagCategories.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchTagsForCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTagsForCategory.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const category = state.categories.find((category) => category.id === payload.id);
      if (category) {
        category.tags = payload.tags;
      }
    });
    builder.addCase(fetchTagsForCategory.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchTagsByOrganizationId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTagsByOrganizationId.fulfilled, (state, { payload, meta: { arg: organizationId } }) => {
      state.byOrganizationId[organizationId] = payload?.tags || [];
      state.isLoading = false;
    });
    builder.addCase(fetchTagsByOrganizationId.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(addTagToCategory.fulfilled, (state, { payload, meta: { arg } }) => {
      const category = state.categories.find((category) => category.id === arg.category);
      if (category) {
        category.tags?.push(payload);
      }
    });
    builder.addCase(updateTag.fulfilled, (state, { payload, meta: { arg } }) => {
      const category = state.categories.find((category) => category.id === arg.category);
      if (category) {
        const tag = category.tags?.find((tag) => tag.id === payload.id);
        if (tag) {
          tag.name = payload.name;
          tag.active = payload.active;
        }
      }
    });
  },
});

export const getTagsLoading = (state: ReduxStoreType) => state[sliceName].isLoading;
export const getTags = (state: ReduxStoreType) => state[sliceName].categories;
export const getTagsByOrganizationId = (state: ReduxStoreType) => (organizationId: string) =>
  (state[sliceName].byOrganizationId?.[organizationId] || []).filter((tag) => tag.active);

export default tagsReducer.reducer;
