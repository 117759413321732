import { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { getFilterSettings, updateFilterSettings } from '../../store/filterSettings';
import {
  fetchLessons,
  fetchAllLessonsOverview,
  getLessons,
  getTotalPages,
  clearLessons,
  getRestartFlag,
  getAllLessonsOverview,
  setInitialTotalPages,
} from '../../store/lessons';

import { paramsProps } from '.';
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import LessonDetailsDrawer, { TabIndex } from '../../components/LessonDetails';
import { VfCheckbox } from '../../components/vfDesign';
import { STATE_TAGNAMES } from '../../consts/lessons';
import { ReportsContext } from '../../context';
import { useAppDispatch } from '../../root';
import Lesson from './Lesson';
import styles from './Lesson.module.scss';

const List: FC = () => {
  const { tabName, lessonId: id } = useParams<paramsProps>();
  const dispatch = useAppDispatch();
  const restartFlag = useSelector(getRestartFlag);
  const [page, setPage] = useState(1);
  const [lessonId, setLessonId] = useState(id || '');
  const [passedTab, setPassedTab] = useState(0);
  const requestParams = useSelector(getFilterSettings);
  const totalPages = useSelector(getTotalPages);
  const { isEnabled, isSelectedAll, handleSelectAll, selected, handleClearSelected } = useContext(ReportsContext);

  const { data, isLoading } = useSelector(getLessons);
  const { data: allLessonsOverview } = useSelector(getAllLessonsOverview);
  const [lastActionRequestTime, setLastActionRequestTime] = useState<string | undefined>(undefined);

  const history = useHistory();

  useEffect(() => {
    setPage(1);
  }, [restartFlag]);

  useEffect(() => {
    dispatch(fetchAllLessonsOverview(requestParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams.title, requestParams.tabName, JSON.stringify(requestParams.advancedFilters)]);

  useEffect(() => {
    if (page <= totalPages) {
      dispatch(fetchLessons(requestParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(requestParams)]);

  useEffect(() => {
    // Cleaning function for infinite-scroll proper behavior
    dispatch(setInitialTotalPages());
    dispatch(updateFilterSettings({ tabName }));
    setPage(1);
    dispatch(clearLessons([]));
    handleClearSelected?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabName]);

  useEffect(() => {
    dispatch(updateFilterSettings({ page }));
  }, [dispatch, page]);

  const handleCloseDrawer = () => {
    setLessonId('');
    history.replace(`/lessons/${tabName}`);
  };

  const handleOpenDrawer = (lessonId: string, tabIndex: TabIndex) => {
    setLessonId(lessonId);
    setPassedTab(tabIndex);

    history.replace(`/lessons/${tabName}/${lessonId}`);

    const lesson = data.filter(l => l.id === lessonId)[0];
    if(lesson) {
      setLastActionRequestTime(lesson.lastActionRequestTime)
    }
  };

  const isMyLesson = tabName === STATE_TAGNAMES.MY_LESSONS;

  const lessons = data?.map(
    ({
      actionTaker,
      actionTakerDeputy,
      delayed,
      description,
      favourite,
      id,
      lastActionRequestTime,
      priority,
      processName,
      projectName,
      published,
      recommendation,
      state,
      title,
      workshopId,
      workshopName,
    }) => (
      <Lesson
        actionTaker={actionTaker}
        actionTakerDeputy={actionTakerDeputy}
        delayed={delayed}
        description={description}
        favourite={favourite}
        id={id}
        key={id}
        name={title}
        lastActionRequestTime={lastActionRequestTime}
        priority={priority}
        process={processName}
        project={projectName}
        published={published}
        recommendation={recommendation}
        setLessonId={handleOpenDrawer}
        showDeleteOption={isMyLesson}
        state={state}
        workshopId={workshopId}
        workshopName={workshopName}
      />
    ),
  );

  const handleSelection = () => {
    const itemsToBeSelected = allLessonsOverview.map((l) => ({ id: l.id, name: l.title }));
    if (!isSelectedAll) {
      handleSelectAll?.(itemsToBeSelected);
    }
    if (isSelectedAll) {
      handleSelectAll?.([]);
    }
  };

  return (
    <div className="vf-container">
      <LessonDetailsDrawer
        open={!!lessonId}
        isOpen={!!lessonId}
        onClose={handleCloseDrawer}
        side="right"
        onOutsideClick={handleCloseDrawer}
        lessonId={lessonId}
        passedTab={passedTab}
        setPassedTab={setPassedTab}
        lastActionRequestTime={lastActionRequestTime}
      />
      <div className="vf-row mt-3">
        <div className="vf-col">
          <div className="vf-container" id="scrollableDiv">
            {isEnabled && (
              <div className={styles.selectAllCheckboxWrapper}>
                <span className={styles.text}>Select all ({<b>{selected?.length}</b>})</span>
                <VfCheckbox onChange={handleSelection} disabled={false} checked={isSelectedAll} />
              </div>
            )}

            <InfiniteScroll
              isLoading={isLoading}
              page={page}
              totalPages={totalPages}
              noItemsMessage="No lessons found"
              onFetchMore={setPage}
            >
              {lessons}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
