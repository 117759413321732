import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserDetails } from '../../store/user';

import { getNameFromDisplayName } from '../../utils/functions';

import BackToPage from '../../components/BackToPage';
import NewLessonForm from '../../components/NewLessonForm';
import Spinner from '../../components/Spinner';
import { VfButton } from '../../components/vfDesign';
import styles from './NewLesson.module.scss';

const NewLessonPage: FC = () => {
  const { data, isLoading } = useSelector(getUserDetails);
  const [started, setStarted] = useState<boolean>(false);

  const displayName = getNameFromDisplayName(data?.displayName);

  const handleStart = () => {
    setStarted(true);
  };

  if (!started) {
    return (
      <>
        <BackToPage />

        <div className={styles.wrapper}>
          <div className="vf-row no-gutters h-100">
            <div className="vf-col h-100">
              <div className="vf-row h-100 d-flex flex-column align-items-center justify-content-center no-gutters flex-grow-1">
                {isLoading && <Spinner />}

                {!isLoading && <h5 className={styles.header}>Hello {displayName}</h5>}

                <p className={styles.text}>
                  Glad you are here to share a lesson,
                  <br /> this helps BA Wind to continuously improve!
                </p>

                <p className={styles.text}>
                  Let me ask you a few questions about your lesson so I can <br />
                  forward it to the right person in the organization.
                </p>

                <VfButton type="primary" onClick={handleStart} className="mt-3">
                  Add Lesson
                </VfButton>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <BackToPage />

      <div className={styles.wrapper}>
        <NewLessonForm />
      </div>
    </>
  );
};

export default NewLessonPage;
