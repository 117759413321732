import { string } from 'yup';

import { ADUser } from '../../../models/user';

export const takeActionValidationSchema = {
  reasonDescription: string().max(2000, 'Reason description can be 2000 chars at most'),
  actionTakerAdditionalInformation: string().max(2000, 'Action taker comment can be 2000 chars at most'),
  improvementDescription: string()
    .nullable()
    .when('improvementDelivered', {
      is: (val: boolean) => val === true,
      then: string()
        .required('Improvement description is required')
        .max(2000, 'Improvement description can be 2000 chars at most'),
    }),
  typeOfImprovement: string().when('improvementDelivered', {
    is: (val: boolean) => val === true,
    then: string().required('Type of improvement is required'),
  }),
};

export const baseValidationSchema = {
  description: string().required('Description is required').max(2000, 'Description can be 2000 chars at most'),
  title: string().required('Please add lesson title').max(255, 'Title can be 255 chars at most'),
  organizationId: string().required('Organization is required'),
  recommendation: string().max(2000, 'Recommendation can be 2000 chars at most'),

  projectTypeId: string().when('origin', {
    is: (val: string) => val !== 'ORGANIZATION_UNIT',
    then: string().required('Project Type is required'),
    otherwise: string(),
  }),
  priorityJustification: string().when('priority', {
    is: (val: string) => val === 'HIGH',
    then: string()
      .required('Priority justification is required')
      .max(2000, 'Priority justification can be 2000 chars at most'),
    otherwise: string().max(2000, 'Priority justification can be 2000 chars at most'),
  }),
  workstreamId: string().when('actionTaker', {
    is: (val: Partial<ADUser>) => !val?.id,
    then: string().required('Workstream is required'),
    otherwise: string(),
  }),

  operatingSiteId: string().when('origin', {
    is: (val: string) => val === 'OPERATING_SITE',
    then: string().required('Site is required'),
    otherwise: string(),
  }),

  processId: string(),
};

export const notLegacyProjectSchema = {
  ...baseValidationSchema,
  projectId: string().when('origin', {
    is: (val: string) => val === 'PROJECT',
    then: string().required('Project is required'),
    otherwise: string(),
  }),
};
