import { FC } from 'react';

import styles from './flexDrawer.module.scss';

const DrawerFooter: FC = ({ children }) => {
  return (
    <div className={styles[`drawer__footer`]}>
      <div className={styles['drawer__footer-row']}>{children}</div>
    </div>
  );
};

export default DrawerFooter;
