import { FC, useContext, useEffect } from 'react';

import { useFormikContext } from 'formik';

import { dispatchConfirmationEvent } from '../../events/helpers';
import { VfButton } from '../vfDesign';
import { findCurrentFlow, initialValues, NewLessonContext } from './Context';
import FormRenderer from './FormRenderer';
import styles from './Shared.module.scss';
import { ProjectFormValues } from './types';

const NewLessonForm: FC = () => {
  const { currentStep, config, currentFlowType, setCurrentStep, finished, resetFlowType, setFinished } =
    useContext(NewLessonContext);

  const { handleSubmit, setValues, validateForm, dirty, values, setErrors, setFieldValue } =
    useFormikContext<Partial<ProjectFormValues>>();

  const shouldClearErrors = JSON.stringify(values);

  useEffect(() => {
    setErrors({});
  }, [shouldClearErrors, setErrors]);

  const flow = config.find(findCurrentFlow(currentFlowType));

  const onReset = () => {
    resetFlowType && resetFlowType();
    setValues(initialValues, false);
  };

  const goBack = () => {
    if (currentStep === 1) {
      dispatchConfirmationEvent(onReset, () => <p>Are you sure you want to go back? All inputs will be lost</p>);

      return;
    }

    if (setCurrentStep) {
      setCurrentStep(currentStep - 1);
    }
  };

  const goForward = async () => {
    const formErrors = await validateForm(values);

    if (flow?.steps && setCurrentStep && currentStep < flow?.steps?.length && Object.keys(formErrors).length === 0) {
      setCurrentStep(currentStep + 1);
    }

    setErrors(formErrors);
  };

  const clearStep = () => {
    flow?.steps[currentStep - 1].clearStepInput?.forEach((input) => {
      setFieldValue(input.key, input.initialVal);
    });
  };

  const handleSend = async () => {
    const formErrors = await validateForm(values);
    if (!formErrors || Object.keys(formErrors).length === 0) {
      setFinished && setFinished();
      handleSubmit();
    }

    setErrors(formErrors);
  };

  const isDisabled = (() => {
    if (flow?.steps) {
      return currentStep === flow?.steps?.length || flow?.steps?.length === 0;
    }

    return true;
  })();

  return (
    <>
      <div className={styles.header}>
        <h5 className={styles.steps}>
          Step {currentStep + 1} {flow && flow.steps.length > 0 && `of ${flow.steps.length + 1}`}
        </h5>
      </div>

      <FormRenderer />

      <div className={styles.footer}>
        {currentStep > 0 && !finished && (
          <VfButton type="tertiary" onClick={goBack} noArrow>
            Back
          </VfButton>
        )}

        {currentStep !== flow?.steps.length && currentStep !== 0 && (
          <>
            {flow?.steps[currentStep - 1].clearStepInput && (
              <VfButton type="secondary" onClick={clearStep} className="mt-md-0 mt-1 mr-1">
                Clear Step
              </VfButton>
            )}
            <VfButton type="primary" onClick={goForward} disabled={isDisabled || !dirty} className="mt-md-0 mt-1">
              Next Question
            </VfButton>
          </>
        )}

        {currentStep === flow?.steps.length && !finished && (
          <div className="mt-md-0 mt-1">
            <VfButton type="primary" onClick={handleSend} htmlType="submit" className="w-100 w-md-auto">
              Submit
            </VfButton>
          </div>
        )}
      </div>

      {currentStep > 0 && !finished && (
        <div
          className={
            flow?.steps[currentStep - 1].showMandatory ? styles.mandatoryLegend : styles.mandatoryLegend__hidden
          }
        >
          * Mandatory item
        </div>
      )}
    </>
  );
};

export default NewLessonForm;
