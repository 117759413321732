// MSAL imports
import { FC } from 'react';

import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';

import Spinner from '../../components/Spinner';
// Auth components
import LoginError from '../../components/Wrappers/Auth/LoginError';
import LoginSilent from '../../components/Wrappers/Auth/LoginSilent';
import { loginScopes } from '../../services/auth';

const LoadingComponent: FC = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vf-vh-100 ">
      <Spinner />
    </div>
  );
};

const Login: FC = () => {
  const authRequest = {
    scopes: loginScopes,
  };

  return (
    <MsalAuthenticationTemplate
      authenticationRequest={authRequest}
      errorComponent={LoginError}
      interactionType={InteractionType.Redirect}
      loadingComponent={LoadingComponent}
    >
      <LoginSilent />
    </MsalAuthenticationTemplate>
  );
};

export default Login;
