import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InstructionLink, InstructionLinkType } from '../../../models/InstructionLinks';

import {
  getInstructionLinks,
  getInstructionLinksIsLoading,
  removeInstructionLink,
} from '../../../store/instructionLink';

import IconButton from '../../../components/IconButton';
import Spinner from '../../../components/Spinner';
import styles from './InstructionLinks.module.scss';

const InstructionLinksList: FC = () => {
  const isLoading = useSelector(getInstructionLinksIsLoading);
  const { data } = useSelector(getInstructionLinks);
  const dispatch = useDispatch();

  const removeButtonHandler = (id: string) => {
    dispatch(removeInstructionLink(id));
  };

  const TableLegend: FC = () => {
    return (
      <>
        <div className={styles.section}>
          <p className="font-weight-bold">Name</p>
        </div>

        <div className={styles.section}>
          <p className="font-weight-bold">URL</p>
        </div>

        <div className={styles.section}>
          <p className="font-weight-bold">Type</p>
        </div>

        <div className={styles.section}>
          <p className="font-weight-bold">Delete</p>
        </div>
      </>
    );
  };

  const TableElement: FC<InstructionLink> = ({ name, url, type, id }) => {
    return (
      <>
        <div className={styles.section}>
          <p className={styles.paragraph__break_words}>{name}</p>
        </div>

        <div className={styles.section}>
          <p className={styles.paragraph__break_words}>{url}</p>
        </div>

        <div className={styles.section}>
          <p className={styles.paragraph__break_words}>{InstructionLinkType[type]}</p>
        </div>

        <div className={styles.section}>
          <IconButton
            icon="trash"
            onClick={() => {
              removeButtonHandler(id);
            }}
            disabled={isLoading}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading && (
        <div className="d-flex flex-fill justify-content-center align-items-center vf-vh-100 vf-vw-100">
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <div className="vf-container">
          <div className="vf-row mt-2">
            <div className="vf-col">
              <div className={styles.list}>
                <TableLegend />
                {data.map((link) => {
                  return <TableElement {...link} />;
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InstructionLinksList;
