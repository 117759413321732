import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Phase } from '../../../models/structure';

import { fetchPhases, getPhases } from '../../../store/structure';

import { NO_OPTION } from '../../../components/vfDesign/vfMulitpleDropdown';
import { useAppDispatch } from '../../../root';

const usePhases = (): { isLoading: boolean; phases: Phase[]; phasesWithoutNoOption: Phase[] } => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useSelector(getPhases);

  useEffect(() => {
    dispatch(fetchPhases());
  }, [dispatch]);

  return {
    isLoading,
    phases: data,
    phasesWithoutNoOption: data.filter((item) => item.id !== NO_OPTION),
  };
};

export default usePhases;
