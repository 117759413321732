/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import VfCheckbox from '../vfCheckbox';
import { ActionOption } from './actions';
import Headers, { ORDER } from './headers';
import Row from './row';
import SelectedRows from './selectedRows';
import './table.scss';
import { IGNORED_DEFAULT_CELL } from './tableHelpers';

interface SelectionState {
  selected: string[];
  selectAll: boolean;
}

const defaultSelectionState: SelectionState = {
  selected: [],
  selectAll: false,
};

interface VfTableProps {
  data: any[]; //TODO: handle any
  noAccess?: boolean;
  onRowClick: (row: any) => void; //TODO: handle any
  rowActions?: ActionOption[];
  selectAllFn?: (selectAll: boolean) => void;
  selectedActions?: ActionOption[];
  selectedAllIds?: string[];
  setSort?: (sortBy: string, order: ORDER) => void;
  sortBy?: string;
  sortColumns?: string[];
  sortOrder?: ORDER;
  withCheckboxes?: boolean;
  withRowClick?: boolean;
}

const VfTable: FC<VfTableProps> = ({
  data,
  noAccess = false,
  onRowClick,
  rowActions = [],
  selectAllFn,
  selectedActions = [],
  selectedAllIds = [],
  setSort,
  sortBy = '',
  sortColumns = [],
  sortOrder = ORDER.ASC,
  withCheckboxes = false,
  withRowClick,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<SelectionState>(defaultSelectionState);

  useEffect(() => {
    if (withCheckboxes) {
      setState({
        ...state,
        selected: selectedAllIds,
        selectAll: selectedAllIds.length !== 0,
      });
    }
  }, [selectedAllIds.length]);

  if (noAccess) {
    return (
      <div className="vf-table--no-items">
        <p>No access</p>
      </div>
    );
  }

  if (!data.length) {
    return (
      <div className="vf-table--no-items">
        <p>No Items Found</p>
      </div>
    );
  }
  const headers = Object.keys(data[0]).filter((item) => !IGNORED_DEFAULT_CELL.includes(item));

  const checkAll = () => {
    selectAllFn && dispatch(selectAllFn(!state.selectAll));
    setState({
      ...state,
      selectAll: !state.selectAll,
    });
  };

  const renderHeaders = headers.map((column) => {
    const headerWithSort = sortColumns.includes(column.toUpperCase());

    if (headerWithSort) {
      return (
        <Headers
          header={column}
          key={column}
          setSort={setSort}
          withSort={headerWithSort}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
      );
    }

    return <Headers header={column} key={column} withSort={headerWithSort} />;
  });

  const renderRows = data.map((row) => {
    const selectedIds = state.selected;
    const isSelected = selectedIds.includes(row.id);
    const onRowSelect = (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      const isChecked = e.target.checked;
      const selected = isChecked ? [...selectedIds, row.id] : selectedIds.filter((id) => id !== row.id);

      setState({
        ...state,
        selected,
      });
    };

    if (withRowClick) {
      const rowClickWithItemPassed = () => onRowClick(row);

      return (
        <Row
          key={row.item.id}
          isSelected={isSelected}
          onRowClick={rowClickWithItemPassed}
          onRowSelect={onRowSelect}
          row={row}
          rowActions={rowActions}
          withCheckboxes={withCheckboxes}
          withRowClick
        />
      );
    }

    return (
      <Row
        key={row.id}
        isSelected={isSelected}
        onRowSelect={onRowSelect}
        row={row}
        rowActions={rowActions}
        withCheckboxes={withCheckboxes}
      />
    );
  });

  return (
    <>
      {withCheckboxes && <SelectedRows selected={state.selected} actions={selectedActions} />}
      <table className="vf-table vf-simple-table">
        <thead>
          <tr>
            {withCheckboxes && (
              <th className="check-all">
                <VfCheckbox id="check_all" name="check_all" onChange={checkAll} checked={state.selectAll} />
              </th>
            )}
            {renderHeaders}
            {rowActions.length > 0 && <th className="action-header" />}
          </tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </table>
    </>
  );
};

VfTable.defaultProps = {
  onRowClick: () => {},
  rowActions: [],
  selectAllFn: () => {},
  selectedActions: [],
  selectedAllIds: [],
  setSort: () => false,
  sortBy: '',
  sortColumns: [],
  sortOrder: ORDER.ASC,
  withCheckboxes: false,
  withRowClick: false,
};

export default VfTable;
