import { FC } from 'react';

import classNames from 'classnames';

import Footer from '../../Footer';
import Header from '../../Header';
import styles from './Layout.module.scss';

const Layout: FC = ({ children }) => {
  const classes = classNames(styles.llp);
  
  return (
    <main className={classes}>
      <Header />

      <div className={styles.main}>{children}</div>

      <Footer />
    </main>
  );
};

export default Layout;
