import { createContext, FC, ReactNode, useState } from 'react';

export interface ISelectedReportItem {
  id: string;
  name: string;
}

export interface ContextState {
  handleToggleSelected?: (item: ISelectedReportItem) => void;
  handleRemoveSelected?: (item: ISelectedReportItem) => void;
  handleSelectAll?: (items: ISelectedReportItem[]) => void;
  handleClearSelected?: () => void;
  toggleSelection?: () => void;
  isSelected?: (item: ISelectedReportItem) => boolean;
  isEnabled: boolean;
  isSelectedAll: boolean;
  selected: ISelectedReportItem[];
}

const ReportsContext = createContext<ContextState>({
  isEnabled: false,
  isSelectedAll: false,
  selected: [],
});

interface ProviderProps {
  children: ReactNode;
}

const ReportsProvider: FC<ProviderProps> = ({ children }) => {
  const [selected, setSelected] = useState<ISelectedReportItem[]>([]);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);

  const handleToggleSelected = (item: ISelectedReportItem) => {
    const foundSelected = selected.find((s) => s.id === item.id);

    if (!foundSelected) {
      const newSelected = [...selected, item];
      setSelected(newSelected);
      return;
    }

    const newSelected = [...selected].filter((s) => s.id !== item.id);
    setSelected(newSelected);
  };

  const handleSelectAll = (items: ISelectedReportItem[]) => {
    setIsSelectedAll(!isSelectedAll);
    setSelected(items);
  };

  const handleRemoveSelected = (item: ISelectedReportItem) => {
    const newSelected = [...selected].filter((s) => s.id !== item.id);

    setSelected(newSelected);
  };

  const toggleSelection = () => {
    setIsEnabled(!isEnabled);
  };

  const handleClearSelected = () => {
    setSelected([]);
    setIsSelectedAll(false);
  };

  const isSelected = (item: ISelectedReportItem) => {
    return !!selected.find((s) => s.id === item.id);
  };

  const value = {
    selected,
    isSelected,
    handleToggleSelected,
    handleRemoveSelected,
    handleSelectAll,
    handleClearSelected,
    toggleSelection,
    isEnabled,
    isSelectedAll,
  };

  return <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>;
};

export { ReportsProvider, ReportsContext };
