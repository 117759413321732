import { FC } from 'react';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { reactPlugin } from '../../services/insights';

const InsightsWrapper: FC = ({ children }) => (
  <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
);

export default InsightsWrapper;
