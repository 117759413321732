import { FC, ReactNode, useEffect, useState } from 'react';

import { sortArrayOfObjects } from '../../../utils/functions';

import { TabIndex } from '../../LessonDetails';
import VfAutoComplete from '../vfAutocomplete';
import './vfTabs.scss';

interface Tab {
  component: ReactNode;
  id: number;
  name: string;
}

interface VfTabsProps {
  asDropdown?: boolean;
  callback?: (tabName: string) => void;
  dropDownLabel?: string;
  passedTab?: TabIndex;
  setPassedTab?: (tabIdx: number) => void;
  small?: boolean;
  tabs?: Tab[];
}

const VfTabs: FC<VfTabsProps> = ({
  asDropdown = false,
  callback,
  dropDownLabel,
  passedTab,
  setPassedTab,
  small,
  tabs = [],
}) => {
  const sortedTabs = sortArrayOfObjects(tabs, 'id');

  const [currentTab, setCurrentTab] = useState(passedTab ? sortedTabs[passedTab] : sortedTabs[0]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setCurrentTab(sortedTabs[passedTab || 0]);
  }, [sortedTabs, passedTab]);

  const renderTabs = sortedTabs.map((tab) => {
    const onClick = () => {
      if (callback) {
        callback(tab);
      }
      if (setPassedTab) {
        setPassedTab(sortedTabs?.indexOf(tab) || 0);
      }
      //  setCurrentTab(tab);
    };

    const tabId = `tab:${tab?.name?.split(' ')?.join('')}`;

    return (
      <li
        className={`vf-tab-bar-item ${tab?.id === currentTab?.id && 'vf-tab-bar-item--active'}`}
        id={tabId}
        key={tab?.id}
        onClick={onClick}
        role="tab"
      >
        <div className={`vf-tab-bar-link${small ? ' vf-tab-bar-link--small' : ''}`}>{tab.name}</div>
      </li>
    );
  });

  const tabsAsOptions = sortedTabs
    .filter((t) => t.name.toLocaleLowerCase().includes(filter?.toLocaleLowerCase()))
    .map((t) => ({
      label: t.name,
      value: t.name,
    }));

  const handleChange = (value: string) => {
    setFilter(value);
  };

  const onClick = (_: string, { value }: { value: string }) => {
    const foundTab = sortedTabs.find((t) => t.name === value);
    if (callback) {
      callback(foundTab);
    }
    if (setPassedTab) {
      setPassedTab(sortedTabs?.indexOf(foundTab) || 0);
    }
  };

  return (
    <>
      {sortedTabs.length > 0 && !asDropdown && (
        <div className="vf-container vf-tab-bar-container">
          <ul className="vf-tab-bar" role="tablist">
            {renderTabs}
          </ul>
        </div>
      )}

      {sortedTabs.length > 0 && asDropdown && (
        <div className="vf-container vf-tab-bar-container mt-3">
          <VfAutoComplete
            disableClearButton
            id="tabs-autocomplete"
            initialValue={sortedTabs[0].name}
            label={dropDownLabel}
            name="tab"
            onChange={handleChange}
            onClick={onClick}
            options={tabsAsOptions}
            preserveValue
          />
        </div>
      )}

      <currentTab.component {...currentTab.props} />
    </>
  );
};

VfTabs.defaultProps = {
  dropDownLabel: '',
  passedTab: 0,
  small: false,
};

export default VfTabs;
