import { FC, useState } from 'react';

import { Form, FormikProps } from 'formik';

import { LessonDetails } from '../../../../models/lessonDetails';
import { ADUser } from '../../../../models/user';

import {
  clearProcesses,
  clearWorkstreams,
  fetchProcesses,
  fetchWorkstreams,
  getProcesses,
  getWorkstreams,
} from '../../../../store/structure';
import { clearCurrentLesson } from '../../../../store/workshopSpaceLessons';

import { useBoolean } from '../../../../utils/hooks';
import usePhases from '../../../../utils/hooks/usePhases';
import PriorityTooltipMessage from '../../../../utils/tooltipMessages';

import { PRIORITIES } from '../../../../consts/newLessonForm';
import { useAppDispatch } from '../../../../root';
import DrawerFooter from '../../../FlexDrawer/Footer';
import { Input, MultiDropdown, Textarea } from '../../../Forms';
import { AutoCompleteWithReduxData } from '../../../Forms/Autocomplete';
import { StructureEntities } from '../../../Forms/Dropdown';
import IconButton from '../../../IconButton';
import Tooltip from '../../../Tooltip';
import UserSelector from '../../../UserSelector';
import { VfButton, VfIcon, VfRadios } from '../../../vfDesign';
import styles from './workshop-lesson-drawer.module.scss';

interface InfoTabProps extends FormikProps<LessonDetails> {
  onClose: () => void;
  isEditMode: boolean;
  isDisabled: boolean;
  currentLesson: LessonDetails;
}

const InfoTab: FC<InfoTabProps> = ({
  currentLesson,
  isDisabled,
  isEditMode = false,
  onClose,
  resetForm,
  setFieldValue,
  validateForm,
  values,
}) => {
  const dispatch = useAppDispatch();
  const { phasesWithoutNoOption: phases, isLoading } = usePhases();

  const [actionTakerSetManually, setActionTakerSetManually] = useState(
    isEditMode ? currentLesson?.actionTakerSetManually : false,
  );
  const [editActionTaker, setEditActionTaker] = useState(false);
  const [editCreator, setEditCreator] = useState(false);

  // eslint-disable-next-line
  const [editActionTakerDeputy, _, closeActionTakerDeputyEdit, toggleActionTakerDeputy] = useBoolean(false);
  const isHighPrioritySet = values?.priority === 'HIGH';

  const toggleActionTakerEdit = () => {
    setEditActionTaker(!editActionTaker);
  };

  const toggleCreatorEdit = () => {
    setEditCreator(!editCreator);
  };

  const tooltipText = 'Action taker will be filled automatically if you will choose Workstream or Process';

  const handleTooltipVisibility = (actionTakerEdition: boolean, actionTakerDisplayNameIsNotEmpty: boolean) => {
    return !actionTakerEdition && !actionTakerDisplayNameIsNotEmpty;
  };

  const onSubmitHandler = (option: StructureEntities) => {
    setTimeout(() => validateForm());
  };

  const handleClose = () => {
    resetForm();
    dispatch(clearCurrentLesson());
    onClose();
  };

  const setOwner = (item: { owner: { id: string } }) => {
    if (item?.owner && !actionTakerSetManually) {
      setFieldValue('actionTaker', item.owner);
      setFieldValue('actionTakerId', item.owner.id);
    }
    onSubmitHandler(null);
  };

  const onActionTakerSelect = (name: string, user: Partial<ADUser>) => {
    setFieldValue(name, user.id);
    setFieldValue('actionTaker', user);
    setFieldValue('actionTakerId', user.id);
    setEditActionTaker(false);
    setActionTakerSetManually(true);
  };

  const onCreatorSelect = (name: string, user: Partial<ADUser>) => {
    setFieldValue(name, user.id);
    setFieldValue('creator', user);
    setEditCreator(false);
  };

  const onActionTakerDeputySelect = (name: string, user: Partial<ADUser>) => {
    setFieldValue('actionTakerDeputy', user);
    setFieldValue('actionTakerDeputyId', user.id);
    closeActionTakerDeputyEdit();
  };

  const setPriority = (value: string) => {
    setFieldValue('priority', value);

    if (values.priority !== 'HIGH') {
      setFieldValue('priorityJustification', '');
    }
  };

  const handleActionTakerDeputyDelete = () => {
    setFieldValue('actionTakerDeputy', null);
    setFieldValue('actionTakerDeputyId', '');
  };

  const actionTakerContainerStyles = values?.actionTaker?.id ? styles['lesson-owner'] : styles['no-owner'];

  const creatorContainerStyles = values?.creator?.id ? styles['lesson-owner'] : styles['no-owner'];

  const showTooltip = handleTooltipVisibility(editActionTaker, !!values?.actionTaker?.displayName?.length);

  return (
    <Form>
      <div className={styles['drawer-form']}>
        <div className={styles['drawer-form__inputs']}>
          <Input label="Lesson title*" name="title" />
          <div className="mt-2">{isEditMode && <Input name="positionInWorkshop" label="Order" type="number" />}</div>
          <div className="mt-2">
            <Textarea name="description" label="What happened" />
          </div>
          <div className="mt-2">
            <Textarea name="recommendation" label="How to deal with it" />
          </div>
          <div className="mt-2">
            <AutoCompleteWithReduxData
              dispatchFn={fetchWorkstreams}
              label="Workstream"
              labelKey="name"
              name="workstreamId"
              selector={getWorkstreams}
              fetchOn={{
                projectTypeId: values['projectTypeId'],
              }}
              params={{
                organizationId: values['organizationId'],
                projectTypeId: values['projectTypeId'],
                lessonOrigin: 'PROJECT',
              }}
              onDismountCallback={clearWorkstreams}
              parentField={['operatingSiteId', 'projectTypeId']}
              relatedFields={['processId']}
              skipFields={['organizationId', 'projectTypeId', 'operatingSiteId']}
              onSelectCallback={setOwner}
            />
          </div>
          <div className="mt-2">
            <AutoCompleteWithReduxData
              dispatchFn={fetchProcesses}
              label="Process"
              labelKey="name"
              name="processId"
              selector={getProcesses}
              parentField={['workstreamId']}
              params={{
                organizationId: values['organizationId'],
                projectTypeId: values['projectTypeId'],
                workstreamId: values['workstreamId'],
                lessonOrigin: 'PROJECT',
              }}
              onDismountCallback={clearProcesses}
              skipFields={['organizationId', 'projectTypeId', 'workstreamId']}
              onSelectCallback={setOwner}
            />
            <MultiDropdown
              className="mt-2"
              isLoading={isLoading}
              label="Project Phase"
              name="tgPhase"
              options={phases}
            />
          </div>

          <div className="mt-2">
            <div className={styles['lesson-details__headline']}>
              Priority{` `}
              <Tooltip text={<PriorityTooltipMessage />}>
                <VfIcon name="info" />
              </Tooltip>
            </div>

            <VfRadios
              onBlur={() => {}}
              onChange={setPriority}
              value={values?.priority}
              options={PRIORITIES}
              className={styles['lesson-details__priorities']}
            />

            {isHighPrioritySet && (
              <div className="mt-2">
                <Input label="Reason to set high priority *" name="priorityJustification" />
              </div>
            )}
          </div>

          <div className="mt-2">
            <div className={styles['lesson-details__owner']}>
              <div className={styles['lesson-details__actionTaker-container']}>
                <div className={styles['lesson-details__headline']}>Action Taker</div>
                {showTooltip && (
                  <div className={styles['workshop__lesson__icons-container']}>
                    <Tooltip text={tooltipText} position="bottom" className={styles['lesson-details__tooltip']}>
                      <VfIcon name="info" />
                    </Tooltip>
                  </div>
                )}
              </div>

              <div className={actionTakerContainerStyles}>
                {values?.actionTaker?.displayName}
                <IconButton icon="pencil" onClick={toggleActionTakerEdit} />
              </div>
              <div className="mt-2">
                {editActionTaker && <UserSelector onSelect={onActionTakerSelect} name="actionTaker" />}
              </div>
            </div>
          </div>

          <div className="mt-2">
            <div className={styles['lesson-details__owner']}>
              <div className={styles['lesson-details__actionTaker-container']}>
                <div className={styles['lesson-details__headline']}>Action Taker Deputy</div>
              </div>

              <div className={actionTakerContainerStyles}>
                {values?.actionTakerDeputy?.displayName}
                {values?.actionTakerDeputy?.displayName ? (
                  <IconButton icon="trash" onClick={handleActionTakerDeputyDelete} />
                ) : null}
                <IconButton icon="pencil" onClick={toggleActionTakerDeputy} />
              </div>
              {editActionTakerDeputy && (
                <div className="mt-2">
                  <UserSelector onSelect={onActionTakerDeputySelect} name="actionTakerDeputy" />
                </div>
              )}
            </div>
          </div>

          <div className="mt-2 mb-4">
            <div className={styles['lesson-details__owner']}>
              <div className={styles['lesson-details__headline']}>Creator</div>
              <div className={creatorContainerStyles}>
                {values?.creator?.displayName}
                <IconButton icon="pencil" onClick={toggleCreatorEdit} />
              </div>

              {editCreator && (
                <div className="mt-2">
                  <UserSelector onSelect={onCreatorSelect} name="creator" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <DrawerFooter>
        <VfButton outline="secondary" onClick={handleClose}>
          Cancel
        </VfButton>
        <VfButton type="primary" htmlType="submit" disabled={isDisabled}>
          {isEditMode ? 'Update' : 'Create'}
        </VfButton>
      </DrawerFooter>
    </Form>
  );
};

export default InfoTab;
