import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { WorkshopSpace, WorkshopSpaceTableItem } from '../../models/workshop';

import { getIsAdmin } from '../../store/user';
import {
  clearCurrentWorkshopSpace,
  clearWorkshopSpace,
  fetchProjectEnvironments,
  getWorkshopSpaces,
  removeProjectEnvironment, // setCurrentWorkshopSpace,  // TODO: wait for business to respond if this action is needed
  setWorkshopSpacesPage,
  setWorkshopSpacesSort,
} from '../../store/workshopSpace';

import { useBoolean } from '../../utils/hooks';

import Spinner from '../../components/Spinner';
import { VfFab } from '../../components/vfDesign';
import { VfTable, VfPagination } from '../../components/vfDesign';
import { ActionOption } from '../../components/vfDesign/vfTable/actions';
import { ORDER } from '../../components/vfDesign/vfTable/headers';
import { dispatchConfirmationEvent } from '../../events/helpers';
import { useAppDispatch } from '../../root';
import WorkshopDrawer from './workshopDrawer';
import styles from './workshops.module.scss';

const Workshops: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isAdmin = useSelector(getIsAdmin);
  const [open, setOpen, onClose] = useBoolean(false);
  const {
    data: { content, totalPages, sortColumns },
    isLoading,
    page,
    sort,
  } = useSelector(getWorkshopSpaces);

  useEffect(() => {
    dispatch(fetchProjectEnvironments());
  }, [dispatch, page, sort.sortBy, sort.order]);

  useEffect(() => {
    return () => {
      dispatch(clearWorkshopSpace());
    };
  }, [dispatch]);

  // TODO: wait for business to respond if this action is needed
  // const handleEdit = ({ projectId, organizationId, projectTypeId }: WorkshopSpace): void => {
  //   dispatch(setCurrentWorkshopSpace({ projectId, organizationId, projectTypeId }));
  //   setOpen();
  // };

  const handleWorkshopDelete = (id: string): void => {
    dispatch(removeProjectEnvironment(id));
  };

  const confirmationHeader = () => (
    <p>
      Be aware that deleting this project workshop environment will immediately delete{' '}
      <span className={styles['workshops__red_warning']}> all lessons</span> of all workshops that are registered in the
      LLP workshop environment for this project. Directly after deletion, these lessons{' '}
      <span className={styles['workshops__red_warning']}>including their details</span> will not be visible or
      accessible anymore by anyone.
    </p>
  );

  const handleDelete = ({ id }: WorkshopSpace) => {
    dispatchConfirmationEvent(() => handleWorkshopDelete(id), confirmationHeader);
  };

  const setPage = (page: number): void => {
    dispatch(setWorkshopSpacesPage(page));
  };

  const handleSortChange = (sortBy: string, order: ORDER) => {
    dispatch(setWorkshopSpacesSort({ sortBy, order }));
  };

  const addNewProjectEnvironment = (): void => {
    dispatch(clearCurrentWorkshopSpace());
    setOpen();
  };

  const onRowClick = ({ item: { id } }: WorkshopSpaceTableItem) => {
    history.push(`/workshops/${id}`);
  };

  const rowActions: ActionOption[] = [
    // {   // TODO: wait for business to respond if this action is needed
    //   label: () => 'Edit',
    //   method: (row: WorkshopSpace) => handleEdit(row),
    //   disabled: () => false,
    // },
    {
      label: () => 'Delete',
      method: (row: WorkshopSpace) => handleDelete(row),
      disabled: () => false,
    },
  ];

  const tableItems: WorkshopSpaceTableItem[] = content?.map((item) => {
    return {
      projectName: item.projectName,
      businessUnit: item.organizationName,
      projectType: item.projectTypeName,
      item,
    };
  });

  return (
    <div className={styles.workshops}>
      <WorkshopDrawer open={open} onClose={onClose} />
      <div className="vf-container">
        <div className="vf-row">
          <div className="vf-col">
            <h1 className={styles['workshops__headline']}>Workshops</h1>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="vf-container">
          <div className="d-flex flex-fill justify-content-center align-items-center">
            <Spinner />
          </div>
        </div>
      )}

      {!isLoading && (
        <div className={`vf-container ${styles['bigger-for-monitors']}`}>
          <VfTable
            data={tableItems || []}
            onRowClick={onRowClick}
            rowActions={isAdmin ? rowActions : undefined}
            setSort={handleSortChange}
            sortBy={sort.sortBy}
            sortColumns={sortColumns}
            sortOrder={sort.order}
            withRowClick
          />
          {content?.length > 0 && (
            <VfPagination navType="icons" currentPage={page} totalPages={totalPages} changePage={setPage} />
          )}
        </div>
      )}

      {isAdmin && <VfFab onClick={addNewProjectEnvironment} />}
    </div>
  );
};

export default Workshops;
