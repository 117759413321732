import { FC } from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';

import {
  clearDepartments,
  fetchOrganizationContainers,
  fetchOrganizationDepartments,
  getDepartments,
  getOrganizationContainers,
} from '../../../../store/structure';

import useInfoElement from '../../../../utils/hooks/useInfoElement';

import { AutoCompleteWithReduxData } from '../../../Forms/Autocomplete';
import EnhancedFormikDropdown from '../../../Forms/Dropdown';
import Tooltip from '../../../Tooltip';
import { InfoElementNames } from '../../../Tooltip/consts';
import styles from '../../Shared.module.scss';

interface FormValues {
  organizationId: string;
  projectTypeId: string;
  operatingSiteId: string;
}

const OrganizationalUnitBasics: FC = () => {
  const { values } = useFormikContext<FormValues>();
  const [{ popupText: tooltipText }] = useInfoElement(InfoElementNames.ORGANIZATIONAL_UNIT);

  const tooltipParagraphStyles = classNames(styles.pair__labelRequired, styles.pair__paragraph__withTooltip);
  const tooltipDivStyles = classNames(styles.pair, styles.pair__withTooltip);

  return (
    <div className={styles.content}>
      <div className={styles.pair}>
        <p className={styles.pair__labelRequired}>Which Business Unit?</p>

        <EnhancedFormikDropdown
          dispatchFn={fetchOrganizationContainers}
          fullWidth
          label="Organization"
          labelKey="name"
          name="organizationId"
          relatedFields={['projectTypeId', 'projectId', 'workstreamId', 'processId']}
          selector={getOrganizationContainers}
          showMinifiedLabel={false}
          setByLabel
        />
      </div>

      <div className={tooltipDivStyles}>
        <Tooltip text={tooltipText} position='bottom'>
          <p className={tooltipParagraphStyles}>Which department?</p>
        </Tooltip>

        <AutoCompleteWithReduxData
          dispatchFn={fetchOrganizationDepartments}
          label="Department"
          labelKey="name"
          name="departmentId"
          disabled={!values.organizationId}
          params={{
            organizationId: values['organizationId'],
          }}
          relatedFields={['workstreamId', 'processId']}
          selector={getDepartments}
          onDismountCallback={clearDepartments}
          skipFields={['organizationId']}
          setByLabel
        />
      </div>
    </div>
  );
};

export default OrganizationalUnitBasics;
