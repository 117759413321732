import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  clearWorkshopDetails,
  fetchWorkshopSpace,
  getUserRightsForWorkshopSpace,
  getWorkshopSpace,
} from '../../../store/workshopDetails';

import { useBoolean } from '../../../utils/hooks';

import { useAppDispatch } from '../../../root';
// components
import BackToPage from '../../BackToPage';
import Spinner from '../../Spinner';
import { VfTabs } from '../../vfDesign';
import Workshop from '../Workshop';
import WorkshopDetailsDrawer from '../WorkshopDetailsDrawer';

interface Param {
  id: string;
}

interface WorkshopDetailsProps {}

const WorkshopDetails: FC<WorkshopDetailsProps> = () => {
  const dispatch = useAppDispatch();
  const [tabs, setTabs] = useState([]);
  const [passedTab, setPassedTab] = useState(0);
  const { id } = useParams<Param>();
  const { data, isLoading } = useSelector(getWorkshopSpace);
  const [open, setOpen, onClose] = useBoolean(false);
  const userRights = useSelector(getUserRightsForWorkshopSpace);
  const isUserAllowToCreateWorkshop = userRights?.canCreateWorkshops;

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [])

  useEffect(() => {
    dispatch(fetchWorkshopSpace(id));

    return () => {
      dispatch(clearWorkshopDetails());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const workshopTabs: any = data.workshops?.map((workshop, index) => {
        return {
          id: index,
          component: Workshop,
          name: workshop.name,
          props: {
            workshop,
            data,
            workshopSpaceId: id,
          },
        };
      });

      setTabs(workshopTabs);
    }
  }, [data?.workshops?.length, data, id]);

  return (
    <>
      <WorkshopDetailsDrawer open={open} onClose={onClose} workshopSpaceId={id} {...data} />
      {isUserAllowToCreateWorkshop && (
        <BackToPage
          title="Project environments"
          link="/workshops"
          withButton
          buttonTitle="Create new workshop"
          onButtonClick={setOpen}
        />
      )}
      {isLoading && (
        <div className="vf-container">
          <div className="d-flex flex-fill justify-content-center align-items-center vf-vh-100">
            <Spinner />
          </div>
        </div>
      )}
      {tabs?.length === 0 && (
        <div className="vf-container">
          <div className="d-flex flex-fill justify-content-center align-items-center vf-vh-100">
            <h3>No workshops created</h3>
          </div>
        </div>
      )}
      {tabs?.length > 0 && (
        <VfTabs tabs={tabs} passedTab={passedTab} setPassedTab={setPassedTab} asDropdown dropDownLabel="Workshop" />
      )}
    </>
  );
};

export default WorkshopDetails;
