import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice, Draft } from '@reduxjs/toolkit';

import {
  IAdvancedFilters,
  IDynamicState,
  IFilterSettings,
  IFilterSettingsWithStuctures,
  IFilterStructures,
  IOrganizationOrContainerItem,
  IOrganizationsAndContainersStructures,
  IProjectAndSitesStructures,
  IProjectOrSiteItem,
  IProjectTypeOrDepartmentItem,
  IProjectTypesAndDepartmentsStructures,
} from '../models/filterSettings';
import { Tag } from '../models/tags';

import { dashSpacedToCapitalize, formatDate, handleApiError, replaceFieldIdWIthName } from '../utils/functions';

import { ReduxStoreType } from '.';
import {
  DELETE_RELATIONS,
  FIELDS_NAMES_ONLY,
  FIELDS_TO_REPLACE_ID_WITH_NAME,
  FILTER_LABELS,
} from '../components/LessonsFilters/consts';
import { ChipEntity } from '../components/vfDesign/vfChips';
import Api from '../services/api';

export const advancedFilterInitials: IAdvancedFilters = {
  actionTakerDeputyId: '',
  actionTakerDeputyName: '',
  actionTakerId: '',
  actionTakerName: '',
  createDateLimitation: '',
  creatorId: '',
  creatorName: '',
  customDate: null,
  departmentId: '',
  departmentName: '',
  endDate: null,
  favourites: '',
  improvementDelivered: '',
  olc: '',
  operatingSiteId: '',
  operatingSiteName: '',
  organizationId: '',
  organizationName: '',
  origin: '',
  afterDueDate: '',
  delayed: '',
  poked: '',
  phaseName: '',
  priority: '',
  processId: '',
  processName: '',
  projectId: '',
  projectName: '',
  projectTypeId: '',
  projectTypeName: '',
  startDate: null,
  states: [],
  tags: [],
  tgPhase: [],
  workstreamId: '',
  workstreamName: '',
};

export const structuresInitials: IFilterStructures = {
  organizationsAndContainers: {
    data: [],
    isLoading: false,
  },
  projectTypesAndDepartments: {
    data: [],
    isLoading: false,
  },
  projectAndSites: {
    data: [],
    isLoading: false,
  },
};

export const initialState: IFilterSettingsWithStuctures = {
  page: 1,
  pageSize: 5,
  tabName: '',
  title: '',
  structures: structuresInitials,
  advancedFilters: {
    ...advancedFilterInitials,
  },
};

const filterRemovedValues = (
  filterValue: any,
  { value }: { value: string }, // TODO: fix any
) => (filterValue?.id ? filterValue.id !== value : filterValue !== value);

export const sliceName = 'filterSettings';

export const clearProjectAndSites = createAsyncThunk(`${sliceName}/clearProjectAndSites`, async () => {
  return [] as IProjectOrSiteItem[];
});

export const fetchOrganizationsAndContainers = createAsyncThunk(
  `${sliceName}/organizationsAndContainers`,
  async (_, { getState, dispatch }) => {
    try {
      const {
        [sliceName]: {
          structures: {
            organizationsAndContainers: { data: storeData },
          },
        },
      } = getState() as ReduxStoreType;

      if (storeData.length) {
        return storeData;
      }

      const { data } = await Api.get<IOrganizationOrContainerItem[]>('/structures/organizations-and-containers');

      return data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch organizations and containers for filters', dispatch);

      throw err;
    }
  },
);

export const fetchProjectTypesAndDepartments = createAsyncThunk(
  `${sliceName}/projectTypesAndDepartments`,
  async (_, { dispatch, getState }) => {
    try {
      const {
        [sliceName]: {
          structures: {
            projectTypesAndDepartments: { data: storeData },
          },
        },
      } = getState() as ReduxStoreType;

      if (storeData.length) {
        return storeData;
      }

      const { data } = await Api.get<IProjectTypeOrDepartmentItem[]>('/structures/projecttypes-and-departments');

      return data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch project types and departments for filters', dispatch);

      throw err;
    }
  },
);

export const fetchProjectAndSites = createAsyncThunk(
  `${sliceName}/projectAndSites`,
  async (_, { dispatch, getState }) => {
    try {
      const {
        [sliceName]: {
          structures: {
            projectAndSites: { data: storeData },
          },
        },
      } = getState() as ReduxStoreType;

      if (storeData.length) {
        return storeData;
      }

      const { data } = await Api.get<IProjectOrSiteItem[]>('/structures/projects-and-sites');

      return data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch projects and sites for filters', dispatch);

      throw err;
    }
  },
);

export const structuresReducers = [
  { action: fetchOrganizationsAndContainers, name: 'organizationsAndContainers' },
  { action: fetchProjectTypesAndDepartments, name: 'projectTypesAndDepartments' },
  { action: fetchProjectAndSites, name: 'projectAndSites' },
];

export const filterSettings = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    updateFilterSettings: (state: Draft<IFilterSettings>, { payload }) => {
      const { page, pageSize, title, tabName } = payload;

      state.title = title === '' ? title : title || state.title;
      state.page = page || initialState.page; // always reset page when apply new filter
      state.pageSize = pageSize || state.pageSize;
      state.tabName = tabName || state.tabName;
    },
    setAdvancedFilters: (state: Draft<IFilterSettings>, { payload }) => {
      state.advancedFilters = payload;
    },

    removeAdvancedFilter: (state: Draft<IFilterSettings>, { payload }) => {
      const filterId = payload.label;

      if (Array.isArray(state.advancedFilters[payload.label])) {
        state.advancedFilters[payload.label] = Array.from(state.advancedFilters[payload.label]).filter((filterValue) =>
          filterRemovedValues(filterValue, payload),
        );
      } else {
        state.advancedFilters[payload.label] = advancedFilterInitials[payload.label];
      }

      if (filterId === 'createDateLimitation') {
        DELETE_RELATIONS[filterId].forEach((dependentFilterId: string) => {
          state.advancedFilters[dependentFilterId] = advancedFilterInitials[dependentFilterId];
        });
      }
    },

    clearAdvancedFilters: (state: Draft<IFilterSettings>) => {
      state.advancedFilters = initialState.advancedFilters;
    },
  },
  extraReducers: (builder) => {
    structuresReducers.forEach(({ action, name }) => {
      builder.addCase(action.pending, (state: IDynamicState) => {
        state.structures[name].isLoading = true;
      });

      builder.addCase(action.fulfilled, (state: IDynamicState, { payload }) => {
        state.structures[name].data = payload;
        state.structures[name].isLoading = false;
      });

      builder.addCase(action.rejected, (state: IDynamicState) => {
        state.structures[name].isLoading = false;
      });
    });
  },
});

export const { clearAdvancedFilters, removeAdvancedFilter, setAdvancedFilters, updateFilterSettings } =
  filterSettings.actions;
export const getFilterSettings = (state: ReduxStoreType): IFilterSettings => {
  const { structures, ...filterSetting } = state[sliceName];

  return filterSetting;
};
export const getAdvancedFilters = (state: ReduxStoreType): IAdvancedFilters => state[sliceName].advancedFilters;
export const getOrganizationsAndContainers = (state: ReduxStoreType): IOrganizationsAndContainersStructures =>
  state[sliceName].structures.organizationsAndContainers;
export const getProjectTypesAndDepartments = (state: ReduxStoreType): IProjectTypesAndDepartmentsStructures =>
  state[sliceName].structures.projectTypesAndDepartments;
export const getProjectsAndSites = (state: ReduxStoreType): IProjectAndSitesStructures =>
  state[sliceName].structures.projectAndSites;

// TODO: tmp workaround, they still planning some filter changes after MVP
export const getFilterChips = (state: ReduxStoreType): ChipEntity[] => {
  const { advancedFilters } = state[sliceName] as IFilterSettings;
  const chips: ChipEntity[] = [];

  const createChip = (label: string, value: string | Tag) => {
    const labelsToRemove = ['customDate', 'startDate', 'endDate'];
    if (labelsToRemove.includes(label)) {
      return;
    }

    if (label === 'tags') {
      const tag = value as Tag;

      chips.push({
        label: `${FILTER_LABELS[label]}: ${tag.name}`,
        value: { label, value: tag.id },
      });
    } else if (
      label === 'createDateLimitation' &&
      value === 'CUSTOM_DATE' &&
      advancedFilters.startDate &&
      advancedFilters.endDate
    ) {
      const formattedStartDate = formatDate(advancedFilters.startDate.toISOString());
      const formattedEndDate = formatDate(advancedFilters.endDate.toISOString());
      chips.push({
        label: `Date added: ${formattedStartDate} - ${formattedEndDate}`,
        value: { label: label, value: value },
      });
    } else if (
      label === 'createDateLimitation' &&
      value === 'CUSTOM_DATE' &&
      !(advancedFilters.startDate && advancedFilters.endDate)
    ) {
      return;
    } else if (FIELDS_TO_REPLACE_ID_WITH_NAME.includes(label)) {
      // to replace id value with name
      const nameField = replaceFieldIdWIthName(label);

      chips.push({ label: `${FILTER_LABELS[label]}: ${advancedFilters[nameField]}`, value: { label, value } });
    } else if (!FIELDS_NAMES_ONLY.includes(label)) {
      // to hide name fields for other types: priority, status etc
      // chips.push({ label: `${FILTER_LABELS[label]}: ${dashSpacedToCapitalize(value)}`, value: label })

      chips.push({
        label: `${FILTER_LABELS[label]}: ${label === 'tgPhase' ? value : dashSpacedToCapitalize(value as string)}`,
        value: { label, value },
      });
    }
  };

  Object.entries(advancedFilters).forEach(([label, value]: [string, string | string[]]) => {
    if (Array.isArray(value) && value.length) {
      Array.from(value).forEach((valueElement) => {
        const labelsToRemove = ['customDate', 'startDate', 'endDate'];
        if (!labelsToRemove.includes(label.trim())) {
          createChip(label, valueElement);
        }
      });
    } else if (!Array.isArray(value) && value) {
      createChip(label, value);
    }
  });

  return chips.filter((v) => v);
};

export default filterSettings.reducer;
