import React from 'react';

const PriorityTooltipMessage = () => {
  return (
    <>
      <div>
        <strong>Low:{' '}</strong>
        Lesson has no direct action connected to it or action has already been taken.<br />
      </div>
      <div>
        <strong>Medium:{' '}</strong>
        Lesson has substantial potential for improvement.<br />
      </div>
      <div>
        <strong>High:{' '}</strong>
        Lesson has extremely high potential for improvement or requires urgent attention.<br />
      </div>
    </>
  );
};

export default PriorityTooltipMessage;
