import { FC, SyntheticEvent } from 'react';

import classNames from 'classnames';

import Icon from '../Icon';
import styles from './IconButton.module.scss';

export interface IconButtonProps {
  className?: string;
  color?: string;
  disabled?: boolean;
  doNotPropagate?: boolean;
  htmlType?: 'button' | 'reset' | 'submit';
  icon: string;
  onClick?: () => void;
}

const IconButton: FC<IconButtonProps> = ({
  children,
  className,
  color,
  disabled,
  doNotPropagate = false,
  htmlType,
  icon,
  onClick = () => {},
  ...rest
}) => {
  const buttonClasses = classNames(styles.button, className);

  const handleClick = (e: SyntheticEvent) => {
    if (doNotPropagate) {
      e.stopPropagation();
    }

    onClick();
  };

  return (
    <button {...rest} type={htmlType} onClick={handleClick} disabled={disabled} className={buttonClasses}>
      <Icon name={icon} color={color} />
    </button>
  );
};

IconButton.defaultProps = {
  className: '',
  color: '',
  disabled: false,
  doNotPropagate: false,
  htmlType: 'button',
  icon: '',
  onClick: () => {},
};

export default IconButton;
