import { FC } from 'react';

import classNames from 'classnames';

import Icon from '../Icon';
import styles from './status-icon.module.scss';

interface StatusIconProps {
  color?: string;
  iconName: string;
  title: string;
  subtitle?: string;
}

const StatusIcon: FC<StatusIconProps> = ({ iconName, color, title, subtitle }) => {
  const iconStyles = classNames(styles['status-icon__icon'], styles[`status-icon__icon--${color}`]);
  const statusStyles = classNames(styles['status-icon__status'], {
    [styles['status-icon__status--with-subtitle']]: !!subtitle
  });

  return (
    <div className={styles['status-icon']}>
      <div className={iconStyles}>
        <Icon name={iconName} />
      </div>
      <div className={statusStyles}>
        <span className={styles.title}>{title}</span>
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      </div>
    </div>
  );
};

StatusIcon.defaultProps = {
  color: 'light-grey',
  subtitle: '',
};

export default StatusIcon;
