import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearAdvancedFilters,
  getFilterChips,
  removeAdvancedFilter,
  updateFilterSettings,
} from '../../store/filterSettings';
import { clearLessons, setInitialTotalPages, setRestartPages } from '../../store/lessons';

import { isMobile } from '../../utils/functions';
import useDebouncedValue from '../../utils/hooks/useDebouncedValue';

import { ReportsContext } from '../../context';
import Icon from '../Icon';
import BulkUpdateLessonsDrawer from '../LessonDetails/BulkUpdateLessonsDrawer/BulkUpdateLessonsDrawer';
import { useBulkUpdateLessonsPermissions } from '../LessonDetails/BulkUpdateLessonsDrawer/useBulkUpdateLessonsPermissions';
import LessonFilters from '../LessonsFilters';
import { DELETE_RELATIONS } from '../LessonsFilters/consts';
import { ReportsDrawer } from '../ReportsDrawer';
import { VfButton, VfChips, VfInput } from '../vfDesign';
import styles from './Searchbar.module.scss';

export interface SearchBarProps {
  placeholder?: string;
}

const SearchBar: FC<SearchBarProps> = () => {
  const dispatch = useDispatch();

  const { isEnabled, isSelectedAll, handleSelectAll, toggleSelection, selected, handleClearSelected } =
    useContext(ReportsContext);

  const { canBulkEditActionTaker, canBulkEditActionTakerDeputy } = useBulkUpdateLessonsPermissions(selected);

  const chips = useSelector(getFilterChips);
  const [title, setTitle] = useState<string>('');
  const [filtersDrawerIsOpen, setFilterDrawerIsOpen] = useState<boolean>(false);
  const [queryValue, setQuery] = useDebouncedValue(400);
  const [reportsDrawerOpened, setReportsDrawerOpened] = useState(false);
  const [bulkUpdateDrawerIsOpen, setBulkUpdateDrawerIsOpen] = useState(false);

  useEffect(() => {
    dispatch(updateFilterSettings({ title: queryValue }));
  }, [dispatch, queryValue]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const newTitle = e?.currentTarget?.value || '';
    dispatch(setRestartPages());
    dispatch(setInitialTotalPages());
    dispatch(clearLessons([]));
    setTitle(newTitle);
    setQuery(newTitle);
  };

  const showFiltersDrawer = (): void => {
    setFilterDrawerIsOpen(true);
  };
  const hideFiltersDrawer = (): void => {
    setFilterDrawerIsOpen(false);
  };
  const onRemove = (id: string): void => {
    dispatch(removeAdvancedFilter(id));
    dispatch(setRestartPages());
    dispatch(setInitialTotalPages());
    dispatch(clearLessons([]));
    if (isSelectedAll) {
      handleSelectAll?.([]);
    }

    if (DELETE_RELATIONS[id]) {
      DELETE_RELATIONS[id].forEach((relatedFilterId: string) => {
        dispatch(removeAdvancedFilter(relatedFilterId));
      });
    }
  };

  const onBlur = () => {};

  const clearAll = (): void => {
    dispatch(setRestartPages());
    dispatch(setInitialTotalPages());
    dispatch(clearLessons([]));
    dispatch(clearAdvancedFilters());
    if (isSelectedAll) {
      handleSelectAll?.([]);
    }
  };

  const placeholder = isMobile() ? 'Find a lesson' : 'Find a lesson (e.g. dry summer months)';

  const handleSelection = () => {
    if (isEnabled) {
      handleClearSelected?.();
    }
    toggleSelection?.();
  };

  const handleCloseReportsDrawer = () => {
    setReportsDrawerOpened(false);
  };

  const handleOpenReportsDrawer = () => {
    setReportsDrawerOpened(true);
  };

  const handleOpenBulkUpdateDrawer = () => {
    setBulkUpdateDrawerIsOpen(true);
  };

  const handleCloseBulkUpdateDrawer = () => {
    setBulkUpdateDrawerIsOpen(false);
  };

  const canOpenBulkUpdateDrawer = canBulkEditActionTaker || canBulkEditActionTakerDeputy;

  return (
    <>
      <BulkUpdateLessonsDrawer isOpen={bulkUpdateDrawerIsOpen} onClose={handleCloseBulkUpdateDrawer} />
      <ReportsDrawer isOpen={reportsDrawerOpened} onClose={handleCloseReportsDrawer} />
      <LessonFilters isOpen={filtersDrawerIsOpen} onClose={hideFiltersDrawer} open={filtersDrawerIsOpen} side="right" />
      <div className={styles.searchbar}>
        <div className={styles.searchbar__elements}>
          <VfInput
            icon="search"
            id="searchInput"
            onChange={onChange}
            onBlur={onBlur}
            value={title}
            placeholder={placeholder}
            rounded
          />
          <VfButton outline="secondary" onClick={showFiltersDrawer}>
            <Icon className="mr-1" color="blue" name="filter" />
            Filters
          </VfButton>
          <VfButton outline="secondary" onClick={handleSelection}>
            <Icon className="mr-1" color="blue" name="progress--todo" />
            {isEnabled ? 'Clear' : 'Select'}
          </VfButton>
          {isEnabled && (
            <VfButton outline="secondary" onClick={handleOpenReportsDrawer} disabled={!selected.length}>
              <Icon className="mr-1" color="blue" name="file" />
              Generate report
            </VfButton>
          )}
          {isEnabled && canOpenBulkUpdateDrawer && (
            <VfButton outline="secondary" onClick={handleOpenBulkUpdateDrawer} disabled={!selected.length}>
              <Icon className="mr-1" color="blue" name="user" />
              Change Action Takers
            </VfButton>
          )}
        </div>
        <div className={styles.searchbar__chips}>
          <VfChips chips={chips} clearAll={clearAll} onRemove={onRemove} />
        </div>
      </div>
    </>
  );
};

export default SearchBar;
