export enum InstructionLinkType {
  VIDEO_TUTORIAL = 'Video Tutorial',
  GUIDE = 'Guide',
}

export interface InstructionLink {
  id: string;
  name: string;
  url: string;
  type: keyof typeof InstructionLinkType;
}

export interface NewInstructionLink {
  name: string;
  url: string;
  type: InstructionLinkType;
}
