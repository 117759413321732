import { v4 } from 'uuid';

import { CreateConfirmationEvent, CreateToastEvent, EVENT_DEFAULTS, EVENT_TYPES } from './types';

// Queue Events

export const dispatchQueueEvent = (eventToBeQueued: CustomEvent, timeout = null): void => {
  const event = new CustomEvent(EVENT_TYPES.QUEUE, {
    bubbles: true,
    detail: {
      id: v4(),
      event: eventToBeQueued,
      timeout,
    },
  });

  document.dispatchEvent(event);
};

export const dispatchClearFromQueueEvent = (id: string): void => {
  const event = new CustomEvent(EVENT_TYPES.CLEAR_FROM_QUEUE, {
    bubbles: true,
    detail: {
      id,
    },
  });

  document.dispatchEvent(event);
};

// Confirmation Events

export const createConfirmationEvent = (
  callback: () => void = () => {},
  header = EVENT_DEFAULTS.CONFIRMATION.HEADER,
): CreateConfirmationEvent => {
  const event = new CustomEvent(EVENT_TYPES.CONFIRM_MODAL_EVENT, {
    bubbles: true,
    detail: {
      id: v4(),
      callback,
      header,
    },
  });

  return [event, event.detail.id];
};

export const dispatchConfirmationEvent = (callback: () => void, header = EVENT_DEFAULTS.CONFIRMATION.HEADER): void => {
  const [event] = createConfirmationEvent(callback, header);

  document.dispatchEvent(event);
};

export const queueConfirmationEvent = (
  callback: () => void,
  header = EVENT_DEFAULTS.CONFIRMATION.HEADER,
  timeout = null,
): string | Element => {
  const [event, id] = createConfirmationEvent(callback, header);

  dispatchQueueEvent(event, timeout);

  return id;
};

// Toast events

export const createToastEvent = (
  callback: () => void = () => {},
  header = EVENT_DEFAULTS.TOAST.HEADER,
  position = EVENT_DEFAULTS.TOAST.POSITION,
): CreateToastEvent => {
  const event = new CustomEvent(EVENT_TYPES.TOAST_MODAL_EVENT, {
    bubbles: true,
    detail: {
      id: v4(),
      callback,
      header,
      position,
    },
  });

  return [event, event.detail.id];
};

export const dispatchToastEvent = (
  callback: () => void = () => {},
  header = EVENT_DEFAULTS.TOAST.HEADER,
  position = EVENT_DEFAULTS.TOAST.POSITION,
): void => {
  const [event] = createToastEvent(callback, header, position);

  document.dispatchEvent(event);
};
