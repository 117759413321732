import { FC, ReactElement, useEffect, useState } from 'react';

export interface WrappedWithDelayedUnMountProps {
  delayTime?: number;
  full?: boolean;
  isMounted: boolean;
  isOpen?: boolean; // TODO: check issue with drawer
  onClose?: () => void;
  onOutsideClick?: () => void;
  side?: string;
  wide?: boolean;
}

const withDelayedUnMounting = <P extends object>(BaseComponent: FC<P>) => {
  const WrappedWithDelayedUnMount: FC<WrappedWithDelayedUnMountProps> = ({ isMounted, delayTime, ...rest }) => {
    const [shouldRender, setShouldRender] = useState(isMounted);

    useEffect(() => {
      let timeout: any;

      if (!isMounted) {
        timeout = setTimeout(() => setShouldRender(false), delayTime);
      }

      if (isMounted) {
        setShouldRender(true);
      }

      return () => clearTimeout(timeout);
    }, [isMounted, delayTime]);

    if (shouldRender) {
      return <BaseComponent {...(rest as P)} />;
    }

    return null;
  };

  return WrappedWithDelayedUnMount;
};

const DelayedWrapper = withDelayedUnMounting(({ children }: { children: ReactElement }) => children);

export { withDelayedUnMounting as default, DelayedWrapper };
