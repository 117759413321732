import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getLessons } from '../../../store/lessons';
import { getIsAdmin, getUserId } from '../../../store/user';

import { ISelectedReportItem } from '../../../context';

const useBulkUpdateLessonsPermissions = (selected: ISelectedReportItem[]) => {
  const userId = useSelector(getUserId);
  const isAdmin = useSelector(getIsAdmin);
  const { data } = useSelector(getLessons);

  const { isActionTakerDeputyForAllSelected, isActionTakerForAllSelected } = useMemo(() => {
    const selectedLessons = data.filter(({ id }) => selected.find((item) => item.id === id));
    const isActionTakerForAllSelected = selectedLessons.every(({ actionTaker }) => actionTaker?.id === userId);
    const isActionTakerDeputyForAllSelected = selectedLessons.every(
      ({ actionTakerDeputy }) => actionTakerDeputy?.id === userId,
    );

    return {
      isActionTakerDeputyForAllSelected,
      isActionTakerForAllSelected,
    };
  }, [data, selected, userId]);

  const canBulkEditActionTaker = isAdmin || isActionTakerForAllSelected;
  const canBulkEditActionTakerDeputy = isAdmin || isActionTakerDeputyForAllSelected;

  return {
    canBulkEditActionTaker,
    canBulkEditActionTakerDeputy,
  };
};

export { useBulkUpdateLessonsPermissions };
