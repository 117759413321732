export interface Message<K = {}> extends MessageResponse {
  reached?: K;
  setReachedState: (state: K) => void;
}

export interface Parameters {
  [key: string]: string;
}

export interface MessageResponse {
  createDate: Date;
  id: string;
  markedRead: boolean;
  notificationType: string;
  parameters: Parameters;
  severity: string;
}

export interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface PageAble {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: Sort;
  unpaged: boolean;
}

export interface MessagesPaginatedResponse {
  content: MessageResponse[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: PageAble;
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;
}

export const MESSAGE_TYPE: { [key: string]: string } = {
  LESSON_CREATED: 'New lesson was created',
  LESSON_UPDATED: 'Lesson was updated',
  LESSON_CLOSED: 'Lesson resolved',
  LESSON_NOT_PROGRESSED: 'No actions were performed on a lesson',
  ASSIGNED_TO_LESSON: 'User was assigned to a lesson',

  BULK_UPDATE_ABANDONED_FOR_FOLLOWING_PROJECTS: 'Project has been removed from update schedule',
  BULK_UPDATE_FAILED_FOR_FOLLOWING_PROJECTS: 'Failed to update following projects',
  BULK_UPDATE_SUCCEEDED: 'Following projects have been updated',
  COMMENT_ADDED: 'You were mentioned in a comment',
  COMMENT_CHANGED: 'Comment was updated',
  DOCUMENT_LINKED_TO_PARENT_DOCUMENT: 'Related document was linked',
  DOCUMENT_UNLINKED_FROM_PARENT_DOCUMENT: 'Related document was unlinked',
  DOCUMENT_UNLINKED_FROM_SHAREPOINT: 'Document was unlinked from sharepoint',
  DOCUMENT_UNLINKED_FROM_TASK: 'Document was unlinked',
  NEW_DOCUMENT_LINKED: 'New document was linked',
  NEW_TASK_ASSIGNMENT: 'New task was assigned to you',
  PROJECT_CREATED: 'Project created',
  PROJECT_MOVED_TO_NEXT_TOLLGATE: 'Project moved to the next tollgate',
  PROJECT_STATUS_CHANGED: 'Project status has changed',
  PROJECT_TIMELINE_UPDATED: 'Project timeline deadlines were updated',
  TASK_CHANGED: 'One of your tasks was changed',
  TASK_DEADLINE_OVERDUE: 'One of your task is overdue',
  TAXONOMY_MAPPING_FAILURE: 'Failure while mapping taxonomy',
  TAXONOMY_UPLOAD_FAILURE: 'Failure while updating taxonomy',
  UPCOMING_MILESTONE: 'Next milestone will be reached soon',
  UPCOMING_PHASE_DECISION: 'Next TG/RM decision will take place soon',
  UPCOMING_TASK_DEADLINE: 'One of your task will be due',
  UPDATE_CANCELLED_FOR_PROJECT: 'Project update has been canceled',
  USER_ASSIGNED_TO_PROJECT_ROLE: 'You have new tasks',
};

export const MESSAGES_WITH_LINK: string[] = ['NOT_AVAILABLE_YET'];
