import { FileWithPath } from 'react-dropzone';

import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ADUser } from '../models/user';
import { UserRights, UserRightsForWorkshopSpace, Workshops, WorkshopSpace } from '../models/workshop';

import { handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import Api from '../services/api';

export interface WorkshopCreateInitialValues {
  facilitators: ADUser[];
  participants: ADUser[];
  performedAt: Date[] | null;
  workshopState: string;
  name: string;
}

export interface WorkshopLessonInitialValues {
  actionTaker: Partial<ADUser> | null;
  actionTakerId: string | undefined;
  actionTakerDeputy: Partial<ADUser> | null;
  actionTakerDeputyId: string | undefined;
  creator: ADUser;
  creatorId: string;
  description: string;
  filesUpload: FileWithPath[];
  organizationId: string;
  origin: any;
  positionInWorkshop: string | number;
  priority: string;
  priorityJustification: string | undefined;
  processId: string;
  projectTypeId: string;
  recommendation: string;
  tgPhase: string[] | null;
  title: string;
  workshopId: string;
  workstreamId: string;
}

export interface WorkshopSpacesState {
  current: Workshops;
  data: WorkshopSpace;
  isCurrentLoading: boolean;
  isEditMode: boolean;
  isLoading: boolean;
  participants: ADUser[];
  participantsIsLoading: boolean;
}

export interface GetParticipants {
  participants: ADUser[];
  participantsIsLoading: boolean;
}

export interface GetIsEditMode {
  isEditMode: boolean;
}

export interface GetWorkshopSpace {
  data: WorkshopSpace;
  isLoading: boolean;
}

export interface GetCurrentWorkshop {
  current: Workshops;
  isCurrentLoading: boolean;
}

export interface GetIsAdded {
  isAdded: boolean;
}
export interface EditWorkshopArgs {
  id: string;
  body: any;
  workshopEnvId?: string;
}

export interface AddWorkshop {
  id: string;
  body: any;
}

export interface FetchParticipants {
  organizationId: string;
  projectTypeId: string;
  projectId: string;
}

export interface DeleteLessonFromWorkshopArgs {
  id: string;
  workshopId: string;
}

export interface EditLessonInWorkshopArgs {
  id: string;
  body: WorkshopLessonInitialValues;
}

export const sliceName = 'workshopDetails';
export const INITIAL_PAGE: number = 1;
export const PAGE_SIZE: number = 30;

export const fetchWorkshopSpace = createAsyncThunk(`${sliceName}`, async (id: string, { dispatch }) => {
  try {
    const response = await Api.get<WorkshopSpace>(`workshop-spaces/${id}`);
    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch workshop space details', dispatch);

    throw err;
  }
});

export const fetchParticipants = createAsyncThunk(
  `${sliceName}/participants`,
  async ({ organizationId, projectTypeId, projectId }: FetchParticipants, { dispatch }) => {
    try {
      const response = await Api.get<ADUser[]>(
        `structures/organizations/${organizationId}/project-types/${projectTypeId}/projects/${projectId}/members`,
      );

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch project participants', dispatch);

      throw err;
    }
  },
);

export const addWorkshop = createAsyncThunk(
  `${sliceName}/workshops`,
  async ({ body, id }: AddWorkshop, { dispatch }) => {
    try {
      const response = await Api.post<Workshops>('/workshops', body);

      await dispatch(fetchWorkshopSpace(id));
      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not add workshops', dispatch);

      throw err;
    }
  },
);

export const fetchWorkshop = createAsyncThunk(`${sliceName}/workshops/id`, async (id: string, { dispatch }) => {
  try {
    const response = await Api.get<Workshops>(`/workshops/${id}`);
    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch workshop', dispatch);
    throw err;
  }
});

export const editWorkshop = createAsyncThunk(
  `${sliceName}/workshops/id/edit`,
  async ({ id, body, workshopEnvId }: EditWorkshopArgs, { dispatch }) => {
    try {
      const response = await Api.patch<Workshops>(`/workshops/${id}`, body);

      if (workshopEnvId) {
        await dispatch(fetchWorkshopSpace(workshopEnvId));
      }
      await dispatch(fetchWorkshop(id));

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not edit workshop', dispatch);
    }
  },
);

export const initialState: WorkshopSpacesState = {
  current: {} as Workshops,
  data: {} as WorkshopSpace,
  isCurrentLoading: false,
  isEditMode: false,
  isLoading: false,
  participants: [],
  participantsIsLoading: false,
};

export const WorkshopSpacesReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearWorkshopDetails: (state: WorkshopSpacesState) => {
      Object.assign(state, initialState);
    },
    setCurrentWorkshop: (state: WorkshopSpacesState) => {
      state.isEditMode = true;
    },
    clearEditMode: (state: WorkshopSpacesState) => {
      state.isEditMode = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchParticipants.pending, (state: WorkshopSpacesState) => {
      state.participantsIsLoading = true;
    });
    builder.addCase(fetchParticipants.fulfilled, (state: WorkshopSpacesState, { payload }) => {
      state.participants = payload;
      state.participantsIsLoading = false;
    });
    builder.addCase(fetchParticipants.rejected, (state: WorkshopSpacesState) => {
      state.participantsIsLoading = false;
    });
    builder.addCase(fetchWorkshopSpace.pending, (state: WorkshopSpacesState) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWorkshopSpace.fulfilled, (state: WorkshopSpacesState, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchWorkshopSpace.rejected, (state: WorkshopSpacesState) => {
      state.isLoading = false;
    });
    builder.addCase(fetchWorkshop.pending, (state: WorkshopSpacesState) => {
      state.isCurrentLoading = true;
    });
    builder.addCase(fetchWorkshop.fulfilled, (state: WorkshopSpacesState, { payload }) => {
      state.isCurrentLoading = false;
      state.current = payload;
    });
    builder.addCase(fetchWorkshop.rejected, (state: WorkshopSpacesState) => {
      state.isCurrentLoading = false;
    });
  },
});

export const getParticipants = (state: ReduxStoreType): GetParticipants => ({
  participants: state[sliceName].participants,
  participantsIsLoading: state[sliceName].participantsIsLoading,
});

export const getWorkshopSpace = (state: ReduxStoreType): GetWorkshopSpace => ({
  data: state[sliceName].data,
  isLoading: state[sliceName].isLoading,
});

export const getCurrentWorkshop = (state: ReduxStoreType): GetCurrentWorkshop => ({
  current: state[sliceName].current,
  isCurrentLoading: state[sliceName].isCurrentLoading,
});

export const getIsEditMode = (state: ReduxStoreType): GetIsEditMode => ({
  isEditMode: state[sliceName].isEditMode,
});

export const getWorkshopDetails = (state: ReduxStoreType): WorkshopSpacesState => state[sliceName];

export const getUserRights = (state: ReduxStoreType): UserRights => state[sliceName].current?.userRights;

export const getUserRightsForWorkshopSpace = (state: ReduxStoreType): UserRightsForWorkshopSpace =>
  state[sliceName].data?.userRights;

export const { clearEditMode, clearWorkshopDetails, setCurrentWorkshop } = WorkshopSpacesReducer.actions;

export default WorkshopSpacesReducer.reducer;
