import { StructureEntities } from '../../../components/Forms/Dropdown';
import { replaceFieldIdWIthName } from '../../functions';

export const handleRelatedFields = (
  relatedFields: string[],
  setFieldValue: (name: string, value: string) => void,
  isSetByLabel: boolean = false,
): void => {
  if (relatedFields?.length) {
    relatedFields.forEach((fieldName) => {
      setFieldValue(fieldName, '');
      if (isSetByLabel) {
        const fieldToClear = replaceFieldIdWIthName(fieldName);
        setFieldValue(fieldToClear, '');
      }
    });
  }
};

export const handleSelectCallback = (
  onSelectCallback: ((option: StructureEntities) => void) | null = null,
  data: StructureEntities[],
  searchValue: string = '',
): void => {
  if (onSelectCallback) {
    const selected = data.find((item: StructureEntities) => item.id === searchValue) || {};

    onSelectCallback(selected);
  }
};
