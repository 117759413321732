import { FC } from 'react';

import classNames from 'classnames';

import { upperCamelCaseToSpaced } from '../../../utils/functions';

import VfIcon from '../vfIcon';

export enum ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface HeadersProps {
  header: string;
  setSort?: (header: string, order: ORDER) => void;
  sortBy?: string;
  sortOrder?: string;
  withSort: boolean;
}

const Headers: FC<HeadersProps> = ({ header, withSort, setSort, sortOrder, sortBy }) => {
  const isSortedByThisHeader = sortBy?.toUpperCase() === header.toUpperCase();

  const iconFlipped = isSortedByThisHeader && sortOrder === ORDER.DESC;
  const iconClasses = classNames('ml-2', 'sort-icon', {
    'sort-icon--UP': iconFlipped,
  });

  const handleClick = () => {
    if (withSort && setSort) {
      const order = sortOrder === ORDER.ASC ? ORDER.DESC : ORDER.ASC;

      setSort(header.toUpperCase(), order);
    }
  };

  const handleDisplayHeaderWithOrder = () => {
    if (header === 'position_in_workshop') {
      return upperCamelCaseToSpaced('Order');
    }
    return upperCamelCaseToSpaced(header);
  };

  return (
    <th onClick={handleClick} id={header}>
      <span className={`${withSort ? 'sort' : ''}`}>
        {handleDisplayHeaderWithOrder()}
        {withSort && (
          <VfIcon name="chevron--full" color={isSortedByThisHeader ? 'blue' : 'grey'} className={iconClasses} />
        )}
      </span>
    </th>
  );
};

Headers.defaultProps = {
  setSort: () => {},
  sortBy: ORDER.ASC,
  sortOrder: '',
};

export default Headers;
