import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  Department,
  ImprovementType,
  Organization,
  OrganizationContainer,
  Phase,
  Process,
  Project,
  ProjectType,
  Site,
  Workstream,
} from '../models/structure';

import { dashSpacedToCapitalize, handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import { IOption } from '../components/vfDesign/vfDropdown/models';
import Api from '../services/api';

export const sliceName = 'structures';

const TAG_DEPARTMENT = 'department';
const TAG_IMPROVEMENT_TYPES = 'improvementTypes';
const TAG_ORGANIZATION = 'organization';
const TAG_ORGANIZATION_CONTAINERS = 'organization-containers';
const TAG_PHASES = 'phases';
const TAG_PROCESS = 'process';
const TAG_PROJECT = 'project';
const TAG_PROJECT_TYPE = 'projectType';
const TAG_SITES = 'sites';
const TAG_WORKSTREAM = 'workstream';

export interface DynamicState {
  [key: string]: { data: any; isLoading: boolean };
}

export interface IStructureItemState<T> {
  data: T[];
  isLoading: boolean;
}

export interface StructureState {
  [TAG_DEPARTMENT]: IStructureItemState<Department>;
  [TAG_IMPROVEMENT_TYPES]: IStructureItemState<ImprovementType>;
  [TAG_ORGANIZATION_CONTAINERS]: IStructureItemState<OrganizationContainer>;
  [TAG_ORGANIZATION]: IStructureItemState<Organization>;
  [TAG_PHASES]: IStructureItemState<Phase>;
  [TAG_PROCESS]: IStructureItemState<Process>;
  [TAG_PROJECT_TYPE]: IStructureItemState<ProjectType>;
  [TAG_PROJECT]: IStructureItemState<Project>;
  [TAG_SITES]: IStructureItemState<Site>;
  [TAG_WORKSTREAM]: IStructureItemState<Workstream>;
}

export interface GetProjectTypeParams {
  organizationId: string;
}

export interface GetDepartmentTypeParams extends GetProjectTypeParams {}

export interface GetSitesTypeParams extends GetProjectTypeParams {
  projectTypeId: string;
}

export interface GetProjectParams {
  organizationId: string;
  projectTypeId: string;
}

export interface GetWorkstreamParams {
  organizationId: string;
  projectTypeId: string;
  departmentId: string;
  lessonOrigin?: string;
}

export interface GetProcessParams {
  organizationId: string;
  projectTypeId: string;
  workstreamId: string;
  departmentId: string;
  lessonOrigin?: string;
}

export type StructureTypes =
  | IStructureItemState<Department>
  | IStructureItemState<OrganizationContainer>
  | IStructureItemState<Organization>
  | IStructureItemState<Phase>
  | IStructureItemState<ImprovementType>
  | IStructureItemState<Process>
  | IStructureItemState<ProjectType>
  | IStructureItemState<Project>
  | IStructureItemState<Site>
  | IStructureItemState<Workstream>;

export const initialState: StructureState = [
  TAG_DEPARTMENT,
  TAG_IMPROVEMENT_TYPES,
  TAG_ORGANIZATION_CONTAINERS,
  TAG_ORGANIZATION,
  TAG_PHASES,
  TAG_PROCESS,
  TAG_PROJECT_TYPE,
  TAG_PROJECT,
  TAG_SITES,
  TAG_WORKSTREAM,
].reduce((acc: any, curr: string) => {
  // TODO: handle any
  acc[curr] = {
    data: [],
    isLoading: false,
  };

  return acc;
}, {});

export const fetchOrganizations = createAsyncThunk(`${sliceName}/${TAG_ORGANIZATION}`, async (_, { dispatch }) => {
  try {
    const response = await Api.get<Organization[]>('/structures/organizations');

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch organizations', dispatch);

    throw err;
  }
});

export const fetchOrganizationContainers = createAsyncThunk(
  `${sliceName}/${TAG_ORGANIZATION_CONTAINERS}`,
  async (_, { dispatch }) => {
    try {
      const response = await Api.get<OrganizationContainer[]>('/structures/organization-containers');

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch organization containers', dispatch);

      throw err;
    }
  },
);

export const fetchOrganizationDepartments = createAsyncThunk(
  `${sliceName}/${TAG_DEPARTMENT}`,
  async ({ organizationId }: GetDepartmentTypeParams, { dispatch }) => {
    try {
      if (!organizationId) {
        return [];
      }

      const response = await Api.get<Department[]>(`/structures/organizations/${organizationId}/departments`);

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch departments', dispatch);

      throw err;
    }
  },
);

export const fetchSites = createAsyncThunk(
  `${sliceName}/${TAG_SITES}`,
  async ({ projectTypeId, organizationId }: GetSitesTypeParams, { dispatch }) => {
    try {
      if (!organizationId) {
        return [];
      }

      const response = await Api.get<Site[]>(
        `/structures/organizations/${organizationId}/project-types/${projectTypeId}/sites`,
      );

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch sites', dispatch);

      throw err;
    }
  },
);

export const fetchProjectTypes = createAsyncThunk(
  `${sliceName}/${TAG_PROJECT_TYPE}`,
  async ({ organizationId }: GetProjectTypeParams, { dispatch }) => {
    try {
      if (!organizationId) {
        return [];
      }

      const response = await Api.get<ProjectType[]>(`/structures/organizations/${organizationId}`);

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch project types', dispatch);

      throw err;
    }
  },
);

export const fetchProjects = createAsyncThunk(
  `${sliceName}/${TAG_PROJECT}`,
  async ({ organizationId, projectTypeId }: GetProjectParams, { dispatch }) => {
    try {
      if (!organizationId) {
        return [];
      }

      const response = await Api.get<Project[]>(
        `/structures/organizations/${organizationId}/project-types/${projectTypeId}`,
      );

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch projects', dispatch);

      throw err;
    }
  },
);
export const fetchWorkstreams = createAsyncThunk(
  `${sliceName}/${TAG_WORKSTREAM}`,
  async ({ organizationId, projectTypeId, lessonOrigin }: GetWorkstreamParams, { dispatch }) => {
    try {
      if (!organizationId) {
        return [];
      }

      const response = await Api.get<Workstream[]>(
        `/structures/organizations/${organizationId}/project-types/${projectTypeId}/workstreams`,
        {
          lessonOrigin,
        },
      );

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch workstreams', dispatch);

      throw err;
    }
  },
);
export const fetchWorkstreamsByDepartment = createAsyncThunk(
  `${sliceName}/${TAG_WORKSTREAM}`,
  async ({ organizationId, departmentId, lessonOrigin }: GetWorkstreamParams, { dispatch }) => {
    try {
      if (!organizationId) {
        return [];
      }

      const response = await Api.get<Workstream[]>(
        `/structures/organizations/${organizationId}/project-types/${departmentId}/workstreams`,
        {
          lessonOrigin,
        },
      );

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch workstreams', dispatch);

      throw err;
    }
  },
);

export const fetchProcesses = createAsyncThunk(
  `${sliceName}/${TAG_PROCESS}`,
  async ({ organizationId, projectTypeId, workstreamId, lessonOrigin }: GetProcessParams, { dispatch }) => {
    if (!workstreamId) {
      return [];
    }

    try {
      const response = await Api.get<Process[]>(
        `/structures/organizations/${organizationId}/project-types/${projectTypeId}/workstreams/${workstreamId}`,
        {
          lessonOrigin,
        },
      );

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch processes', dispatch);

      throw err;
    }
  },
);

export const fetchProcessesByDepartment = createAsyncThunk(
  `${sliceName}/${TAG_PROCESS}`,
  async ({ organizationId, departmentId, workstreamId, lessonOrigin }: GetProcessParams, { dispatch }) => {
    if (!workstreamId) {
      return [];
    }

    try {
      const response = await Api.get<Process[]>(
        `/structures/organizations/${organizationId}/project-types/${departmentId}/workstreams/${workstreamId}`,
        {
          lessonOrigin,
        },
      );

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch processes', dispatch);

      throw err;
    }
  },
);

export const fetchPhases = createAsyncThunk(`${sliceName}/${TAG_PHASES}`, async (_, { dispatch }) => {
  try {
    const response = await Api.get<IOption[]>('/constants/tgphase-values');

    return response.data.map(({ label, value }) => ({ id: value, name: label }));
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch project phases', dispatch);

    throw err;
  }
});

export const fetchImprovementTypes = createAsyncThunk(
  `${sliceName}/${TAG_IMPROVEMENT_TYPES}`,
  async (_, { dispatch }) => {
    try {
      const response = await Api.get<string[]>('/constants/improvement-types');

      return response.data.map((improvementType) => ({
        label: dashSpacedToCapitalize(improvementType),
        value: improvementType,
      }));
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not fetch improvement types', dispatch);

      throw err;
    }
  },
);

export const clearWorkstreams = createAsyncThunk(`${sliceName}/${TAG_WORKSTREAM}`, async () => {
  return [] as Workstream[];
});

export const clearProcesses = createAsyncThunk(`${sliceName}/${TAG_PROCESS}`, async () => {
  return [] as Process[];
});

export const clearProjectTypes = createAsyncThunk(`${sliceName}/${TAG_PROJECT_TYPE}`, async () => {
  return [] as ProjectType[];
});

export const clearSites = createAsyncThunk(`${sliceName}/${TAG_SITES}`, async () => {
  return [] as Site[];
});

export const clearProjects = createAsyncThunk(`${sliceName}/${TAG_PROJECT}`, async () => {
  return [] as Project[];
});

export const clearDepartments = createAsyncThunk(`${sliceName}/${TAG_DEPARTMENT}`, async () => {
  return [] as Department[];
});

export const reducersMap = [
  { action: fetchImprovementTypes, name: TAG_IMPROVEMENT_TYPES },
  { action: fetchOrganizationContainers, name: TAG_ORGANIZATION_CONTAINERS },
  { action: fetchOrganizationDepartments, name: TAG_DEPARTMENT },
  { action: fetchOrganizations, name: TAG_ORGANIZATION },
  { action: fetchPhases, name: TAG_PHASES },
  { action: fetchProcesses, name: TAG_PROCESS },
  { action: fetchProjects, name: TAG_PROJECT },
  { action: fetchProjectTypes, name: TAG_PROJECT_TYPE },
  { action: fetchSites, name: TAG_SITES },
  { action: fetchWorkstreams, name: TAG_WORKSTREAM },
];

export const structureReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    reducersMap.forEach(({ action, name }) => {
      builder.addCase(action.pending, (state: DynamicState) => {
        state[name].isLoading = true;
      });

      builder.addCase(action.fulfilled, (state: DynamicState, { payload }) => {
        state[name].data = payload;
        state[name].isLoading = false;
      });

      builder.addCase(action.rejected, (state: DynamicState) => {
        state[name].isLoading = false;
      });
    });
  },
});

export const getOrganizations = (state: ReduxStoreType): IStructureItemState<Organization> =>
  state[sliceName][TAG_ORGANIZATION];
export const getOrganizationContainers = (state: ReduxStoreType): IStructureItemState<OrganizationContainer> =>
  state[sliceName][TAG_ORGANIZATION_CONTAINERS];
export const getDepartments = (state: ReduxStoreType): IStructureItemState<Department> =>
  state[sliceName][TAG_DEPARTMENT];
export const getProjectTypes = (state: ReduxStoreType): IStructureItemState<ProjectType> =>
  state[sliceName][TAG_PROJECT_TYPE];
export const getProjects = (state: ReduxStoreType): IStructureItemState<Project> => state[sliceName][TAG_PROJECT];
export const getWorkstreams = (state: ReduxStoreType): IStructureItemState<Workstream> =>
  state[sliceName][TAG_WORKSTREAM];
export const getProcesses = (state: ReduxStoreType): IStructureItemState<Process> => state[sliceName][TAG_PROCESS];
export const getSites = (state: ReduxStoreType): IStructureItemState<Site> => state[sliceName][TAG_SITES];
export const getPhases = (state: ReduxStoreType): IStructureItemState<Phase> => state[sliceName][TAG_PHASES];
export const getImprovementTypes = (state: ReduxStoreType): IStructureItemState<ImprovementType> =>
  state[sliceName][TAG_IMPROVEMENT_TYPES];

export default structureReducer.reducer;
