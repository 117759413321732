import React, { FC } from 'react';

import styles from '../lessonDetails.module.scss';

export interface IActionTakerPromptProps {
  newTaker?: string;
  previousTaker?: string;
  process?: string;
  workstream?: string;
}

export interface ITitleValue {
  title: string;
  value?: string;
}

const ActionTakerPrompt: FC<IActionTakerPromptProps> = ({
  newTaker = '',
  previousTaker = '',
  process = '',
  workstream = '',
}) => {
  const options: ITitleValue[] = [
    {
      title: 'Workstream / Organizational unit',
      value: workstream,
    },
    {
      title: 'Process / Organizational sub-unit',
      value: process,
    },

    {
      title: 'Previous Action Taker',
      value: previousTaker,
    },
    {
      title: 'New Action Taker',
      value: newTaker,
    },
  ];

  const renderValues = options.map(({ title, value }) => (
    <div className={styles['prompt-item']} key={`prompt-item-${title}`}>
      <div className={styles['prompt-item__headline']}>{title}</div>
      <div className={styles['prompt-item__value']}>{value || '----'}</div>
    </div>
  ));

  return (
    <div className={styles['taker-prompt']}>
      <div>
        <p className={styles['taker-prompt__headline']}>
          Please review the workstream & process before changing the action taker.
        </p>
      </div>

      <div className={styles['taker-prompt__info']}>{renderValues}</div>
    </div>
  );
};
export default ActionTakerPrompt;
