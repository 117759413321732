import { LESSON_STATUS } from '../../../consts/lessons';

export interface IFilterProps {
  actionTakerId: string;
  organizationId: string;
  states: string[];
}

export const initialFilters: IFilterProps = {
  actionTakerId: '',
  organizationId: '',
  states: [LESSON_STATUS.TO_DO, LESSON_STATUS.IN_PROGRESS],
};
