import { FC } from 'react';

import { ExpandableItem } from '../vfDesign/vfExpendable';
import styles from './authorComment.module.scss';

export interface AuthorCommentProps {
  comment: string;
  defaultExpanded?: boolean;
}

const AuthorComment: FC<AuthorCommentProps> = ({ comment, defaultExpanded = false }) => {
  return (
    <div className={styles['author-comment__container']}>
      <ExpandableItem
        title="Author comment"
        hideHint
        isEditable={true}
        usedItems="yes"
        showOverflow
        defaultExpanded={defaultExpanded}
      >
        <span>{comment || 'There is no author comment.'}</span>
      </ExpandableItem>
    </div>
  );
};

export default AuthorComment;
