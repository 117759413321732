import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getTags, getTagsLoading } from '../../store/tags';
import VfSpinner from '../../components/Spinner';
import BackToPage from '../../components/BackToPage';

import styles from './TagEditor.module.scss';

export const TagCategoryList: FC = () => {
  const tagCategories = useSelector(getTags);
  const isLoading = useSelector(getTagsLoading);

  if (isLoading) {
    return <div className="d-flex flex-fill justify-content-center align-items-center mb-2">
      <VfSpinner />
    </div>;
  }

  return <>
    <BackToPage />

    <div className="vf-container">
      <h1 className={styles.headline}>Create & Edit Tags</h1>
      <ul>
        {tagCategories.map(category => <li key={category.id}>
          <Link to={`/tags/${category.id}`}>{category.name}</Link>
        </li>)}
      </ul>
    </div>
  </>;
};
