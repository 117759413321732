import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Tag } from '../../../models/tags';

import { updateLessonTags } from '../../../store/lessonsDetails';
import { fetchTagsByOrganizationId, getTagsByOrganizationId } from '../../../store/tags';

import { TabIndex } from '..';
import { useAppDispatch } from '../../../root';
import DrawerFooter from '../../FlexDrawer/Footer';
import { VfButton, VfTagCloud } from '../../vfDesign';
import styles from '../lessonDetails.module.scss';

export interface TagsTabProps {
  lessonId: string;
  lessonTags?: Tag[];
  onClose: () => void;
  organizationId: string;
  readonly?: boolean;
  tabNameCallback: (tabName: TabIndex) => void;
}

const TagsTab: FC<TagsTabProps> = ({
  lessonId,
  lessonTags = [],
  onClose,
  organizationId,
  readonly = false,
  tabNameCallback,
}) => {
  const dispatch = useAppDispatch();
  const tags = useSelector(getTagsByOrganizationId)(organizationId);
  const [usedTags, setUsedTags] = useState<Tag[]>(lessonTags);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    tabNameCallback(TabIndex.TAGS);
  }, [tabNameCallback]);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchTagsByOrganizationId(organizationId));
    }
  }, [dispatch, organizationId]);

  const handleChange = (updatedTags: Tag[]) => {
    setUsedTags(updatedTags);
    setIsDirty(true);
  };

  const onTagsSubmit = () => {
    dispatch(updateLessonTags({ lessonId, tags: usedTags }));
    onClose();
  };

  return (
    <>
      <div className={styles['lesson-details']}>
        <VfTagCloud tags={tags} usedTags={usedTags} onTagsChange={handleChange} readonly={readonly} />
      </div>
      <DrawerFooter>
        <VfButton outline="secondary" onClick={onClose}>
          Cancel
        </VfButton>
        <VfButton type="primary" onClick={onTagsSubmit} disabled={!isDirty}>
          Submit
        </VfButton>
      </DrawerFooter>
    </>
  );
};

export default TagsTab;
