import { FC } from 'react';
import { createPortal } from 'react-dom';

import { usePortal } from '../../utils/hooks';

export interface PortalProps {
  id: string;
}

const Portal: FC<PortalProps> = ({ id, children }) => {
  const target = usePortal(id);

  return createPortal(children, target);
};

export default Portal;
