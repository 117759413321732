/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC } from 'react';

import classNames from 'classnames';

import './_vf-switch.scss';

export const TOGGLE_INPUT_ID = 'toggle-input-id';

interface VfSwitchProps {
  checked?: boolean;
  className?: string;
  name: string;
  onChange?: (name: string, checked: boolean) => void;
  onClick?: (name: string, checked: boolean) => void;
  small?: boolean;
  title?: string;
  disabled?: boolean;
}

const VfSwitch: FC<VfSwitchProps> = ({
  checked,
  className = '',
  disabled,
  name,
  onChange,
  onClick,
  small = false,
  title = '',
}) => {
  const onToggle = () => {
    if (disabled || !onChange) {
      return;
    }

    onChange(name, !checked);
  };

  const handleClick = () => {
    if (disabled || !onClick) {
      return;
    }

    onClick(name, !checked);
  };

  const switchClasses = classNames('vf-switch', {
    small,
  });

  const togglerClasses = classNames('vf-switch__toggler', {
    'vf-switch__toggler--disabled': disabled,
    'vf-switch__toggler--small': small,
  });

  return (
    <div className={`toggle-switch ${className}`}>
      <label className={switchClasses}>
        {onChange && <input type="checkbox" checked={checked} onChange={onToggle} disabled={disabled} />}
        {onClick && <input type="checkbox" onChange={handleClick} defaultChecked={checked} disabled={disabled} />}
        <span className={togglerClasses} id={TOGGLE_INPUT_ID} />
      </label>
      {title && <span className="toggle-switch__title">{title}</span>}
    </div>
  );
};

VfSwitch.defaultProps = {
  checked: false,
  className: '',
  disabled: false,
  small: false,
  title: '',
};

export default VfSwitch;
