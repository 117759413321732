import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';

import styles from '../BackToPage/BackToPage.module.scss';
import IconButton from '../IconButton';
import { VfButton } from '../vfDesign';

export interface BackToPageProps {
  buttonTitle?: string;
  link?: string;
  onButtonClick?: () => void;
  title?: string;
  withButton?: boolean;
}

const BackToPage: FC<BackToPageProps> = ({
  buttonTitle = '',
  link = '/home',
  onButtonClick = () => {},
  title = 'homepage',
  withButton = false,
}) => {
  const history = useHistory();
  const classes = classNames(styles.searchbar, 'vf-container--bleed');
  const backToHomePageTextClasses = classNames(styles.homepageText__inline);
  const headerClasses = classNames(styles.homepageHeader__inline);

  const handleBackToHomePageClick = () => {
    history.push(link);
  };

  return (
    <div className={classes}>
      <div className="vf-row mt-1">
        <div className="vf-col d-flex justify-content-between align-items-center" style={{paddingRight: 0, paddingLeft: 0}}>
          <div className={headerClasses} onClick={handleBackToHomePageClick}>
            <IconButton icon="arrow--left" />
            <p className={backToHomePageTextClasses}>Back to {title}</p>
          </div>
          {withButton && (
            <div className="mr-2">
              <VfButton type="primary" onClick={onButtonClick}>
                {buttonTitle}
              </VfButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BackToPage;
