import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';

import { InfoElement } from '../../../models/infoElement';

import { addInfoElement, getInfoElementByName, getInfoElements, removeInfoElement } from '../../../store/infoElement';

import Drawer from '../../../components/Drawer';
import Input from '../../../components/Forms/Input';
import { VfButton } from '../../../components/vfDesign';
import { useAppDispatch } from '../../../root';
import styles from './AddInfoElements.module.scss';

export interface InfoElementProps {
  name: string;
  open: boolean;
  // FIXME: create drawer context for sharing setOpen
  setOpen: (open: boolean) => void;
}

const validationSchema = object().shape({
  name: string().required('This field is required').max(255, 'Name can be 255 chars at most'),
  popupText: string().required('This field is required').max(255, 'Popup text can be 255 chars at most'),
});

const AddEdit: FC<InfoElementProps> = ({ name, open, setOpen }) => {
  const dispatch = useAppDispatch();
  const data: InfoElement = useSelector(getInfoElementByName)(name);
  const { isLoading } = useSelector(getInfoElements);

  const initialValues = {
    ...data,
  };

  const handleSubmit = async (values: typeof initialValues, { resetForm }: FormikHelpers<typeof initialValues>) => {
    dispatch(addInfoElement(values));

    if (name && values.name !== name) {
      dispatch(removeInfoElement(name));
    }

    resetForm();

    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, resetForm, errors }) => {
        const formHasErrors = !errors || Object.keys(errors).length > 0;
        const isDisabled = isSubmitting || isLoading || formHasErrors;

        return (
          <Drawer
            onClose={resetForm}
            open={open}
            rightSide
            setOpen={setOpen}
            title={`${name ? 'Edit' : 'Add'} info element`}
          >
            <Form className={styles['add-form']}>
              <Input className="mb-2" label="Element name" name="name" />

              <Input className="mb-2" label="Popup text" name="popupText" />

              <div className={styles.submit}>
                <VfButton
                  className={styles['submit__button']}
                  disabled={isDisabled}
                  fullWidth
                  htmlType="submit"
                  type="primary"
                >
                  Submit
                </VfButton>
              </div>
            </Form>
          </Drawer>
        );
      }}
    </Formik>
  );
};

AddEdit.defaultProps = {
  name: '',
};

export default AddEdit;
