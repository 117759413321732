import { useState, MouseEvent } from 'react';

import { TOGGLE_INPUT_ID } from '../vfSwitch';

export const IGNORED_DEFAULT_CELL: string[] = ['id', 'item'];
export const IGNORED_ONROWCLICK_CELL: string[] = [TOGGLE_INPUT_ID];

type UseActionMenuReturn = [boolean, (e: MouseEvent<HTMLDivElement>) => void, () => void];
export const useActionMenu = (): UseActionMenuReturn => {
  const [actionMenuVisible, setActionMenuVisible] = useState<boolean>(false);

  const toggleActionMenu = (e: MouseEvent<HTMLDivElement>) => {
    setActionMenuVisible(!actionMenuVisible);
    e.stopPropagation();
  };

  const closeActionMenu = () => {
    setActionMenuVisible(false);
  };

  return [actionMenuVisible, toggleActionMenu, closeActionMenu];
};
