import { FC, MouseEvent } from 'react';

import classNames from 'classnames';

import { IOption } from './models';
import './vf-dropdown.scss';

export interface IDropdownOptions {
  isShowFullText?: boolean;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  options: IOption[];
}

export const DropdownOptions: FC<IDropdownOptions> = ({ onClick, options, isShowFullText = false }) => {
  const labelStyles = classNames('vf-dropdown-option', {
    'vf-dropdown-option--FULL': isShowFullText,
  });

  const renderOptions = options.map(({ label, value }) => (
    <div key={`option-${value}-${label}`} className="vf-dropdown-list-item" onClick={onClick} id={`${value}`}>
      <label className={labelStyles}>{label}</label>
    </div>
  ));

  return <>{renderOptions}</>;
};

export default DropdownOptions;
