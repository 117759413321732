import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';

import withDelayedUnMounting from '../../utils/hoc/withDelayedUnmount';

import Portal from '../Portal';
import styles from './flexDrawer.module.scss';

export interface FlexDrawerProps {
  full?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onOutsideClick: () => void;
  side: string;
  wide?: boolean;
}

export const FlexDrawer: FC<FlexDrawerProps> = ({
  full = false,
  children,
  isOpen = false,
  onClose,
  onOutsideClick = () => {},
  side = 'left',
  wide = false,
}) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTouched(true);
    }
  }, [isOpen]);

  const drawerClasses = classNames(styles['drawer'], styles[`drawer--${side}`], {
    [styles['drawer--show']]: isOpen,
    [styles['drawer--full']]: full,
    [styles['drawer--wide']]: wide,
    [styles['drawer--touched']]: touched,
  });
  const overlayClasses = classNames(styles['drawer__overlay'], styles[`drawer__overlay--${side}`], {
    [styles['drawer__overlay--show']]: isOpen,
  });

  const onOverlayClose = () => {
    onOutsideClick();
    onClose();
  };

  return (
    <Portal id="drawer-root">
      <div className={overlayClasses} onClick={onOverlayClose} />
      <aside className={drawerClasses}>{children}</aside>
    </Portal>
  );
};

export default withDelayedUnMounting(FlexDrawer);
